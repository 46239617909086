import each from 'lodash/each';
import filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import some from 'lodash/some';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';

angular
  .module('app')
  .controller('MonitorController', function (
    ipCookie,
    $rootScope,
    $scope,
    $state,
    PreviewFactory,
    ArticlesFactory,
    ClientsFactory,
    CommonFactory,
    BriefingTypesFactory,
    ngToast,
    FavouritesFactory,
    AutoSaveFactory,
    ContentContainersFactory
  ) {
    $scope.showAutoSave = true;
    $scope.sortMethods = [
      { name: 'Favourite', field: 'favourite' },
      { name: 'Name', field: 'common_field' },
      { name: 'Sector', field: 'sector' },
      { name: 'Type', field: 'type' }
    ];
    $scope.sectors = [];
    $scope.clientGroups = [];
    var sortMethod = ipCookie('default_sort_method') || 'favourite';
    $scope.sortByModel = { method: sortMethod };
    $scope.feeds = [];

    AutoSaveFactory.getAllSavedForms().then(function (response) {
      $scope.urls = response.data;
    });

    $scope.titlerize = (s) =>
      s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    $scope.changeSortMethod = function () {
      ipCookie('default_sort_method', $scope.sortByModel.method, {
        expires: 60
      });
      reloadFeeds();
    };

    $scope.orderBySector = function (sector) {
      if (sector == 'briefings' || sector == 'true') return -2;
      if (sector == 'null' || sector == 'false') return -1;
      return sector;
    };

    $scope.openAllForms = function () {
      each($scope.urls, function (item, index) {
        AutoSaveFactory.verifyToken(item.token).then(function (response) {
          if (response.data.status) {
            window.open(item.url);
          } else {
            $scope.urls.splice(index, 1);
          }
        });
      });
    };

    $scope.destroyAllForms = function () {
      AutoSaveFactory.destroyAllForms();
      $scope.showAutoSave = false;
    };

    var reloadFeeds = function () {
      if (isEmpty($scope.feeds)) {
        FavouritesFactory.getMonitorItems().then(function (response) {
          $scope.feeds = response;
          $scope.feedsLength = response.length;
          var clients = filter($scope.feeds, function (i) {
            return i['type'] != 'briefings';
          });
          $scope.feedItems = filter($scope.feeds, { type: 'briefings' }).concat(
            clients
          );
          reloadGroups();
        });
      } else {
        reloadGroups();
      }
    };

    var reloadGroups = function () {
      var groupBy = $scope.sortByModel.method;

      $scope.groupWithStatus = {};

      var groups = _groupBy($scope.feedItems, function (fi) {
        return fi[groupBy];
      });

      each(groups, function (group, key) {
        $scope.groupWithStatus[key] = some(group, function (item) {
          return !item.notification_status;
        });
      });
      $scope.feedGroups = groups;
      $scope.feedGroupsKeys = keys(groups);
    };

    reloadFeeds();

    $scope.$on('monitor.reload', function () {
      reloadFeeds();
    });

    $scope.$on('monitor.toggleSingleElement', function (event, params) {
      if (params.group_id != 'true' && params.group_id != 'false') {
        var find_by = {};
        find_by[params.group_type] = params.group_id;
        var status = some(filter($scope.feedItems, find_by), function (item) {
          return !item.notification_status;
        });

        $scope.$broadcast('monitor.changeStatus', {
          group_id: params.group_id,
          status: status
        });
      }
      $scope.$broadcast('monitor.updateLiveAlertStatus', {
        client_id: params.client_id,
        email_notification_status: params.disabled
      });
      reloadGroups();
    });

    function toggleGroup(params) {
      each($scope.feedGroups[params.name], function (item) {
        item.notification_status =
          params.sector || params.alert ? params.status : !params.status;
        $scope.$broadcast('monitor.changeStatus', {
          group_id: 'monitor_toggle_' + item.id,
          status: params.sector || params.alert ? !params.status : params.status
        });

        if (!item.notification_status) {
          $scope.$broadcast('monitor.updateLiveAlertStatus', {
            client_id: item.id.toString(),
            email_notification_status: item.notification_status
          });
        }
      });
    }

    $scope.$on('monitor.toggleGroup', function (event, params) {
      toggleGroup(params);
      reloadFeeds();
    });

    $scope.destroyClient = function (client_id, clientName, e) {
      e.stopPropagation();

      CommonFactory.confirm({
        event: e,
        message:
          '<p>You are going to delete <strong>' +
          clientName +
          '</strong></p><p>Are you sure?</p>',
        confirmation: function () {
          ClientsFactory.destroyClient(client_id).then(function () {
            $scope.$emit('monitor.reload');
            ngToast.create('Client deleted');
          });
        }
      });
    };

    var toggleFavourites = function () {
      reloadGroups();
    };

    $scope.toggleFavoriteContentContainers = function (
      content_container,
      event
    ) {
      event.stopPropagation();

      ContentContainersFactory.toggleFavouriteContentContainer(
        content_container.id
      ).then(function () {
        content_container.favourite = !content_container.favourite;

        if (content_container.favourite) {
          toggleFavourites();
          ngToast.create('Content Container has been added to favourites');
          return;
        }
        toggleFavourites();
        ngToast.create('Content Container has been removed from favourites');
      });
    };

    $scope.editClient = function (e, client) {
      e.stopPropagation();
      $rootScope.$broadcast('monitor.editClient', client);
    };

    $scope.editBriefingType = function (e, briefingType) {
      e.stopPropagation();
      $scope.$broadcast('monitor.editBriefingType', briefingType);
    };

    $scope.editCompanyNote = function (e, company) {
      e.stopPropagation();
      $scope.$broadcast('monitor.editCompanyNoteType', company);
    };

    $scope.preview = function (strUrl, articleId) {
      PreviewFactory.preview(strUrl);
      ArticlesFactory.markAsRead(articleId);
    };

    $scope.showGroup = {
      true: true,
      false: true
    };

    $scope.toggleAccordionGroup = function (key) {
      $scope.showGroup[key] = !$scope.showGroup[key];
    };

    $scope.toggleSectorGroup = (name) => {
      $scope.sectors.forEach((sector) => {
        if (sector.name === name) {
          sector.show = !sector.show;
        }
      });
    };

    window.scope = $scope;
  });
