import map from 'lodash/map';
import without from 'lodash/without';
import reject from 'lodash/reject';


angular.module('app').controller('CoverageGroupController', function ($scope, $stateParams, $state, $controller, $http, Config, CommonFactory, ClientCoveragesFactory, ngToast) {

  $.extend(this, $controller('BaseListController', {$scope: $scope}));

  $scope.checkAllListItems = function(all_items) {
    if(!$scope.$parent.$parent.selectedListItems) {
      $scope.$parent.$parent.selectedListItems = [];
    }

    if(!$scope.$parent.$parent.selectedForExport) {
      $scope.$parent.$parent.selectedForExport = [];
    }

    $scope.$parent.$parent.selectedListItems = reject($scope.$parent.$parent.selectedListItems, (item) => {
      const itemIndex = $scope.selectedListItems.map(coverage => coverage.id).indexOf(item.id)
      return itemIndex != -1;
    });

    if($scope.itemsCheckList) {
      $scope.selectedListItems = [];
      reject(all_items, (item) => {
        $scope.selectedListItems.push(item);
        $scope.$parent.$parent.selectedForExport.push(item);
        $scope.$parent.$parent.selectedListItems.push(item);
        item.selected = true;
      });
    } else {
      $scope.selectedListItems = [];
      reject(all_items, (item) => {
        $scope.$parent.$parent.selectedForExport = $scope.$parent.$parent.selectedForExport.filter(({id}) => id !== item.id);
        item.selected = false;
      });
    }
  }
  $scope.printSentiment = sentiment =>
    sentiment == 'negative' ? 'Negative' :
      (sentiment == 'neutral' ? 'Neutral' :
        (sentiment == 'positive' ? 'Positive' :
          (sentiment == 'slightly_positive' ? 'Slightly positive' :
            (sentiment == 'slightly_negative' ? 'Slightly negative' : '-'))));

  $scope.selectListItem = function(event, coverage, coveragesCount) {
    if(!$scope.$parent.$parent.selectedListItems) {
      $scope.$parent.$parent.selectedListItems = [];
    }

    if(!$scope.$parent.$parent.selectedForExport) {
      $scope.$parent.$parent.selectedForExport = [];
    }

    if(coverage.selected) {
      $scope.$parent.$parent.selectedForExport.push(coverage)
      $scope.selectedListItems.push(coverage);
      $scope.$parent.$parent.selectedListItems.push(coverage);
      if($scope.selectedListItems.length === coveragesCount) {
        $scope.itemsCheckList = true;
      }
    } else {
      $scope.$parent.$parent.selectedForExport = reject($scope.$parent.$parent.selectedForExport, (item) => {
        return item == coverage;
      });
      $scope.selectedListItems = reject($scope.selectedListItems, (item) => {
        return item == coverage;
      });
      $scope.$parent.$parent.selectedListItems = reject($scope.$parent.$parent.selectedListItems, (item) => {
        return item == coverage;
      });
      $scope.itemsCheckList = false;
    }
  }

  $scope.sortableOptions = {
    disabled: ($state.current.name.indexOf('unpublished') == -1),
    stop: function(e, ui) {
      var originalPosition = ui.item.sortable.index;
      var position = ui.item.index();
      if ( position !== originalPosition && ui.item.sortable.droptargetModel != undefined ) {
        const startPosition = Math.min(...ui.item.sortable.droptargetModel.map(({ position }) => position));
        var prevItem = ui.item.sortable.droptargetModel[originalPosition > position ? position + 1 : position - 1];
        $http.post(
          Config.shibiUrl + '/content_publications/' + ui.item.sortable.model.id + '/reorder.json',
          {
            position: startPosition + position,
            global_position: prevItem.global_position,
            replace: prevItem.id
          }
        );
      }
    },
    axis: 'y'
  };

  $scope.changeCoverageOrder = function (groupCoverages, coverageId, idx, placement) {
    let position = 0;
    if (placement == 'bottom') {
      position = groupCoverages.length - 1;
    }

    if ($scope.$parent && $scope.$parent.$parent && $scope.$parent.$parent.coverageGroups) {
      $scope.$parent.$parent.coverageGroups[0].coverages = $scope.move($scope.$parent.$parent.coverageGroups[0].coverages, idx, position);
    }
    const startPosition = Math.min(...$scope.$parent.$parent.coverageGroups[0].coverages.map(({ position }) => position));

    $http.post(
      Config.shibiUrl + '/content_publications/' + coverageId + '/reorder.json',
      {
        position: startPosition + position,
        replace: groupCoverages[position].id
      }
    );
  };

  $scope.publishSelectedCoverages = function (event, coveragesSortArray) {
    var title = "Publishing Selected Coverages"
    var message = "<p>You are about to publish selected coverages. They will become visible online.</p>" +
        "<p>NOTE: If these coverages are related to coverages in other client apps (grouped coverages), coverages for other client apps will not be published.</p>" +
        "<p>Are you sure?</p>"
    publish(event, coveragesSortArray, message, title, false)
  };


  $scope.publishSelectedWithRelatedCoverages = function (event, coveragesSortArray) {
    var title = "Publishing Selected Coverages with all related coverages"
    var message = "<p>You are about to publish selected coverages. They will become visible online.</p>" +
        "<p>NOTE: If these coverages are related to coverages in other client apps (grouped coverages), coverages for other client apps will be published.</p>" +
        "<p>Are you sure?</p>"
    publish(event, coveragesSortArray, message, title, true)
  };

  $scope.exportSelected = async function () {
    if($scope.$parent.selectedForExport && $scope.$parent.selectedForExport.length > 0) {
      const response = await $http.post(
        Config.shibiUrl + '/content_publications/export.json',
        {
          selectedForExportIds: $scope.$parent.selectedForExport.map(coverage => coverage.id),
        }
      );
      if(response.status === 200) {
        ngToast.create("Client coverages you requested will be provided to you via email.");
      }
    }
  };

  var publish = function(event, coveragesSortArray, message, title, publisheRelated) {
    $scope.coverageIdsSortArray = map(coveragesSortArray, 'id')
    CommonFactory.confirm({
      event: event,
      title: title,
      message: message,
      confirmation: function() {
        var coverageIds = map($scope.selectedListItems, "id");
        var result = [];
        for(var i = 0; i < $scope.coverageIdsSortArray.length; i++) {
          var index = coverageIds.indexOf($scope.coverageIdsSortArray[i])
          if(index >= 0) {
            result.push(coverageIds[index]);
          }
        }

        ClientCoveragesFactory.publishCoverages(result, publisheRelated).then(function() {
          $state.go($state.current, {}, { reload: true });
        });
      }
    });
  }

});
