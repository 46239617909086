import isEmpty from 'lodash/isEmpty';

angular.module('app').service('NavAlertService', function (NavAlertFactory, ipCookie) {
  var getAlerts = function() {
    const buildTimestampCookie = ipCookie('build_timestamp');
    var hiddenAlerts = JSON.parse(localStorage.getItem("hiddenAlerts"));
    let restart = false;

    if (hiddenAlerts && hiddenAlerts.length > 40) {
      hiddenAlerts = hiddenAlerts.slice(20);
      localStorage.setItem("hiddenAlerts", JSON.stringify(hiddenAlerts));
    }

    return NavAlertFactory.fetchAlerts().then(function(res) {
      let alerts = [];
      const majorBuildVersionTS = res.build_timestamp;
      const majorLocalVersionTS = ipCookie('major_local_version_timestamp');
      const reloadPageAtTS = ipCookie("reload_page_at_timestamp");
      const now = Date.now();
      if (majorBuildVersionTS && (majorBuildVersionTS != ipCookie("major_build_version_timestamp"))) {
        ipCookie("major_build_version_timestamp", majorBuildVersionTS, { expires: 30 });
      }
      if (majorBuildVersionTS && (!majorLocalVersionTS || (majorLocalVersionTS && (majorBuildVersionTS > majorLocalVersionTS)))) {
        if (reloadPageAtTS > 0) {
          if (now > reloadPageAtTS) {
            restart = true;
          }
        } else {
          const timeToReload = Date.now() + 30*60*1000;
          ipCookie("reload_page_at_timestamp", timeToReload, { expires: 30 });
        }
        const reloadMsg = { description: `There is a new Tool release available. Please reload the page now or it will reload automatically after ${moment(ipCookie("reload_page_at_timestamp")).format('LT')}.`, severity: 'notification', reload: true };
        alerts.push(reloadMsg);
      }
      if (res.tool_messages.length) {
        if (hiddenAlerts != null) {
          var msgs = res.tool_messages.filter(function(msg) {
            var pos = hiddenAlerts.indexOf(msg.id);
            if (pos === -1) {
              return msg;
            }
          });
          if (msgs.length === 0) {
            return {
              restart: restart,
              showAlert: !isEmpty(alerts),
              alerts
            };
          }
          return {
            restart: restart,
            showAlert: true,
            alerts: msgs.concat(alerts)
          };
        }
        return {
          restart: restart,
          showAlert: true,
          alerts: res.tool_messages.concat(alerts)
        };
      } else {
        return {
          restart: restart,
          showAlert: !isEmpty(alerts),
          alerts
        };
      }
    });
  }
  return {
    getAlerts: getAlerts
  }
});
