import styled from 'styled-components';

const TextField = styled.input`
  border-radius: 10px !important;
  padding: 5px 15px;
  width: 100%;
  height: 47px;
  border: none;
  :focus {
    outline: none;
  }
`;

export default TextField;
