angular.module('app').controller('FirstClientController', function($scope, $state, ClientSectionsFactory, ClientsFactory){

  $scope.showSidebar = true;

  $scope.toggleSidebar = function () {
    $scope.showSidebar = !$scope.showSidebar;
  };

  if($state.current.name === 'client.unpublished') {
    ClientSectionsFactory.countUnpublishedSections($state.params.clientId).then(function (response) {
      if(response.unpublished_count === 0) {
        if(response.published_count === 0) {
          // $scope.clientName = response.client.name;
          $scope.clientId = $state.params.clientId;
          $state.go("client.no_results", {}, {location: 'replace'});
        } else {
          $state.go("client.published", { clientId: $state.params.clientId, sectionId: 'all', page: 1 }, { location: 'replace', reload: true });
        }
      } else {
        if($state.params.clientId === 'all') {
          setPublishedUnpublishedClients();
        } else {
          setPublishedUnpublishedSections();
        }
      }
    });
  } else {
    if($state.params.clientId === 'all') {
      setPublishedUnpublishedClients();
    } else {
      setPublishedUnpublishedSections();
    }
  }

  function setPublishedUnpublishedSections() {
    ClientSectionsFactory.getPublishedAndUnpublishedSections($state.params.clientId).then(function(response) {
      // $scope.clientName = response.client.name;
      $scope.clientId = $state.params.clientId;
      $scope.publishedSections = response.published;
      $scope.unpublishedSections = response.unpublished;
      $scope.state = $state;
    });
  }

  function setPublishedUnpublishedClients() {
    $scope.clientName = 'All Clients';
    ClientsFactory.getClientsWithPublishedAndUnpublishedCoverages().then(function(response) {
      $scope.clientsWithPublished = response.published;
      $scope.clientsWithUnpublished = response.unpublished;
    });
  }

});
