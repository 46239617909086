import { observable, action } from "mobx";
import { todosSocket } from '@inbox/_app/socket';

class TodoStore {
  @observable todoList = [];
  @observable uncompletedTodos = 0;
  @observable isLoading = false;

  constructor(todosSocket) {
    this.socket = todosSocket;
    this.socket.on('todos', this.allTodos);
  }

  @action addTodo = (title) => {
    if (title.trim()) {
      const newTodo = {
        title,
        completed: false,
        starred: false,
      };
      this.socket.addTodo(newTodo);
    }
  };

  @action editTodo = (todo, text) => {
    if (!(text === null) && text.trim()) {
      todo.title = text;
      this.socket.updateTodo(todo);
    }
  };

  @action markComplete = (todo) => {
    todo.completed = !todo.completed;
    this.socket.updateTodo(todo);
  };

  @action markStar = (todo) => {
    todo.starred = !todo.starred;
    this.socket.updateTodo(todo);
  };

  @action delTodo = (todo) => this.socket.deleteTodo(todo);

  @action deleteAll = () => this.socket.deleteAllTodos();

  @action clearCompleted = () => this.socket.clearCompleted();

  @action allTodos = (todos) => {
    this.todoList = todos
    this.uncompletedTodos = todos.filter(todo => !todo.completed).length
    this.isLoading = false;
  }

  @action moveTodo = (oldPosition, newPosition) => {
    if(oldPosition !== newPosition) {
      this.isLoading = true;
      this.todoList = [];
      this.socket.moveTodo({oldPosition, newPosition})
    }
  }

}

export default new TodoStore(todosSocket);