angular.module('app').controller('MonitorSearchPhraseListController', function($scope, SearchPhrasesFactory, ngToast) {

  var reloadSearchPhrases = function () {
    SearchPhrasesFactory.getSearchPhrasesForClient($scope.item.id).then( function (search_phrases) {
      $scope.search_phrases = search_phrases;
      $scope.$emit('dataLoaded', 'client_' + $scope.item.id);
    });
  };

  reloadSearchPhrases();

  $scope.$on('search_phrases.reload', function (event, data) {
    reloadSearchPhrases();
  });

  $scope.editSearchPhrase = function (searchPhrase, e) {
    e.stopPropagation();
    $scope.$broadcast('monitor.editPhrase', searchPhrase);
  };

});
