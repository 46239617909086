import { action, observable } from "mobx";
import find from "lodash/find";
import Cookies from "js-cookie";
import api from "../_app/api";
import { API_ROUTES } from "../_app/routes";

class SettingsStore {
  @observable notificationStatus = false;
  @observable firstRequest = true;
  @observable inProgress = false;
  @observable analyst = {};
  @observable
  avatar = {
    isLoading: false,
    error: false,
    avatar: null,
    fileName: "",
    wasEdited: false,
  };

  @action
  getNotificationStatus = async () => {
    if (!this.firstRequest) {
      return;
    }
    this.firstRequest = false;
    try {
      const {
        data: { client_notification_status: status },
      } = await api.get(API_ROUTES.notificationStatus);
      this.notificationStatus = status;
    } catch (e) {
      console.error(e);
    }
  };

  @action
  updateNotificationStatus = async () => {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    try {
      await api.patch(API_ROUTES.updateNotificationStatus, {
        status: !this.notificationStatus,
      });
      this.notificationStatus = !this.notificationStatus;
      this.inProgress = false;
    } catch (e) {
      this.inProgress = false;
    }
  };

  @action
  postAvatar = async () => {
    this.avatar.isLoading = true;
    try {
      await api.post(API_ROUTES.updateAvatar(this.analyst.id), {
        orig_filename: this.avatar.fileName,
        file: this.avatar.avatar.split("base64")[1],
      });
      this.avatar.error = "";
      this.avatar.wasEdited = false;
    } catch (e) {
      this.avatar.error =
        "We could not save your avatar. Please check your file size. Please note that a filename cannot have white spaces nor special characters.";
    } finally {
      this.avatar.isLoading = false;
    }
  };
  @action
  fetchAvatar = async () => {
    if (!this.avatar.avatar) {
      const userEmail = Cookies.get("analyst_email");
      const {
        data: { data },
      } = await api(API_ROUTES.analysts);
      this.analyst = find(data, (analyst) => analyst.email === userEmail);
      this.avatar.avatar = this.analyst.avatar_url;
    }
  };

  @action
  onAvatarChange = async (img, fileName) => {
    this.avatar.avatar = img;
    this.avatar.fileName = fileName;
    this.avatar.wasEdited = true;
    this.avatar.error = "";
  };
}

export default new SettingsStore();
