import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import InfiniteScroll from 'react-infinite-scroller';
import isNull from 'lodash/isNull';
import Loader from '@inbox/_common/components/Loader';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import AlertListItem from './AlertListItem';

const ScrollWrapper = styled(Box)`
  height: calc(100vh - 220px);
  overflow: auto;
`;

@observer
class AlertList extends Component {
  render() {
    const {
      isLoading,
      setCurrentAlert,
      error,
      fetchAlerts,
      deleteAlert,
      validAlerts,
      toggleForwardForm,
      flagAlert,
      hasMoreAlerts,
      isTrash,
      currentAlert,
      toggleUnread,
      toggleSelected,
    } = this.props.store;

    return (
      <ScrollWrapper>
        {error && (
          <Box mt={20} className="alert alert-danger">
            {error}
          </Box>
        )}
        {isEmpty(validAlerts)
          && !isLoading
          && !error && (
          <Text my={20} fontSize={25}>
              The list is empty.
          </Text>
        )}
        {!isNull(isTrash) && (
          <InfiniteScroll
            pageStart={1}
            loadMore={fetchAlerts}
            hasMore={hasMoreAlerts}
            useWindow={false}
            loader={(
              <Box key={0} my={40}>
                <Loader />
              </Box>
            )}
          >
            {validAlerts.map((item, i) => (
              <AlertListItem
                toggleUnread={isTrash ? null : toggleUnread}
                flagAlert={flagAlert}
                toggleForwardForm={toggleForwardForm}
                deleteAlert={isTrash ? null : deleteAlert}
                key={`alertListItem${i}`}
                alert={item}
                setCurrentAlert={setCurrentAlert}
                currentAlertId={currentAlert._id}
                toggleSelected={toggleSelected}
              />
            ))}
          </InfiniteScroll>
        )}
      </ScrollWrapper>
    );
  }
}

AlertList.propTypes = {
  store: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    setCurrentAlert: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchAlerts: PropTypes.func.isRequired,
    deleteAlert: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
    validAlerts: PropTypes.array.isRequired,
    toggleForwardForm: PropTypes.func,
    flagAlert: PropTypes.func.isRequired,
    hasMoreAlerts: PropTypes.bool.isRequired,
  }).isRequired,
};

export default AlertList;
