import React, { Component } from 'react';

import { Provider } from 'mobx-react';
import * as stores from '../../stores';
import ContentEditor from './ContentEditor';
import CoverageEditor from './CoverageEditor';
import SummaryEditor from './SummaryEditor';
import EditorFooter from './EditorFooter';
import RelatedCoverages from './RelatedCoverages';

export default class ContentCreator extends Component {
  render() {
    return (
      <Provider {...stores}>
        <div style={{ width: '100%', marginBottom: '300px' }}>
          <ContentEditor
            contentId={this.props.contentId}
            articleId={this.props.articleId}
            token={this.props.token}
            type={this.props.type}
          />
          <SummaryEditor />
          <CoverageEditor />
          <RelatedCoverages />
          <EditorFooter action={this.props.action} />
        </div>
      </Provider>
    );
  }
}
