var app = angular.module('app');

app.controller("BriefingListController", function ($scope, $state, $http, Config, openedBriefings, closedBriefings) {

    $scope.state = $state;
    $scope.opened_briefings = openedBriefings.data.briefings;
    $scope.closed_briefings = closedBriefings.data.briefings;
    $scope.total_pages = closedBriefings.data.meta.total_pages;
    $scope.page = 1;

    $scope.loadMore = function (page) {
      $http.get(Config.shibiUrl + '/content_containers/closed.json', {params: { page: page }}).
        then(function (response) {
          $scope.closed_briefings = $scope.closed_briefings.concat(response.data.briefings);
          $scope.total_pages = response.data.meta.total_pages;
          $scope.page = page;
        });
    };

});
