import React, { useState } from "react";
import { Provider } from "mobx-react";
import * as stores from "../../stores";
import TodoList from "./Components/TodoList";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import DeleteConfirm from "./Components/DeleteConfirm";
import DeleteOptions from "./Components/DeleteOptions";
import AddTodo from "./Components/AddTodo";

const TodoContainer = ( ) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOptionsOpen, setDeleteOptionsOpen] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);

  return (
    <Provider {...stores}>
      <div style={{ width: '100%', marginBottom: '300px' }}>
        <AppBar style={{ background: '#74009D' }}>
          <Toolbar>
            <Grid container>
              <Grid item sm={6} style={{ margin: "auto"}}>
                <Typography variant="h6">Tool to-do list</Typography>
              </Grid>
              <Grid item sm={6} style={{ textAlign: "right"}}>
                <IconButton onClick={() => setDeleteOptionsOpen(true)}>
                  <DeleteIcon style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div style={{ width: '100%', marginBottom: '300px' }}>
          <AddTodo addTodo={stores.todoStore.addTodo}/>
          <TodoList />
        </div>
        <DeleteOptions
          deleteAll={() => {
            setDeleteAll(true);
            setDeleteOpen(true)
            setDeleteOptionsOpen(false)
          }}
          clearCompleted={() => {
            setDeleteAll(false);
            setDeleteOpen(true)
            setDeleteOptionsOpen(false)
          }}
          open={deleteOptionsOpen}
          close={() => setDeleteOptionsOpen(false)}
        />
        <DeleteConfirm
          deleteAll={deleteAll}
          yes={() => {
            setDeleteOpen(false);
            if(deleteAll) {
              stores.todoStore.deleteAll()
            } else {
              stores.todoStore.clearCompleted()
            }
          }}
          open={deleteOpen}
          close={() => setDeleteOpen(false)}
        />
      </div>
    </Provider>
  );
}

export default TodoContainer;