import React from 'react';
import { Box, Text } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  background: red;
  padding: 5px 20px;
  width: 100%;
  color: white;
  border-radius: 9px;
  margin: 15px 0px;
`;

const Embargoed = ({ embargoedUntil, ...props }) => (
  <Wrapper {...props}>
    <Text fontSize={16}>
      {'EMBARGOED'}
      {' '}
      {embargoedUntil !== null ? `- ${embargoedUntil}` : ''}
    </Text>
  </Wrapper>
);

export default Embargoed;
