import React, { Component } from 'react';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function getButtonStyles(active, color) {
  const classes = makeStyles({
    outlined: {
      border: `1px solid ${color}`
    }
  });
  const styles = {
    color: active !== null ? (active ? "white" : "#505a6b") : "#1e88e5",
    borderColor: active !== null ? "auto" : "#adadad",
    backgroundColor: active !== null ? (active ? color : "transparent") : "transparent",
    fontSize: '9px',
    borderRadius: '50px',
    marginTop: '5px',
    textTransform: 'none',
    marginRight: '10px',
    height: '20px',
    minWidth: '80px'
  };

  return { classes: classes(), styles };
}

const styles = {
  error: {
    backgroundColor: '#ad0a0a',
    color: 'white',
    height: '20px',
    padding: '2px 8px',
    fontSize: '10px'
  }
};

@inject('contentStore')
@observer
class SentimentButton extends Component {
  render() {
    const { value, section } = this.props;
    const active = section.sentiment && section.sentiment === value;
    return (
      <InnerButton
        setSentiment={this.props.contentStore.setSentiment}
        active={active}
        {...this.props}
      />
    );
  }
}

@inject('contentStore')
@observer
class MentionedButton extends Component {
  render() {
    const { active, onClick } = this.props;
    const label = active !== null ? (active ? 'YES' : 'NO') : '?';
    return (
      <span>
        <span style={{ fontSize: '11px', paddingRight: '5px', verticalAlign: '-20%' }}>{ `${this.props.title}:` }</span>
        <InnerButton
          setSentiment={onClick}
          sentiment={label}
          active={active}
          color="#34034B"
          style={{ minWidth: '40px' }}
          {...this.props}
        />
      </span>
    );
  }
}

function InnerButton(props) {
  const { section, value, sentiment, color, setSentiment, active, style: pstyles } = props;
  const { classes, styles } = getButtonStyles(active, color);

  return (
    <Button
      classes={{ outlined: classes.outlined }}
      onClick={() => setSentiment(section, value)}
      variant="outlined"
      style={{...styles, ...pstyles}}
      size="small"
    >
      {sentiment}
    </Button>
  );
}

@inject('contentStore')
@observer
class Row extends Component {
  render() {
    const { row } = this.props;

    return (
      <React.Fragment>
        <TableRow>
          <TableCell
            component="th"
            scope="row"
            style={{ fontSize: '11px', padding: '3px 0 0 10px', width: '10px' }}
            colSpan={4}
          >
            {row.name}
          </TableCell>
          <TableCell
            style={{ padding: '3px 0 7px 10px', textAlign: 'right' }}
            colSpan={8}
          >
            <SentimentButton
              color={'#07Bf24'}
              section={row}
              value={'positive'}
              sentiment={'Positive'}
            />
            <SentimentButton
              color={'#67C575'}
              section={row}
              value={'slightly_positive'}
              sentiment={'Slightly positive'}
            />
            <SentimentButton
              color={'#Fb8c00'}
              section={row}
              value={'neutral'}
              sentiment={'Neutral'}
            />
            <SentimentButton
              color={'#ec8d70'}
              section={row}
              value={'slightly_negative'}
              sentiment={'Slightly negative'}
            />
            <SentimentButton
              color={'#e64a19'}
              section={row}
              value={'negative'}
              sentiment={'Negative'}
            />

            <label style={styles.label}>
              <MentionedButton
                title="Spokesperson"
                section={row}
                value={"spokesperson_mention"}
                active={row.spokesperson_mention}
                onClick={this.props.contentStore.setSpokespersonMention}
              />
              <MentionedButton
                title="Headline"
                section={row}
                value={"headline_mention"}
                active={row.headline_mention}
                onClick={this.props.contentStore.setHeadlineMention}
              />
            </label>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

@inject('contentStore')
@observer
export default class SentimentsTable extends Component {
  state = {
    expanded: true
  };
  render() {
    const expandSentiments = () => {
      const newValue = !this.state.expanded;
      this.setState({ expanded: newValue });
    };

    return (
      <TableContainer style={{ maxHeight: '400px' }}>
        {this.props.contentStore.validationErrors.sentiments && (
          <div style={styles.error}>
            {this.props.contentStore.validationErrors.sentiments}
          </div>
        )}
        <Table stickyHeader>
          {this.props.contentStore.sentimentedSections.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: 'white',
                    color: '#74009d',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    textAlign: 'left',
                    paddingLeft: '10px',
                    paddingTop: '3px',
                    paddingBottom: '6px'
                  }}
                  colSpan={5}
                >
                  Analytics
                </TableCell>
                <TableCell
                  colSpan={1}
                  style={{
                    backgroundColor: 'white',
                    textAlign: 'right',
                    fontSize: '11px',
                    padding: '3px 0 0 00px'
                  }}
                >
                  <IconButton
                    style={{ paddingTop: '3px', paddingBottom: '6px' }}
                    aria-label="expand row"
                    onClick={() => expandSentiments()}
                  >
                    {this.state.expanded ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {this.state.expanded &&
              this.props.contentStore.sentimentedSections.map((section) => (
                <Row key={section.id} row={section} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
