import React from "react";

export default function CountSection(props) {
  const { summariesCount, coverageCount } = props;
  const styles = {
    root: {
      display: "flex",
      width: 300,
      flexDirection: "row-reverse",
    },
    counter: {
      width: "85px",
      display: "inline-block",
      fontSize: ".625rem",
      fontWeight: "700",
      textAlign: "center",
      color: "rgba(116,0,157,.2)",
    },
  };

  return (
    <div style={styles.root}>
      {coverageCount !== 0 ? (
        <span style={styles.counter}>Coverages: {coverageCount}</span>
      ) : (
        <span style={styles.counter}>No coverage</span>
      )}
      {summariesCount !== 0 ? (
        <span style={styles.counter}>Summaries: {summariesCount}</span>
      ) : (
        <span style={styles.counter}>No summaries</span>
      )}
    </div>
  );
}
