angular.module("app").directive('publishCoveragePopup', ['$http', 'Config', '$stateParams', 'ClientCoveragesFactory', function($http, Config, $stateParams, ClientCoveragesFactory) {
  return {
    restrict: 'AE', //attribute or element
    template: require("./publish_coverage_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.popupOpened = false;

      var allCoveragesIds, unpublishedCoveragesIds;

      var close = function() {
        $scope.popupOpened = false;
        angular.element('body').removeClass('popup-opened');
      };

      $scope.open = function() {
        angular.element('body').addClass('popup-opened');
        $scope.popupOpened = true;
        $scope.hideFirstPopup = $scope.publishOne = $scope.publishAll = false;
        ClientCoveragesFactory.getRelatedCoveragesData($scope.coverage.id).
          then( function(data) {
            $scope.unpublishedCoveragesCount = data.unpublished_coverages_count;
            $scope.clientNameListOrCount = data.all_coverages_count > 5 ? data.all_coverages_count : data.client_names;
            $scope.allCoveragesCount = data.all_coverages_count;
            // unpublishedCoveragesIds = data.unpublished_coverages_ids;
            // allCoveragesIds = data.all_coverages_ids;
            $scope.uniqClientCount = data.uniq_client_count;
          });
      };

      $scope.close = function(event) {
        close();
        $scope.$parent.submit(event, true);
      };

      $scope.cancel = function() {
        close();
      };

      $scope.clickPublishOnePopup = function() {
        $scope.hideFirstPopup = true;
        $scope.publishOne = true;
      };

      $scope.clickPublishAllPopup = function() {
        $scope.hideFirstPopup = true;
        $scope.publishAll = true;
      };

      $scope.clickPublishOne = function(event) {
        $scope.publishCoverage(event, $scope.coverage, false)
      };

      $scope.clickPublishAll = function() {
        $scope.publishCoverage(event, $scope.coverage, true)
      };
    }
  }
}]);
