import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import withStyles from "@material-ui/core/styles/withStyles";

const ShrinkedDatePicker = withStyles({
  root: {
    width: "135px",
    marginBottom: "13px"
  },
})(KeyboardDatePicker);
export default function DatePickers(props) {
  const { toDate, setToDate, fromDate, setFromDate, getDateHyphenFormat } = props;

  const handleDateChange = (date, handler) => {
    handler(getDateHyphenFormat(date));
  };

  const handleValue = (date) => {
    if (date === undefined) {
      return undefined;
    }
    const dateArr = getDateHyphenFormat(date).split("-");
    return new Date(dateArr[2], parseInt(dateArr[1], 10) - 1, dateArr[0]);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>From:</>
      <ShrinkedDatePicker
        disableToolbar
        variant="inline"
        format="dd-MM-yyyy"
        margin="normal"
        id="date-picker-inline"
        value={handleValue(fromDate)}
        maxDate={handleValue(toDate)}
        onChange={(e) => handleDateChange(e, setFromDate)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <>To:</>
      <ShrinkedDatePicker
        disableToolbar
        variant="inline"
        format="dd-MM-yyyy"
        margin="normal"
        id="date-picker-inline"
        value={handleValue(toDate)}
        minDate={handleValue(fromDate)}
        onChange={(e) => handleDateChange(e, setToDate)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
