import React from 'react';
import { Button as RebassButton } from 'rebass';
import styled from 'styled-components';

const Btn = styled(RebassButton)`
  ${props => props.active
    && `
    background-color: #4A90E2;
    color: #fff;
  `};
  cursor: pointer;
`;

const Button = ({ children, ...props }) => <Btn {...props}>{children}</Btn>;

Button.defaultProps = {
  bg: '#fff',
  color: '#000',
  px: 20,
  py: 15,
  borderRadius: 10,
  fontSize: 12,
};

export default Button;
