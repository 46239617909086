import React  from "react";
import { inject, observer } from "mobx-react";
import Todo from "./Todo";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";
import DraggableTodo from "./DraggableTodo";

const TodoList = ({ todoStore }) => {
  const { todoList, markComplete, delTodo, editTodo, markStar, moveTodo, isLoading } = todoStore;

  const onDragEnd = (x) => {
    if (!x.destination) return console.log(x);
    moveTodo(x.source.index, x.destination.index);
  };
  console.log(isLoading)

  return (
    <>
      {isLoading && (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: 20 }}>
          <CircularProgress />
        </Container>)}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="0">
          {(p) => (
            <div {...p.droppableProps} ref={p.innerRef}>
              {todoList.filter(todo => todo.completed === false).map((todo) => {
                return <DraggableTodo
                  index={todo.position}
                  todo={todo}
                  key={todo._id}
                  markComplete={markComplete}
                  delTodo={delTodo}
                  editTodo={editTodo}
                  markStar={markStar}
                />
              })}
              {p.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: 20 }}>
        <div style={{ border: "solid 1px lightgrey", marginTop: 30 }} />
      </Container>
      {todoList.filter(todo => todo.completed === true).map((todo) => {
        return <Todo
          index={todo.position}
          todo={todo}
          key={todo._id}
          markComplete={markComplete}
          delTodo={delTodo}
          editTodo={editTodo}
          markStar={markStar}
        />
      })}
    </>
  );
}

export default inject('todoStore')(observer(TodoList));