angular.module("app").
  directive('toggleLiveAlerts', ['ClientNotificationFactory', '$rootScope', function(ClientNotificationFactory, $rootScope) {
    return {
      restrict: 'AE',
      template: require("./toggle_live_alerts.html.haml"),
      link: function($scope, elem, attr, ctrl) {
        $scope.analyst_notification_live_alerts_toggle_id = attr.toggleId;

        $scope.onLoad = function() {
          $scope.liveAlertStatusDisabled = !$scope.$parent.item.live_alerts_status;
          $scope.liveAlertDisableToggle = !$scope.$parent.item.notification_status;
        }
        $scope.changeLiveAlert = function($event) {
          if(!$scope.$parent.item.notification_status) {
            $event.stopPropagation();
            $event.preventDefault();
            return
          }
          $event.stopPropagation();
          ClientNotificationFactory.updateClientNotificationStatus($scope.$parent.item.id, !$scope.disabled, !$scope.$parent.item.live_alerts_status ).then(() => {
            $scope.$parent.item.live_alerts_status = !$scope.$parent.item.live_alerts_status;
            $scope.liveAlertStatusDisabled = !$scope.$parent.item.live_alerts_status;
            $scope.$emit('monitor.reload');
          })
          $event.preventDefault();
        };

        $scope.$on('monitor.updateLiveAlertStatus', function(event, params) {
          if(params.client_id === attr.clientId) {
            if(!params.email_notification_status) {
              $scope.liveAlertStatusDisabled = true
              $scope.liveAlertDisableToggle = true
              $scope.$emit('monitor.reload');
            }
          }
        });
        $scope.onLoad();
      }
    };
}]);
