angular.module('app').factory("ArticleSourcesFactory", function ($http, Config) {

  const getArticleSources = function () {
    return $http.get(Config.shibiUrl + '/article_sources.json').then(function (response) {
      return response.data;
    });
  };

  const getArticleSourceTypes = function () {
    return $http.get(Config.shibiUrl + '/article_source_types.json').
      then(function (response) {
        return response.data.article_source_types;
      });
  };

  const getArticleSource = function (article_source_id) {
    return $http.get(Config.shibiUrl + '/article_sources/' + article_source_id + '.json').
      then(function (response) {
        return response.data.article_source;
      });
  };

  const getArticleSourceDetails = (article_source_id) => {
    return $http
      .get(`${Config.shibiUrl}/article_sources/details/${article_source_id}`)
      .then((response) => response.data.article_source);
  };

  const getBriefingTypeArticleSources = function(briefingTypeId) {
    return $http.get(Config.shibiUrl + '/briefing_types/' + briefingTypeId + '/article_sources.json').then(function (response) {
      return response.data;
    });
  };

  const getArticleSourcesForBriefingType = function(briefing_type_id) {
    return $http.get(Config.shibiUrl + '/briefing_types/' + briefing_type_id + '/article_sources_with_is_checked_flag.json').
      then(function (response) {
        return response.data;
      });
  };

  return {
    getArticleSourcesForBriefingType,
    getArticleSources,
    getArticleSource,
    getArticleSourceTypes,
    getBriefingTypeArticleSources,
    getArticleSourceDetails
  };
});
