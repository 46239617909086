import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import React from "react";

function getButtonStyles() {
  const classes = makeStyles({
    outlined: {
      border: `1px solid #adadad`,
    },
  });
  const styles = {
    color: "#adadad",
    backgroundColor: "transparent",
    fontSize: "9px",
    borderRadius: "50px",
    marginBottom: "5px",
    textTransform: "none",
    marginRight: "10px",
    height: "20px",
    minWidth: "80px",
  };

  return { classes: classes(), styles };
}

export default function SectionsSelectButton(props) {
  const { label, value, onClick } = props;
  const { classes, styles } = getButtonStyles();

  return (
    <Button
      classes={{ outlined: classes.outlined }}
      onClick={() => onClick(value)}
      variant="outlined"
      style={{ marginLeft: "10px", ...styles }}
      size="small"
    >
      {label}
    </Button>
  );
}