var app = angular.module('app');
app.factory("OtherSourcesFactory", function ($http, Config) {

  var getOtherSource = function (other_source_id) {
    return $http.get(Config.shibiUrl + '/other_sources/' + other_source_id + '.json').
      then( function (response) {
        return response.data;
      });
  };

  return {
    getOtherSource: getOtherSource,
  }
});
