angular.module('app').controller('SearchPhraseController', function($scope, $stateParams, $state, $attrs, SearchPhrasesFactory, ArticleSourcesFactory, CommonFactory, ngToast){

  $scope.$on("$destroy", function() {
    Waypoint.destroyAll();
  });

  $scope.destroySearchPhrase = function (search_phrase_id, search_phrase, e) {
    e.stopPropagation();

    CommonFactory.confirm({
      event: e,
      message: "<p>You are going to delete <strong>" + search_phrase + "</strong> from keywords.</p><p>Are you sure?</p>",
      confirmation: function() {
        SearchPhrasesFactory.destroySearchPhrase(search_phrase_id).then(function () {
          $scope.$emit('search_phrases.reload');
          ngToast.create('Keyword deleted');
        });
      }
    });
  };
});
