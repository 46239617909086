import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";
import { useMediaQuery } from '@material-ui/core';

const ShrinkedTextField = styled.input`
  border: 0px;
  height: 26px;
  width: 100%;
  :focus {
    outline: 0;
  }
  padding: 5px 15px;
  margin-left: 20px;
  color: black;
`;

const setBorderHighlight = (divClassName, desiredColor) => {
  document.getElementsByClassName(
    divClassName
  )[0].style.borderColor = desiredColor;
};

const SmallClearIcon = withStyles({
  root: {
    width: "18px",
    height: "18px",
  },
})(HighlightOffIcon);

export default function SearchBar(props) {
  const smallScreen = useMediaQuery("(max-width: 1250px)");
  const styles = {
    root: {
      backgroundColor: "white",
      border: "1px solid #9CA2AB",
      maxWidth: smallScreen ? "200px" : "600px",
      height: "30px",
      borderRadius: "100px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      backgroundColor: "white",
      border: "0px",
      borderLeft: "1px solid #E3E3E3",
      width: "100px",
      height: "20px",
      color: "#9CA2AB",
      margin: "0px 5px 0px 0px",
      display: "flex",
      justifyContent: "center",
    },
  };
  return (
    <div className="highlight-box" style={styles.root}>
      <ShrinkedTextField
        style={{ width: "100%" }}
        value={props.value || ""}
        onChange={(e) => props.setSearch(e.target.value)}
        placeholder="Search"
        onFocus={() => setBorderHighlight("highlight-box", "#6B9FFA")}
        onBlur={() => setBorderHighlight("highlight-box", "#E3E3E3")}
      />
      {props.isActive ? (
        <IconButton
          size="small"
          onClick={() => props.clearSearch()}
          style={{
            padding: 5,
            marginLeft: "5px",
            marginBottom: "2px",
            color: "#9CA2AB",
          }}
        >
          <SmallClearIcon />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          onClick={() => {}}
          disabled={true}
          style={{
            padding: 5,
            marginLeft: "5px",
            marginBottom: "2px",
            color: "#ffffff",
          }}
        >
          <SmallClearIcon />
        </IconButton>
      )}
      <div style={styles.button}>
        <IconButton
          onClick={() => props.onClick()}
          style={{ padding: "0px 0px 0px 5px", margin: "", color: "#9CA2AB" }}
        >
          <SearchIcon style={{}} />
        </IconButton>
      </div>
    </div>
  );
}
