var app = angular.module('app');
app.controller("ArticleSummaryListController", function ($scope, $http, SummariesFactory) {
  var reloadSummariesList = function () {
    SummariesFactory.getArticleSummaries($scope.articleId).
      then(function (summaries){
        $scope.article_summaries = summaries;
      });
  }

  reloadSummariesList();

  $scope.$on('summaries.reload', function (event, data) {
    reloadSummariesList();
  });

})
.directive('iframeLoaded', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            $(element).load(function() {
                $(this).addClass(attrs.iframeLoaded);
            });
        }
    };
});
