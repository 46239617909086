import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";

export const GreenCheckbox = withStyles({
  root: {
    color: "#9CA2AB",
    '&$checked': {
      color: "#07BF24",
    },
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);