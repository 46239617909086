angular.module("app").directive('listDropdown', ['$compile', 'FavouritesFactory', 'ClientsFactory', function($compile, FavouritesFactory, ClientsFactory) {
  return {
    restrict: 'AE', //attribute or element
    scope: {
      type: '=',
      source: '='
    },
    template: require("./list_dropdown.html.haml"),
    replace: true,
    //require: 'ngModel',
    link: function($scope, elem, attr, ctrl) {
      $scope.opened = false;

      $scope.listSources = {};
      $scope.type = attr.type;

      $scope.$watch('source', function(val) {
        if(val){
          $scope.selectedSource = val;
        }
      });

      FavouritesFactory.getFavourites(attr.type).then(function(response) {
        $scope.listSources.favourites = response.favourite
        $scope.listSources.others = response.other
      });


      $scope.toggleDropdown = function () {
        $scope.opened = !$scope.opened;
      };

      $scope.select = function (source) {
        $scope.opened = false;
        $scope.selectedSource = source.name;

        if (attr.type == 'clients') {
          ClientsFactory.getClientSections(source.id);
        }
      };
    }
  };
}]);
