var app = angular.module('app');

app.controller("OpenFirstClientController", function ($state, clients) {

  (function redirectToFirstClient() {
    if (clients.length !== 0) {
      $state.go("search_phrases.client.all_articles", { clientId: clients[0].id, page: 1 }, {location: 'replace'});
    }
  })();
});
