import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TextField from "./components/TextField";
import TextArea from "./components/TextArea";
import { Editor } from "./components/Editor";
import Select from 'react-select';
import { GreenCheckbox } from "./components/GreenCheckbox";
import Button from "@material-ui/core/Button";
import InputButton from "./components/InputButton";
import InteractiveTextField from "./components/InteractiveTextField";
import styled from "styled-components";
import Loader from "@inbox/_common/components/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AsyncCreatableSelect from 'react-select/async-creatable';
import createContentAndOpenPreview from "@inbox/helpers/createContentAndOpenPreview";

const styles = {
  label: {
    width: "100%",
    textAlign: "left",
    marginBottom: "10px",
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px",
  },
};

const ErrorMessage = styled.div`
  background-color: #ad0a0a;
  color: white;
  padding: 2px 5px;
  font-size: 10px;
  position: relative;
  float: right;
  border-radius: 5px;
`;

const SourceAutocomplete = ({articleSources, setArticleSource, validationErrors, getArticleSource}) => (
  <Autocomplete
    id="combo-box-article-sources"
    options={articleSources}
    value={getArticleSource()}
    onChange={(event, value) => setArticleSource(value)}
    getOptionLabel={(option) => option.title}
    style={{ marginBottom: 10 }}
    renderInput={params => (
      <div ref={params.InputProps.ref}>
        <label style={styles.label} {...params.InputLabelProps}>Source</label>
        <TextField {...params.inputProps} error={validationErrors.articleSourceId} autoFocus/>
        {validationErrors.articleSourceId && (
          <ErrorMessage>{validationErrors.articleSourceId}</ErrorMessage>
        )}
      </div>
    )}
  />
)


@inject("contentStore")
@observer
export default class ContentEditor extends Component {
  async componentDidMount() {
    const { contentStore, articleId, contentId, token, type } = this.props;

    const queryParams = new URLSearchParams(window.location.search);
    const keywords = queryParams.get("keywords");
    const showPreview = queryParams.get("showPreview") === "true";

    if (keywords) contentStore.keywords = keywords;

    contentStore.clearContent();
    await contentStore.fetchGroupsAndSectors();
    await contentStore.fetchArticleSources();

    if (articleId) {
      await contentStore.fetchArticle(articleId);

      if (showPreview) {
        createContentAndOpenPreview(contentStore.article);
      }
    }

    if (contentId) {
      await contentStore.fetchContent(contentId);
    } else if (token) {
      if (token) {
        contentStore.token = token;
        await contentStore.fillFromAutosave(token);
      }

      contentStore.currentContentObj = contentStore.getContentObj();

      setInterval(contentStore.autosave, 5e3);
    } else {
      if (articleId) {
        await contentStore.fillWithDataFromArticle();
      }

      contentStore.currentContentObj = contentStore.getContentObj();

      if(type === "coverage") {
        contentStore.createClientCoverage = true;
      } else {
        contentStore.createBriefing = true;
      }

      setInterval(contentStore.autosave, 5e3);
    }
  }

  render() {
    const {
      setTitle,
      title,
      author,
      setAuthor,
      titleToLowerCase,
      content,
      setContent,
      richTextFlag,
      setRichTextFlag,
      wordCount,
      fileInput,
      setFileInput,
      fileName,
      validationErrors,
      isLoading,
      cleanUpText,
      charCount,
      insertStandfirst,
      insertSmartSnippet,
      articleSources,
      articleSourceId,
      setArticleSource,
      getArticleSource,
      shouldDisableSourceDropdown,
      fetchAuthors,
      acastUrl,
      setAcastUrl,
      spotifyUrl,
      setSpotifyUrl,
      appleMusicUrl,
      setAppleMusicUrl,
      keywords,
      keywordsWeight,
      setKeywords,
      setKeywordsWeight,
    } = this.props.contentStore;

    const articleSource = getArticleSource();

    return (
      <div>
        {isLoading && <Loader />}
        <div
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            padding: "19px",
            marginTop: "20px",
          }}
        >
          {articleSourceId && shouldDisableSourceDropdown && articleSources && articleSource &&
          <label style={styles.label}>
            Source
            <TextField
              style={{ marginTop: "5px" }}
              value={articleSource.title}
              error={validationErrors.articleSourceId}
              disabled
            />
            {validationErrors.articleSourceId && (
              <ErrorMessage>{validationErrors.articleSourceId}</ErrorMessage>
            )}
          </label>}
          {!shouldDisableSourceDropdown && articleSources &&
            <SourceAutocomplete
              articleSources={articleSources}
              setArticleSource={setArticleSource}
              validationErrors={validationErrors}
              getArticleSource={getArticleSource}
            />}
          <label style={styles.label}>
            Title
            <InteractiveTextField
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              interactiveClickAction={titleToLowerCase}
              interactiveActionDescription="To lowercase"
              interactiveAvailable={true}
              style={{ marginTop: "5px" }}
              error={validationErrors.title}
            />
            {validationErrors.title && (
              <ErrorMessage>{validationErrors.title}</ErrorMessage>
            )}
          </label>
          <label style={styles.label}>
            Author
            <AsyncCreatableSelect
              value={author !== '' ? author.split(', ').map(value => ({label: value, value})) : null}
              isMulti
              cacheOptions
              defaultOptions={{value: '', label: 'Unspecified'}}
              loadOptions={(inputValue) => fetchAuthors(inputValue)}
              getOptionValue={(option) => option.label}
              getOptionLabel={(option) => option.label}
              onChange={setAuthor}
            />
          </label>
          <label style={styles.label}>
            <GreenCheckbox
              checked={richTextFlag}
              onChange={(event) => setRichTextFlag(event.target.checked)}
              style={styles.checkbox}
            />
            Rich text editor
          </label>
          {richTextFlag && (
            <Editor
              content={content}
              handleChange={(newContent) => setContent(newContent)}
            />
          )}
          {!richTextFlag && (
            <TextArea
              value={content}
              onChange={(event) => setContent(event.target.value)}
              error={validationErrors.content}
            />
          )}
          {validationErrors.content && (
            <ErrorMessage>{validationErrors.content}</ErrorMessage>
          )}

          <div style={{ width: "100%", display: "flex", fontSize: "12px" }}>
            <div
              style={{
                width: "50%",
                textAlign: "left",
                display: "inline-block",
              }}
            >
              <Button
                onClick={insertStandfirst}
                style={{
                  color: "#1E88E5",
                  fontSize: "12px",
                  padding: "2px 6px",
                  textTransform: "none",
                  border: "1px solid",
                  borderRadius: "14px",
                  margin: "4px",
                }}
              >
                Insert Snippet
              </Button>
              <Button
                onClick={insertSmartSnippet}
                style={{
                  color: "#1E88E5",
                  fontSize: "12px",
                  padding: "2px 6px",
                  textTransform: "none",
                  border: "1px solid",
                  borderRadius: "14px",
                  margin: "4px",
                }}
              >
                Insert Smart Snippet
              </Button>
              <Button
                onClick={cleanUpText}
                style={{
                  color: "#1E88E5",
                  fontSize: "12px",
                  padding: "2px 6px",
                  textTransform: "none",
                  border: "1px solid",
                  borderRadius: "14px",
                  margin: "4px",
                }}>
                Clean up text
              </Button>
              <div>
                <div style={{ display: "inline-block", marginRight: "30px" }}>
                  Keywords:
                  <input type="text" value={keywords} onChange={(e) => setKeywords(e.target.value)}/>
                </div>

                <div style={{ display: "inline-block", marginRight: "30px" }}>
                  Keywords importance:
                  <Select defaultValue={keywordsWeight} onChange={setKeywordsWeight} options={[
                    { value: 1.5, label: "Default" },
                    { value: 2.0, label: "Medium" },
                    { value: 2.5, label: "High" },
                  ]}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                textAlign: "right",
                display: "inline-block",
              }}
            >
              <span style={{ marginRight: "10px" }}>Character count: {charCount}</span>
              <span>Word count: {wordCount}</span>
            </div>
          </div>
          <label style={{ ...styles.label, marginTop: "20px" }}>
            Attach an image (jpg, jpeg, gif, png) or PDF:
            <InputButton
              input={fileInput}
              fileName={fileName}
              setInput={setFileInput}
            />
          </label>
          <label style={styles.label}>
            Acast URL:
            <TextField
              onChange={(e) => setAcastUrl(e.target.value)}
              value={acastUrl}
              style={{ marginTop: "5px" }}
            />
          </label>
          <label style={styles.label}>
            Spotify URL:
            <TextField
              onChange={(e) => setSpotifyUrl(e.target.value)}
              value={spotifyUrl}
              style={{ marginTop: "5px" }}
            />
          </label>
          <label style={styles.label}>
            Apple Music URL:
            <TextField
              onChange={(e) => setAppleMusicUrl(e.target.value)}
              value={appleMusicUrl}
              style={{ marginTop: "5px" }}
            />
          </label>
        </div>
      </div>
    );
  }
}
