import find from 'lodash/find';

angular.module('app').controller("ArticleController", function ($scope, $stateParams, $sce, $rootScope, ArticleSourcesFactory, ArticlesFactory, CommonFactory, PreviewFactory, client, searchPhrases) {

  $scope.articleId = $stateParams.articleId;

  $scope.preview = function(strUrl) {
    PreviewFactory.preview(strUrl);
  };

  ArticlesFactory.getArticle($scope.articleId).
      then(function (article) {
        // breadcrumb keys
        if ($stateParams.clientId) {
          $scope.$parent.client_name = client.name;
        }
        $scope.$parent.article_title = article.title;
        if ($stateParams.searchPhraseId) {
          $scope.$parent.search_phrase_name = find(searchPhrases, function (sp) {
            return sp.id == $stateParams.searchPhraseId;
          }).phrase;
        }

        $scope.article = article;
        $scope.article_url = $sce.trustAsResourceUrl($scope.article.url);
        $scope.articleSourceId =article.article_source_id;
      }).then(function () {
        return ArticleSourcesFactory.getArticleSource($scope.articleSourceId);
      }).then(function (article_source) {
        $scope.article_source = article_source;
      });;

  ArticlesFactory.markAsRead($scope.articleId).
      then(function () {
        $scope.$emit("article_sources.reload");
        $rootScope.$broadcast("search_phrases.reload");
      });
});
