angular.module("app").
  controller("AnalystClientNotificationsController", function ($scope, $http, $stateParams, Config, $rootScope) {

    $http.post(Config.shibiUrl + '/analyst/email_client_notifications_unsubscribe.json', { analyst_id: $stateParams.analyst_id, token: $stateParams.token, client_id: $stateParams.client_id }).
      then(function () {
        $scope.success = true;
        if($stateParams.client_id === undefined) {
          $rootScope.$broadcast('globalAlertsToggle.reload');
        }
      });
  });