import React, {Component} from 'react';
import Notification from "./Notification";
import { inject, observer } from 'mobx-react';

@inject('publicationAlertsStore')
@observer
export default class NotificationsContainer extends Component {
  render() {
    const { popupAlerts, closePopup, markAsRead } = this.props.publicationAlertsStore;

    return (
      <div>
        {popupAlerts.map(alert => (
          <Notification key={alert.content_id} alert={alert} onClose={closePopup} markAsRead={markAsRead} />
        ))}
      </div>
    );
  }
}
