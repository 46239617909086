import values from 'lodash/values';
import includes from 'lodash/includes';
import each from 'lodash/each';

angular.module('app').controller('SearchPhraseFormController', function($scope, $stateParams, $state, $attrs, SearchPhrasesFactory, ArticleSourcesFactory, CommonFactory, ngToast){

  var reloadArticleSourceTypes = function () {
    ArticleSourcesFactory.getArticleSourceTypes().then( function (article_source_types) {
      $scope.article_source_types = article_source_types;
      $scope.article_source_types_map = {}
      each(article_source_types, function (ast) {
        $scope.article_source_types_map[ast.id] = true
      });
    });
    $scope.buttonIsDisabled = false;
  }


  $scope.stateChanged = function () {
    $scope.buttonIsDisabled = !(includes(values($scope.article_source_types_map), true));
  }
});
