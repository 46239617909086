angular.module('app').controller("ClientSearchPhrasesArticlesController", function($scope, $state, $http, $stateParams, SearchPhrasesFactory, ClientsFactory) {

  $scope.page = $stateParams.page;
  var prevSearchPhraseId = $scope.$parent.searchPhraseId;
  $scope.$parent.searchPhraseId = $stateParams.searchPhraseId;
  var prevClient = $scope.$parent.clientId;
  $scope.$parent.clientId = $stateParams.clientId;
  $scope.stateParams = $stateParams;

  if($scope.$parent.searchPhraseId === 'all') {
    $scope.$parent.searchPhraseName = 'All';
  } else if(prevSearchPhraseId != $stateParams.searchPhraseId) {
    SearchPhrasesFactory.getSearchPhrase($scope.$parent.searchPhraseId).then(function (response) {
      if (!$scope.$parent) $scope.$parent = {};
      $scope.$parent.searchPhraseName = response.phrase;
    });
  }

  if(prevClient != $stateParams.clientId) {
    ClientsFactory.getClient($stateParams.clientId).then(function (response) {
	    $scope.$parent.sourceName = response.name;
	});
  }

  SearchPhrasesFactory.getArticlesForSearchPhrase($stateParams.clientId, $stateParams.searchPhraseId, 1).then(function (response) {
    $scope.articles = response.articles;
    $scope.totalPages = response.meta.total_pages;
  });

});