angular.module('app').factory("SearchPhrasesFactory", function ($http, $stateParams, $state, $q, Config) {

  var getSearchPhrase = function(search_phrase_id) {
    return $http.get(Config.shibiUrl + '/search_phrases/' + search_phrase_id + '.json').then(function (response) {
      return response.data;
    });
  };

  var updateSearchPhrase = function (search_phrase, article_source_type_ids, for_private_sections, for_public_sections) {
    return $http.patch(Config.shibiUrl + '/search_phrases/'+ search_phrase.id +'.json', {
      search_phrase: {
        ...search_phrase,
        article_source_type_ids,
        for_private_sections,
        for_public_sections
      }
    }).then(function (response) {
      return response.data;
    }).catch(function (response) {
      return $q.reject(response.data.errors);
    });
  };

  var getArticleSourceTypesForSearchPhrase = function (search_phrase_id) {
    return $http.get(Config.shibiUrl + '/search_phrases/' + search_phrase_id + '/article_source_types.json').
      then(function (response) {
        return response.data.article_source_types;
      });
  };

  var getArticlesForSearchPhrase = function (content_container_id, search_phrase_id, page) {
    return $http.get(Config.shibiUrl + '/search_phrases/' + search_phrase_id + '/articles.json', { params: { page: page, content_container_id: content_container_id, received_date: $stateParams.sort == 'received' } }).then(function (response) {
      return response.data;
    });
  };

  var getAllSearchPhrases = function () {
    return $http.get(Config.shibiUrl + '/search_phrases.json').
      then(function (response) {
        return response.data.search_phrases;
      });
  };

  var getSearchPhrasesForClient = function (client_id) {
    return $http.get(Config.shibiUrl + '/content_containers/' + client_id + '/search_phrases.json').
      then(function (response) {
        return response.data.search_phrases;
      });
  };

  var getSearchPhrasesForClientWithAllSection = function (client_id) {
    return $http.get(Config.shibiUrl + '/content_containers/' + client_id + '/search_phrases/with_all_section.json').
      then(function (response) {
        return response.data.search_phrases;
      });
  };

  var createSearchPhraseForClient = function (client_id, search_phrase, article_source_type_ids, for_private_sections, for_public_sections) {
    return $http.post(Config.shibiUrl + '/content_containers/' + client_id + '/search_phrases.json',
        {
          search_phrase: {
            article_source_type_ids,
            for_private_sections,
            for_public_sections,
            ...search_phrase
          }
        }
      ).catch(function (response) {
        return $q.reject(response.data.errors);
      });
  };

  var destroySearchPhrase = function (search_phrase_id) {
    return $http.delete(Config.shibiUrl + '/search_phrases/'+ search_phrase_id +'.json').then(function (response) {
      return response.data;
    });
  };

  return {
    getSearchPhrase: getSearchPhrase,
    updateSearchPhrase: updateSearchPhrase,
    getAllSearchPhrases: getAllSearchPhrases,
    destroySearchPhrase: destroySearchPhrase,
    getArticlesForSearchPhrase: getArticlesForSearchPhrase,
    getSearchPhrasesForClient: getSearchPhrasesForClient,
    createSearchPhraseForClient: createSearchPhraseForClient,
    getArticleSourceTypesForSearchPhrase: getArticleSourceTypesForSearchPhrase,
    getSearchPhrasesForClientWithAllSection: getSearchPhrasesForClientWithAllSection
  };
});
