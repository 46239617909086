import first from 'lodash/first';
import find from 'lodash/find';

angular.module('app').controller("FindFirstBriefingTypeArticles", function ($scope, $state, ArticleSourcesFactory) {
  
  ArticleSourcesFactory.getBriefingTypeArticleSources($state.params.briefingTypeId).then(function (response) {
    if (response.article_sources.length !== 0 || response.other_sources.length !== 0) {
      if (response.article_sources.length !== 0) {
        var sourceWithUnreadArticles = find(response.article_sources, function (source) {
          return source.published_today_and_unread_count !== 0;
        });
        if (sourceWithUnreadArticles) {
          $state.go("briefing_types_article_sources.articles", { briefingTypeId: $state.params.briefingTypeId, articleSourceId: sourceWithUnreadArticles.id, page: 1 });
        } else {
          $state.go("briefing_types_article_sources.articles", { briefingTypeId: $state.params.briefingTypeId, articleSourceId: first(response.article_sources).id, page: 1 });
        }
      } else {
        $state.go("briefing_types_article_sources.create_from_other_source", { briefingTypeId: $state.params.briefingTypeId, otherSourceId: first(response.other_sources).id });
      }
    } else {
      $state.go("briefing_types_article_sources.briefing_type_without_article_sources", { briefingTypeId: $state.params.briefingTypeId, articleSourceId: 'empty' });
    }
  });
});
