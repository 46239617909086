import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

export const FavouriteCheckbox = withStyles({
  root: {
    color: "#9CA2AB",
    '&$checked': {
      color: "#1e88e5",
    },
  },
  checked: {}
})((props) =>
  <Checkbox
    {...props}
    color="default"
    icon={<StarBorderIcon fontSize="small"/>}
    checkedIcon={<StarIcon fontSize="small"/>}
  />);