import React, { Component } from 'react';

const itemStyles = {
  root: {
    border: '2px solid #f0f0f0',
    padding: '10px',
    position: 'relative',
    margin: '20px 0px'
  },
  title: {
    textAlign: 'left',
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  content: {
    textAlign: 'left',
    fontSize: '8pt'
  },
  contentContainer: {
    display: 'block',
    position: 'absolute',
    top: '0px',
    right: '0px',
    padding: '5px 8px',
    fontSize: '10pt',
    backgroundColor: '#8FD14F',
    color: 'white'
  }
};

export default class RelatedCoverage extends Component {
  render() {
    const { contentPublication } = this.props;

    return (
      <div style={itemStyles.root}>
        <div style={itemStyles.title}>{contentPublication.title}</div>
        <div style={itemStyles.content}>{contentPublication.content}</div>
        <div style={itemStyles.contentContainer}>
          {contentPublication.content_container_name}
        </div>
      </div>
    );
  }
}
