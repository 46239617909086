import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const Editor = (props) => {
  return (
    <CKEditor
      editor={ ClassicEditor }
      data={props.content}
      onChange={(event, editor) => props.handleChange(editor.getData())}
    />
  );
}
