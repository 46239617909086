import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import MessageBox from '@inbox/_common/components/MessageBox';
import AlertFilters from './AlertFilters';
import AlertList from './AlertsList';
import AlertItem from './AlertItem';
import ForwardMessageForm from './forms/ForwardMessageForm';

@inject('alertsStore')
@observer
export default class AlertsContainer extends Component {
  componentDidMount() {
    const {
      isTrash,
      alertsStore: { setIsTrash, handleKeyEvents },
    } = this.props;
    setIsTrash(isTrash);
    document.addEventListener('keydown', handleKeyEvents, true);
  }

  componentWillUnmount() {
    const {
      alertsStore: { handleKeyEvents },
    } = this.props;
    document.removeEventListener('keydown', handleKeyEvents, true);
  }

  render() {
    const {
      isTrash,
      alertsStore: {
        showForwardForm,
        toggleForwardForm,
        currentAlert,
        actionMessage,
        deleteAlert,
        flagAlert,
        toggleInputFocus,
      },
    } = this.props;
    return (
      <Flex>
        <Box width={1 / 3}>
          <AlertFilters isTrash={isTrash} />
          <AlertList store={this.props.alertsStore} />
        </Box>
        <Box width={2 / 3} px={20}>
          <AlertItem
            currentAlert={currentAlert}
            toggleForwardForm={toggleForwardForm}
            showForwardForm={showForwardForm}
            deleteAlert={isTrash ? null : deleteAlert}
            flagAlert={flagAlert}
          />
          {showForwardForm && (
            <ForwardMessageForm
              toggleForwardForm={toggleForwardForm}
              defaultInputProps={{
                onFocus: toggleInputFocus,
                onBlur: toggleInputFocus,
              }}
            />
          )}
          {!isEmpty(actionMessage) && (
            <MessageBox
              mx={50}
              mt={105}
              imgSrc={actionMessage.icon}
              text={actionMessage.text}
            >
              {actionMessage.secondaryText}
            </MessageBox>
          )}
        </Box>
      </Flex>
    );
  }
}

AlertsContainer.propTypes = {
  isTrash: PropTypes.bool.isRequired,
};
