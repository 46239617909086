import find from 'lodash/find';
import first from 'lodash/first';

var app = angular.module('app');

app.controller("OpenFirstSourceController", function ($scope, $state) {

  $scope.$parent.$watch('article_sources', function (article_sources) {
    if(article_sources && article_sources.length !== 0) {
      (function redirectToFirstSource() {
        var sourceWithUnreadArticles = find(article_sources, function (source) {
          return source.published_today_and_unread_count !== 0;
        });
        if (sourceWithUnreadArticles) {
          $state.go("briefing_types_article_sources.first_source_articles", { articleSourceId: sourceWithUnreadArticles.id, page: 1 }, { location: 'replace', reload: true });
        } else {
          $state.go("briefing_types_article_sources.first_source_articles", { articleSourceId: first(article_sources).id, page: 1 }, { location: 'replace', reload: true });
        }
      })();
    }

  });

});
