import config from 'config';
require('textangular/dist/textAngular-sanitize.min');
import 'ng-rollbar';
import io from 'socket.io-client';
import Cookies from 'js-cookie';

angular.module(
  'app',
  [
    'tandibar/ng-rollbar',
    'ui.router',
    'ipCookie',
    'pascalprecht.translate',
    'angular-loading-bar',
    'ngAnimate',
    'ngToast',
    'angular.filter',
    'infinite-scroll',
    'ngDialog',
    'angular-toArrayFilter',
    'colorpicker.module',
    require('textangular'),
    'angucomplete-alt',
    'ui.sortable',
    'ngTagsInput',
    'vTabs',
    'slickCarousel'
  ]
);


// last change 2018-04-13
var last_change_date_for_deployment = '2018-06-18';

var app = angular.module('app');

app.constant("Config", {
  ssoEnabled: config.ssoEnabled,
  ssoSuffix: config.ssoSuffix,
  ssoUrl: config.ssoUrl,
  shibiUrl: config.shibiUrl,
  kefearUrl: config.kefearUrl,
  stathat: config.stathat,
  autosaveInterval: config.autosaveInterval,
  enableAutosave: config.enableAutosave
});

app.config(['RollbarProvider', 'Config', function(RollbarProvider, Config) {
  if (process.env.NODE_ENV === 'development') {
    RollbarProvider.init({ enabled: false });
  } else {
    const suffix = Config.ssoSuffix;
    const analystEmail = (getCookieValue('analyst_email') || getCookieValue(`analyst_email${suffix}`)).replace('%40', '@');
    const rollbarConfig = {
      accessToken: config.rollbarAccessToken,
      captureUncaught: true,
      payload: {
        environment: process.env.NODE_ENV,
        code_version: buildTimestamp,
        person: {
          id: analystEmail,
          email: analystEmail
        },
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: buildTimestamp,
            guess_uncaught_frames: true
          }
        }
      }
    };
    RollbarProvider.init(rollbarConfig);
  }
}]);

app.run(function($rootScope, $state, $window, $location, ngToast, AuthenticationFactory, ipCookie) {
  const buildTimestampCookie = ipCookie('build_timestamp');
  if (!buildTimestampCookie || buildTimestamp > buildTimestampCookie) {
    ipCookie("build_timestamp", buildTimestamp, { expires: 60 });
  }

  if (ipCookie("reload_page_at_timestamp") && (ipCookie("reload_page_at_timestamp") > 0)) {
    ipCookie.remove("reload_page_at_timestamp");
    if (ipCookie("major_build_version_timestamp") && (ipCookie("major_build_version_timestamp") > 0)) {
      ipCookie("major_local_version_timestamp", ipCookie("major_build_version_timestamp"), { expires: 30 });
    }
  }

  var statesWithoutAuthentication = ["login", "register", "request_password_reset", "change_password", "confirm_email", "analyst_client_notifications_unsubscribe", "analyst_client_notifications_unsubscribe_one"];
  $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
    if (!AuthenticationFactory.isLoggedIn()) {
      if (statesWithoutAuthentication.indexOf(toState.name) === -1) {
        event.preventDefault();
        $state.transitionTo('login');
      }
    }
  });
  $rootScope.online = navigator.onLine;
  $rootScope.newApp = function () {
    return $location.$$path.includes('alerts');
  };
  $window.addEventListener("offline", function() {
    $rootScope.$apply(function() {
      $rootScope.online = false;
      $rootScope.offlineWarningToast = ngToast.create({
        className: 'danger',
        content: 'You seem to be offline. Please make sure You are connected to the internet.',
        dismissOnTimeout: false,
      });
    });
  }, false);

  $window.addEventListener("online", function() {
    $rootScope.$apply(function() {
      $rootScope.online = true;
      ngToast.dismiss($rootScope.offlineWarningToast);
      ngToast.create({
        className: 'info',
        content: 'You seem to be back online.',
      });
    });
  }, false);
  $window.ga('create', config.googleAnalyticsID, 'auto');
  $rootScope.$on('$stateChangeSuccess', function (event) {
    $window.ga('send', 'pageview', $location.path());
  });
});

app.run(function ($rootScope, $location) {
  var secondStateChanges = false;
  var history = [];

  $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
    $rootScope.previousState = from;
    $rootScope.previousStateParams = fromParams;

    window.scrollTo(0, 0);
    history.push($location.$$path);
  });

  $rootScope.back = function () {
    window.history.back();
  };
});

app.factory('httpInterceptor', function ($q, ipCookie, $injector, $rootScope, $window, AuthenticationFactory, Config, ngToast) {
  var numLoadings = 0;

  return {
    request: function (config) {

      numLoadings++;
      if (config.method !== 'GET') {
        $rootScope.$broadcast("request_processing_start");
      }

      // token authenticable headers
      let suffix = Config.ssoSuffix;

      config.headers['X-ANALYST-EMAIL'] = ipCookie(`analyst_email${suffix}`) || ipCookie('analyst_email');
      config.headers['X-ANALYST-TOKEN'] = ipCookie(`analyst_token${suffix}`) || ipCookie('analyst_token');
      config.headers['X-ANALYST-ID'] = ipCookie(`analyst_id${suffix}`) || ipCookie('analyst_id');

      return config || $q.when(config)
    },
    response: function (response) {
      if ((--numLoadings) === 0) {
        $rootScope.$broadcast("request_processing_stop");
      }
      return response || $q.when(response);
    },
    responseError: function (rejection) {
      if (!(--numLoadings)) {
        $rootScope.$broadcast("request_processing_stop");
      }

      if (rejection.status <= 0 && numLoadings < 1) {
        ngToast.create({
          className: 'danger',
          content: 'Lost connection to server. Please try again later',
          timeout: 60000
        });
        Rollbar.error('connection error', {url: rejection.config.url, params: rejection.config.params, headers: rejection.config.headers, method: rejection.config.method, data: rejection.config.data });
        $window._StatHat.push(['_trackCount', Config.stathat.down, 1]);
      }

      if (rejection.status === 401) {
        AuthenticationFactory.clearAuthenticationCookies();

        var stateService = $injector.get('$state');
        stateService.transitionTo('login');
      }

      if (rejection.status === 403) {
        localStorage.removeItem("kefear-jwt");
        $window.location.href = Config.ssoUrl + '/forbidden'
      }

      if (rejection.status >= 500) {
        ngToast.create({
          className: 'danger',
          content: 'Server error. Please try again later'
        });
      }

      if (rejection.status === 422) {
        ngToast.create({
          className: 'danger',
          content: 'Incorrect action performed. Please refresh the page.',
          timeout: 60000
        });
      }

      return $q.reject(rejection);
    }
  }
});

app.config(function ($httpProvider) {
  $httpProvider.interceptors.push('httpInterceptor');
});

app.config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
  cfpLoadingBarProvider.includeSpinner = true;
  cfpLoadingBarProvider.includeBar = true;
  cfpLoadingBarProvider.spinnerTemplate = '<div class="loading-bar-spinner-wrapper not-active"><span class="loading-bar-spinner"></span></div>';
}])

app.config(['ngToastProvider', function (ngToast) {
  ngToast.configure({
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    dismissButton: true
  });
}]);

app.config(['ngDialogProvider', function (ngDialog) {
  ngDialog.setDefaults({
    disableAnimation: true,
    showClose: false,
    closeByDocument: true,
    closeByEscape: true,
    closeByNavigation: true,
    appendTo: false
  });
}]);

app.config(function($provide){
  $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions){
    taRegisterTool('fontColor', {
      display: "<button type='button' colorpicker colorpicker-text-editor='true' ng-change='!!fontColor && action(fontColor)' class='btn btn-default font-color' ng-disabled='showHtml()' ng-init-'fontColor=\"#000\"' ng-model='fontColor'><i class='fa fa-paint-brush'></i></button>",
      action: function (color) {
        if (typeof color === 'string') {
          var me = this;
          if (!this.$editor().wrapSelection) {
            setTimeout(function () {
              me.action(color);
            }, 100)
          } else {
            return this.$editor().wrapSelection('foreColor', color);
          }
        }
      }
    });

    // add the button to the default toolbar definition
    taOptions.toolbar[1].push('fontColor');
    return taOptions;
  }]);
});

app.directive('fileUpload', function () {
  return {
    scope: true,        //create a new scope
    link: function (scope, el, attrs) {
      el.bind('change', function (event) {
        var files = event.target.files;
        //iterate files since 'multiple' may be specified on the element
        for (var i = 0;i<files.length;i++) {
          //emit event upward
          scope.$emit("fileSelected", { file: files[i] });
        }
      });
    }
  };
});

app.directive("submitblocker", function () {
  return function ($scope, element, attrs) {

    var defaultSaveText = element.html();
    var replacementText = attrs.submitblocker

    $scope.$on("request_processing_start", function () {
      if (replacementText) {
        element.html(replacementText);
      }
      return element.attr("disabled", "disabled");
    });
    return $scope.$on("request_processing_stop", function () {
      if (replacementText) {
        element.html(defaultSaveText);
      }
      return element.removeAttr("disabled");
    });
  };
});

angular.module("app").config(function ($provide) {
  $provide.decorator("$exceptionHandler", function($delegate) {
    return function (exception, cause) {
      Rollbar.error(exception, {cause: cause});
      $delegate(exception, cause);
    };
  });
});

app.filter('trusted', ['$sce', function ($sce) {
  return function(url) {
    return $sce.trustAsResourceUrl(url);
  };
}]);

app.directive("validateDate", function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.validateDate = function(modelValue, viewValue) {
        if(!isNaN(modelValue) || ctrl.$isEmpty(modelValue) || !moment(modelValue, "DD.MM.YYYY HH:mm", true).isValid()){
          angular.element('#published_at--error').removeClass("ng-hide");
          angular.element('#save_date_button').addClass("not-active");
          return true;
        }
        angular.element('#published_at--error').addClass("ng-hide");
        angular.element('#save_date_button').removeClass("not-active");
        return modelValue;
      };
    }
  };
});


function getCookieValue(a) {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}
