import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { observer, inject } from "mobx-react";
import SentimentsTable from "./components/SentimentsTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const styles = {
  root: {
    padding: "6px 19px 10px 19px",
    position: "fixed",
    right: "15px",
    bottom: "0px",
    width: "74%",
  },
  label: {
    width: "100%",
    textAlign: "left",
    color: "#9ca2ab",
    fontSize: "13px",
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px",
  },
  bottomButtons: {
    fontSize: "11px",
    borderRadius: "50px",
    marginTop: "5px",
    textTransform: "none",
    marginRight: "10px",
    width: "112px",
    height: "30px",
  },
};

function getButtonStyles(active) {
  const classes = makeStyles({
    outlined: {
      border: `1px solid #34034B`,
    },
  });
  const styles = {
    color: active !== null ? (active ? "white" : "#505a6b") : "#1e88e5",
    borderColor: active !== null ? "auto" : "#adadad",
    backgroundColor: active !== null ? (active ? "#34034B" : "transparent") : "transparent",
    fontSize: "9px",
    borderRadius: "50px",
    marginTop: "5px",
    textTransform: "none",
    marginRight: "10px",
    height: "20px",
    minWidth: "80px",
  };

  return { classes: classes(), styles };
}

@inject("contentStore")
@observer
class ContentSectionButton extends Component {
  render() {
    const { value, briefingId } = this.props;
    let active;
    let onClick;
    if (value === "straight" || value === "opinion") {
      active = this.props.contentStore.contentType === value;
      onClick = this.props.contentStore.setContentType;
    } else {
      active = this.props.contentStore.briefingSection[briefingId] === value;
      onClick = (section) => this.props.contentStore.setBriefingSection(briefingId, section);
    }
    return <InnerButton active={active} onClick={onClick} {...this.props} />;
  }
}

function InnerButton(props) {
  const { label, active, value, onClick, style: pstyle } = props;
  const { classes, styles } = getButtonStyles(active);

  return (
    <Button
      classes={{ outlined: classes.outlined }}
      onClick={() => onClick(value)}
      variant="outlined"
      style={{ marginLeft: "10px", marginBottom: "6px", ...styles, ...pstyle }}
      size="small"
    >
      {label}
    </Button>
  );
}

@inject("contentStore")
@observer
export default class EditorFooter extends Component {
  render() {
    const chosenBriefingTypeIds = this.props.contentStore.briefingTypeIds;
    const briefingTypesWithTags = this.props.contentStore.briefingTypes.filter(
      (bt) => bt.meta.tags && chosenBriefingTypeIds.indexOf(bt.id) >= 0
    );

    return (
      <Paper style={styles.root}>
        {this.props.contentStore.createBriefing && briefingTypesWithTags.length > 0 &&
        briefingTypesWithTags.map((bt) => (
          <div
            key={bt.id}
            style={{
              textAlign: "left",
              marginTop: "4px",
              marginBottom: "6px",
              paddingBottom: "5px",
              borderBottom: "1px solid #e0e0e0"
            }}
          >
            <label style={styles.label}>
              {`Select briefing type (${bt.name}):`}
              {bt.meta.tags.map((tag) => (
                <ContentSectionButton briefingId={bt.id} key={tag} label={tag} value={tag}/>
              ))}
            </label>
          </div>
        ))}
        {this.props.contentStore.sentimentedSections && <SentimentsTable />}
        {this.props.contentStore.createClientCoverage && (
          <div
            style={{
              textAlign: "left",
              marginTop: "6px",
              marginBottom: "6px",
            }}
          >
            <label style={styles.label}>
              Select article type:
              <ContentSectionButton
                label="Straight reporting"
                value={"straight"}
              />
              <ContentSectionButton
                label="Opinion"
                value={"opinion"}
              />
            </label>
          </div>
        )}
        <div style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            style={{ color: "#808080", ...styles.bottomButtons }}
            onClick={() => window.history.back()}
          >
            Cancel
          </Button>
          {this.props.action === "create" ? (
            <Button
              onClick={this.props.contentStore.createContent}
              style={{
                backgroundColor: "#07BF24",
                color: "white",
                ...styles.bottomButtons,
              }}
            >
              Create
            </Button>
          ) : (
            <Button
              onClick={this.props.contentStore.updateContent}
              style={{
                backgroundColor: "#07BF24",
                color: "white",
                ...styles.bottomButtons,
              }}
            >
              Update
            </Button>
          )}
        </div>
      </Paper>
    );
  }
}
