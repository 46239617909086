angular.module('app').controller("CreateFromFirstOtherSourceController", function($scope, $http, $controller, $state, $stateParams,
                                                                                  Config, article, ArticleSourcesFactory) {

  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.$parent.articleSourcesScope = true;
  $scope.$parent.hasBriefingTypeId = false;

  $scope.disableUrl = false;

  $scope.create_summary = false;
  $scope.create_client_coverage = false;
  $scope.title = article.title || '';
  $scope.author = article.author || '';
  $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;

  $scope.updatePublishCheckbox = function (coverage = false) {
    if (coverage) {
      $scope.coverageUrlPublished = !!$scope.coverageUrl && !$scope.disablePublishing;
    } else {
      $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;
    }
  };


  $scope.restoreForm($stateParams.token);

  $scope.summaryUrlPublished = false;
});
