import React from 'react';
import ReactDOM from 'react-dom';
import App from '../../inbox/App';

const app = angular.module('app');

const reactDirective = app.directive('reactDirective', function() {
  return {
    template: '<div id="reactapp"></div>',
    link: function(scope, el, attrs) {
      const reactapp = document.getElementById('reactapp');
      ReactDOM.render(<App />, reactapp);
    },
  };
});
