import each from 'lodash/each';
import find from 'lodash/find';
import filter from 'lodash/filter';
import every from 'lodash/every';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import createContentAndOpenPreview from "../../../inbox/helpers/createContentAndOpenPreview";

var app = angular.module('app');

app.controller("SummaryListController", function (
  $scope,
  $http,
  $stateParams,
  $state,
  $controller,
  $location,
  $anchorScroll,
  Config,
  SummariesFactory,
  PreviewFactory,
  CommonFactory,
  briefing,
  ngToast
) {
  $.extend(this, $controller('BaseListController', {$scope: $scope}));
  $scope.gotoAnchor = function(x) {
    $anchorScroll.yOffset = 160;
    var newHash = 'anchor' + x;
    if ($location.hash() !== newHash) {
      // set the $location.hash to `newHash` and
      // $anchorScroll will automatically scroll to it
      $location.hash('anchor' + x);
    } else {
      // call $anchorScroll() explicitly,
      // since $location.hash hasn't changed
      $anchorScroll();
    }
  };
  $scope.briefing = briefing.data;
  $scope.dropdownValue = "empty";
  $http.get(Config.shibiUrl + '/content_containers/' + $stateParams.briefingId + '/summary_publications.json').
      then(function success(response) {
        $scope.summaries = response.data.summaries; // filter(response.data.summaries, {status: 'unpublished'}).concat(filter(response.data.summaries, function(item){ return item.status != "unpublished"}));
        const summaryId = $location.search()["summaryId"]
        if(summaryId) {
          $scope.gotoAnchor(summaryId)
        }
        $scope.groupedSummaries = groupBy($scope.summaries, "briefing_section");
        $scope.tagsForDropdown = $scope.briefing.tags;

        if ($scope.tagsForDropdown) {
          $scope.tagsForDropdown = $scope.tagsForDropdown.concat(Object.keys($scope.groupedSummaries).filter(e => $scope.tagsForDropdown.indexOf(e) === -1 && e !== ""));
          if($scope.tagsForDropdown) {
            $scope.tagsForDropdown.push("");
          }
          $scope.tags = $scope.tagsForDropdown.filter(tag => tag !== "");
        }

  }, function error(response) {
        $state.go('briefing_publisher.first_briefing', {});
      });


  $scope.getNumPublished = ()  => $scope.summaries && $scope.summaries.length && $scope.summaries.filter(e => e.status === "published").length || 0;
  $scope.submit = function (event, reload) {
    return CommonFactory.confirm({
      event: event,
      message: "<p>The summaries will be published within the briefing in the order presented. Email notifications about this briefing will be sent to subscribed users.</p><p>Are you sure you want to proceed?</p>",
      confirmation: function() {
        $http.post(Config.shibiUrl + '/content_containers/' + $stateParams.briefingId + '/close_briefing.json').
          then(function (response) {
            $state.go($state.current, {}, {reload: true});
          })
          .catch(function (e) {
            console.error(e)
            return ngToast.create({
              className: 'danger',
              content: e.data.error,
              timeout: 6000
            })
          })
      },
      cancel: function() {
        if (reload) {
          $state.go($state.current, {}, {reload: true});
          $scope.reorderBackground = false;
        }
      }
    });
  };

  $scope.submitWithoutPublishing = function (event, reload) {
    return CommonFactory.confirm({
      event: event,
      message: "<p>All summaries in this briefing will not be published for users.</p><p>Are you sure you want to proceed?</p>",
      confirmation: function() {
        $http.post(Config.shibiUrl + '/content_containers/' + $stateParams.briefingId + '/close_briefing_without_publish.json').
        then(function (response) {
          $state.go($state.current, {}, {reload: true});
        })
          .catch(function (e) {
            console.error(e)
            return ngToast.create({
              className: 'danger',
              content: e.data.error,
              timeout: 6000
            })
          })
      },
      cancel: function() {
        if (reload) {
          $state.go($state.current, {}, {reload: true});
          $scope.reorderBackground = false;
        }
      }
    });
  };

  $scope.submitAfterReorder = function (event, reload, json) {
    return CommonFactory.confirm({
      event: event,
      message: "<p>This action will publish all summaries that were not published yet and that were not dismissed. The summaries will be published within the briefing in the order presented. Email notifications about this briefing will be sent to subscribed users.</p><p>Are you sure you want to proceed?</p>",
      confirmation: function() {
        $http.post(Config.shibiUrl + '/briefings/' + $stateParams.briefingId + '/publish.json', { summaries: json })
        .then(function (response) {
          $state.go($state.current, {}, {reload: true});
        }).catch(function (e) {
            console.error(e)
            return ngToast.create({
              className: 'danger',
              content: e.data.errors,
              timeout: 6000
            })
          });
      },
      cancel: function() {
        if (reload) {
          $state.go($state.current, {}, {reload: true});
          $scope.reorderBackground = false;
        }
      }
    });
  };

  $scope.delete = function (event) {
    CommonFactory.confirm({
      event: event,
      message: "<p>This action will delete this briefing.</p><p>Are you sure you want to proceed?</p>",
      confirmation: function() {
        $http.post(Config.shibiUrl + '/content_containers/' + $stateParams.briefingId + '/delete.json').
          then(() => {
            ngToast.create('Successfully deleted');
            $state.go('briefing_publisher.first_briefing', {}, {reload: true});
          }, response => {
            if (response && response.data && response.data.error) {
              ngToast.create({
                className: 'danger',
                content: response.data.error
              });
            }
            $state.go('briefing_publisher.first_briefing', {}, {reload: true});
          });
      }
    });
  };

  $scope.publishSummary = function (e, summary) {
    return CommonFactory.confirm({
      event: e,
      title: "Publishing Reail Briefing",
      message: "<p>You are about to publish this summary. It will become visible online.</p><p>Are you sure?</p>",
      confirmation: function() {
        SummariesFactory.publish(summary)
          .then(function() {
          $state.go($state.current, {}, {reload: true});
        })
          .catch(function(e) {
          console.error(e)
          return ngToast.create({
            className: 'danger',
            content: e.data.errors,
            timeout: 6000
          }
          )});
      }
    });
  };

  $scope.dismissSummary = function (e, summary) {
    CommonFactory.confirm({
      event: e,
      title: "Dismiss Summary",
      message: "<p>You are going to dismiss this summary.</p><p>Are you sure?</p>",
      confirmation: function() {
        SummariesFactory.reject(summary).then(function() {
          $state.go($state.current, {}, {reload: true});
        });
      }
    });
  };

  $scope.acceptSummary = function (e, summary) {
    CommonFactory.confirm({
      event: e,
      title: "Accept Summary",
      message: "<p>You are going to accept this summary.</p><p>Are you sure?</p>",
      confirmation: function() {
        SummariesFactory.accept(summary).then(function() {
          $state.go($state.current, {}, {reload: true});
        });
      }
    });
  };

  $scope.deleteSummary = function (e, summary) {
    CommonFactory.confirm({
      event: e,
      title: "Deleting published summary",
      message: "<p>You are going to delete this summary. It will no longer be visible in the app. You will not be able to add more summaries to this briefing.</p><p>Are you sure?</p>",
      confirmation: function() {
        SummariesFactory.deleteSummary(summary).then(function() {
          $state.go($state.current, {}, {reload: true});
        });
      }
    });
  };
  $scope.showDeleteButton = false;

  $scope.isOpenedBriefing = function () {
    if ($scope.briefing) {
      return $scope.briefing.status !== "closed";
    } else {
      return false;
    }
  };

  $scope.allSummariesPublishedOrDissmised = function () {
    return every($scope.summaries, ( function(e) { return ((e.status == 'published') || (e.status == 'rejected')); } ));
  };

  $scope.allSummariesDismissed = function () {
    $scope.showDeleteButton = every($scope.summaries, ( function(e) { return e.status == 'rejected'; } ));
    return $scope.showDeleteButton;
  };

  $scope.publishSelected = function(e) {
    CommonFactory.confirm({
      event: e,
      title: "Publishing real Briefing",
      message: "<p>You are about to publish selected summaries from briefing. They will become visible online.</p><p>Are you sure?</p>",
      confirmation: function() {
        var summaryIds = map($scope.selectedListItems, "id");
          SummariesFactory.publishSummaries(summaryIds)
            .then(function() {
            $state.go($state.current, {}, {reload: true});
          })
          .catch(function (e) {
          console.error(e)
          return ngToast.create({
            className: 'danger',
            content: e.data.errors,
            timeout: 6000
          });
        })
      }
    });
  };

  $scope.dismissSelected = function(e) {
    CommonFactory.confirm({
      event: e,
      title: "Dissmis selected summaries",
      message: "<p>You are about to dissmis selected summaries.</p><p>Are you sure?</p>",
      confirmation: function() {
        each($scope.selectedListItems, function(summary, idx) {
          if (idx == $scope.selectedListItems.length -1 ) {
            SummariesFactory.reject(summary).then(function() {
              $state.go($state.current, {}, {reload: true});
            });
          }
          else {
            SummariesFactory.reject(summary);
          }
        });
      }
    });
  };

  $scope.moveUp = function(summary) {
    $http.post(Config.shibiUrl + '/briefings/' + $stateParams.briefingId + '/move_summary.json', { summaryId: summary.id, dir: 'up' }).
      then(function () {
        $scope.$emit('briefing.reload');
      });
  };

  $scope.moveDown = function(summary) {
    $http.post(Config.shibiUrl + '/briefings/' + $stateParams.briefingId + '/move_summary.json', { summaryId: summary.id, dir: 'down' }).
      then(function () {
        $scope.$emit('briefing.reload');
      });
  };

  $scope.moveToOtherSection = function (section, summaryId) {
    if (section !== "empty" && section !== null) {
      $http.post(
        Config.shibiUrl + '/content_publications/' + summaryId + '/change_section.json', { section: section }
      ).then(function () {
        $scope.$emit('briefing.reload');
      });
    }
  };

  $scope.sortableOptions = {
    sendRequest: true,
    disabled: ($scope.briefing.status == 'closed'),

    start: function() {
      this.sendRequest = true;
    },

    beforeStop: function(e, ui) {
      var position = ui.item.sortable.index;
      var prevElem = $scope.summaries[position];

      // if(prevElem.status != 'unpublished') {
      //   ui.item.sortable.cancel();
      //   this.sendRequest = false;
      // }
    },

    stop: function(e, ui) {
      var position = ui.item.index() + 1;
      if(this.sendRequest) {
        $http.post(
          Config.shibiUrl + '/content_publications/' + ui.item.sortable.model.id + '/reorder.json', { position: position - 1 }
        ).
          then(() => {}, response => {
            if (response && response.data && response.data.error) {
              ngToast.create({
                className: 'danger',
                content: response.data.error
              });
            }
          });
      }
    },
    axis: 'y'
  };
  window.scope = $scope;

  $scope.changeSummariesOrder = function (summaries, summaryId, idx, placement, tag) {
    let position = 0;
    if (placement == 'bottom') {
      position = $scope.groupedSummaries[tag].length - 1;
    }

    if ($scope.groupedSummaries[tag]) {
      $scope.groupedSummaries[tag] = $scope.move($scope.groupedSummaries[tag], idx, position);
    }

    $http.post(
      Config.shibiUrl + '/content_publications/' + summaryId + '/reorder.json', { position: position }
    );
  };

  $scope.sortableOptionsForPopup = {
    sendRequest: false,
    disabled: ($scope.briefing.status == 'closed'),

    start: function() {
      this.sendRequest = false;
    },

    beforeStop: function(e, ui) {
      var position = ui.item.sortable.index;
      var prevElem = $scope.summaries[position];
    },

    stop: function(e, ui) {
      var position = ui.item.sortable.index;
    },
    axis: 'y'
  };

  $scope.$on('briefing.reload', function (event, data) {
    $state.go($state.current, {}, {reload: true});
  });

  $scope.preview = function(article) {
    createContentAndOpenPreview(article)
  };

  $scope.isValidUrl = function (url) {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

});
