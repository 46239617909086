angular.module("app").
  controller("ConfirmEmailController", function ($scope, $http, $stateParams, Config) {

    $http.post(Config.shibiUrl + '/analysts/confirm.json', { confirmation_token: $stateParams.token }).
      then(function () {
        $scope.success = true;
      }, function (response) {
        $scope.errorMessage = response.data.errors;
      });
  });
