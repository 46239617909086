import React, { Component } from 'react';
import { Card, CardContent } from '@material-ui/core';
import FormGroup from "@material-ui/core/FormGroup";
import { IOSSwitch } from "./Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { inject, observer } from "mobx-react";
import Cookies from "js-cookie";
import config from 'config';

const style = {
  scrollable: {
    maxWidth: "350px",
    maxHeight: "470px",
    overflow: "scroll"
  },
  title: {
    fontSize: 15,
  },
  menuItem: {
    whiteSpace: "normal"
  },
};

@inject('analystFollowersStore')
@observer
class Settings extends Component {
  state = {
    alertsOn: true,
  };

  async componentDidMount() {
    await this.props.analystFollowersStore.prepareFollowedAnalystList(Cookies.get(`analyst_id${config.ssoSuffix}`));
  }

  render() {

    return (
      <div>
        <div style={style.scrollable}>
          <Card>
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  style={{ padding: "5px", color: "black" }}
                  control={
                    <IOSSwitch
                      id={"follow-all"}
                      checked={this.props.analystFollowersStore.followAll}
                      onChange={() => this.props.analystFollowersStore.toggleAll()}
                      value={this.props.analystFollowersStore.followAll}
                    />
                  }
                  label="Follow all"
                />
                { this.props.analystFollowersStore.followedAnalysts.map(analyst => (
                  <FormControlLabel
                    key={analyst.id}
                    style={{ padding: "5px", color: "black" }}
                    control={
                      <IOSSwitch
                        id={analyst.id}
                        checked={analyst.followed}
                        onChange={() => this.props.analystFollowersStore.toggleAnalyst(analyst)}
                        value={analyst.followed}
                      />
                    }
                    label={analyst.name}
                  />
                ))}
              </FormGroup>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}


export default Settings;
