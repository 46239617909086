import groupBy from 'lodash/groupBy';
import toArray from 'lodash/toArray';

angular.module('app').controller("ArticleSourcesDashboardController", function ($scope, ArticleSourcesFactory) {

  ArticleSourcesFactory.getArticleSources().
    then(function(response) {
      var articleSources = response.article_sources;

      var lists = groupBy(articleSources, function(element, index){
        return Math.ceil(index / 2);
      });

      $scope.groupedArticleSources = toArray(lists);
  });
});
