var app = angular.module('app');
app.factory("ToolFactory", function () {

  var addHttpToUrl = function(url) {
    var regexp = /^(?:http|https)(?::\/{2})/;

    if (regexp.test(url)) {
      return url;
    } else {
      return 'http://' + url;
    }
  }

  return {
    addHttpToUrl: addHttpToUrl,
  };
});
