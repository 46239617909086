angular.module('app').
  controller("ArticlesByClientController", function ($scope, $http, $stateParams, Config, client) {
    $scope.page = $stateParams.page;

    // breadcrumb keys
    $scope.$parent.client_name = client.name;

    $http.get(Config.shibiUrl + '/clients/' + $stateParams.clientId + '/articles.json', { params: { page: $scope.page } }).
      then(function (response) {
        $scope.articles = response.data.articles;
        $scope.totalPages = response.data.meta.total_pages;
      });
});
