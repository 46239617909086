import React, { useEffect, useState } from 'react';
import ArticleHeader from './ArticleHeader';
import ArticleSummary from './ArticleSummary';
import ArticleHighlights from './ArticleHighlights';
import ArticleFooter from './ArticleFooter';
import SmallLoader from '../../../_common/components/SmallLoader';

export default function ArticlePreview(props) {
  const { article, preview, translate, translations, isLoading } = props;
  const [useTranslation, setUseTranslation] = useState(false);

  const styles = {
    root: {
      width: '100%',
      borderBottom: '1px solid #1e88e5',
      borderRight: '1px solid #ddd',
      borderLeft: article.published_today_and_unread
        ? '5px solid #1e88e5'
        : '1px solid #ddd'
    }
  };

  useEffect(() => {
    if(useTranslation) {
      translate(article);
    }
  }, [
    useTranslation
  ]);

  useEffect(() => {}, [translations])

  return (
    <div style={styles.root} className={'article-preview'}>
      <div className={'article-preview-clickable'}>
        <ArticleHeader
          article={useTranslation ? { nla_link: article.nla_link, title: translations.title } : article}
          preview={preview}
          setUseTranslation={setUseTranslation}
          useTranslation={useTranslation}
        />
        {isLoading ? <SmallLoader key='article-translate-loader' /> : null}
        <ArticleSummary
          summary={useTranslation ? translations.summary : article.summary}
          preview={() => preview(article, null, true)}
        />
        {article.highlights && (
          <ArticleHighlights
            highlights={article.highlights}
            preview={() => preview(article, null, true)}
          />
        )}
      </div>
      <ArticleFooter article={article} />
    </div>
  );
}
