import React, {Component} from 'react';
import { Provider } from 'mobx-react';
import Preview from './Preview';
import * as stores from '../../stores';


export default class ArticlePreview extends Component {
  render() {
    return (
      <Provider {...stores}>
        <Preview articleId={this.props.articleId} />
      </Provider>
    );
  }
}
