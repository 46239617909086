angular.module('app').factory('NavAlertFactory', function ($http, Config) {
  var fetchAlerts = function () {
    return $http
      .get(Config.shibiUrl + '/tool_messages.json')
      .then(function (response) {
        return response.data;
      });
  };

  return {
    fetchAlerts
  };
});
