angular.module('app').factory("BriefingTypesFactory", function ($http, Config) {

  var getBriefingType = function (briefingTypeId) {
    return $http.get(Config.shibiUrl + '/content_containers/' +briefingTypeId+ '/briefing_type.json').then(function (response) {
      return response.data.content_container;
    });
  };

  var getBriefingTypes = function () {
    return $http.get(Config.shibiUrl + '/content_containers/briefing_types.json').
      then(function (response) {
        return response.data.briefing_types;
      });
  };

  var updateBriefingTypeSources = function(briefing_type_id, data) {
    return $http.post(Config.shibiUrl + '/content_containers/' + briefing_type_id + '/update_sources', data).then(function (response) {
      return response;
    });
  };

  return {
    getBriefingType: getBriefingType,
    updateBriefingTypeSources: updateBriefingTypeSources,
    getBriefingTypes: getBriefingTypes
  };
});
