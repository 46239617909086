import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { GreenCheckbox } from "./GreenCheckbox";
import { FavouriteCheckbox } from "./FavouriteCheckbox";
import { observer, inject } from "mobx-react";
import SectionButton from "./SectionButton";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = {
  label: {
    width: "100%",
    textAlign: "left",
    color: "#9ca2ab",
    fontSize: "12px"
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px"
  },
  lock: {
    width: "11px",
    height: "11px",
    marginRight: "5px"
  }
};
@inject("contentStore")
@observer
class Row extends Component {

  render() {
    const { row, contentStore, sectorId } = this.props;

    const handleCheckboxChange = () => {
      contentStore.toggleCompany(row.id, sectorId);

    };

    return (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row" style={{ fontSize: "11px", padding: "3px 0 0 10px", width: "20px" }} colSpan={1}>
            {row.has_bespoke && <img style={{...styles.lock}} src="/images/icons/inbox/lock-purple.svg" />}
          </TableCell>
          <TableCell component="th" scope="row" style={{ fontSize: "11px", padding: "3px 0 0 10px"}} colSpan={1}>
            <label style={styles.label}>
              <GreenCheckbox checked={contentStore.selectedCompanyIds.has(row.id)} onChange={ event => handleCheckboxChange(event.target.checked)} size="small" style={styles.checkbox}/>
              {row.name}
            </label>
          </TableCell>
          <TableCell style={{ padding: "3px 0 7px 10px" }} colSpan={9}>
            <TableRow>
              {row.sections.filter((section) => section.section_type != "bespoke").map((section) => (
                <SectionButton key={section.id} section={section} companyId={row.id} sectorId={sectorId}/>
              ))}
            </ TableRow>
            <TableRow>
              {row.sections.filter((section) => section.section_type === "bespoke").map((section) => (
                <SectionButton key={section.id} section={section} companyId={row.id} sectorId={sectorId}/>
              ))}
            </ TableRow>
          </TableCell>
          <TableCell style={{ padding: "3px 10px 0 10px", textAlign: "right"}} colSpan={1}>
            <FavouriteCheckbox checked={row.is_favourite} onChange={() => contentStore.toggleFavouriteCompany(sectorId, row.id)} size="small" />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

}

const TableContainerStyled = withStyles({
  root: {
    overflowX: "visible",
  }
})(TableContainer);

@inject("contentStore")
@observer
export default class CompaniesTable extends Component {
  render() {
    return (
      <TableContainerStyled>
        <Table>
          <TableBody style={{ backgroundColor: "#F0F0F0"}}>
            {this.props.companies.map((company) => (
              <Row key={company.id} row={company} sectorId={this.props.sectorId || company.sectorId}/>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    );
  }
}
