var app = angular.module('app');
app.factory("ArticlesFactory", function ($http, $stateParams, $window, Config) {
  var getArticles = function (article_source_id, page, index) {
    var start = performance.now();
    return $http.get(Config.shibiUrl + '/article_sources/' + article_source_id + '/articles.json', { params: { page: page, without_data_range: true, index: index, received_date: $stateParams.sort == 'received' } }).
      then(function (response) {
        $window._StatHat.push(["_trackValue", Config.stathat.timing.get_articles, performance.now() - start]);
        return {
          articles: response.data.articles,
          totalPages: response.data.meta.total_pages,
          currentPage: response.data.meta.current_page,
          index: response.data.meta.index
        };
      });
  };

  var searchArticles = function (phrase, page, _categories) {
    var categories = _categories || [];
    var start = performance.now();
    return $http.get(Config.shibiUrl + '/articles/search', {
                        params: {
                          search_phrase: phrase,
                          page: page,
                          received_date: $stateParams.sort == 'received',
                          categories: JSON.stringify(categories)
                        }
                      }).
      then(function (response) {
        $window._StatHat.push(["_trackValue", Config.stathat.timing.search_articles, performance.now() - start]);
        return {
          articles: response.data.articles,
          totalPages: response.data.meta.total_pages
        };
      }).catch(function (response) {
        return {
          error: response.data.error
        };
      });
  };


  var getArticle = function (article_id, client_id, search_phrase_id) {
    var p = "";
    if (client_id) {
      p = "?client_id=" + client_id + (search_phrase_id != null ? '&search_phrase_id=' + search_phrase_id : '');
    } else if (search_phrase_id) {
      p = (search_phrase_id != null ? '?search_phrase_id=' + search_phrase_id : '');
    }
    var start = performance.now();
    return $http.get(Config.shibiUrl + '/articles/'+ article_id +'.json' + p).
      then(function (response) {
        $window._StatHat.push(["_trackValue", Config.stathat.timing.get_article, performance.now() - start]);
        return response.data.article;
      });
  };

  var getSourceCategories = function () {
    return $http.get(Config.shibiUrl + '/article_source_types/show_categories.json');
  };

  var markAsRead = function (article_id) {
    return $http.post(Config.shibiUrl + '/articles/' + article_id + '/mark_as_read.json');
  };

  var translate = function (text, article_source_id) {
    return $http.get(Config.shibiUrl + `/translations/translate_text.json`, { params: { text: encodeURIComponent(text), article_source_id } })
  };

  return {
    getArticles: getArticles,
    getArticle: getArticle,
    searchArticles: searchArticles,
    getSourceCategories: getSourceCategories,
    markAsRead: markAsRead,
    translate: translate
  };
});
