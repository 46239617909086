import React, { Component } from 'react';
import { GreenCheckbox } from "./components/GreenCheckbox";
import { inject, observer } from "mobx-react";
import InteractiveTextField from "./components/InteractiveTextField";
import SectorsTable from "./components/SectorsTable";
import createContentAndOpenPreview from "@inbox/helpers/createContentAndOpenPreview"

const styles = {
  labelCheckbox: {
    width: "100%",
    textAlign: "left",
  },
  label: {
    width: "100%",
    textAlign: "left",
    marginBottom: "10px"
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px",
  },
  error: {
    backgroundColor: "#ad0a0a",
    color: "white",
    height: "20px",
    padding: "2px 8px",
    fontSize: "10px"
  }
};

@inject('contentStore')
@observer
export default class CoverageEditor extends Component {
  render() {
    const {articleUrl, setArticleUrl, createClientCoverage, setCreateClientCoverage, articleId, checkBriefingSummaryError, briefingSummaryError, validationErrors, article } = this.props.contentStore;

    const handleOpenCheckbox = event => {
      setCreateClientCoverage(event.target.checked);
      checkBriefingSummaryError();
    }
    return (
      <div style={{ marginTop: "20px" }}>
        {(briefingSummaryError || validationErrors.section) && (
          <div style={styles.error}>
            { validationErrors.section || 'You have to select at least one option' }
          </div>
        )}
        <div style={{ backgroundColor: "#F0F0F0", textAlign: "center", padding: "19px" }}>
          <label style={styles.labelCheckbox}>
            <GreenCheckbox checked={createClientCoverage} onChange={handleOpenCheckbox} style={styles.checkbox}/>
            Create Company Coverage
          </label>
          {createClientCoverage && (
            <div>
              <label style={{ ...styles.label, marginTop: "20px" }}>
                Article URL
                <InteractiveTextField
                  onChange={(e) => setArticleUrl(e.target.value)}
                  disabled={!!articleId}
                  interactiveAvailable={!!articleId}
                  value={articleUrl}
                  interactiveClickAction={()=>{
                    createContentAndOpenPreview(article)
                  }}
                  interactiveActionDescription="Preview"
                  style={{ marginTop: "5px" }}
                />
              </label>
            </div>
          )}
        </div>
        { createClientCoverage && <SectorsTable/> }
      </div>
    )
  }
}
