import { action, observable } from "mobx";
import { API_ROUTES } from "../_app/routes";
import api from "@inbox/_app/api";
import querystring from "qs";

class ArticleListStore {
  @observable articles = undefined;
  @observable totalPages = 0;
  @observable isLoading = false;

  @observable translations = {}

  @observable isLoadingTranslations = {}

  buildQuery = (params) => {
    return querystring.stringify(params, { arrayFormat: "brackets" });
  };

  @action.bound
  async search(params, page, append = false) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    if (page !== undefined) {
      params.page = page;
    }

    try {
      const { data } = await api.get(
        API_ROUTES.searchArticles(this.buildQuery(params))
      );
      const { articles, meta } = data;
      if (append) {
        this.articles =
          this.articles === undefined
            ? articles
            : this.articles.concat(articles);
      } else {
        this.articles = articles;
      }
      this.totalPages = meta.total_pages;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  async translate(article) {
    this.isLoadingTranslations[article.id] = true;
    if(!this.translations[article.id]) {
      const { data: { translated_text: translatedTitle } } = await api.get(
        API_ROUTES.translate, { params: { 
          text: encodeURIComponent(article.title), 
          article_source_id: article.article_source_id
        } }
      );
      const { data: { translated_text: translatedSummary } } = await api.get(
        API_ROUTES.translate, { params: {
          text: encodeURIComponent(article.summary),
          article_source_id: article.article_source_id
        } }
      );
      this.translations[article.id] = {
        title: translatedTitle,
        summary: translatedSummary
      }
    }
    this.isLoadingTranslations[article.id] = false;
    return this.translations[article.id];
  }
}

export default new ArticleListStore();
