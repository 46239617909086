import React, { Component } from "react";
import { Box } from "rebass";
import styled from "styled-components";

const Spinner = styled(Box)`
  background: url("/images/icons/loader-100.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 35px;
  /* margin-top: 30%; */
`;

Spinner.defaultProps = {
  width: 1,
};

class Loader extends Component {
  render() {
    return (
      <>
        {this.props.spinnerOnly ? (
          <Spinner />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(255, 255, 255 , 0.5)",
            }}
          >
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

export default Loader;
