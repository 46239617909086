angular.module("app").
  directive('sentimentMeter', function(ArticlesFactory, PreviewFactory, SearchPhrasesFactory, $state, $window) {
    return {
      restrict: 'E',
      scope: {
        score: '=score'
      },
      template: require("./sentiment_meter.html.haml"),
      link: function (scope, element, attrs) {
        scope.sentiment = '';
        // scope.score = -20;
        scope.scoreBarWidth = Math.abs(scope.score) / 2;

        if (scope.score < 0) {
          scope.scoreSide = -1;
        }
        else if (scope.score === 0) {
          scope.scoreSide = 0;
        }
        else {
          scope.scoreSide = 1;
        }

        switch (true) {
          case ( scope.score >= -100 && scope.score <= -75 ):
            scope.sentiment = 'extremely negative';
            scope.sentimentColorId = 1;
            scope.scoreBarWidth = 50;
            break;
          case ( scope.score > -75 && scope.score <= -50 ):
            scope.sentiment = 'very negative';
            scope.sentimentColorId = 2;
            scope.scoreBarWidth = 37.5;
            break;
          case ( scope.score > -50 && scope.score <= -25 ):
            scope.sentiment = 'negative';
            scope.sentimentColorId = 3;
            scope.scoreBarWidth = 25;
            break;
          case ( scope.score > -25 && scope.score <= -1 ):
            scope.sentiment = 'slightly negative';
            scope.sentimentColorId = 4;
            scope.scoreBarWidth = 10;
            break;
          case ( scope.score === 0 ):
            scope.sentiment = 'neutral';
            scope.sentimentColorId = 0;
            scope.scoreBarWidth = 1;
            break;
          case ( scope.score >= 1 && scope.score <= 25 ):
            scope.sentiment = 'slightly positive';
            scope.sentimentColorId = 5;
            scope.scoreBarWidth = 10;
            break;
          case ( scope.score > 25 && scope.score <= 50 ):
            scope.sentiment = 'positive';
            scope.sentimentColorId = 6;
            scope.scoreBarWidth = 25;
            break;
          case ( scope.score > 50 && scope.score <= 70 ):
            scope.sentiment = 'very positive';
            scope.sentimentColorId = 7;
            scope.scoreBarWidth = 37.5;
            break;
          case ( scope.score > 75 && scope.score <= 100 ):
            scope.sentiment = 'extremely positive';
            scope.sentimentColorId = 8;
            scope.scoreBarWidth = 50;
            break;
        }
      }
    }
});
