import styled from 'styled-components';
import { Image } from 'rebass';

const ClickableImage = styled(Image)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

export default ClickableImage;
