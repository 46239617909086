import React from 'react';
import styled from 'styled-components';
import TextField from './TextField';

const Wrapper = styled.div`
  position: relative;
`;
const Image = styled.img`
  width: 30px;
  position: absolute;
  top: 11px;
  right: 15px;
`;
const InputField = styled(TextField)`
  padding-right: 60px;
`;

const TextFieldWithIcon = ({ src, onClick, ...props }) => (
  <Wrapper>
    <InputField {...props} />
    <Image className="clickable" src={src} onClick={onClick} />
  </Wrapper>
);

export default TextFieldWithIcon;
