import React from 'react';
import { Box, Text } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  border-radius: 4px;
`;

const ForwardMessageBox = ({ forwardedBy, message, ...props }) => (
  <Wrapper {...props}>
    <Text fontSize={20} mb={10}>
      {`This message was forwarded to you by ${forwardedBy}`}
    </Text>
    <Text fontSize={16}>{message}</Text>
  </Wrapper>
);

export default ForwardMessageBox;
