import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const Wrapper = styled(Box)`
  display: inline-block;
  font-size: 16px;
  line-height: 35px;
  border-radius: 25px;
  background-color: #d5d5d5;
`;

const Close = styled.span`
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
  :hover {
    color: #000;
  }
`;

const Tag = ({ children, onClick, ...props }) => (
  <Wrapper {...props}>
    {children}
    <Close onClick={onClick}>&times;</Close>
  </Wrapper>
);

Wrapper.defaultProps = {
  px: 15,
};

export default Tag;
