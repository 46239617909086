import React from 'react'
import Button from "@material-ui/core/Button";

const styles= {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    height: "40px"
  },
  button: {
    color: "#1E88E5",
    fontSize: "11px",
    borderRadius: "50px",
    marginTop: "5px",
    textTransform: "none",
    height: "31.5px"
  },
  input: {
    display: "none",
  },
  output: {
    paddingTop: "9px",
    marginLeft: "10px"
  },
  clearInput: {
    border: "0px",
    backgroundColor: "white",
    height: "20px",
    width: "20px",
    padding: "0px",
    margin: "10px 0px 5px",
    fontWeight: "bold"
  }
}

export default function InputButton(props) {
  const handleFileUploudClick = () => {
    document.getElementById("fileUpload").click();
  }

  const handleFileUpload = event => {
    props.setInput(event.target.files[0])
  }
  return (
    <div style={styles.container}>
      <input id="fileUpload" type="file" style={styles.input} name="attachment" onChange={handleFileUpload}/>
      <Button
        variant="outlined"
        style={styles.button}
        onClick={handleFileUploudClick}>
          Choose file
      </Button>
      {props.fileName && (
        <div style={styles.container}>
          <p style={styles.output}>{props.fileName}</p>
          <button style={styles.clearInput} onClick={() => props.setInput(undefined)}>X</button>
        </div>
      )}
    </div>
  );
}
