import { action, observable } from 'mobx';
import { API_ROUTES } from '@inbox/_app/routes';
import api from '@inbox/_app/api';
import contentStore from './contentStore';

const INITIAL_STATE = {
  searchResults: [],
  chosenCompanies: [],
  startCompaniesValues: [],
  // sectors : [],
  isLoadingSectors: false,
  isLoadingSearch: false,
  groupName: '',
  groupDescription: '',
  isGroupPublic: false,
  group: {},
  isAfterMainAction: false,
  analystMessage: '',
  groupIdForShare: null,
  analystsToSend: [],
  shouldDeleteGroup: false,
  selectedSector: '',
  isValidated: true,
  errorMessage: ''
};

class GroupsStore {
  @observable searchResults = [];
  @observable chosenCompanies = [];
  @observable startCompaniesValues = [];
  @observable sectors = [];
  @observable isLoadingSectors = false;
  @observable isLoadingSearch = false;
  @observable groupName = '';
  @observable groupDescription = '';
  @observable isGroupPublic = false;
  @observable isAfterMainAction = false;
  @observable shouldDeleteGroup = false;
  @observable group = {};
  @observable analysts = [];
  @observable analystsToSend = [];
  @observable analystMessage = '';
  @observable groupIdForShare = null;
  @observable selectedSector = '';
  @observable isValidated = true;
  @observable errorMessage = '';

  constructor() {
    for (const key of Object.keys(this)) {
      if (typeof this[key] === 'function') {
        this[key] = this[key].bind(this);
      }
    }
  }

  @action
  clearStore = () => {
    for (const key of Object.keys(INITIAL_STATE)) {
      this[key] = INITIAL_STATE[key];
    }
  };

  @action
  setGroupName = (groupName) => {
    this.groupName = groupName;
  };

  @action
  setAnalystMessage = (analystMessage) => {
    this.analystMessage = analystMessage;
  };

  @action
  toggleDeleteGroup = (shouldDelete) => {
    this.shouldDeleteGroup = shouldDelete;
  };

  @action
  setGroupDescription = (groupDescription) => {
    this.groupDescription = groupDescription;
  };

  @action
  changeGroupPublic = (checked) => {
    this.isGroupPublic = checked;
  };

  @action
  fetchGroup = async (id) => {
    const { data } = await api.get(API_ROUTES.manageGroup(id));
    this.group = data.data;
    this.groupName = this.group.name;
    this.groupDescription = this.group.description;
    this.chosenCompanies = this.group.companies;
    this.startCompaniesValues = this.group.companies;
  };

  @action
  search = async (query) => {
    this.isLoadingSearch = true;
    this.selectedSector = '';
    const { data } = await api.get(API_ROUTES.searchForGroups, {
      params: { query }
    });
    this.searchResults = data.companies;
    this.isLoadingSearch = false;
  };

  @action
  getSectors = async () => {
    this.isLoadingSectors = true;
    const { data } = await api.get(API_ROUTES.getSectorsForGroups);
    this.sectors = data.data;
    this.isLoadingSectors = false;
  };

  @action
  fetchAnalysts = async () => {
    if (this.analysts.length === 0) {
      const { data } = await api.get(API_ROUTES.getAnalysts);
      this.analysts = data.data;
    }
  };

  @action
  changeSelectedGroups = (value) => {
    this.chosenCompanies = value;
  };

  @action
  changeSelectedAnalysts = (value) => {
    this.analystsToSend = value;
  };

  @action
  setSector = (companies, sectorName) => {
    this.searchResults = companies;
    this.selectedSector = sectorName;
  };

  validateGroup = () => {
    this.isValidated = this.groupName !== '' && this.chosenCompanies.length > 0;
    if (!this.isValidated) {
      this.errorMessage = `Fields "Group name" and "Add companies" cannot be blank`;
    }
  };

  validateShare = () => {
    this.isValidated =
      this.analystMessage !== '' && this.analystsToSend.length > 0;
    if (!this.isValidated) {
      this.errorMessage = `Fields "Share with" and "Add a message" cannot be blank`;
    }
  };

  @action
  createGroup = async () => {
    this.validateGroup();
    if (this.isValidated) {
      const payload = {
        content_container_ids: this.chosenCompanies.map(
          (company) => company.id
        ),
        name: this.groupName,
        description: this.groupDescription,
        is_public: this.isGroupPublic
      };
      const { data } = await api.post(API_ROUTES.createGroup, {
        client_group: payload
      });

      if (data.status === 'CREATED') {
        this.groupIdForShare = data.client_group.id;
        this.isAfterMainAction = true;
        contentStore.isLoading = true;
        contentStore.groups.groups.push(data.client_group);
        contentStore.isLoading = false;
        await this.fetchAnalysts();
      }
    }
  };

  @action
  updateGroup = async () => {
    this.validateGroup();
    if (this.isValidated) {
      const payload = {
        content_container_ids: this.chosenCompanies.map(
          (company) => company.id
        ),
        name: this.groupName,
        description: this.groupDescription,
        is_public: this.isGroupPublic
      };
      const { data } = await api.patch(API_ROUTES.manageGroup(this.group.id), {
        client_group: payload
      });
      if (data.status === 'UPDATED') {
        this.groupIdForShare = this.group.id;
        this.isAfterMainAction = true;
        await this.fetchAnalysts();
        await contentStore.fetchGroupsAndSectors();
      }
    }
  };

  @action
  shareWithAnalysts = async () => {
    this.validateShare();
    if (this.isValidated) {
      const payload = {
        analyst_ids: this.analystsToSend.map((company) => company.id),
        message: this.analystMessage,
        group_id: this.groupIdForShare
      };
      await api.post(API_ROUTES.shareWithAnalysts, payload);
      await contentStore.fetchGroupsAndSectors();
      this.clearStore();
      contentStore.toggleModal(false, null);
    }
  };

  @action
  skipToSharingScreen = async () => {
    await this.fetchAnalysts();
    this.groupIdForShare = this.group.id;
    this.isAfterMainAction = true;
  };

  @action
  removeGroup = async () => {
    await api.delete(API_ROUTES.manageGroup(this.groupIdForShare));
    await contentStore.fetchGroupsAndSectors();
    this.clearStore();
    contentStore.toggleModal(false, null);
  };
}

export default new GroupsStore();
