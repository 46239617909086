var app = angular.module('app');
app.factory("PreviewFactory", function ($window, $timeout) {

  var windowObjectReference = null;
  var PreviousUrl;

  var mapArticleUrl = function (url) {
    return url && url.replace(/[\?\&]*FTCamp.*/g, '') || url;
  };

  var previewAsync = function (strUrl) {
    $timeout(function() {
      preview(strUrl);
    });
  };

  var preview = function (strUrl) {
    if(windowObjectReference == null || windowObjectReference.closed) {
      windowObjectReference = $window.open(mapArticleUrl(strUrl), "TulchanPreview", "resizable,scrollbars,status");
    } else if(PreviousUrl != strUrl) {
      windowObjectReference = $window.open(mapArticleUrl(strUrl), "TulchanPreview", "resizable=yes,scrollbars=yes,status=yes");
      /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    };

    PreviousUrl = strUrl;
  };

  return {
    preview: previewAsync,
  };
});
