import React, { Component } from "react";
import { Box } from "rebass";
import styled from "styled-components";

const Spinner = styled(Box)`
  background: url("/images/icons/loader-100.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 25px;
  margin: 25px auto;
`;

Spinner.defaultProps = {
  width: 1,
};

export default class SmallLoader extends Component {
  render() {
    return <Spinner />;
  }
}
