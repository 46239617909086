import styled from 'styled-components';

const TextArea = styled.textarea`
  margin-top: 5p;
  height: 132px;
  border-radius: 4px;
  padding: 6px 15px;
  border: 1px solid ${props => props.error ? '#ad0a0a' : '#E3E3E3'};
  :focus {
    outline: none !important;
    border:1px solid ${props => props.error ? '#ad0a0a' : '#6B9FFA'};
  };
`;

export default TextArea;
