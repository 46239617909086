angular.module('app').factory("AuthenticationFactory", function (ipCookie, Config) {

  var isLoggedIn = function () {
    const suffix = Config.ssoSuffix;
    const isSSOLogged = (ipCookie(`analyst_token${suffix}`) !== undefined && ipCookie(`analyst_token${suffix}`).length > 0)
    return (ipCookie('analyst_token') !== undefined && ipCookie('analyst_token').length > 0) || isSSOLogged;
  };

  var loggedInAs = function(){
    return (isLoggedIn() ? ipCookie(`analyst_email${Config.ssoSuffix}`) && ipCookie(`analyst_email${Config.ssoSuffix}`).split('@')[0] : "")
  };

  var clearAuthenticationCookies = function () {
    const suffix = Config.ssoSuffix;

    ipCookie.remove(`analyst_id${suffix}`, { domain: '.briefcase.news' });
    ipCookie.remove(`analyst_email${suffix}`, { domain: '.briefcase.news' });
    ipCookie.remove(`analyst_token${suffix}`, { domain: '.briefcase.news' });
    localStorage.removeItem("kefear-jwt");
  };

  var setEmail = function (email) {
    ipCookie("analyst_email", email, { expires: 60 });
  };

  var setToken = function (token) {
    ipCookie("analyst_token", token, { expires: 60 });
  };

  var setId = function (analyst_id) {
    ipCookie("analyst_id", analyst_id, { expires: 60 });
  };

  return {
    isLoggedIn: isLoggedIn,
    loggedInAs: loggedInAs,
    clearAuthenticationCookies: clearAuthenticationCookies,
    setEmail: setEmail,
    setToken: setToken,
    setId: setId
  };
});
