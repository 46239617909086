angular.module('app').controller("InvitationWithPackageController", function ($controller, $scope, $http, Config, PackageFactory) {
  $.extend(this, $controller('InvitationBaseController', {$scope: $scope}));

  PackageFactory.getAllPackages().then(function(response) {
    $scope.packagesList = response;
  });

  $scope.packageId = undefined;

  $scope.invite = function() {
    var params = {
      email: $scope.email || "",
      nla_password: $scope.nlaPassword || "",
      nla_email: $scope.nlaEmail || "",
      package_id: $scope.packageId || "",
    };

    $http.post(Config.shibiUrl + '/invitations.json', params).
      then(function () {
        $scope.success = true;
        $scope.lastEmail = $scope.email;
        $scope.email = "";
        $scope.nlaPassword = "";
        $scope.nlaEmail = "";
      }).
      catch(function (response) {
        $scope.errorMessage = response.data.errors;
      });
  };
});
