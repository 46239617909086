import React, { Component } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { observer, inject } from "mobx-react";


function getButtonStyles(active, primaryColor) {
  const classes = makeStyles({
    outlined: {
      border: `1px solid ${primaryColor}`
    }
  });
  const styles = {
    color: active? "white" : primaryColor,
    backgroundColor: active? primaryColor : "transparent",
    fontSize: "11px",
    borderRadius: "50px",
    marginTop: "5px",
    textTransform: "none",
    marginRight: "10px"
  };

  return { classes: classes(), styles };
}

function getStyles(bespoke, active) {
  return getButtonStyles(active, bespoke ? "#530C74" : "#1E88E5")
}

function InnerButton({section, active, contentStore, companyId, sectorId}) {
  const {classes, styles} = getStyles(section.section_type === "bespoke", active);
  const lockSrc = active? "/images/icons/inbox/lock-white.svg" : "/images/icons/inbox/lock-purple.svg";
  return (
    <Button key={section.id}
      classes={{
        outlined: classes.outlined,
      }}
      variant="outlined"
      style={styles}
      size="small"
      onClick={() => contentStore.toggleSection(section.id, companyId, sectorId)}>
      {section.section_type === "bespoke" && <img style={{width: "11px", height: "11px", marginRight: "5px"}} src={lockSrc}/>}
      {section.name}
    </Button>
  )
}

@inject("contentStore")
@observer
export default class SectionButton extends Component {
  render() {
    const { section, contentStore, companyId, sectorId } = this.props;
    const active = contentStore.selectedSectionIds.has(section.id);
    section.companyId = companyId;
    return (
      <InnerButton section={section} active={active} contentStore={contentStore} companyId={companyId} sectorId={sectorId}/>
    )
  }
}
