import React  from "react";
import { Provider } from "mobx-react";
import * as stores from "../../stores";
import UnpublishedCoveragesCount from './Components/UnpublisheCoveragesCount';
import { useMediaQuery } from '@material-ui/core';

const UnpublishedCoveragesCountContainer = ( ) => {
  const smallScreen = useMediaQuery("(max-width: 1250px)");

  return (
    <Provider {...stores}>
      <UnpublishedCoveragesCount smallScreen={smallScreen} />
    </Provider>
  );
}

export default UnpublishedCoveragesCountContainer;