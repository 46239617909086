import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "./TextField"
import { default as MuiTextField } from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Carousel, { consts } from 'react-elastic-carousel'
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import withStyles from "@material-ui/core/styles/withStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GreenCheckbox } from "./GreenCheckbox";
import TextArea from "./TextArea";

const styles = {
  label: {
    width: "100%",
    textAlign: "left",
    color: "#9ca2ab",
    fontSize: "12px"
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px"
  },
  bottomButtons: {
    fontSize: "11px",
    borderRadius: "50px",
    textTransform: "none",
    marginRight: "10px",
    width: "112px",
    height: "30px",
    backgroundColor: "#07BF24",
    color: "white"
  },
  leftBottomButtons: {
    fontSize: "11px",
    borderRadius: "50px",
    textTransform: "none",
    height: "30px",
    backgroundColor: "transparent",
    color: "#9ca2ab",
    marginRight: "100px",
    width: "150px"
  }
};

const StyledCard = styled.div`
  border: 1px solid #e3e3e3;
  min-height: 80px;
  border-radius: 4px;
  margin-bottom: 6px;
`;

const BackArrow = withStyles({
  root: {
    color: "#1e87e5",
  }
})(ArrowBackIosIcon);

const NextArrow = withStyles({
  root: {
    color: "#1e87e5",
  }
})(ArrowForwardIosIcon);

const IconButtonStyled = withStyles({
  root: {
    padding: 0,
  }
})(IconButton);

const AutocompleteStyled = withStyles({
  // root: {
  //   "&:focus": {
  //     outlineColor: "#6B9FFA"
  //   },
  // },
  // inputFocused: {
  //   borderColor: "#6B9FFA",
  //   outlineColor: "#6B9FFA"
  // },
  inputRoot: {
    padding: "3px",
    '& $input': {
      border: "0"
    }
  }
})(Autocomplete);

@inject("groupsStore")
@observer
export default class GroupModal extends Component {

  componentDidMount() {
    this.props.groupsStore.getSectors();
  }

  componentDidUpdate(prevProps) {
    if (this.props.groupId !== prevProps.groupId) {
      if (this.props.groupId !== null) {
        this.props.groupsStore.fetchGroup(this.props.groupId);
      }
    }
  }

  render() {

    const {
      search,
      searchResults,
      sectors,
      isLoadingSectors,
      changeSelectedGroups,
      setSector,
      startCompaniesValues,
      setGroupName,
      setGroupDescription,
      isGroupPublic,
      changeGroupPublic,
      createGroup,
      group,
      groupName,
      groupDescription,
      clearStore,
      updateGroup,
      isAfterMainAction,
      analysts,
      analystMessage,
      setAnalystMessage,
      shareWithAnalysts,
      changeSelectedAnalysts,
      skipToSharingScreen,
      removeGroup,
      shouldDeleteGroup,
      toggleDeleteGroup,
      selectedSector,
      isValidated,
      errorMessage
    } = this.props.groupsStore;

    const arrow = ({ type, onClick, isEdge }) => {
      const pointer = type === consts.PREV ? <BackArrow/> : <NextArrow/>;
      return (
        <Button style={{ padding: "15px 10px" }} onClick={onClick} disabled={isEdge}>
          {pointer}
        </Button>
      )
    };

    const closeModal = () => {
      clearStore();
      this.props.onClose();
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={() => closeModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle style={{ textAlign: "left", minWidth: "400px", backgroundColor: "#34064b", color: "white" }}>
          <div style={{ textAlign: "left", display: "inline-block" }}>
            Create new group
          </div>
          <div style={{ textAlign: "right", display: "inline-block", marginLeft: "167px" }}>
            <IconButtonStyled aria-label="close" onClick={() => closeModal()}>
              <CloseIcon style={{ color: "white" }}/>
            </IconButtonStyled>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {!this.props.groupId &&
          <StyledCard>
            <Carousel style={{ height: "80px" }} renderArrow={arrow} itemsToShow={1} pagination={false}>
              {!isLoadingSectors && sectors.map(sector =>
                <div key={sector.id} onClick={() => setSector(sector.companies, sector.name)}
                  style={{height: "80px", width: "100%", padding: "10px 0", textAlign: "left", display: "block" }}>
                  <p style={{ fontSize: "14px", textAlign: "left", color: "#505a6b" }}>{sector.name}</p>
                  <p style={{ fontSize: "12px", textAlign: "left", color: "#9ca2ab" }}>{sector.description}</p>
                </div>
              )}
            </Carousel>
          </StyledCard>}
          {this.props.groupId &&
          <div>
            <p style={{ fontSize: "14px", color: "#505a6b" }}>Group
              owner: {group.owner_fullname || group.owner_email}</p>
          </div>}
          <div>
            <label style={styles.label}>
              Group name
              <TextField value={groupName} style={{ marginTop: "5px" }}
                onChange={event => setGroupName(event.target.value)}/>
            </label>
          </div>
          <div>
            <label style={styles.label}>
              Group description
              <TextField value={groupDescription} style={{ marginTop: "5px" }}
                onChange={event => setGroupDescription(event.target.value)}/>
            </label>
          </div>
          <div>
            <label style={{ ...styles.label, marginBottom: "10px", whiteSpace: "pre-wrap" }}>
              Add companies {"\n"}
              {selectedSector? `Selected ${selectedSector} - expand to view companies` : ""}
              {(!this.props.groupId || startCompaniesValues.length > 0) &&
              <AutocompleteStyled
                style={{ marginTop: "5px", maxWidth: "360px" }}
                multiple
                filterSelectedOptions
                options={searchResults}
                onChange={(event, value) => changeSelectedGroups(value)}
                getOptionLabel={(option) => option.name}
                onInputChange={event => search(event.target.value)}
                defaultValue={startCompaniesValues}
                renderInput={(params) => (
                  <MuiTextField {...params} variant={"outlined"}/>
                )}
              />}
            </label>
          </div>
          <div>
            {!this.props.groupId &&
           <label style={{ ...styles.label, marginTop: "10px" }}>
             <GreenCheckbox checked={isGroupPublic} onChange={event => changeGroupPublic(event.target.checked)}
               style={styles.checkbox}/>
              Available for all analyst
           </label>}
          </div>
          {isAfterMainAction &&
          <div style={{ borderTop: "3px solid #e3e3e3", marginTop: "20px", marginBottom: "20px" }}>
            <div style={{ marginTop: "20px" }}>
              <label style={styles.label}>
                Share with
                {analysts.length > 0 &&
                <AutocompleteStyled
                  style={{ marginTop: "5px", maxWidth: "360px" }}
                  multiple
                  filterSelectedOptions
                  id="tags-outlined"
                  options={analysts}
                  onChange={(event, value) => changeSelectedAnalysts(value)}
                  getOptionLabel={(option) => (option.first_name && option.last_name) ? `${option.first_name} ${option.last_name}` : option.email}
                  renderInput={(params) => (
                    <MuiTextField {...params} variant="outlined"/>
                  )}
                />}
              </label>
            </div>
            <div style={{ minWidth: "340px" }}>
              <label style={styles.label}>
                Add a message
                <TextArea style={{ height: "70px", marginTop: "5px" }} value={analystMessage}
                  onChange={event => setAnalystMessage(event.target.value)}/>
              </label>
            </div>
          </div>}
          {!isValidated && <p style={{fontSize: "11px", marginTop: "10px" ,color: "#ad0a0a"}}>{errorMessage}</p>}
        </DialogContent>
        <DialogActions>
          {this.props.groupId && !isAfterMainAction &&
          <Button groupcolor="primary" onClick={skipToSharingScreen} style={styles.leftBottomButtons}>Skip to sharing
            screen</Button>
          }
          {this.props.groupId && isAfterMainAction && !shouldDeleteGroup &&
          <Button groupcolor="primary" onClick={() => toggleDeleteGroup(true)} style={styles.leftBottomButtons}>Remove
            group</Button>
          }
          {!shouldDeleteGroup && (this.props.groupId ?
            (isAfterMainAction ? <Button onClick={shareWithAnalysts} style={styles.bottomButtons}>Send now</Button> :
              <Button onClick={updateGroup} groupcolor="primary" style={styles.bottomButtons}>Apply changes</Button>)
            : (isAfterMainAction ? <Button onClick={shareWithAnalysts} style={styles.bottomButtons}>Send now</Button> :
              <Button onClick={createGroup} groupcolor="primary" style={styles.bottomButtons}>Create group</Button>))
          }
          {(this.props.groupId && shouldDeleteGroup) &&
          <div>
            <Button groupcolor="primary" onClick={() => toggleDeleteGroup(false)} style={{
              ...styles.bottomButtons,
              backgroundColor: "transparent",
              color: "#9ca2ab"
            }}>Ignore</Button>
            <Button groupcolor="primary" onClick={removeGroup}
              style={{ ...styles.bottomButtons, backgroundColor: "#e64a19" }}>Confirm</Button>
          </div>
          }
        </DialogActions>
      </Dialog>
    );
  }
}
