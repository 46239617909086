var app = angular.module('app');
app.factory("ClientNotificationFactory", function ($http, Config) {

  var getGlobalClientNotificationStatus = function () {
    return $http.get(Config.shibiUrl + '/analysts/get_global_email_client_notification_status.json', {}).
      then(function (response) {
        return response.data.client_notification_status;
      });
  };

  var updateGlobalClientNotificationStatus = function (status) {
    return $http.patch(Config.shibiUrl + '/analysts/update_global_email_client_notification_status.json', { status: status });
  };

  var updateClientNotificationStatus = function (client_id, status, liveAlertsStatus) {
    return $http.patch(Config.shibiUrl + '/analyst_notifications/update.json', { client_id: client_id, email_enabled: status, live_alerts_enabled: liveAlertsStatus });
  };

  var updateSector = function (sector, status) {
    return $http.patch(Config.shibiUrl + '/analyst_notifications/update_sector.json', { sector: sector, status: status });
  };

  var updateAlertStatusGroup = function (alert_status, status) {
    return $http.patch(Config.shibiUrl + '/analyst_notifications/update_alert.json', { alert_status: alert_status, status: status });
  };

  var updateAlertTypeGroup = function (type, status) {
    return $http.patch(Config.shibiUrl + '/analyst_notifications/update_type.json', { type: type, status: status });
  };


  return {
    getGlobalClientNotificationStatus: getGlobalClientNotificationStatus,
    updateGlobalClientNotificationStatus: updateGlobalClientNotificationStatus,
    updateClientNotificationStatus: updateClientNotificationStatus,
    updateSector: updateSector,
    updateAlertStatusGroup: updateAlertStatusGroup,
    updateAlertTypeGroup: updateAlertTypeGroup,
  };
});
