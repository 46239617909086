import React, { Component } from "react";
import { GreenCheckbox } from "./components/GreenCheckbox";
import Button from "@material-ui/core/Button";
import InteractiveTextField from "./components/InteractiveTextField";
import { inject, observer } from "mobx-react";
import createContentAndOpenPreview from "@inbox/helpers/createContentAndOpenPreview"


const styles = {
  labelCheckbox: {
    width: "100%",
    textAlign: "left",
  },
  label: {
    width: "100%",
    textAlign: "left",
    marginBottom: "10px",
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px",
  },
  error: {
    backgroundColor: "#ad0a0a",
    color: "white",
    height: "20px",
    padding: "2px 8px",
    fontSize: "10px",
  },
};

@inject("contentStore")
@observer
class BriefingButton extends Component {
  render() {
    const { briefingType, onClick, contentStore } = this.props;
    let colors = {};

    if (contentStore.briefingTypeIds.indexOf(briefingType.id) >= 0) {
      colors.backgroundColor = "#1E88E5";
      colors.color = "white";
    } else {
      colors.backgroundColor = "transparent";
      colors.color = "#1E88E5";
    }
    return (
      <Button
        key={briefingType.id}
        variant="outlined"
        style={{
          ...colors,
          fontSize: "11px",
          borderRadius: "50px",
          marginTop: "5px",
          textTransform: "none",
          marginRight: "10px",
        }}
        onClick={() => onClick(briefingType)}
      >
        {briefingType.name}
      </Button>
    );
  }
}

@inject("contentStore")
@observer
export default class SummaryEditor extends Component {
  componentDidUpdate() {
    if (this.props.contentStore.createBriefing){
      this.props.contentStore.fetchBriefingTypes();
    }
  }

  render() {
    const {
      articleUrl,
      setArticleUrl,
      createBriefing,
      setCreateBriefing,
      articleId,
      checkBriefingSummaryError,
      briefingSummaryError,
      validationErrors,
      article,
    } = this.props.contentStore;

    const handleOpenCheckbox = (event) => {
      setCreateBriefing(event.target.checked);
      checkBriefingSummaryError();
    };

    return (
      <div style={{ marginTop: "20px" }}>
        {(briefingSummaryError || validationErrors.briefingType) && (
          <div style={styles.error}>
            {validationErrors.briefingType ||
              "You have to select at least one option"}
          </div>
        )}
        <div
          style={{
            backgroundColor: "#F0F0F0",
            textAlign: "center",
            padding: "19px",
          }}
        >
          <label style={styles.labelCheckbox}>
            <GreenCheckbox
              checked={createBriefing}
              onChange={handleOpenCheckbox}
              style={styles.checkbox}
            />
            Create Briefing
          </label>
          {createBriefing && (
            <div>
              <label style={{ ...styles.label, marginTop: "20px" }}>
                Article URL
                <InteractiveTextField
                  onChange={(e) => setArticleUrl(e.target.value)}
                  disabled={!!articleId}
                  interactiveAvailable={!!articleId}
                  value={articleUrl}
                  interactiveClickAction={()=>{
                    createContentAndOpenPreview(article)
                  }}
                  interactiveActionDescription="Preview"
                  style={{ marginTop: "5px" }}
                />
              </label>
              <label style={styles.label}>
                <p>Briefing Type</p>
                {this.props.contentStore.briefingTypes && this.props.contentStore.briefingTypes.map((briefingType) => (
                  <BriefingButton
                    key={briefingType.id}
                    briefingType={briefingType}
                    onClick={this.props.contentStore.setBriefingType}
                  />
                ))}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}
