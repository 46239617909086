import React from "react";

export default function ArticleSummary(props) {
  const styles = {
    root: {
      padding: "9px 11px 11px 24px",
      fontSize: ".875rem",
      lineHeight: "1.125rem",
      textAlign: "justify",
      cursor: "pointer",
    },
  };
  return (
    <div
      onClick={props.preview}
      dangerouslySetInnerHTML={{ __html: props.summary || "" }}
      style={styles.root}
    ></div>
  );
}
