import { action, observable, computed } from 'mobx';
import { API_ROUTES } from '../_app/routes';
import api from '@inbox/_app/api';
import { extend } from 'lodash';
import querystring from 'qs';
import moment from 'moment';

const INIT_PARAMS = {
  from_date: moment().subtract(30, 'days').format('DD-MM-YYYY'),
  to_date: moment().format('DD-MM-YYYY'),
  search_phrase: '',
  orig_guid: '',
  article_sources: [],
  fetchedArticleSources: [],
  selectedArticleSources: []
};

class SearchContainerStore {
  @observable search_phrase = '';
  @observable from_date = moment().subtract(30, 'days').format('DD-MM-YYYY');
  @observable to_date = moment().format('DD-MM-YYYY');
  @observable article_sources = [];
  @observable orig_guid = '';
  @observable fetchedArticleSources = [];
  @observable selectedArticleSources = [];
  @observable showAdditionalFilters = false;

  defaultQueryParams = {
    categories: ['ALL'],
    page: 1,
    received_date: false
  };

  @action.bound
  async fetchArticleSources() {
    try {
      const {
        data: { article_sources }
      } = await api.get(API_ROUTES.getArticleSources());
      const mappedSources = article_sources.map((e) => {
        return {
          id: e.id,
          title: e.title
        };
      });
      this.setFetchedArticleSources(mappedSources);

      if (
        this.article_sources.length > 0 &&
        this.selectedArticleSources.length === 0
      ) {
        this.setSelectedArticleSources(
          mappedSources.filter((e) => this.article_sources.indexOf(e.id) !== -1)
        );
      }
    } catch (e) {
      console.error(e);
      this.setFetchedArticleSources(['loading error']);
    }
  }

  @action.bound
  setFetchedArticleSources(sources) {
    this.fetchedArticleSources = sources;
  }

  @action.bound
  setSelectedArticleSources(sources) {
    this.selectedArticleSources = sources;
  }

  @computed
  get isActive() {
    return this.search_phrase;
  }

  buildQuery = () => {
    const queryParams = this.clearObj({
      from_date: this.from_date,
      to_date: this.to_date,
      search_phrase: this.search_phrase,
      orig_guid: this.orig_guid,
      article_sources: this.article_sources
    });

    return querystring.stringify(
      extend({ ...this.defaultQueryParams }, queryParams),
      { arrayFormat: 'brackets' }
    );
  };

  clearObj(obj) {
    const result = {};
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] === undefined ||
        obj[key] === null ||
        obj[key] === [] ||
        obj[key] === ''
      ) {
        return;
      }
      result[key] = obj[key];
    });
    return result;
  }

  @action
  clearParams = () => {
    for (const key of Object.keys(INIT_PARAMS)) {
      this[key] = INIT_PARAMS[key];
    }
  };

  @action.bound
  setShowAdditionalFilters() {
    this.showAdditionalFilters = !this.showAdditionalFilters;
  }

  @action.bound
  getDateHyphenFormat(date = new Date()) {
    if (typeof date === 'string') {
      return date;
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return (
      (day < 10 ? '0' + day : day) +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      date.getFullYear()
    );
  }

  @action
  search = async () => {
    if (this.search_phrase) {
      window.location.href = `/search?${this.buildQuery()}`;
    }
  };

  @action
  clearSearch = () => {
    this.search_phrase = '';
  };

  @action
  setSearch = (query) => {
    this.search_phrase = query;
  };

  @action
  setFromDate = (date) => {
    this.from_date = date;
  };

  @action
  setToDate = (date) => {
    this.to_date = date;
  };

  @action.bound
  setArticleSources = (value) => {
    this.article_sources = value;
  };

  @action
  setOrigGuid = (orig_guid) => {
    this.orig_guid = orig_guid;
  };
}

export default new SearchContainerStore();
