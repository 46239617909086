
angular.module("app").directive('silentPublishPopup', ['ContentContainersFactory', '$stateParams', function(ContentContainersFactory, $stateParams) {
  return {
    restrict: 'AE', //attribute or element
    template: require("./silent_publish_popup.html.haml"),
    link: function($scope, elem, attr, ctrl) {
      $scope.silentPopupOpened = false; // popup closed on init

      const close = () => {
        $scope.silentPopupOpened = false;
        angular.element('body').removeClass('silent-popup-opened');
      };

      $scope.silentPopupOpen = function() {
        angular.element('body').addClass('silent-popup-opened');
        $scope.silentPopupOpened = true;
      };

      $scope.silentPopupClose = function(event) {
        ContentContainersFactory.silentlyCloseBriefing($scope.$parent.briefing.id).then(close);
      };

      $scope.silentPopupCancel = function() {
        close();
      };

    }
  };
}]);
