angular
.module('app')
.factory("ClientGroupsJoinFactory", function (
  $http,
  Config
) {

  var joinGroup = function(groupId) {
    return $http.post(Config.shibiUrl + '/merged_client_groups/join', { "group_id": groupId })
  }

  return {
    joinGroup: joinGroup
  };
});
