import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Text } from 'rebass';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    bottom: '20px',
    right: '20px',
    top: 'auto',
    left: 'auto',
    width: '100%',
    maxWidth: '250px',
    maxHeight: '70px',
    borderRadius: '20px',
    background: '#4BB543 none repeat scroll 0',
    overflow: 'hidden',
    textAlign: 'center',
  },
  overlay: {
    background: null,
    zIndex: 10000,
  },
};

@inject('commonStore')
@observer
export default class MessageModal extends Component {
  static propTypes = {
    commonStore: PropTypes.shape({
      toggleMessageSentDialog: PropTypes.func.isRequired,
      sendSuccessDialogOpened: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const {
      sendSuccessDialogOpened,
      toggleMessageSentDialog,
    } = this.props.commonStore;

    return (
      <Modal
        isOpen={sendSuccessDialogOpened}
        onRequestClose={toggleMessageSentDialog}
        style={customStyles}
      >
        <Text
          color="#fff"
          className="clickable"
          onClick={toggleMessageSentDialog}
        >
          Message successfully sent.
        </Text>
      </Modal>
    );
  }
}
