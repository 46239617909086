import React from "react";
import { Provider, observer, inject } from "mobx-react";
import * as stores from "../../../stores";
import { Badge, } from "@material-ui/core";

const OpenTodosNav = () =>
  <Provider {...stores}>
    <IconWithBadge/>
  </Provider>

const IconWithBadge = inject('todoStore')(observer(({ todoStore }) =>
  <Badge badgeContent={todoStore.uncompletedTodos} color="error">
    <img src="/images/icons/todo-list.svg" style={{ height: 20, marginBottom: 3 }}/>
  </Badge>
))

export default OpenTodosNav;