import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  border-radius: 11px;
  padding: 4px 15px;
  font-size: 12px;
  ${props => props.secondary
    && `
    background-color: #F5A623;
    color: black;
    border-radius: 0;
  `};
`;

const KeywordHighlight = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

KeywordHighlight.defaultProps = {
  bg: '#4A90E2',
  color: '#fff',
  secondary: false,
};

KeywordHighlight.propTypes = {
  secondary: PropTypes.bool,
  bg: PropTypes.string,
  color: PropTypes.string,
};

export default KeywordHighlight;
