angular.module('app').controller("ClientListController", function ($scope, $state, ClientsFactory) {

  var reloadClients = function(){
    ClientsFactory.getClients().then(function(clients){
      var clients = clients;
      $scope.clients = clients;
      $scope.clientsLength = clients.length;
    });
  };

  reloadClients();

  $scope.$on('clients.reload',function (event,  data, ClientsFactory) {
    reloadClients();
  });

});
