import find from 'lodash/find';

angular.module('app').
  controller("ArticlesByPhraseController", function ($rootScope, $scope, $http, $stateParams, Config, client, searchPhrases) {
    $scope.page = $stateParams.page;

    // breadcrumb keys
    $scope.$parent.client_name = client.name;
    $scope.$parent.search_phrase_name = find(searchPhrases, function (sp) {
      return sp.id == $stateParams.searchPhraseId;
    }).phrase;

    $http.get(Config.shibiUrl + '/search_phrases/' + $stateParams.searchPhraseId + '/articles.json', { params: { page: $scope.page } }).
      then(function (response) {
        $scope.articles = response.data.articles;
        $scope.totalPages = response.data.meta.total_pages;
      });
});
