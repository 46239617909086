angular
.module('app')
.controller("ClientGroupsJoinController", function (
  $scope,
  $stateParams,
  ClientGroupsJoinFactory
) {
  ClientGroupsJoinFactory.joinGroup($stateParams.groupId).then(function(res) {
    if (res.status == 204) {
      $scope.message = "You have successfully joined the group"
      $scope.status = "SUCCESS"
    } else {
      $scope.message = "There was a problem while joining the group"
      $scope.status = "FAILURE"
    }
  }).catch(function(err) {
    if (err.status == 404) {
      $scope.message = "The group doesn't exist"
      $scope.status = "FAILURE"
    } else {
      $scope.message = err.data.message;
      $scope.status = "FAILURE"
    }
  });
});
