angular.module("app").
  controller("ChangePasswordController", function ($scope, $http, $stateParams, Config) {

    $scope.changePassword = function () {
      var params = { 
        analyst: { 
          password: $scope.password,
          password_confirmation: $scope.password_confirmation,
          reset_password_token: $stateParams.token,
        },
      };

      $http.patch(Config.shibiUrl + '/analysts/password.json', params).
        then(function () {
          $scope.updateSuccessful = true;
        }, function (response) {
          $scope.errorMessage = response.data.errors;
          $scope.password = "";
          $scope.password_confirmation = "";
        });
    };

  });
