import React from 'react';
import {Card, CardContent, Button } from '@material-ui/core';

const styles = {
  root: {
    maxWidth: "350px",
    width: "350px",
    position: "fixed",
    bottom: "50px",
    right: "50px",
  },
  title: {
    fontSize: 15,
  },
};


export default class Notification extends React.Component {

  handleUrlClick() {
    this.props.markAsRead(this.props.alert);
    window.open(this.props.alert.content_publication_url);
  }

  render() {
    return (
      <div style={styles.root}>
        <Card style={{backgroundColor: "#eeefda"}}>
          <CardContent>

            <div style={styles.title}>
              <div style={{width:"100%"}}>
                <div style={{width:"80%", float: "left"}}>
                  {this.props.alert.analyst_name} just created a summary for {this.props.alert.company_name} - {this.props.alert.article_source}
                </div>
                <div style={{float: "right", width:"20%", paddingRight: "5px"}}>
                  <Button size="small" onClick={() => this.props.onClose(this.props.alert)} >X</Button>
                </div>
              </div>
              <br />
              <div style={{width:"100%"}}>
                <Button onClick={this.handleUrlClick.bind(this)} style={{width:"50%", marginTop: "20px", textAlign:"left"}} size="small">Edit and publish</Button>
                <Button onClick={() => this.props.markAsRead(this.props.alert)} style={{width:"50%", marginTop: "20px", textAlign:"left"}} size="small">Mark as read</Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
