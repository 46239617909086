angular.module('app').controller("AccordionController", function($scope, $element, $attrs) {
  //initiate an array to hold all active tabs
  $scope.activeTabs = [];
  $scope.loadingTabs = [];

  $scope.showHide = {};
  $scope.toggleText = '';

  if ($attrs.options) {
    $scope.showHide.options = $.parseJSON( $attrs.options );
  }
  else {
    $scope.showHide.options = {};
  }

  if ($scope.showHide.options.opened) {
    $scope.activeTabs = $scope.showHide.options.opened;
  }

  $scope.$on('openAccordion', function(event, data) {
    $scope.openTab('client_' + data.client.id);
  });

  //check if the tab is active
  $scope.isOpenTab = function(id) {
    //check if this tab is already in the activeTabs array
    if ($scope.activeTabs.indexOf(id) > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  $scope.tabLoading = function(id) {
    //check if this tab is already in the activeTabs array
    if ($scope.loadingTabs.indexOf(id) > -1 && $scope.activeTabs.indexOf(id) == -1) {
      return true;
    }
    else {
      return false;
    }
  }

  //function to 'open' a tab
  $scope.openTab = function(id) {
    if ($scope.isOpenTab(id)) {
      $scope.activeTabs.splice($scope.activeTabs.indexOf(id), 1);
    }
    else {
      $scope.loadingTabs.push(id);
    }
  }

  $scope.$on('dataLoaded', function(event, id){
    $scope.activeTabs.push(id);
    $scope.loadingTabs.splice(id, 1);
  });

});
