import axios from 'axios';
import Cookies from 'js-cookie';
import config from 'config';

const axiosInstance = axios.create();

export const setApiHeaders = () => {

  const suffix = config.ssoSuffix;
  const email = Cookies.get(`analyst_email${suffix}`) || Cookies.get('analyst_email');
  const token = Cookies.get(`analyst_token${suffix}`) || Cookies.get('analyst_token');
  const id = Cookies.get(`analyst_id${suffix}`) || Cookies.get('analyst_id');

  axiosInstance.defaults.headers.common = {
    'Content-Type': 'application/json',
    'kefear-jwt': `Bearer ${localStorage.getItem('kefear-jwt')}`,
    'X-ANALYST-EMAIL': email,
    'X-ANALYST-TOKEN': token,
    'X-ANALYST-ID': id,
  };
};

setApiHeaders();

export default axiosInstance;
