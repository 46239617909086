import * as React from "react";

function SvgIconPlaintextBlue(props) {
  return (
    <svg
      id="icon-plaintext-blue_svg__icon-plaintext-active"
      viewBox="4365 1865 12 15"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".icon-plaintext-blue_svg__cls-2{fill:none;stroke:#fff;stroke-linecap:round}"
          }
        </style>
      </defs>
      <path
        id="icon-plaintext-blue_svg__Path_1730"
        data-name="Path 1730"
        d="M-.143 0A.847.847 0 00-1 .882v13.236A.857.857 0 00-.143 15h10.286a.847.847 0 00.857-.882V.882A.857.857 0 0010.143 0z"
        transform="translate(4366 1865)"
        fill="#1e88e5"
      />
      <path
        id="icon-plaintext-blue_svg__Path_1732"
        data-name="Path 1732"
        className="icon-plaintext-blue_svg__cls-2"
        d="M0 0h7.714"
        transform="translate(4367.143 1868.53)"
      />
      <path
        id="icon-plaintext-blue_svg__Path_1733"
        data-name="Path 1733"
        className="icon-plaintext-blue_svg__cls-2"
        d="M0 0h7.714"
        transform="translate(4367.143 1871.177)"
      />
      <path
        id="icon-plaintext-blue_svg__Path_1734"
        data-name="Path 1734"
        className="icon-plaintext-blue_svg__cls-2"
        d="M0 0h7.714"
        transform="translate(4367.143 1873.824)"
      />
      <path
        id="icon-plaintext-blue_svg__Path_1735"
        data-name="Path 1735"
        className="icon-plaintext-blue_svg__cls-2"
        d="M0 0h3.819"
        transform="translate(4367.143 1876.471)"
      />
    </svg>
  );
}

export default SvgIconPlaintextBlue;

