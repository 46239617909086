import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Box, Text } from 'rebass';
import SideBar from './components/SideBar/index.js';
import AlertsContainer from './alerts/container';
import SettingsContainer from './settings/container';
import { APP_ROUTES } from './_app/routes';

@withRouter
@inject('commonStore')
@observer
export default class Routes extends Component {
  componentDidMount() {
    const {
      location: { pathname },
      push,
    } = this.props.history;
    if (!pathname.includes('alerts')) {
      push(APP_ROUTES.alerts);
    }
  }

  render() {
    const { connectionStatus } = this.props.commonStore;

    return (
      <Fragment>
        <SideBar />
        {connectionStatus ? (
          <Box
            mt={20}
            p={20}
            width={[0.98, 0.98, 0.98, 700]}
            className="alert alert-danger"
          >
            <Text fontSize={26} fontWeight="bold" mb={30}>
              Oops! Something went wrong!
            </Text>
            <Text fontSize={20}>{connectionStatus}</Text>
          </Box>
        ) : (
          <Switch>
            <Route
              exact
              path={APP_ROUTES.alerts}
              component={() => <AlertsContainer isTrash={false} />}
            />
            <Route
              exact
              path={APP_ROUTES.trash}
              component={() => <AlertsContainer isTrash />}
            />
            <Route
              exact
              path={APP_ROUTES.settings}
              component={SettingsContainer}
            />
          </Switch>
        )}
      </Fragment>
    );
  }
}
