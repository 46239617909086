angular.module('app').controller("LogoutController", function ($http, $state, AuthenticationFactory, Config, $window) {
  if(Config.ssoEnabled) {
    AuthenticationFactory.clearAuthenticationCookies();
    const url = new URL($window.location.href);
    $window.location.href = Config.ssoUrl + `/?signout=true&origin=${url.origin}/login`
  } else {
    $http.delete(Config.shibiUrl + '/analysts/sign_out.json').
    then(function () {
      AuthenticationFactory.clearAuthenticationCookies();
      $state.go('login');
    });
  }
});
