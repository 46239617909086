import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import Button from '@inbox/_common/components/Button';
import FilterContainer from './FilterContainer';
import TextField from '../../_common/components/forms/TextFieldWithIcon';

@observer
class SearchFilters extends Component {
  render() {
    const {
      searchFilters: { show, phrase },
      onChange,
      toggleSearch,
    } = this.props;
    if (!show) {
      return null;
    }
    return (
      <FilterContainer heading="Search Inbox">
        <Flex justifyContent="space-between" alignItems="center">
          <Box width={1} mr={9} disabled>
            <TextField
              src="/images/icons/inbox/search_icon.svg"
              placeholder="Type something"
              onChange={e => onChange(e.target.value)}
              value={phrase}
              onClick={() => onChange(phrase)}
            />
          </Box>
          <Button width={55} onClick={toggleSearch}>
            x
          </Button>
        </Flex>
      </FilterContainer>
    );
  }
}

SearchFilters.protoTypes = {
  searchFilters: PropTypes.shape({
    value: PropTypes.string.isRequired,
    phrase: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
};

export default SearchFilters;
