import React, {Component} from 'react';
import Dropdown from "./Dropdown";
import NotificationsContainer from "./NotificationsContainer";
import { Provider } from 'mobx-react';
import * as stores from '../../stores';

export default class ContentPublicationAlerts extends Component {
  render() {
    return (
      <Provider {...stores}>
        <div style={{marginLeft: "30px"}}>
          <Dropdown/>
          <NotificationsContainer/>
        </div>
      </Provider>
    );
  }
}
