var app = angular.module('app');
app.config(function($translateProvider) {

  var translations = {
    "authentication": {
      "link": {
        "login": "Log In",
        "logout": "Logout"
        },
      "form": {
        "header": {
          "login": "Login",
          "register": "Register",
          "request_password_reset": "Request password reset",
          "change_password": "Change password",
          "register_user": "Register user",
        },
        "email": "Email",
        "password": "Password",
        "default_nla_password": "Default password for NLA account",
        "nla_email": "Email for NLA account",
        "password_confirmation": "Confirm your password",
        "email_prefix": "Username",
        "hint": "Please provide your credentials",
        "btn": {
          "login": "Log in",
          "register": "Register",
          "request_password_reset": "Send email with reset instructions",
          "change_password": "Change",
          "register_user": "Add user and send invitation",
        }
      }
    }
  };

  $translateProvider.translations('en', translations)
    .preferredLanguage('en');

  $translateProvider.useSanitizeValueStrategy('escape');
});