import includes from 'lodash/includes';
import each from 'lodash/each';
import unionBy from 'lodash/unionBy';
import _result from 'lodash/result';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import pull from 'lodash/pull';

angular
  .module('app')
  .controller('BaseFormSummaryController', function(
    $scope,
    $interval,
    $http,
    $stateParams,
    $state,
    $rootScope,
    BriefingTypesFactory,
    ClientsFactory,
    ClientSectionsFactory,
    Config,
    PreviewFactory,
    SummariesFactory,
    ClientCoveragesFactory,
    ArticlesFactory,
    ToolFactory,
    AutoSaveFactory,
    ngDialog,
    ClientGroupsFactory,
    CommonFactory,
    ipCookie,
    ngToast,
  ) {
    $scope.create_summary = true;
    $scope.create_client_coverage = false;
    $scope.nlaLink = false;
    $scope.stateParams = $state.params;
    $scope.errorMessage = {};
    var stop;
    var formToken = $stateParams.token;
    var initialWordCount = 0;
    var anyChangesForAutosave = false;

    var startAutoSave = function() {
      // "$stateParams.clientCoverageId || $stateParams.summaryId" <- for edit form
      if (
        !anyChangesForAutosave ||
        angular.isDefined(stop) ||
        $stateParams.clientCoverageId ||
        $stateParams.summaryId
      )
        return;
      autoSave();
      stop = $interval(autoSave, Config.autosaveInterval * 1000);
    };

    var autoSave = function() {
      var formData = pick($scope, [
        'article_source',
        'coverageUrlPublished',
        'client_coverage',
        'summary',
        'summaryUrl',
        'coverageUrl',
        'summaryUrlPublished',
        'disableUrl',
        'article',
        'url',
        'sectionName',
        'body',
        'author',
        'title',
        'createSummary',
        'createClientCoverage'
      ]);
      AutoSaveFactory.saveForm(formData, window.location.href, formToken).then(
        function(response) {
          if (!formToken || formToken != response.data.token) {
            formToken = response.data.token;
          }
        }
      );
    };

    $scope.stopAutoSave = function() {
      if (angular.isDefined(stop)) {
        $interval.cancel(stop);
        stop = undefined;
      }
    };

    $scope.$on('$destroy', function() {
      $scope.stopAutoSave();
    });

    BriefingTypesFactory.getBriefingTypes().then(function(briefingTypes) {
      $scope.briefingTypes = briefingTypes;
    });

    $rootScope.$on('removeClientGroup', function(event, payload) {
      $scope.allSegments.segments[
        payload.segmentId
      ].groups.splice(payload.groupId, 1);
    });

    $rootScope.$on('createClientGroup', function(event) {
      loadSegments();
    });

    function loadSegments() {
      ClientsFactory.getAllSegments().then(
        function(allSegments) {
          $scope.segmentsFetched = true;
          allSegments.segments = [{
            name: 'search_results',
            label: 'Search results',
            expanded: false,
            clients: [],
          }, ...allSegments.segments,
          ];
          $scope.allSegments = allSegments;
        },
      );
    }
    loadSegments();

    if ($stateParams.articleId !== undefined) {
      SummariesFactory.getArticleSummaries($stateParams.articleId).then(
        function(summaries) {
          $scope.article_summaries = summaries;
        }
      );

      ClientCoveragesFactory.getArticleCoverages($stateParams.articleId).then(
        function(data) {
          $scope.clientCoverages = data.client_coverages;
        }
      );
    }

    $scope.clientSections = [];

    $scope.summaryUrlPublished = false;

    $scope.updateClientSection = function(selectedClient, index) {
      $scope.clientSections[index] = [];
      if (selectedClient !== 'all' && selectedClient && selectedClient !== '') {
        ClientSectionsFactory.getClientSections(selectedClient).then(function(
          clientSections
        ) {
          $scope.clientSections[index] = clientSections;
        });
      }
    };

    $scope.countOf = function(text) {
      const s = text
        ? text
            .replace(/<(?:.|\n)*?>/gm, '') // strip html tags
            .replace(/(^\s*)|(\s*$)/gi, '') // exclude start and end white-space
            .replace(/[ ]{2,}/gi, ' ') // multiple space to one
            .replace(/\n /, '\n') // exclude newline with a start spacing
            .split(/\s+/)
        : 0; // splits at whitespace
      var result = s ? s.length : 0;

      if (result - initialWordCount > 4) {
        anyChangesForAutosave = true;
        startAutoSave();
      }
      return result;
    };

    $scope.charCountOf = function(text) {
      const s = text
        ? text
            .replace(/(<([^>]+)>)/ig, '') // strip html tags
            .replace(/\s/g, '') // remove whitespace
        : ""; // splits at whitespace

      return s.length;
    };

    $scope.preview = function(strUrl) {
      PreviewFactory.preview(strUrl);
    };

    $scope.check = function(strUrl, domains) {
      if (!strUrl) return false;
      var behindBool = false;

      each(domains, function(domain) {
        if (strUrl.indexOf(domain) !== -1) {
          behindBool = true;
        }
      });

      return behindBool;
    };

    $scope.privateDomains = function(strUrl) {
      var privateDomains = ['www.nytsyn.com'];
      return $scope.check(strUrl, privateDomains);
    };

    $scope.behindPaywall = function(article) {
      var paywallDomains = [
        'www.nytimes.com',
        'www.inframationnews.com'
      ];
      var paywallASIDs = [390];
      return (
        $scope.check(article.url, paywallDomains) ||
        paywallASIDs.indexOf(article.article_source_id) > -1
      );
    };

    $scope.isInternalUrl = function(strUrl) {
      return strUrl && strUrl.indexOf('http') == -1 || strUrl == null;
    };
    window.scope = $scope;

    $scope.getCoverageUrl = function(article) {
      const url = $scope.getArticleUrl(article);
      return $scope.isInternalUrl(url) ? 'INTERNAL' : url;
    };

    $scope.getArticleUrl = (article) => {
      return article.nla_link
        ? article.nla_link
        : article.url;
    }

    $scope.getSummaryUrl = function(article) {
      const url = $scope.getArticleUrl(article);
      return $scope.isInternalUrl(url) ? 'INTERNAL' : url;
    };

    $scope.getPreviewSummaryUrl = function(article) {
      const url = $scope.getArticleUrl(article);
      if ($scope.isInternalUrl(url) || article.url !== url) {
        return '/preview/' + article.id;
      } else {
        return url;
      }
    };

    $scope.getPreviewCoverageUrl = function(article) {
      return (
        article.nla_link ||
        ($scope.isInternalUrl(article.url) || $scope.behindPaywall(article)
          ? '/preview/' + article.id
          : article.url)
      );
    };

    //listen for the file selected event
    $scope.$on('fileSelected', function(event, args) {
      $scope.$apply(function() {
        $scope.file = args.file;
      });
    });

    $scope.getError = function(what) {
      if ($scope.errorMessage) {
        return _result($scope.errorMessage, what, false);
      } else {
        return false;
      }
    };

    function emptyFieldError(fieldName, message = `There must be ${fieldName}`) {
      $scope.errorMessage = {};
      $scope.errorMessage[fieldName] = [message];
      ngToast.create({
        className: 'danger',
        content: message,
        timeout: 60000,
      });
    }

    function isAnyFieldEmpty() {
      if (!$scope.title) {
        emptyFieldError('title');
        return true;
      }

      // Allow author to be empty
      // if (!$scope.author) {
      //  emptyFieldError('author');
      //  return true;
      // }

      if (!$scope.body) {
        emptyFieldError('body');
        return true;
      }

      if (!$scope.selectedBriefingType && $scope.create_summary) {
        const message = 'You must select a briefing type to create a summary.';
        emptyFieldError('selectedBriefingType', message);
        return true;
      }

      if ($scope.sectionIds.length === 0 && $scope.create_client_coverage) {
        const message = 'You must select at least one company or a sector to create a coverage.';
        emptyFieldError('sectionIds', message);
        return true;
      }
      return false;
    }

    $scope.save = function() {
      if ($scope.file && $scope.file.size > 30 * 1024 * 1024) {
        $scope.errorMessage = {
          file: ['File size is too large']
        };
        return;
      }

      if (isAnyFieldEmpty()) {
        return;
      }

      if ($scope.summaryUrl)
        $scope.summaryUrl = ToolFactory.addHttpToUrl($scope.summaryUrl);
      if ($scope.coverageUrl)
        $scope.coverageUrl = ToolFactory.addHttpToUrl($scope.coverageUrl);

      var coverage_manually_provided_url = $scope.disableUrl
        ? $stateParams.otherSourceId !== undefined
          ? $scope.coverageUrl || ''
          : ''
        : $scope.coverageUrl || '';
      var summary_manually_provided_url = $scope.disableUrl
        ? $stateParams.otherSourceId !== undefined
          ? $scope.summaryUrl || ''
          : ''
        : $scope.summaryUrl || '';
      if (
        coverage_manually_provided_url.length === 0 &&
        $scope.coverageUrlPublished &&
        $state.params.articleId === undefined
      )
        $scope.coverageUrlPublished = false;

      $http({
        method: 'POST',
        url: Config.shibiUrl + '/content_publications.json',
        //IMPORTANT!!! You might think this should be set to 'multipart/form-data'
        // but this is not true because when we are sending up files the request
        // needs to include a 'boundary' parameter which identifies the boundary
        // name between parts in this multi-part request and setting the Content-type
        // manually will not set this boundary parameter. For whatever reason,
        // setting the Content-type to 'false' will force the request to automatically
        // populate the headers properly including the boundary parameter.
        headers: { 'Content-Type': undefined },
        //This method will allow us to change how the data is sent up to the server
        // for which we'll need to encapsulate the model data in 'FormData'
        transformRequest: function(data) {
          var formData = new FormData();
          //need to convert our json object to a string version of json otherwise
          // the browser will do a 'toString()' on the object which will result
          // in the value '[Object object]' on the server.
          formData.append('model', angular.toJson(data.model));
          if (data.file) {
            formData.append('file', data.file);
          }
          return formData;
        },
        //Create an object that contains the model and files which will be transformed
        // in the above transformRequest method
        data: {
          file: $scope.file,
          model: {
            create_summary: $scope.create_summary,
            create_client_coverage: $scope.create_client_coverage,
            content: {
              title: $scope.title || '',
              body: $scope.body || '',
              author: $scope.author || '',
              rich_text_in_body: $scope.richTextInBody || false,
              content_type: $scope.contentType
            },
            section_ids: $scope.sectionIds,
            sentiments: Object.values($scope.sentimentData),
            common_params: {
              article_id: $state.params.articleId,
              manually_provided_summary_url: $scope.summaryUrl || '',
              manually_provided_coverage_url: $scope.coverageUrl || ''
            },
            briefing_type_id: $scope.selectedBriefingType,
            summary_url_published: $scope.summaryUrlPublished,
            coverage_url_published: $scope.coverageUrlPublished,
            nla_link: $scope.nlaLink
          }
        }
      })
        .success(function(data, status, headers, config) {
          $scope.deleteForm(formToken);

          if (data.briefing_id) {
            $state.go(
              'briefing_publisher.summaries',
              { briefingId: data.briefing_id },
              { location: 'replace', reload: true }
            );
          } else {
            $state.go(
              'client.unpublished',
              {
                clientId: data.client_id,
                sectionId: data.section_id,
                page: 1
              },
              { location: 'replace', reload: true }
            );
          }
        })
        .error(function(data, status, headers, config) {
          $scope.errorMessage = data.errors;
          var ind = _result($scope.errorMessage, 'client_index', false);
          if (ind) {
            $scope.clientList.items[ind].has_error = true;
          }
        });
    };


    $scope.insertStandfirst = function() {
      return ArticlesFactory.getArticle($state.params.articleId).then(function(
        response
      ) {
        $scope.body = response.summary;
      });
    };

    $scope.cleanUpTextAreaContent = function() {
      const wordSubstitute = [
        ['million pounds', '£m'],
        ['percent', '%'],
        ['per cent', '%'],
        ['chief executive officer', 'CEO'],
        ['Chief Executive Officer', 'CEO'],
        ['PLC', ''],
      ];
      let text = $scope.body;
      if (text) {
        for (let i = 0; i < wordSubstitute.length; i++) {
          $scope.body = text.replace(wordSubstitute[i][0], wordSubstitute[i][1])
          text = $scope.body;
        }
      }
    }

    $scope.articleUrlAlreadyInDb = { coverage_url: false, summary_url: false };
    $scope.checkIfArticleUrlExists = function(urlType) {
      if (
        (urlType == 'coverage_url' && !!$scope.coverageUrl) ||
        (urlType == 'summary_url' && !!$scope.summaryUrl)
      ) {
        return $http
          .post(Config.shibiUrl + '/articles/check_url', {
            url:
              urlType == 'coverage_url' ? $scope.coverageUrl : $scope.summaryUrl
          })
          .then(function(response) {
            if (response.data.article_source_id && response.data.article_id) {
              $scope.$broadcast('monitor.articleUrlAlreadyInDb', {
                articleId: response.data.article_id,
                urlType: urlType
              });
            } else if ($scope.article) {
              $scope.$broadcast('monitor.articleUrlNotInDb', {
                urlType: urlType
              });
            }
          });
      } else {
        $scope.$broadcast('monitor.articleUrlNotInDb', { urlType: urlType });
      }
    };
    $scope.briefingContentPermitted = true;
    $scope.coverageContentPermitted = true;
    $scope.$on('monitor.articleUrlAlreadyInDb', function(event, params) {
      $http
        .get(Config.shibiUrl + '/articles/' + params.articleId + '.json')
        .then(function(response) {
          if (params.urlType === 'coverage_url') {
            $scope.coverageContentPermitted = response.data.article.full_article_content_permitted;
            $scope.coverageUrlPublished = $scope.coverageContentPermitted;
          } else {
            $scope.briefingContentPermitted = response.data.article.full_article_content_permitted;
            $scope.summaryUrlPublished = $scope.briefingContentPermitted;
          }
          $scope.articleUrlAlreadyInDb[params.urlType] = true;
          $scope.article_id = params.articleId;
          $scope.article = response.data.article;
          $scope.article_url = $scope.coverageUrl || $scope.summaryUrl;
          $scope.title = $scope.title || $scope.article.title;
          $scope.author = $scope.author || $scope.article.author;
        });
    });

    if ($state.params.articleId) {
      $http
        .get(Config.shibiUrl + '/articles/' + $state.params.articleId + '.json')
        .then(function(response) {
          $scope.article = response.data.article;
          $scope.nlaLink = !!$scope.article.nla_link;
        });
    }

    $scope.$on('monitor.articleUrlNotInDb', function(event, params) {
      $scope.articleUrlAlreadyInDb[params.urlType] = false;
      delete $scope.article_id;
      delete $scope.article;
      delete $scope.article_url;
      delete $scope.title;
      delete $scope.author;
    });

    $scope.restoreForm = function(formToken) {
      if (formToken) {
        AutoSaveFactory.getForm(formToken).then(function(response) {
          initialWordCount = $scope.countOf(response.data.body);
          for (var i in response.data) {
            $scope[i] = response.data[i];
          }
        });
      }
    };

    $scope.deleteForm = function(formToken) {
      if (formToken) {
        AutoSaveFactory.deleteForm(formToken);
      }
    };

    $scope.cancel = function() {
      $scope.deleteForm(formToken);
      $state.go(
        $rootScope.previousStateParams.token
          ? 'monitor'
          : $rootScope.previousState.name || 'monitor',
        $rootScope.previousStateParams || {}
      );
    };

    if (Config.enableAutosave === 'true') {
      startAutoSave();
    }

    $scope.openPopupClientGroup = function(groupData, segmentIdx, groupIdx) {
      groupData = groupData || null;
      segmentIdx = segmentIdx || null;
      groupIdx = groupIdx || null;
      if (groupData) {
        ClientGroupsFactory.getClientGroup(groupData.id).then(function(res) {
          $scope.clientGroups = $scope.clientGroups || [];
          res.data.data.clients = res.data.data.companies;
          $scope.clientGroups[0] = res.data.data;
          var data = res.data.data;
          if (isGroupOwner(data.owner_email)) {
            data.hideSharingContainer = true;
          }
          ngDialog.open({
            plain: true,
            templateUrl: require('../modals/client_group_new_group_popup.html.haml'),
            className: 'ng-group-box',
            showClose: true,
            controller: 'ClientGroupsController',
            data: {
              groupData: data,
              segmentIdx: segmentIdx,
              groupIdx: groupIdx,
              isGroupOwner: isGroupOwner(data.owner_email)
            }
          });
        });
      } else {
        ngDialog.open({
          plain: true,
          template: require('../modals/client_group_new_group_popup.html.haml'),
          className: 'ng-group-box',
          showClose: true,
          controller: 'ClientGroupsController',
          data: {
            groupData: groupData
          }
        });
      }
    };

    function findIndex(container, comparator) {
      for (var i = 0; i < container.length; ++i) {
        if (comparator(container[i])) return i;
      }
      return -1;
    }

    $scope.clientsSelected = {};
    $scope.clearSearchInput = () => {
      $scope.searchInput = '';
      $scope.allSegments.segments = $scope.allSegments.segments.map(e => {
        e.clients = undefined;
        e.expanded = false;
        return e;
      });
    };

    $scope.searchClientGroups = function(value) {
      if (!isEmpty($scope.allSegments.segments[0].clients)) {
        each($scope.allSegments.segments[0].clients, (client) => {
          if (client.selected === false || !client.selected) {
            $scope.allSegments.segments[0].clients = $scope.allSegments.segments[0].clients.filter((element) => {
              return element.id !== client.id;
            });
          }
        });
      }
      ClientsFactory.searchClient(value).then(function(results) {
        for (var i = 0; i < results.segments.length; ++i) {
          let previousContainer = ($scope.allSegments.segments[i].clients || $scope.allSegments.segments[i].groups || []).filter(e => e.selected);
          let newContainer = results.segments[i].clients || results.segments[i].groups || [];

          for (var j = 0; j < previousContainer.length; ++j) {
            if (previousContainer[j].selected) {
              let indexInNewContainer = findIndex(newContainer, e => e.id === previousContainer[j].id);
              if (indexInNewContainer === -1) newContainer.push(previousContainer[j]);
              else newContainer[indexInNewContainer] = previousContainer[j];
            }
          }

          results.segments[i][(results.segments[i].clients) ? 'clients' : 'groups'] = newContainer;
          if (!isEmpty(newContainer)) {
            $scope.allSegments.segments[0].clients = unionBy($scope.allSegments.segments[0].clients, newContainer, 'id');
          }
        }
        each(results.segments, function(segment, key) {
          const skipSearchGroup = 1;
          if (!isEmpty(segment.clients)) {
            var counter = 0;
            each(segment.clients, function(client) {
              if ($scope.clientsSelected.hasOwnProperty(client.id)) {
                client.selected = $scope.clientsSelected[client.id].selected;
                client.default_section_id = $scope.clientsSelected[client.id].default_section_id;
                counter++;
              }
            });

            if (segment.clients.length > 0) {
              $scope.allSegments.segments[key + skipSearchGroup].expanded = true;
              $scope.allSegments.segments[0].expanded = true;
            }

            if (counter === segment.clients.length) {
              $scope.allSegments.segments[key].selected = true;
            } else if (counter > 0) {
              $scope.allSegments.segments[key].selected = null;
            } else {
              $scope.allSegments.segments[key].selected = false;
            }
          }
          $scope.allSegments.segments[key + skipSearchGroup].clients = segment.clients;

          let companies_count = 0;

          each(segment.groups, (group, group_key) => {
            var selectGroup = true;

            each(group.companies, (company, company_key) => {
              companies_count++;
              if ($scope.clientsSelected.hasOwnProperty(company.id)) {
                company.selected = $scope.clientsSelected[company.id].selected;
                company.default_section_id = $scope.clientsSelected[company.id].default_section_id;
              } else {
                selectGroup = false;
              }
            });
            $scope.allSegments.segments[key + skipSearchGroup].groups[group_key].companies = group.companies;
            $scope.allSegments.segments[key + skipSearchGroup].groups[group_key].selected = isEmpty(group.companies) ? false : selectGroup;
            $scope.allSegments.segments[key + skipSearchGroup].groups[group_key].expanded = group.companies && group.companies.length > 0;
            $scope.allSegments.segments[key + skipSearchGroup].expanded = companies_count > 0;
          });

          if (segment.groups && segment.groups.length) {
            $scope.allSegments.segments[key].expanded = true;
          }
        });
      });
    };

    $scope.searchOnEnter = function(event) {
      if (event.keyCode == 13) {
        $scope.searchClientGroups(event.target.value);
        event.preventDefault();
        return false;
      }
    };

    $scope.getSector = function(isSelected, id, name) {
      if (id) {
        const sectorSegmentId = $scope.checkSegmentIdByName($scope.allSegments.segments, name);
        console.error(sectorSegmentId, $scope.allSegments.segments[sectorSegmentId]['clients'], !$scope.allSegments.segments[sectorSegmentId]['clients']);
        if ( !$scope.allSegments.segments[sectorSegmentId]['clients'] ) {
          ClientsFactory.getSector(id).then(function(res) {
            $scope.allSegments.segments[sectorSegmentId]['clients'] = res.clients;
          });
        }
      }
    };

    var isToggling = false;
    $scope.toggleFav = function(client) {
      const favouriteSegmentId = $scope.checkSegmentIdByName($scope.allSegments.segments, "favourite_apps");
      const groupsSegmentId = $scope.checkSegmentIdByName($scope.allSegments.segments, "groups");
      if (!isToggling) {
        isToggling = true;
        client.is_favourite = !client.is_favourite;

        each($scope.allSegments.segments[groupsSegmentId].groups, function(group) {
          var index = group.companies.findIndex(function(cl) {
              return client.id === cl.id;
            });
            if (index > -1) {
            group.companies[index].is_favourite = client.is_favourite;
          }
        });

        if (client.is_favourite) {
          ClientGroupsFactory.addClientToFav(client.id).then(function() {
            $scope.allSegments.segments[favouriteSegmentId].clients.unshift(client);
            isToggling = false;
          });
        } else {
          ClientGroupsFactory.removeClientFromFav(client.id).then(function() {
            var index = $scope.allSegments.segments[favouriteSegmentId].clients.findIndex(
                function(cl) {
                  return client.id === cl.id;
                }
              );
            $scope.allSegments.segments[favouriteSegmentId].clients.splice(
                index,
                1
              );
            isToggling = false;
          });
        }
      }
    };

    $scope.toggleClientState = (client, section = null) => {
      if (client.readOnly) return;
      if (section && !client.selected) {
        client.selected = true;
      }
      const hasInitialSection = !!section;
      $scope.sentimentData = $scope.sentimentData || {};

      const sectionId = section ? +client.default_section_id : +client.client_sections[0].id;
      client.default_section_id = sectionId;
      section = section ? section : client.client_sections.find(section => section.id === client.default_section_id);

      if (client.selected) {
        if (hasInitialSection && includes($scope.sectionIds, section.id)) {
          client.selected = false;
          pull($scope.sectionIds, section.id);
        } else {
          pull($scope.sectionIds, ...client.client_sections.map(section => section.id))
          $scope.sectionIds.push(sectionId);
        }
        $scope.sectionIds = union($scope.sectionIds);

        if (section.name == 'company' || client.name.toLowerCase().includes(section.name.toLowerCase())) {
          $scope.sentimentData[client.id] = {
            client_id: +client.id,
            client_section_id: +sectionId,
            client_name: client.name
          };
        }
      } else {
        $scope.sectionIds = $scope.sectionIds.filter(_sectionId => +sectionId !== +_sectionId);
        delete $scope.sentimentData[client.id];
      }

      if ($scope.clientCoverage) {
        $scope.clientCoverage.section_ids = $scope.sectionIds;
      }

      const hasSection = $scope.sectionIds.find(clientSectionId => +clientSectionId === +section.id );
      if (!hasSection || ($scope.sentimentData[client.id] && +$scope.sentimentData[client.id].client_section_id !== +section.id)) {
        delete $scope.sentimentData[client.id];
      }
      toggleRestClients(client);
    };

    function toggleRestClients(client) {
      each($scope.allSegments.segments, segment => {
        let selectedClients = 0;
        if (segment.clients) {
          each(segment.clients, cl => {
            if (cl.id === client.id) {
              cl.selected = client.selected;
              cl.default_section_id = client.default_section_id;
            }
            if (cl.selected) {
              selectedClients++;
            }
          });
          if (segment.clients.length !== 0) {
            segment.selected = selectedClients > 0 && selectedClients < segment.clients.length ? null : selectedClients === segment.clients.length;
          }
        }
        if (segment.groups) {
          each(segment.groups, group => {
            selectedClients = 0;
            each(group.companies, cl => {
              if (cl.id === client.id) {
                cl.selected = client.selected;
                cl.default_section_id = client.default_section_id;
              }
              if (cl.selected) {
                selectedClients++;
              }
            })
            if (group.companies.length) {
              group.selected = selectedClients > 0 && selectedClients < group.companies.length ? null : selectedClients === group.companies.length;
            }
          })
        }
      })
    }

    $scope.selectAllClients = function(segments, index) {
      segments.segments[index].selected = !segments.segments[index].selected;
      each(segments.segments[index].clients, client => {
        if (!client.readOnly) {
          client.selected = segments.segments[index].selected;
        }
      })
      each(segments.segments[index].clients, client => {
        $scope.toggleClientState(client);
      });
    };

    $scope.selectAllGroupClients = function(group) {
      each(group.companies, function(client) {
        if (!client.readOnly) {
          client.selected = group.selected;
        }
      });
      each(group.companies, function(client) {
        $scope.toggleClientState(client);
      });
    };

    const sentimentSection = 'company';
    $scope.sentimentData = {};
    $scope.sectionIds = [];
    $scope.analystSentimentFormData = [];
    $scope.sentimentIsValid = false;

    $scope.isEmpty = obj => isEmpty(obj);

    $scope.clearCoverageSelection = creationSelected => {
      if (!creationSelected) {
        $scope.allSegments.selected = false;

        each($scope.allSegments.segments, segment => {
          segment.selected = false;
          each(segment.clients, client => {
            client.selected = false;
          });
        });

        $scope.clientsSelected = {};
        $scope.sentimentData = {};
        $scope.sectionIds = [];
      }
    }

    $scope.clearInputFile = () => {
      $scope.file = undefined;
    };

    $scope.transformTitle = (title) => {
      $scope.title = title.charAt(0).toUpperCase() + title.toLowerCase().slice(1);
    }

    function isGroupOwner(groupOwnerEmail) {
      return groupOwnerEmail === ipCookie('analyst_email');
    }

    $scope.checkSegmentIdByName = (segments, name) => {
      let segmentIdx = 2;
      each(segments, function(segment, idx) {
        if (segment.name == name) {
          segmentIdx = idx;
        }
      });
      return segmentIdx;
    }
  });
