import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const style = {
  root: {
    marginTop: "-160px",
    padding: "10px",
    background: "white",
    minWidth: "100vw",
    minHeight: "100vh",
    fontFamily: "Times New Roman"
  },
  simpleHr: {
    margin: "2px 0"
  },
  title: {
    margin: "20px 0",
    fontSize: "42px",
    letterSpacing: "-1px",
    fontWeight: "bold"
  },
  date: {
    fontSize: "12px",
    marginBottom: "10px"
  },
  content: {
    fontSize: "18px"
  }
}

const beautify = text => text ? text.split("\n").map(e => `<p>${e}</p>`).join("\n") : '';

@inject('articlesStore')
@observer
class Preview extends Component {
  async componentDidMount() {
    await this.props.articlesStore.getArticle(this.props.articleId);
  }

  render() {
    return (
      <div style={style.root}>
        {this.props.articlesStore.article && (
          <>
            <h1 style={style.title}>{this.props.articlesStore.article.title}</h1>
            <hr style={style.simpleHr} />
            {!!this.props.articlesStore.article.article_source_title && (
              <p>
                <b>Article source:</b> {this.props.articlesStore.article.article_source_title}
              </p>
            )}
            {!!this.props.articlesStore.article.author && (
              <p>
                <b>Author:</b> {this.props.articlesStore.article.author}
              </p>
            )}
            <hr style={style.simpleHr} />
            <div style={style.date}>
              {moment(this.props.articlesStore.article.pubdate).format("DD MMMM YYYY HH:mm:ss")}
            </div>
            <div style={style.content}>
              <div dangerouslySetInnerHTML={{ __html: beautify(this.props.articlesStore.article.content) }} />
            </div>
            <hr />
          </>
        )}
      </div>
    );
  }
}

export default Preview;
