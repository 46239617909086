import * as React from "react";

function SvgLeaflet(props) {
  return (
    <svg height="1em" viewBox="0 0 46 56" width="1em" {...props}>
      <g fill="#1e88e5" fillRule="nonzero">
        <path d="M43.988 0H8.328a1 1 0 00-.922.612L.506 17h37.83L44.91 1.388A1 1 0 0043.988 0zM8 7h4a1 1 0 010 2H8a1 1 0 110-2zm11 6H6a1 1 0 010-2h13a1 1 0 010 2zm2-4h-5a1 1 0 010-2h5a1 1 0 010 2zM10 5a1 1 0 110-2h13a1 1 0 010 2zm26 10H24a1 1 0 01-.91-1.414l5-11A1 1 0 0129 2h12a1 1 0 01.91 1.414l-5 11A1 1 0 0136 15zM45.523 37l-7.2-18H.477l7.2 18zM9 28a1 1 0 011-1h6a1 1 0 010 2h-6a1 1 0 01-1-1zm30 5a1 1 0 01-1 1H13a1 1 0 010-2h25a1 1 0 011 1zm-2-5a1 1 0 01-1 1H20a1 1 0 010-2h16a1 1 0 011 1zM7 23a1 1 0 011-1h26a1 1 0 010 2H8a1 1 0 01-1-1z" />
        <path d="M25.553 13h9.803l4.091-9h-9.803zM7.428 52H17.3l3.272-9H10.7z" />
        <path d="M7.7 39L1.93 54.654A1 1 0 002.869 56h35.736a1 1 0 00.939-.654L45.566 39zM25 47h5a1 1 0 010 2h-5a1 1 0 010-2zm-7 7H6a1 1 0 01-.939-1.342l4-11A1 1 0 0110 41h12a1 1 0 01.939 1.342l-4 11A1 1 0 0118 54zm18-1H23a1 1 0 010-2h13a1 1 0 010 2zm2-4h-4a1 1 0 010-2h4a1 1 0 010 2zm2-4H27a1 1 0 010-2h13a1 1 0 010 2z" />
      </g>
    </svg>
  );
}

export default SvgLeaflet;

