import { action, observable, computed } from 'mobx';
import { API_ROUTES } from '@inbox/_app/routes';
import api from '@inbox/_app/api';
import find from 'lodash/find';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import socket from '@inbox/_app/socket';
import filter from 'lodash/filter';
import filtersStore from './filtersStore';
import commonStore from './commonStore';
import { ALERT_ACTION_TYPES } from '../_app/constants';

const DEFAULT_LIMIT = 20;
const NAVIGATION_DOT_CLASS = '.inbox-navigation__dot';

class AlertsStore {
  constructor(socket) {
    this.socket = socket;
    this.socket.on('newAlert', this.unshiftAlert);
  }

  @observable isLoading = false;
  @observable alerts = [];
  @observable currentAlert = {};
  @observable error = '';
  @observable showForwardForm = false;
  @observable actionMessage = {};
  @observable hasMoreAlerts = true;
  @observable isTrash = null;
  @observable isInputFocused = false;
  @observable lastAlertId = null;

  @action
  setIsTrash = (isTrash) => {
    this.isTrash = isTrash;
    this.clear();
  };

  @action
  unshiftAlert = (alert) => {
    if (!this.isTrash) {
      this.alerts.unshift(alert);
    }
    commonStore.unreadCount++;
    this.setNavigationNotificationDot();
  };

  @action
  forward = async (payload) => {
    try {
      await api.post(API_ROUTES.messageForward(this.currentAlert._id), payload);
      this.actionMessage = ALERT_ACTION_TYPES.forward;
      this.actionMessage.text = `
        ${ALERT_ACTION_TYPES.forward.text}
        ${(payload.analysts || [])
          .map(
            analyst => (analyst.name.trim() !== '' ? analyst.name : analyst.label),
          )
          .join(', ')}
      `;
      this.showForwardForm = false;
      this.currentAlert = {};
      return {};
    } catch (e) {
      return e.response.data;
    }
  };

  @action
  fetchAlerts = async (page = 1) => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    if (page === 1) {
      this.alerts = [];
      this.hasMoreAlerts = true;
      this.error = '';
      this.lastAlertId = null;
    }
    try {
      const { data } = await api(API_ROUTES.getMessages, {
        params: {
          limit: DEFAULT_LIMIT,
          lastId: this.lastAlertId,
          ...filtersStore.filters,
          deleted: this.isTrash,
        },
      });
      if (data.length < DEFAULT_LIMIT) {
        this.hasMoreAlerts = false;
      }
      if (!isEmpty(data)) {
        this.lastAlertId = last(data)._id;
        this.alerts = this.alerts.concat(data);
      }
    } catch (e) {
      this.error = "Couldn't load data";
      this.hasMoreAlerts = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  setCurrentAlert = (alertId) => {
    const currentAlert = find(this.alerts, { _id: alertId });
    if (currentAlert.unread) {
      this.toggleUnread(alertId, false);
    }
    this.currentAlert = currentAlert;
    this.showForwardForm = false;
    this.actionMessage = {};
  };

  @action
  toggleUnread = async (id, unread) => {
    this.socket.toggleUnread(id, unread);
    const alert = find(this.alerts, { _id: id });
    unread ? commonStore.unreadCount++ : commonStore.unreadCount--;
    alert.unread = unread;
    this.setNavigationNotificationDot();
  };

  @action
  deleteAlert = async (id, deleted) => {
    this.socket.delete(id, deleted);

    const index = findIndex(this.alerts, { _id: id });
    const alert = this.alerts[index];

    if (alert.unread) {
      commonStore.unreadCount--;
      this.setNavigationNotificationDot();
    }
    remove(this.alerts, alertItem => alertItem._id === alert._id);

    if (this.currentAlert._id === id) {
      this.currentAlert = this.alerts[index > 0 ? index - 1 : 0] || {};
    }
    this.showForwardForm = false;
  };

  @action
  toggleForwardForm = (value = true, alertId = null) => {
    if (alertId) {
      this.setCurrentAlert(alertId);
    }
    this.showForwardForm = value;
    this.actionMessage = {};
  };

  @action
  flagAlert = (id, flagged) => {
    this.socket.flag(id, Boolean(flagged));
    const alert = find(this.alerts, { _id: id });
    alert.flagged = Boolean(flagged);
  };

  @computed
  get validAlerts() {
    const filters = pick(filtersStore.filters, ['unread', 'flagged']);
    const currentAlertId = this.currentAlert._id;
    if (currentAlertId && filters.unread) {
      const flagged = filters.flagged ? { flagged: filters.flagged } : {};
      return filter(
        this.alerts,
        alert => (alert._id === currentAlertId || alert.unread)
          && alert.deleted === this.isTrash
          && flagged,
      );
    }
    return filter(this.alerts, { deleted: this.isTrash, ...filters });
  }

  @action
  clear = () => {
    this.isLoading = false;
    this.alerts = [];
    this.currentAlert = {};
    this.error = '';
    this.showForwardForm = false;
    this.actionMessage = {};
    this.hasMoreAlerts = true;
    this.lastAlertId = null;
  };

  @action
  setNavigationNotificationDot = () => {
    const { unreadCount } = commonStore;
    if (unreadCount === 0) {
      document.querySelector(NAVIGATION_DOT_CLASS).style.display = 'none';
    }
    if (unreadCount === 1) {
      document.querySelector(NAVIGATION_DOT_CLASS).style.display = 'block';
    }
  };

  @action
  markAllAsRead = () => {
    if (commonStore.unreadCount > 0) {
      this.socket.markAllAsRead();
      this.alerts.map((alert) => {
        alert.unread = false;
        return alert;
      });
      commonStore.unreadCount = 0;
      this.setNavigationNotificationDot();
    }
  };

  @action
  handleKeyEvents = (e) => {
    if (!this.isInputFocused) {
      let currentAlertIndex = -1;
      const ALLOWED_KEY_CODES = [8, 65, 74, 75, 46, 70, 85, 191];
      const { keyCode } = e;

      if (ALLOWED_KEY_CODES.some(code => code === keyCode)) {
        if (keyCode === 65 && e.ctrlKey) {
          this.alerts.map((alert) => {
            alert.selected = true;
            return alert;
          });
        }
        if (keyCode === 191) {
          commonStore.toggleShortcutDialog();
        }
        currentAlertIndex = findIndex(this.alerts, {
          _id: this.currentAlert._id,
        });
        if (keyCode === 8 || keyCode === 46) {
          if (!isEmpty(this.currentAlert) && !this.currentAlert.deleted) {
            this.deleteAlert(this.currentAlert._id, true);
          }
        }
        if (!isEmpty(this.validAlerts)) {
          if (keyCode === 74) {
            if (e.shiftKey) {
              this.alerts[currentAlertIndex].selected = true;
            }
            const index = currentAlertIndex <= 0 ? 0 : currentAlertIndex - 1;
            this.setCurrentAlert(this.alerts[index]._id);
          }
          if (keyCode === 75) {
            if (e.shiftKey) {
              this.alerts[currentAlertIndex].selected = true;
            }
            const index = currentAlertIndex === this.alerts.length - 1
              ? currentAlertIndex
              : currentAlertIndex + 1;
            this.setCurrentAlert(this.alerts[index]._id);
          }
        }
        if (!isEmpty(this.currentAlert)) {
          if (keyCode === 70) {
            this.showForwardForm = true;
            e.preventDefault();
          }
          if (keyCode === 85) {
            this.toggleUnread(this.currentAlert._id, true);
          }
        }
      }
    }
  };

  @action
  toggleInputFocus = () => {
    this.isInputFocused = !this.isInputFocused;
  };

  @action
  toggleSelected = (id) => {
    const alert = find(this.alerts, { _id: id });
    alert.selected = !alert.selected;
  };

  @action
  deleteSelected = () => {
    const deleted = true;
    this.socket.delete(this.alerts.filter(e => e.selected).map(it => it._id), deleted);

    this.alerts.filter(e => e.selected).forEach(({ _id }) => {
      const index = findIndex(this.alerts, { _id });
      const alert = this.alerts[index];

      remove(this.alerts, alertItem => alertItem._id === alert._id);

      if (this.currentAlert._id === _id) {
        this.currentAlert = this.alerts[index] || {};
      }
      this.showForwardForm = false;
    });
  };
}

export default new AlertsStore(socket);
