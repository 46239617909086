import find from 'lodash/find';
import first from 'lodash/first';

angular.module('app').controller("ClientSearchPhrasesController", function($scope, $state, $http, $stateParams, SearchPhrasesFactory, ClientsFactory) {

  $scope.showSidebar = true;

  $scope.toggleSidebar = function () {
    $scope.showSidebar = !$scope.showSidebar;
  };

  $scope.articleSourcesScope = false;

  ClientsFactory.getClient($stateParams.clientId).then(function (response) {
      $scope.sourceName = response.name;
    });

  SearchPhrasesFactory.getSearchPhrasesForClientWithAllSection($stateParams.clientId).then(function (searchPhrases) {
    $scope.article_sources = searchPhrases;

    var sourceWithUnreadArticles = find($scope.article_sources, function (source) {
      return source.published_today_and_unread_count !== 0;
    });

    var searchPhrase = null;
    if($state.params.searchPhraseId) {
      searchPhrase = find($scope.article_sources, function (source) {
        return source.id == $state.params.searchPhraseId;
      });
    } else {
      if (sourceWithUnreadArticles) {
        searchPhrase = sourceWithUnreadArticles;
      } else if (searchPhrases.length > 0) {
        searchPhrase = first(searchPhrases);
      } else {
        // nothing
      }
    }

    if(searchPhrase != null) {
      if (!$scope.$parent) $scope.$parent = {};
      $scope.$parent.searchPhraseName = searchPhrase.phrase;
      $scope.$parent.searchPhraseId = $stateParams.searchPhraseId;
      $scope.$parent.clientId = $stateParams.clientId;
    }

    if( $state.current.name != 'clients_article_sources.create_client_coverage_with_article' &&
        $state.current.name != 'clients_article_sources.create_covereage_with_client' ) {
      if (searchPhrase != null) {
        $state.go("clients_article_sources.articles", { clientId: $stateParams.clientId, searchPhraseId: searchPhrase.id, page: 1 });
      } else {
        $state.go('clients_article_sources.no_results');
      }
    }
  });
});
