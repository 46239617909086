import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import SearchBar from "./components/SearchBar";
import DatePickers from "./components/DatePickers";
import DropdownSelector from "./components/DropdownSelector";
import TextInput from "./components/TextInput";
import Button from "@material-ui/core/Button";

@inject("searchContainerStore")
@observer
export default class SearchElements extends Component {

  handleKeyDown = (e, search) => {
    if (e.key === "Enter") {
      search();
    }
  };

  render() {
    const {
      search_phrase,
      setSearch,
      isActive,
      search,
      clearSearch,
      to_date,
      from_date,
      setFromDate,
      setToDate,
      orig_guid,
      setOrigGuid,
      clearParams,
      showAdditionalFilters,
      setShowAdditionalFilters,
      getDateHyphenFormat,
    } = this.props.searchContainerStore;

    return (
      <div
        onKeyDown={(e) => this.handleKeyDown(e, search)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DatePickers
            toDate={to_date}
            setToDate={setToDate}
            fromDate={from_date}
            setFromDate={setFromDate}
            getDateHyphenFormat={getDateHyphenFormat}
          />
          <SearchBar
            value={search_phrase}
            isActive={isActive}
            setSearch={setSearch}
            clearSearch={clearSearch}
            onClick={search}
          />
          <div
            onClick={() => setShowAdditionalFilters()}
            style={{
              fontSize: "12px",
              margin: "5px 10px",
              color: "#136FE2",
              cursor: "pointer",
            }}
          >
            {`${showAdditionalFilters ? "Less" : "More"} options`}
          </div>
        </div>
        <div
          style={{
            display: showAdditionalFilters ? "flex" : "none",
            flexWrap: "wrap",
            flexDirection: "row",
            alignContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <DropdownSelector />
          <TextInput value={orig_guid} onChange={setOrigGuid} search={search} />
          <Button onClick={clearParams}>Clear</Button>
        </div>
      </div>
    );
  }
}
