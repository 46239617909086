import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const SmallerFontTextField = withStyles({
  root: {
    maxWidth: "225px",
  },
})(TextField);

export default function TextInput(props) {
  // const { value, onChange, search } = props;
  const { value, onChange } = props;
  const classes = useStyles();

  const handleChange = (e, handler) => {
    handler(e.target.value);
  };
  return (
    <div className={classes.root} noValidate autoComplete="off">
      <SmallerFontTextField
        value={value || ""}
        onChange={(event) => handleChange(event, onChange)}
        id="standard-basic"
        label="GUID or URL"
      />
    </div>
  );
}
