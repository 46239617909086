angular.module("app").directive('coveragesPopup', ['$compile', 'ngToast', 'ClientCoveragesFactory', function($compile, ngToast, ClientCoveragesFactory) {
  return {
    restrict: 'AE',
    template: require('./coverages_popup.html.haml'),
    replace: true,
    link: function ($scope, elem, attr, ctrl) {
      $scope.coveragesPopupOpened = false; // popup closed on init
      $scope.clientCoveragesForPopup = [];

      $scope.closePopupCoverages = function () {
        $scope.coveragesPopupOpened = false;
      };

      $scope.openPopupCoverages = function (articleId) {
        ClientCoveragesFactory.getArticleCoverages(articleId).then(data => {
          $scope.clientCoveragesForPopup = data.client_coverages;
          $scope.coveragesPopupOpened = true;
        });
      };
    }
  };
}]);
