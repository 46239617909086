import React, { useRef } from 'react';
import styled from "styled-components";

const getStyles = props => ({
  root: {
    backgroundColor: `${props.disabled ? "#DADADA" : "white"}`,
    width: "100%",
    height: "32px",
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
    border: `1px solid ${props.error ? '#ad0a0a' : '#E3E3E3'}`,
    borderRadius: "4px",
    marginTop: "5px"
  },
  button: {
    backgroundColor: `${props.disabled ? "#DADADA" : "white"}`,
    border: "0px",
    borderLeft: "1px solid #E3E3E3",
    width: "100px",
    height: "20px",
    fontSize: "80%",
    color: "#136FE2",
    margin: "5px 0"
  }
});

const ShrinkedTextField = styled.input`
  border: 0px;
  height: 30px;
  width: 100%;
  border-radius: ${props => props.disabled ? "0px" : "10px"};
  :focus {
    outline: 0;
  };
  padding: 5px 15px;
  color: black;
`;

const setBorderHighlight = (element, desiredColor, error) => {
  element.current.style.borderColor = error ? '#ad0a0a' : desiredColor;
}

export default function InteractiveTextField(props) {
  const styles = getStyles(props);
  const parentElement = useRef(null);

  return (
    <div className="highlight-box" ref={parentElement} style={styles.root}>
      <ShrinkedTextField
        onFocus={() => setBorderHighlight(parentElement, "#6B9FFA", props.error)}
        onBlur={() => setBorderHighlight(parentElement, "#E3E3E3", props.error)}
        onChange={props.onChange}
        disabled={props.disabled}
        value={props.value}
        interactiveAvailable = {props.interactiveAvailable}
      />
      {props.interactiveAvailable ?
        <button
          tabIndex={-1}
          style={styles.button}
          onClick={props.interactiveClickAction}
        >
          {props.interactiveActionDescription}
        </button>
        : ""
      }
    </div>
  );
}
