var app = angular.module('app');

app.factory("SummariesFactory", function ($http, Config) {

  var getArticleSummaries = function (article_id) {
    return $http.get(Config.shibiUrl + '/articles/' + article_id + '/summaries.json').
    then(function (response) {
      return response.data.summaries;
    });
  };

  var publish = function(summary) {
    return $http.post(Config.shibiUrl + '/content_publications/publish.json', {
      ids: [summary.id]
    });
  };

  var publishSummaries = function(summary_ids) {
    return $http.post(Config.shibiUrl + '/content_publications/publish.json', {
      ids: summary_ids
    });
  };

  var reject = function(summary) {
    return $http.post(Config.shibiUrl + '/content_publications/' + summary.id + '/reject.json');
  };

  var accept = function(summary) {
    return $http.post(Config.shibiUrl + '/content_publications/' + summary.id + '/accept.json');
  };

  var deleteSummary = function(summary) {
    return $http.delete(Config.shibiUrl + '/content_publications/' + summary.id + '.json');
  };

  return {
    getArticleSummaries: getArticleSummaries,
    publish: publish,
    reject: reject,
    accept: accept,
    deleteSummary: deleteSummary,
    publishSummaries: publishSummaries
  };
});
