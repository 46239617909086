import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'rebass';
import ImageFileInput from '@inbox/_common/components/forms/ImageFileInput';
import CardContainer from '@inbox/_common/components/CardContainer';
import Button from '@inbox/_common/components/Button';
import Loader from '@inbox/_common/components/Loader';

@inject('settingsStore')
@observer
export default class SettingsContainer extends Component {
  componentDidMount() {
    const { getNotificationStatus, fetchAvatar } = this.props.settingsStore;
    getNotificationStatus();
    fetchAvatar();
  }
  render() {
    const {
      notificationStatus,
      updateNotificationStatus,
      postAvatar,
      onAvatarChange,
      avatar: {
        avatar,
        wasEdited,
        isLoading,
        error,
      },
    } = this.props.settingsStore;
    return (
      <CardContainer width={1 / 3} px={25} py={35}>
        <Flex justifyContent="space-between" alignItems="center" mb={40}>
          <Text>Send notifications to email</Text>
          <div className="sliding-checkbox">
            <input
              type="checkbox"
              checked={!notificationStatus}
              onChange={updateNotificationStatus}
              id="slidingCheckbox"
              className="sliding-checkbox__checkbox"
            />
            <label
              htmlFor="slidingCheckbox"
              className="sliding-checkbox__label"
            >
              <div className="sliding-checkbox__slider" />
            </label>
          </div>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text>Set profile photo</Text>
          <ImageFileInput onChange={onAvatarChange} avatar={avatar} />
        </Flex>
        {wasEdited && (
          <Flex justifyContent="space-between" mt={20} alignItems="center">
            <Text mr={15}>{error}</Text>
            <Button fontSize={16} px={0} py={0} onClick={() => postAvatar()}>
              Apply
            </Button>
          </Flex>
        )}
        {isLoading && <Loader />}
      </CardContainer>
    );
  }
}
