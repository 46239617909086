angular.module('app').factory('CommonFactory', function ($http, $stateParams, $state, Config) {

  /*
    options:
      event - pass current event
      message - display message
      success - callback function called after confirm button click
  */
  var confirmation_box = function(options) {
    var settings = $.extend({
      btnTrueText: 'Yes',
      btnFalseText: 'No',
      title: 'Confirm',
      reload: false,
      cancel: function() {
        return true
      }
    }, options);

    var $target = $(options.event.currentTarget),
        $element = $target.closest('.ng-scope'),
        overlay = $("<div class='confirmable-layer'></div>").appendTo('body'),
        cBox = $(
                  "<div class='confirmation-box'><div class='modal__header'>" + settings.title +
                  "</div><div class='modal__content modal__content--full-padding'>" + settings.message +
                  "<div class='modal__buttons modal__buttons--centered'>" +
                  "<button class='btn btn-rounded btn-rounded--red btn-rounded--tiny' data-action='cancel'>" + settings.btnFalseText + '</button>' +
                  "<button class='btn btn-rounded btn-rounded--green btn-rounded--tiny test-confirm' data-action='confirm'>" + settings.btnTrueText + '</button></div></div></div>'
                ).appendTo('body'),
        buttons = cBox.find('.btn');

    var close_box = function() {
      if(settings.reload) $state.go($state.current, {}, {reload: true});

      cBox.remove();
      overlay.remove();
    };

    overlay.click(function() {
      close_box();
      if (options.cancelOnOverlay) {
        options.cancel();
      }
    });

    buttons.click(function() {
      switch($(this).data('action')) {
        case 'confirm':
          var close = settings.confirmation();
          if(!close) {
            close_box();
          }
          return true;
        case 'cancel':
          settings.cancel();
          close_box();
          return false;
      }
    });
  };

  var alert_box = function(options) {
    var settings = $.extend({
      title: 'Confirm',
      reload: false,
      cancel: function() {
        return true
      }
    }, options);

    var $target = $(options.event.currentTarget),
        $element = $target.closest('.ng-scope'),
        overlay = $("<div class='confirmable-layer'></div>").appendTo('body'),
        cBox = $(
                  "<div class='confirmation-box'><div class='modal__header'>" + settings.title +
                  "</div><div class='modal__content modal__content--full-padding'>" + settings.message +
                  "<div class='modal__buttons modal__buttons--centered'>" +
                  "<button class='btn btn-rounded btn-rounded--green btn-rounded--tiny test-confirm' data-action='confirm'>Ok</button></div></div></div>"
                ).appendTo('body'),
        buttons = cBox.find('.btn');

    var close_box = function() {
      cBox.remove();
      overlay.remove();
    };

    overlay.click(function() {
      close_box();
    });

    buttons.click(function() {
      close_box();
    });
  }

  var loading_cover = function(context) {
    context.addClass('a--loading');
    $("<div class='a--overlay'></div>").appendTo(context);
  };

  var remove_loading_cover = function(context) {
    context.find('.a--overlay').fadeOut('slow', function() {
      $(this).remove();
    });
  };

  return {
    alert: alert_box,
    confirm: confirmation_box,
    loadingCover: loading_cover,
    removeLoadingCover: remove_loading_cover
  };
});
