var app = angular.module('app');
app.factory("AutoSaveFactory", function ($http, $stateParams, $window, Config) {

  var saveForm = function (data, url, token) {
    return $http.post(Config.shibiUrl + '/auto_save.json', { form_data: data, url: url, token: token });
  };

  var getForm = function (token) {
    return $http.get(Config.shibiUrl + '/auto_save/' + token + '/show_by_token.json');
  };

  var getAllSavedForms = function () {
    return $http.get(Config.shibiUrl + '/auto_save.json');
  };

  var deleteForm = function (token) {
    return $http.delete(Config.shibiUrl + '/auto_save/' + token);
  };

  var verifyToken =  function (token) {
    return $http.get(Config.shibiUrl + '/auto_save/' + token + '/verify_token.json');
  };

  var destroyAllForms = function () {
    return $http.delete(Config.shibiUrl + '/auto_save/destroy_all.json');
  };

  return {
    saveForm: saveForm,
    getForm: getForm,
    getAllSavedForms: getAllSavedForms,
    deleteForm: deleteForm,
    verifyToken: verifyToken,
    destroyAllForms: destroyAllForms,
  };
});
