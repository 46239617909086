import groupBy from 'lodash/groupBy';
import toArray from 'lodash/toArray';

angular.module('app').controller("ArticleSourcesDetailsController", function ($scope, $state, ArticleSourcesFactory) {

  ArticleSourcesFactory.getArticleSourceDetails($state.params.articleSourceId)
    .then((response) => {
      $scope.articleSourceDetails = response;
      $scope.articleSourceDetailsKeys = Object.keys(response);
      $scope.prettifyKey = (key) => key.split("_").map((string) => string.charAt(0).toUpperCase() + string.slice(1)).join(" ");
    });
});
