angular.module("app").
  directive('pagination', function() {
    return {
      scope: {
        page: '=page',
        totalPages: '=total'
      },
      template: require("./pagination.html.haml")
    };
});
