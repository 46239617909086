import config from 'config';
import alertsStore from '../../../inbox/stores/alertsStore';

const app = angular.module('app');
app.controller('AlertsController', function($scope, $state, $rootScope, $location) {
  if (!config.kefearEnabled) {
    $state.go('monitor', {}, { reload: true });
  }
  const body = document.querySelector('body');
  const header = document.querySelector('header');
  $scope.$on('$destroy', function() {
    if (!$location.path().includes('alerts')) {
      const headerHeight = header.clientHeight + 96;
      body.style.paddingTop = headerHeight + 'px';
      document.removeEventListener('keydown', alertsStore.handleKeyEvents, true);
    }
  });
  const headerHeight = header.clientHeight - 86;
  body.style.paddingTop = headerHeight + 'px';
});
