import React, { Component } from "react";

import ArticleList from "./ArticleList";
import { Provider } from "mobx-react";
import * as stores from "../../stores";

export default class ArticleListContainer extends Component {
  render() {
    return (
      <Provider {...stores}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ArticleList key={0} />
        </div>
      </Provider>
    );
  }
}
