import mapValues from 'lodash/mapValues';

export const AnalystSentimentController = {
  template: require('./analyst_sentiment_component.html.haml'),
  controller: function($scope) {
    $scope.onSentimentChange = (client, sentiment) => {
      client.sentiment = sentiment;
    };

    $scope.$parent.$watch('sentimentData', validateSentiment, true)

    function validateSentiment(sentiment) {
      let isValid = true;
      let sentiments = [];
      mapValues(sentiment, client => {
        if (!client.hasOwnProperty('sentiment')) {
          isValid = false;
        }
        let clientId = 0;
        if (client.hasOwnProperty('client_for_section_ids') && client.hasOwnProperty('default_section_id')) {
          clientId = client.client_for_section_ids[client.default_section_id];
        } else {
          clientId = client.client_id ? client.client_id : client.id;
        }
        sentiments.push({
          client_id: +clientId,
          sentiment: +client.sentiment
        });
      });

      if (!isValid) {
        sentiments = [];
      }

      // For edit coverage purposes
      if ($scope.$parent.$parent.analystSentimentFormData) {
        $scope.$parent.$parent.sentimentIsValid = isValid;
        $scope.$parent.$parent.analystSentimentFormData = sentiments;
      } else {
        $scope.$parent.sentimentIsValid = isValid;
        $scope.$parent.analystSentimentFormData = sentiments;
      }
    }

  },
  bindings: {
    sentimentData: '='
  }
}

angular.module('app').component('analystSentiment', AnalystSentimentController);
