angular.module('app').controller("InvitationBaseController", function ($scope, $http, $stateParams, Config) {
  $scope.users = {};

  $scope.showNlaCredentials = function() {
    if ($scope.nlaCredentials) {
      $scope.nlaEmail = $scope.email;
      $scope.nlaPassword = 'pass';
    } else {
      $scope.nlaEmail = '';
      $scope.nlaPassword = '';
    }
  };
});
