import styled from 'styled-components';

const StyledLink = styled.a`
  text-decoration: none;
  color: #000;
  :hover {
    text-decoration: underline;
    color: #000;
  }
`;

export default StyledLink;
