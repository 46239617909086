angular.module('app').controller('ClientsController', function($scope, $state, ClientCoveragesFactory){

  ClientCoveragesFactory.getClientWithUnpublishedOrPublishedCoverage().then( function(response) {
    switch(response.data.status) {
      case 'unpublished':
        $state.go("client.unpublished_without_section", { clientId: 'all', clientIdWithoutSection: response.data.id, page: 1 }, { location: 'replace', reload: true });
        break;
      case 'published':
        $state.go("client.published_without_section", { clientId: 'all', clientIdWithoutSection: response.data.id, page: 1 }, { location: 'replace', reload: true });
        break;
      case 'no_result':
        $state.go("client.no_results", {}, { location: 'replace', reload: true });
        break;
    }
  });

});
