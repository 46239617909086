import React from 'react';
import styled from 'styled-components';
import { Link as RebassLink } from 'rebass';

const Link = styled(RebassLink)`
  :hover {
    color: #fff;
    opacity: 0.8;
  }
`;

const ButtonLink = props => <Link {...props} />;

ButtonLink.defaultProps = {
  bg: '#4A90E2',
  color: '#fff',
  px: 70,
  py: 10,
  borderRadius: 4,
  fontSize: 18,
};

export default ButtonLink;
