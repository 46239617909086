angular.module('app').controller("ArticleSourcesController", function($rootScope, $scope, $state, $http, $stateParams, Config, ArticleSourcesFactory) {

  $scope.edited = [];
  $scope.state = $state;
  $scope.showSidebar = true;

  if(!$state.params.briefingTypeId) {
    ArticleSourcesFactory.getArticleSources().then(function (response) {
      $scope.article_sources = response.article_sources;
      $scope.other_sources = response.other_sources;
    });
  }

  $scope.toggleSidebar = function () {
    $scope.showSidebar = !$scope.showSidebar;
  };

});
