import { action, observable } from "mobx";
import { orderBy } from "lodash";
import { API_ROUTES } from "@inbox/_app/routes";
import api from "@inbox/_app/api";
import { cookieAsObject } from "@inbox/_app/utils";
import commonStore from "./commonStore";

class AnalystFollowersStore {
  @observable error = "";
  @observable followedAnalysts = [];
  @observable followAll = false;

  @action
  updateFollowAllState = () => {
    this.followAll = this.followedAnalysts.filter(e => e.followed).length === this.followedAnalysts.length;
  }

  @action
  fetchFollowedIds = async id => {
    try {
      const followedList = await api.get(API_ROUTES.followedByAnalyst(id));
      return followedList.data.map(x => x.analystId);
    } catch (e) {
      this.error = `Cannot load followed by analyst ${id}`;
    }
  };

  @action
  prepareFollowedAnalystList = async id => {
    const followedIds = await this.fetchFollowedIds(id);
    const fullAnalystList = await commonStore.fetchAnalysts();

    const mappedAnalysts = fullAnalystList.map(analyst => {
      return {
        id: analyst.id,
        name: analyst.first_name
          ? `${analyst.first_name} ${analyst.last_name}`
          : analyst.email,
        followed: followedIds ? followedIds.includes(analyst.id) : false
      };
    });
    this.followedAnalysts = orderBy(mappedAnalysts, [e => e.name.toLowerCase()], ['asc']);
    this.updateFollowAllState();
  };

  @action
  toggleAnalyst = async ({ id }) => {
    const { followAnalyst, unfollowAnalyst } = API_ROUTES;
    const analyst = this.followedAnalysts.find(analyst => analyst.id === id);
    analyst.followed = !analyst.followed;
    const cookie = cookieAsObject();
    await api.post(analyst.followed ? followAnalyst : unfollowAnalyst, { analystId: analyst.id, followerId: parseInt(cookie['analyst_id']) })
    this.updateFollowAllState();
  };

  @action
  toggleAll = async () => {
    this.followAll = !this.followAll;
    const ids = [];
    const mappedAnalysts = this.followedAnalysts.map(analyst => {
      if (analyst.followed !== this.followAll) {
        analyst.followed = this.followAll;
        ids.push(analyst.id);
      }
      return analyst;
    });
    this.followedAnalysts = orderBy(mappedAnalysts, [e => e.name.toLowerCase()], ['asc']);
    const action = this.followAll ? 'follow' : 'unfollow';

    await api.post(API_ROUTES.massFollowToggle, { ids, action })
  };
}

export default new AnalystFollowersStore();
