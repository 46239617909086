import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';

const FilterContainer = ({ children, heading }) => (
  <Box mb={25}>
    <Text fontSize={12} mb={20}>
      {heading}
    </Text>
    {children}
  </Box>
);

FilterContainer.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default FilterContainer;
