angular.module('app').controller("RegisterController", function ($scope, $http, Config) {
  $scope.register = function() {
    var params = {
      analyst: {
        email_prefix: $scope.email_prefix || "",
        email_suffix: $scope.email_suffix,
        password: $scope.password || "",
        password_confirmation: $scope.password_confirmation || "",
      },
    };
    return $http.post(Config.shibiUrl + '/analysts.json', params).
      then(registerSuccessful, registerFailed);
  };

  var registerSuccessful = function () {
    $scope.success = true;
  };

  var registerFailed = function (response) {
    $scope.errorMessage = response.data.errors;
    $scope.password = "";
    $scope.password_confirmation = "";
  };
});
