import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { KEYBOARD_SHORTCUTS } from '@inbox/_app/constants';

Modal.setAppElement('#root');

const HeadingContainer = styled(Flex)`
  border-bottom: 1px solid #fff;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '25px',
    width: '100%',
    maxWidth: '650px',
    borderRadius: 'none',
    textShadow: '#000 1px 1px 7px',
    background: '#000 none repeat scroll 0',
    overflow: 'hidden',
  },
  overlay: {
    zIndex: 10000,
  },
};

@inject('commonStore')
@observer
export default class PrimaryModal extends Component {
  static propTypes = {
    commonStore: PropTypes.shape({
      toggleShortcutDialog: PropTypes.func.isRequired,
      shortcutDialogOpened: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const {
      toggleShortcutDialog,
      shortcutDialogOpened,
    } = this.props.commonStore;

    return (
      <Modal
        isOpen={shortcutDialogOpened}
        onRequestClose={toggleShortcutDialog}
        style={customStyles}
      >
        <HeadingContainer
          pb="5px"
          mb={15}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize={22} color="#fff">
            Keyboard shortcuts
          </Text>
          <Text
            color="#fff"
            className="clickable"
            onClick={toggleShortcutDialog}
          >
            Close
          </Text>
        </HeadingContainer>

        {KEYBOARD_SHORTCUTS.map(({ key, description }, i) => (
          <Flex key={`shortcut${i}`} mb="5px">
            <Text mr={10} color="#dd0">
              {`${key}:`}
            </Text>
            <Text color="#fff">{description}</Text>
          </Flex>
        ))}
      </Modal>
    );
  }
}
