import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Avatar from '../Avatar';

const InputFile = styled.input`
  position: absolute;
  visibility: hidden;
`;

@observer
export default class FileInput extends Component {
  _onChange = ({ target: { files } }) => {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.props.onChange(reader.result, file.name);
    };
  };

  render() {
    const { avatar } = this.props;
    return (
      <Flex alignItems="center">
        <label htmlFor="analystAvatar">
          <Avatar src={avatar} />
          <InputFile type="file" id="analystAvatar" onChange={this._onChange} />
        </label>
      </Flex>
    );
  }
}
