import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import ArticlePreview from "./components/ArticlePreview";
import InfiniteScroll from "react-infinite-scroller";
import SmallLoader from "@inbox/_common/components/SmallLoader";
import querystring from "qs";
import createContentAndOpenPreview from "@inbox/helpers/createContentAndOpenPreview"


const styles = () => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& > *": {
      width: "100% !important",
    },
  },
  nothingFound: {
    margin: "20px",
    textAlign: "center",
    color: "gray",
  },
});

@inject("articleListStore")
@inject("searchContainerStore")
@observer
class ArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMoreItems: true,
    };
  }

  async loadMoreArticles(page) {
    const params = querystring.parse(window.location.search.slice(1));
    await this.props.articleListStore.search(params, page, true);
    this.setState({
      hasMoreItems: page <= this.props.articleListStore.totalPages,
    });
  }

  componentDidMount() {
    const params = querystring.parse(window.location.search.slice(1));
    if (typeof params.article_sources === "string") {
      params.article_sources = params.article_sources.split(",");
    }
    this.props.searchContainerStore.setSearch(params.search_phrase);
    this.props.searchContainerStore.setFromDate(params.from_date);
    this.props.searchContainerStore.setToDate(params.to_date);
    if (params.article_sources || params.orig_guid) {
      this.props.searchContainerStore.setShowAdditionalFilters()
    }
    this.props.searchContainerStore.setArticleSources(
      (params.article_sources &&
        params.article_sources.map((e) => parseInt(e, 10))) ||
        []
    );
    this.props.searchContainerStore.setOrigGuid(params.orig_guid);
  }

  render() {
    const { classes } = this.props;
    const loader = (
      <div className={classes.root}>
        <SmallLoader key={0} />
      </div>
    );
    const { articles, translate, translations, isLoadingTranslations } = this.props.articleListStore;

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMoreArticles.bind(this)}
        hasMore={this.state.hasMoreItems}
        loader={loader}
        threshold={100}
        key={0}
        className={classes.root}
      >
        <div className={classes.root}>
          {articles !== undefined &&
            articles.map((article) => (
              <ArticlePreview
                article={article}
                key={article.id}
                preview={createContentAndOpenPreview.bind(this)}
                translate={translate}
                translations={translations[article.id] || {}}
                isLoading={isLoadingTranslations[article.id] || false}
              />
            ))}
          {articles && articles.length === 0 && (
            <div className={classes.nothingFound}>No articles found :(</div>
          )}
        </div>
      </InfiniteScroll>
    );
  }
}

export default withStyles(styles)(ArticleList);
