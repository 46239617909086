import React  from "react";
import { inject, observer } from 'mobx-react';
import { Tooltip } from '@material-ui/core';

const UnpublishedCoveragesCount = ({ unpublishedCoveragesCountStore, smallScreen }) => {
  if(unpublishedCoveragesCountStore.unpublishedCoveragesCount > 0) {
    return (
      <Tooltip title={<span style={{ fontSize: 12 }}>Unpublished summaries</span>}>
        <div
          style={{
            color: 'white',
            fontSize: smallScreen ? 25 : 18,
            display: 'inline-block',
            height: smallScreen ? 23 : 15,
            minWidth: smallScreen ? 30 : 22,
            backgroundColor: 'red',
            textAlign: 'center',
            borderRadius: 50,
            paddingRight: 5,
            paddingLeft: 5,
            marginTop: '-10px',
          }}>
          <sup>
            {unpublishedCoveragesCountStore.unpublishedCoveragesCount}
          </sup>
        </div>
      </Tooltip>
    );
  }

  return null;
}

export default inject('unpublishedCoveragesCountStore')(observer(UnpublishedCoveragesCount));