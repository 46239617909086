import sortBy from 'lodash/sortBy';

angular.module('app').factory("ClientCoveragesFactory", function ($http, $stateParams, $q, Config) {

  var getArticleCoverages = function (article_id) {
    return $http.get(Config.shibiUrl + '/articles/' + article_id + '/client_coverages.json').then(function (response) {
      return response.data;
    });
  };

  var getCoverage = function (content_publication_id) {
    return $http.get(Config.shibiUrl + '/content_publications/' + content_publication_id + '.json').
      then(function (response) {
        return response.data.content_publication;
      });
  };

  var getPublications = function(company_container_id, section_container_id, status, page, analyst_id = null) {
    const apiUrl = `${Config.shibiUrl}/content_publications.json`;
    return $http.get(apiUrl, { params: {
        company_container_id,
        section_container_id,
        analyst_id,
        status,
        page
      }
    }).then(function (response) {
      let publications = response.data.publications
      if(status === 'unpublished') {
        publications.forEach((group) => {
          group.coverages = sortBy(group.coverages, ['position']);
        })
      }
      return {
        coverage_groups: publications,
        totalPages: response.data.meta.total_pages
      };
    });
  };

  var getRelatedCoverages = function (coverageId) {
    return $http.get(`${Config.shibiUrl}/content_publications/${coverageId}/related.json`).then(function (response) {
      return response.data;
    });
  };

  var publishCoverages = function(coverage_ids, publish_related_coverages) {
    return $http.post(Config.shibiUrl + '/content_publications/publish.json', {
      ids: coverage_ids,
      publish_related: publish_related_coverages
    });
  };

  var publishCoverage = function(coverage, publish_related_coverages) {
    return publishCoverages([coverage.id], publish_related_coverages)
  };

  var deleteCoverage = function(coverage) {
    return $http.delete(Config.shibiUrl + '/content_publications/' + coverage.id + '.json');
  };


  var getClientWithUnpublishedOrPublishedCoverage = function () {
    return $http.get(Config.shibiUrl + '/content_containers/with_unpublished_or_published_coverages.json');
  };

  var getRelatedCoveragesData = function(coverage_id) {
    return $http.get(Config.shibiUrl + '/content_publications/' + coverage_id + '/related.json').
      then(function (response) {
        return response.data;
      });
  };

  return {
    getCoverage,
    getPublications,
    publishCoverage,
    deleteCoverage,
    publishCoverages,
    getClientWithUnpublishedOrPublishedCoverage,
    getArticleCoverages,
    getRelatedCoveragesData,
    getRelatedCoverages
  };
});
