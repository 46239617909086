import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@material-ui/core";

const DeleteOptions = ({ open, close, deleteAll, clearCompleted }) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>DELETE ALL OR CLEAR COMPLETED</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to delete all items or just clear completed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearCompleted} style={{ color: "#74009D" }}>
          Clear completed
        </Button>
        <Button onClick={deleteAll} style={{ background: "#74009D", color: "white" }} variant="contained">
          Delete All
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOptions;
