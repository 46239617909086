import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Flex, Text, Image } from 'rebass';
import styled from 'styled-components';
import { APP_ROUTES } from '@inbox/_app/routes';
import NavItem from './NavItem';

const Wrapper = styled(Flex)`
  background-color: #530c74;
  height: 100%;
  width: 50px;
  position: absolute;
  left: 0;
  top: 0;
`;
const RedDot = styled(Flex)`
  color: white;
  background-color: #d0021b;
  position: absolute;
  z-index: 4;
  width: 15px;
  font-size: 9px;
  height: 15px;
  z-index: 12;
  right: 6px;
  top: 22px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
`;

@withRouter
@inject('commonStore')
@observer
export default class SideBar extends Component {
  componentDidMount() {
    const { fetchSummaries } = this.props.commonStore;
    fetchSummaries();
  }

  onChatClick = () => {
    document
        .querySelector('#drift-widget-container')
        .classList.add('left-side');
  };

  render() {
    const { contentPublicationsCount, unreadCount } = this.props.commonStore;
    return (
      <Wrapper flexDirection="column">
        <Flex flexDirection="column" mb="auto">
          {unreadCount > 0 && <RedDot>{unreadCount}</RedDot>}
          <NavItem
            width={25}
            to={APP_ROUTES.alerts}
            src="/images/icons/inbox/alerts_icon.svg"
          />
          <NavItem to="#" src="/images/icons/inbox/clock_icon.svg" />
          <Text mt={-25} mb={25} textAlign="center" color="#fff" fontSize={10}>
            {contentPublicationsCount}
          </Text>
          <NavItem
            to={APP_ROUTES.trash}
            src="/images/icons/inbox/trash_icon.svg"
          />
        </Flex>
        <Flex flexDirection="column" mb={20} alignItems="center" width={1}>
          <Image
            my={20}
            src="/images/icons/inbox/chat_icon.svg"
            width={25}
            className="drift-open-chat clickable"
            onClick={this.onChatClick}
          />
          <NavItem
            to={APP_ROUTES.settings}
            src="/images/icons/inbox/cogwheel_icon.svg"
          />
        </Flex>
      </Wrapper>
    );
  }
}
