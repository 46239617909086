angular.module('app').controller("ArticlePreviewController", function($scope, $state, $http, $location, Config, ArticlesFactory) {
  var search_phrase_id = $location.search().search_phrase_id || null;
  var client_id = $location.search().client_id || null;

  $scope.articleId = window.location.pathname.split("/").pop();

  ArticlesFactory.getArticle(window.location.pathname.split("/").pop(), client_id, search_phrase_id).
    then(
      function (article) {
        $scope.article = article;
      },
      function (error) {
        $state.go('monitor', {}, {reload: true});
      }
    );
});
