angular.module('app').controller('SourceController', function($scope, $rootScope, $stateParams, $state, ClientsFactory, CommonFactory){


  $scope.destroyArticleSource = function (source_id, source_name, e) {
    e.stopPropagation();

    CommonFactory.confirm(e, source_name, function() {
      alert('deleted')
    });
  }


});
