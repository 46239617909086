angular
  .module('app')
  .controller('ContentController', function ($scope, $state, $location) {
    $scope.articleId = $state.params.articleId;
    $scope.contentId = $state.params.contentId;
    $scope.token = $state.params.token;
    $scope.action = $state.params.contentId ? 'edit' : 'create';
    $scope.type = $location.search().type;
    $scope.$parent.articleSourcesScope = true;
    $scope.$parent.hasBriefingTypeId = true;
  });
