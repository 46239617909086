import React, {Component} from 'react';
import { Card, CardContent, CardActions, MenuItem, Menu, Button, Typography } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import IconButton from '@material-ui/core/IconButton';
import { inject, observer } from 'mobx-react';
import Settings from './Settings'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SettingsRounded, Message, DeleteForever } from '@material-ui/icons';
import Cookies from "js-cookie";
import Badge from '@material-ui/core/Badge';
import config from 'config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const style = {
  root: {
    maxWidth: "350px",
    width: "350px",
    maxHeight: "470px",
  },
  title: {
    fontSize: 15,
  },
  menuItem: {
    whiteSpace: "normal"
  },
  menuItemUnread: {
    whiteSpace: "normal",
    background: "rgba(223, 179, 37, 0.2)"
  },
  menuItemTrash: {
    display: "flex",
    justifyContent: "center"
  },
  verticalFlexBlock: {
    display: "flex",
    flexDirection: "column"
  },
  largeIcon: {
    fontSize: 120
  },
  note: {
    color: "grey"
  }
};

@inject('publicationAlertsStore', 'analystFollowersStore')
@observer
class Dropdown extends Component {
  state = {
    open: false,
    tabValue: 0
  };

  handleTouchTap = (event) => {
    event.preventDefault();
    this.props.publicationAlertsStore.fetchPublicationAlerts();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      tabValue: 0,
    });
  };

  async componentDidMount() {
    await this.props.analystFollowersStore.prepareFollowedAnalystList(Cookies.get(`analyst_id${config.ssoSuffix}`));
    await this.props.publicationAlertsStore.fetchPublicationAlerts().catch();
  }

  handleUrlClick({ _id, content_publication_url }) {
    this.props.publicationAlertsStore.markAsRead({ _id });
    window.open(content_publication_url);
  }

  clickHandle = () => {
    // this.handleRequestClose();
  };

  deleteAllHandle = () => {
    this.props.publicationAlertsStore.deleteAllPublicationAlerts().catch(console.error);
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {

    return (
      <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleTouchTap}>
          <Badge color="error" badgeContent={this.props.publicationAlertsStore.unreadNum || 0}>
            { this.props.analystFollowersStore.followedAnalysts.filter(e => e.followed).length
              ? ( <NotificationsIcon style={{ color: "white" }}/> )
              : ( <NotificationsOffIcon style={{ color: "white" }}/> ) }
          </Badge>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          keepMounted
          style={{marginTop: "40px", minHeight: "500px"}}
        >
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon tabs example"
          >
            <Tab icon={<Message />} aria-label="phone" {...a11yProps(0)} />
            <Tab icon={<SettingsRounded />} aria-label="settings" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={this.state.tabValue} index={0}>
            { this.props.publicationAlertsStore.publicationAlerts.map( alert => (
              <MenuItem key={alert.content_publication_id} onClick={this.clickHandle}>
                <div style={style.root}>
                  <Card style={alert.unread ? style.menuItemUnread : style.menuItem}>
                    <CardContent>
                      <Typography style={style.title}>
                        {alert.analyst_name} just created a summary for {alert.company_name} - {alert.article_source}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button onClick={() => this.handleUrlClick(alert)} size="small">Edit and publish</Button>
                      {alert.unread && <Button onClick={() => this.props.publicationAlertsStore.markAsRead(alert)} size="small">Mark as read</Button>}
                    </CardActions>
                  </Card>
                </div>
              </MenuItem>
            ))}
            <MenuItem key={alert.content_publication_id} onClick={this.deleteAllHandle}>
              <div style={style.root}>
                <Card style={style.menuItemTrash}>
                  <CardContent style={{testAlign: "center"}}>
                    { this.props.publicationAlertsStore.publicationAlerts.length
                      ? ( <DeleteForever fontSize="large" /> )
                      : (
                        <div style={style.verticalFlexBlock}>
                          <IconButton>
                            <SpeakerNotesOffIcon style={style.largeIcon} />
                          </IconButton>
                          <div style={style.note}>
                            No notifications here... Yet!
                          </div>
                        </div>
                      ) }
                  </CardContent>
                </Card>
              </div>
            </MenuItem>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <MenuItem style={style.menuItem} onClick={this.clickHandle}>
              <div style={style.root}>
                <Settings />
              </div>
            </MenuItem>
          </TabPanel>
        </Menu>
      </div>
    );
  }
}


export default Dropdown;
