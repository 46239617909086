import { setApiHeaders } from '../../inbox/_app/api';
import { API_ROUTES } from '../../inbox/_app/routes';
import api from '../../inbox/_app/api';
import socket from '../../inbox/_app/socket';

angular.module('app').controller("LoginController", function ($scope, $http, $state, AuthenticationFactory, Config, ipCookie, $window) {
  if (AuthenticationFactory.isLoggedIn()) {
    setApiHeaders();
    api(API_ROUTES.auth).then(({ data: { token } }) => {
      localStorage.setItem('kefear-jwt', token);
      setApiHeaders();
      socket.setQueryToken(token);
    });
    $state.go('monitor');
  } else {
    if(Config.ssoEnabled) {
      localStorage.removeItem('kefear-jwt');
      const origin = $window.location.origin
      $window.location.href = Config.ssoUrl + `/?origin=${origin}/login`
    }
  }

    $scope.login = function() {
      return $http.post(Config.shibiUrl + '/analysts/sign_in.json', { analyst: { email: $scope.email, password: $scope.password } }).
        then(loginSuccessful, loginFailed);
    };

    var loginSuccessful = function(response) {
      AuthenticationFactory.setEmail($scope.email);
      AuthenticationFactory.setToken(response.data.authentication_token);
      AuthenticationFactory.setId(response.data.analyst_id);
      setApiHeaders();
      api(API_ROUTES.auth).then(({ data: { token } }) => {
        localStorage.setItem('kefear-jwt', token);
        setApiHeaders();
        socket.setQueryToken(token);
      });
      $state.go('monitor');
    };

    var loginFailed = function(response) {
      $scope.errorMessage = response.data.error;
      $scope.email = '';
      $scope.password = '';
    };
  });
