angular.module('app').controller("CreateFromArticleController", function( $scope, $http, $controller, $state, $stateParams, Config,
                                                                          article, BriefingTypesFactory, ArticleSourcesFactory) {

  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.$parent.articleSourcesScope = true;
  $scope.$parent.hasBriefingTypeId = true;
  $scope.$parent.briefingTypeId = $state.params.briefingTypeId;
  $scope.articleSourceId = $state.params.articleSourceId;

  ArticleSourcesFactory.getArticleSource($state.params.articleSourceId).then(function (article_source) {
    $scope.article_source = article_source;
  });

  $scope.coverageUrl = $scope.getCoverageUrl(article);
  $scope.summaryUrl = $scope.getSummaryUrl(article);
  $scope.previewSummaryUrl = $scope.getPreviewSummaryUrl(article);
  $scope.previewCoverageUrl = $scope.getPreviewCoverageUrl(article);

  $scope.updatePublishCheckbox = function (coverage = false) {
    if (coverage) {
      $scope.coverageUrlPublished = !!$scope.coverageUrl && !$scope.disablePublishing;
    } else {
      $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;
    }
  };

  $scope.disableUrl = true;
  $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;

  $scope.selectedBriefingType = $state.params.briefingTypeId;
  $scope.create_summary = true;
  $scope.create_client_coverage = false;
  $scope.title = article.title || '';
  $scope.author = article.author || '';

  ArticleSourcesFactory.getBriefingTypeArticleSources($state.params.briefingTypeId).then(function (response) {
    $scope.$parent.article_sources = response.article_sources;
    $scope.$parent.other_sources = response.other_sources;
    $scope.$parent.sourceName = response.name;
  });

  BriefingTypesFactory.getBriefingType($state.params.briefingTypeId).then(function (response) {
    $scope.selectedBriefingType = response.id;
  });

  $scope.restoreForm($stateParams.token);

  $scope.summaryUrlPublished = false;
});
