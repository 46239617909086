import createContentAndOpenPreview from "../../../inbox/helpers/createContentAndOpenPreview";

angular.module('app').controller("CreateFromFirstArticleController", function($scope, $http, $controller, $location, $state, $stateParams, Config,
                                                                              article, ArticleSourcesFactory, PreviewFactory) {

  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.articleSourceId = $state.params.articleSourceId;

  ArticleSourcesFactory.getArticleSource($state.params.articleSourceId).then(function (article_source) {
    $scope.article_source = article_source;
  });

  createContentAndOpenPreview(article, null, true, "coverage");

  // $scope.coverageUrl = $scope.getCoverageUrl(article);
  // $scope.summaryUrl = $scope.getSummaryUrl(article);
  // $scope.previewSummaryUrl = $scope.getPreviewSummaryUrl(article);
  // $scope.previewCoverageUrl = $scope.getPreviewCoverageUrl(article);
  //
  // $scope.disableUrl = true;
  //
  // $scope.$parent.coverageUrlPublished = article.full_article_content_permitted;
  // $scope.disablePublishing = !article.full_article_content_permitted;
  //
  // $scope.$parent.articleSourcesScope = true;
  // $scope.$parent.hasBriefingTypeId = false;
  //
  // $scope.create_summary = true;
  // $scope.create_client_coverage = false;
  // $scope.title = article.title || '';
  // $scope.author = article.author || '';
  //
  // $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;
  //
  // $scope.updatePublishCheckbox = function (coverage = false) {
  //   if (coverage) {
  //     $scope.coverageUrlPublished = !!$scope.coverageUrl && !$scope.disablePublishing;
  //   } else {
  //     $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;
  //   }
  // };
  //
  // if($location.search().showPreview) {
  //   PreviewFactory.preview($scope.previewCoverageUrl);
  // }
  //
  // $scope.restoreForm($stateParams.token);
  //
  // $scope.summaryUrlPublished = false;
});
