import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Text, Flex, Image, Box,
} from 'rebass';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import moment from 'moment';
import ClickableImg from '@inbox/_common/components/ClickableImg';
import Checkbox from '@inbox/_common/components/StyledCheckbox';
import KeywordHighlight from '@inbox/_common/components/KeywordHighlight';
import StyledLink from '../../_common/components/StyledLink';

const Wrapper = styled(Flex)`
  border-radius: 10px;
  font-size: 12px;
  ${props => !props.isActive
    && `
    opacity: 0.6;
  `};
`;

const SectorBar = styled(Flex)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
`;

const LockIcon = styled(Image)`
  width: 11px;
  height: 11px;
  position: absolute;
  bottom: 14px;
  left: 2px;
`;

const ReceiveDate = styled(Text)`
  min-width: 220px;
`;

export const SectorBarText = styled.div`
  transform: rotate(-90deg);
  color: #fff;
  top: 45%;
  left: -68px;
  position: absolute;
  width: 150px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

@observer
class AlertListItem extends Component {
  componentDidUpdate() {
    const {
      currentAlertId,
      alert: { _id },
    } = this.props;

    if (currentAlertId === _id) {
      const elementOfInterest = this._block.previousSibling || this._block;
      elementOfInterest.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  render() {
    const {
      currentAlertId,
      setCurrentAlert,
      deleteAlert,
      toggleForwardForm,
      flagAlert,
      toggleUnread,
      toggleSelected,
      alert: {
        article,
        company,
        phraseMentions,
        phrases,
        _id,
        unread,
        flagged,
        deleted,
        createdAt,
        clientsHaveBespokeSections,
        clients,
        selected,
        clientsWithLinks = [],
      },
    } = this.props;

    const { title: articleTitle, articleSource } = article || {};

    let ribbonColor = '#FFFFFF';

    if (currentAlertId !== _id) {
      if (unread) {
        ribbonColor = '#5E985F';
      }
    } else {
      ribbonColor = '#0DE800';
    }

    return (
      <Wrapper
        width={1}
        mb={12}
        bg="#fff"
        isActive={currentAlertId === _id}
        ref={(e) => {
          this._block = e;
        }}
      >
        <SectorBar bg={ribbonColor} width={15}>
          {clientsHaveBespokeSections && (
            <LockIcon src="/images/icons/inbox/lock_icon.svg" />
          )}
        </SectorBar>
        <Flex flexDirection="column" width={1}>
          <Flex
            flexDirection="column"
            width={1}
            p={12}
            pb={0}
            onClick={() => setCurrentAlert(_id)}
          >
            <Flex justifyContent="space-between">
              <Flex flexWrap="wrap">
                {isEmpty(clientsWithLinks) && (
                  <Text>{(clients || []).join(', ')}</Text>
                )}
                {clientsWithLinks.map(({ link, name }, idx) => (
                  <Box key={`alert-client-link-box${idx}`} pr="8px">
                    <StyledLink href={link} target="_blank" key={`alert-client-link${idx}`}>
                      {name}
                    </StyledLink>
                  </Box>
                ))}
              </Flex>
              <ReceiveDate ml="5px" textAlign="right">
                Received at:
                {' '}
                {moment(createdAt).format('D/M/YY h:mm A Z')}
              </ReceiveDate>
            </Flex>
            <Flex justifyContent="space-between" mb={10}>
              <Text>{company}</Text>
              <Text color="#D62828">
                {moment.duration(moment().diff(moment(createdAt))).humanize()}
                {' '}
                ago
              </Text>
            </Flex>
            <Text mb={10} dangerouslySetInnerHTML={{ __html: articleTitle }} />
            <Text mb={10}>{articleSource}</Text>
            <Flex>
              <KeywordHighlight>
                {(phrases || []).length === 1
                  ? `${phrases.length} keyword`
                  : `${(phrases || []).length} keywords`}
              </KeywordHighlight>
            </Flex>
          </Flex>
          <Flex flexDirection="column" width={1} p={12} pt={0}>
            <Flex alignItems="center" justifyContent="center" mt={10}>
              <KeywordHighlight mr="auto" secondary>
                {phraseMentions === 1
                  ? `${phraseMentions} keyword mention`
                  : `${phraseMentions} keyword mentions`}
              </KeywordHighlight>
              <Checkbox
                kind="box"
                type="checkbox"
                checked={!!selected}
                onChange={() => toggleSelected(_id)}
              />
              {toggleUnread && (
                <ClickableImg
                  mr={10}
                  src={
                    unread
                      ? '/images/icons/inbox/envelope_icon.png'
                      : '/images/icons/inbox/envelope_open_icon.svg'
                  }
                  alt="Mark as read"
                  width={14}
                  onClick={() => toggleUnread(_id, !unread)}
                />
              )}
              <ClickableImg
                mr={12}
                src="/images/icons/inbox/forward_icon.svg"
                alt="Forward"
                width={20}
                onClick={() => toggleForwardForm(true, _id)}
              />
              <ClickableImg
                mr={12}
                src={
                  flagged
                    ? '/images/icons/inbox/flag_red_icon.svg'
                    : '/images/icons/inbox/flag_icon.svg'
                }
                alt="Flag"
                width={10}
                onClick={() => flagAlert(_id, !flagged)}
              />
              {deleteAlert && (
                <ClickableImg
                  mr={10}
                  src="/images/icons/inbox/alert_trash_icon.svg"
                  alt="Delete"
                  width={10}
                  onClick={() => deleteAlert(_id, !deleted)}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    );
  }
}

AlertListItem.propTypes = {
  deleteAlert: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  alert: PropTypes.object.isRequired,
  setCurrentAlert: PropTypes.func.isRequired,
  toggleForwardForm: PropTypes.func.isRequired,
  flagAlert: PropTypes.func.isRequired,
};

AlertListItem.defaultProps = {
  deleteAlert: null,
};

export default AlertListItem;
