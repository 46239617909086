import React from "react";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "./Icons";
import styled from 'styled-components';

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    minWidth: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#e8f2fc",
  },
  title: {
    maxWidth: "67%",
    fontSize: "1rem",
    fontWeight: "700",
    minHeight: 38,
    paddingTop: 6,
    paddingBottom: 5,
    paddingLeft: 20,
    color: "#530c74",
    cursor: "pointer",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    width: "33%",
  },
};

const StyledHoverDiv = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    opacity: 0.7;
  }
  
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export default function ArticleHeader(props) {
  const { title, nla_link } = props.article;

  return (
    <div style={styles.root}>
      <div
        onClick={() => props.preview(props.article, null, true)}
        dangerouslySetInnerHTML={{ __html: title || "" }}
        style={styles.title}
      />
      <div style={styles.buttons}>
        <StyledHoverDiv>
          <IconButton
            onClick={() => {
              props.setUseTranslation(!props.useTranslation);
            }}
            size={"medium"}
          >
            <Icons.SvgIconTranslate />
            <span style={{ fontSize: 10, marginLeft: 4 }}>EN</span>
          </IconButton>
        </StyledHoverDiv>
        {nla_link ?  (
          <StyledHoverDiv>
            <IconButton
              onClick={() => props.preview(props.article, "nla", true)}
              size={"medium"}
            >
              <Icons.SvgIconNlaBlue />
              <span style={{ fontSize: 10, marginLeft: 4 }}>NLA</span>
            </IconButton>
          </StyledHoverDiv>
        ) : null}
        <StyledHoverDiv>
          <IconButton
            onClick={() => props.preview(props.article, "url", true)}
            size={"medium"}
          >
            <Icons.SvgIconPreviewHover />
            <span style={{ fontSize: 10, marginLeft: 4 }}>URL</span>
          </IconButton>
        </StyledHoverDiv>
        <StyledHoverDiv>
          <IconButton
            onClick={() => props.preview(props.article, "plain_text", true)}
            size={"medium"}
          >
            <Icons.SvgIconPlaintextBlue />
            <span style={{ fontSize: 10, marginLeft: 4 }}>Plain text</span>
          </IconButton>
        </StyledHoverDiv>
        <StyledHoverDiv>
          <IconButton
            onClick={() => props.preview(props.article, "raw", true)}
            size={"medium"}
          >
            <Icons.SvgIconRawText />
            <span style={{ fontSize: 10, marginLeft: 4 }}>Raw text</span>
          </IconButton>
        </StyledHoverDiv>
      </div>
    </div>
  );
}
