var app = angular.module('app');

app.controller("CreateSummaryWithoutArticleSourceController", function ($scope, $controller, $stateParams, BriefingTypesFactory) {
  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.selectedBriefingType = $stateParams.briefingTypeId;
  $scope.create_summary = true;
  $scope.create_client_coverage = false;

  BriefingTypesFactory.getBriefingType($stateParams.briefingTypeId).then(function(response) {
    $scope.$parent.sourceName = response.name;
  });

  $scope.restoreForm($stateParams.token);
});
