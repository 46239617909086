import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@material-ui/core";


const deleteAllLabels = {
  title: 'DELETE ALL ITEMS?',
  content: 'Are you sure you want to delete all items for all analysts?'
}

const clearCompletedLabels = {
  title: 'DELETE COMPLETED ITEMS?',
  content: 'Are you sure you want to delete all completed items for all analysts?'
}

const DeleteConfirm = ({ open, close, yes, deleteAll }) => {
  const labels = deleteAll ? deleteAllLabels : clearCompletedLabels
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{ labels.title }</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { labels.content }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} style={{ color: "#74009D" }}>
          No
        </Button>
        <Button onClick={yes} style={{ background: "#74009D", color: "white" }} variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirm;
