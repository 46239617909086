import find from 'lodash/find';
import map from 'lodash/map';

angular.module("app").directive('clientPopup', ['$compile', 'ngToast', 'ClientsFactory', function($compile, ngToast, ClientsFactory) {
  return {
    restrict: 'AE', //attribute or element
    scope: {
      clientData: '='
    },
    template: require("./client_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.popupOpened = false; // popup closed on init
      $scope.clientSectionFormVisible = false;
      $scope.client = {};
      $scope.form_client_section = {
        name: '',
        description: '',
        color: '',
        client_id: null
      };
      $scope.errors = [];
      $scope.clientSectionFormAction = null;
      $scope.selectedSector = '';

      var close = function() {
        $scope.selectedSector = '';
        // $scope.$broadcast('angucomplete-alt:clearInput', 'client-sector-auto');
        $scope.clientSections = [];
        $scope.buttonIsDisabled = false;
        $scope.popupOpened = false;
        $scope.loading = false;
        if ($scope.new) {
          $scope.client.name = "";
        }
        else {
          $scope.client.name = $scope.client.tmp_name;
        }
        $scope.clientForm.$setPristine();
        $scope.errorMessage = null;
        $scope.$broadcast('angucomplete-alt:clearInput', 'client-sector-auto');
        angular.element('body').removeClass('popup-opened');
      };

      // open popup on edit
      $scope.$on('monitor.editClient', function(event, params) {
        $scope.new = false;
        $scope.client = params;
        $scope.open(false);
      });

      $scope.open = function(isNew) {
        $scope.new = isNew;
        $scope.selectedSector = '';
        angular.element('body').addClass('popup-opened');
        
        ClientsFactory.getSectors().then(function(sectors) {
          $scope.sectors = sectors;
          $scope.sectorNames = map(sectors, 'name');

          if ($scope.new) {
            $scope.popupTitle = "ADD NEW CLIENT";
            $scope.client = {};
            $scope.client.name = '';
            $scope.$broadcast('angucomplete-alt:clearInput', 'client-sector-auto');
          } else if ($scope.client.id) {
            $scope.popupTitle = "EDIT " + $scope.client.name;
            $scope.client.tmp_name = $scope.client.name;
            $scope.selectedSector = find($scope.sectors, function(item){
              return item.name == $scope.client.sector;
            });

            // get client sections
            ClientsFactory.getClientSectionsList($scope.client.id).then(function(clientSections) {
              $scope.clientSections = clientSections;
              if($scope.selectedSector !== undefined) {
                $scope.$broadcast('angucomplete-alt:changeInput', 'client-sector-auto', $scope.selectedSector.name);
              }
            });
          } else {
            close();
            return;
          }

        });
        $scope.popupOpened = true;
      };

      $scope.close = function() {
        close();
      };

      $scope.editClientSection = function(clientSection) {
        $scope.form_client_section = angular.copy(clientSection);
        $scope.clientSectionFormVisible = true;
        $scope.buttonIsDisabled = true;
        $scope.clientSectionFormAction = 'edit';
      };

      $scope.resetClientSectionForm = function() {
        $scope.form_client_section = {
          name: '',
          description: '',
          color: '',
          client_id: null
        };
        $scope.errors = [];
        $scope.clientSectionFormVisible = false;
        $scope.buttonIsDisabled = false;
        $scope.clientSectionFormAction = null;
      };

      $scope.showClientSectionForm = function() {
        $scope.form_client_section = {
          name: '',
          description: '',
          color: '',
          client_id: $scope.client.id
        };

        $scope.clientSectionFormVisible = true;
        $scope.buttonIsDisabled = true;
        $scope.clientSectionFormAction = 'create';
      };

      $scope.clientSectionFormSubmit = function() {
        ClientsFactory.updateClientSection($scope.form_client_section, $scope.clientSectionFormAction, $scope.clientSections.length + 1)
          .then(function(response) {
            ClientsFactory.getClientSectionsList($scope.client.id).then(function(clientSections) {
              $scope.clientSectionFormAction = null;
              $scope.clientSections = clientSections;
              $scope.buttonIsDisabled = false;
              $scope.resetClientSectionForm();
            });
          })
          .catch(function(response) {
            $scope.errors = response.data.errors;
          });
      };

      $scope.submit = function () {
        $scope.loading = true;
        if($scope.selectedSector == undefined) {
          $scope.selectedSector = {name: angular.element('#client-sector-auto_value')[0].value};
        }
        // debugger;
        if ($scope.new) {
          ClientsFactory.createClient($scope.client.name, $scope.selectedSector).then(function () {
            $scope.$emit('monitor.reload');
            $scope.errorMessage = null;
            ngToast.create('Client added');
            close();
          }).catch(function (errors) {
            $scope.errorMessage = errors;
            $scope.loading = false;
          });
        }
        else {
          ClientsFactory.updateClient($scope.client.id, $scope.client.name, $scope.selectedSector).then(function() {
            $scope.$emit('monitor.reload');
            $scope.clientErrorMessage = null;
            ngToast.create('Client updated');
            close();
          }).catch(function (errors) {
            $scope.clientErrorMessage = errors;
            $scope.loading = false;
          });
        }
      };
    }
  };
}]);
