export default {
  shibiUrl: 'https://shibi4tool.briefcase.news/tool',
  kefearUrl: 'https://kefear-prod-linode-1.briefcase.news',
  statrafUrl: 'https://statraf.briefcase.news',
  ssoUrl: 'https://sso.briefcase.news/',
  ssoEnabled: true,
  ssoSuffix: '',
  kefearEnabled: true,
  analystAlertsEnabled: true,
  stathat: {
    down: '0N3m2PdtchUGD9_OxNKGsCBJVHlv',
    timing: {
      get_articles: 'lPU84dV-nJyCqpMrSYFvHiBFVnlT',
      search_articles: 'DopoCLvHpTMmC52AXdqN1iBJNkNy',
      get_article: 'VhceKDFwArAsXuj09yWEzCBXdUpM',
      get_client_sections_list: '-i57a-DQhXvYjirZ08tDsCBRUjRU',
      get_client_sections: 'moaUp_0oyuP484T5BBbKdiBWYlRz',
      get_client_users: 'C9mWmgVwSLC1GhMAPQNqryBGdUdv',
      get_clients: 'Vp-lyboOfQEMNQXISVVtUCBLZnhV',
      get_client: 'oGMJX2WTe-lxiWQWz1KWYyAxaUZy'
    }
  },
  googleAnalyticsID: 'UA-119318424-1',
  autosaveInterval: 60,
  enableAutosave: true,
  rollbarAccessToken: '2f5498eea73f4e9d9f614a981d6f7816'
};
