angular.module("app").directive('tooltip', function() {
  return {
    restrict: 'E',
    template: require("./tooltip.html.haml"),
    link: function($scope, elem, attr) {
      $scope.header = attr.header;
      $scope.contentBlue = attr.contentBlue;
      $scope.contentGreen = attr.contentGreen;
      $scope.contentOrange = attr.contentOrange;
      $scope.contentRed = attr.contentRed;
      $scope.content = attr.content;
    }
  };
});
