import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import each from 'lodash/each';

angular
.module('app')
.controller('ClientGroupsController', function(
  $scope,
  $rootScope,
  ngDialog,
  ClientGroupsFactory,
) {

  $scope.createGroupData = {
    is_public: false,
    name: $scope.ngDialogData.groupData ? $scope.ngDialogData.groupData.name : '',
    description: $scope.ngDialogData.groupData ? $scope.ngDialogData.groupData.description : '',
    client_ids:  $scope.ngDialogData.groupData ? $scope.ngDialogData.groupData.clients : [],
    error: null
  };

  $scope.currentTab = 0;
  $scope.requestStatus = null;

  $scope.share = {
    analyst_ids: [],
    message: '',
    group_id: '',
    error: null,
    status: null
  };

  $scope.suggestion = {
    message: '',
    group_id: '',
    error: null,
    status: null
  };

  var initialGroup = {
    name: 'New Group',
    description: 'Groups are great for getting things done quicker. Name your group and start adding companies.',
  };

  $scope.slickConfig = {
    event: {
      init: function (event, slick) {
        $scope.currentPublicGroupTemplate = 0;
      },
      afterChange: function (event, slick, currentSlide, nextSlide) {
        $scope.currentPublicGroupTemplate = currentSlide;
      }
    }
  };

  $scope.clientGroups = [initialGroup];
  $scope.groupsLoaded = false;

  $scope.currentPublicGroupTemplate = null;
  ClientGroupsFactory.getPublicGroups().then(function(data) {
    $scope.clientGroups = $scope.clientGroups.concat(data);
    $scope.groupsLoaded = true;
  });

  $scope.getClientsTagsListWithQuery = function(query) {
    return ClientGroupsFactory.getCompanyTagsByQuery(query)
      .then(function (clients) {
        return clients;
      });
  };

  $scope.createClientGroup = function() {
    if (isEmpty($scope.createGroupData.name) || isEmpty($scope.clientGroups[$scope.currentPublicGroupTemplate].clients)) {
      $scope.createGroupData.error = 'Fields cannot be blank';
    } else {
      $scope.createGroupData.client_ids = map($scope.clientGroups[$scope.currentPublicGroupTemplate].clients, 'id');
      $scope.createGroupData.error = null;
      $scope.requestStatus = 'Please wait...';

      ClientGroupsFactory.createClientGroup($scope.createGroupData).then(function(res) {
        $rootScope.$emit('createClientGroup');
        $scope.ngDialogData.groupData = res.data.client_group;
      }).catch(function(err) {
        $scope.requestStatus = 'Error...';
      });
    }
  };

  $scope.editClientGroup = function() {
    if (isEmpty($scope.createGroupData.name) || isEmpty($scope.createGroupData.client_ids)) {
      $scope.createGroupData.error = 'Fields cannot be blank';
    } else {
      $scope.createGroupData.client_id_array = map($scope.createGroupData.client_ids, 'id');
      $scope.createGroupData.id = $scope.ngDialogData.groupData.id;
      $scope.createGroupData.error = null;
      $scope.requestStatus = 'Please wait...';

      ClientGroupsFactory.editClientGroup($scope.createGroupData).then(function(res) {
        $rootScope.$emit('createClientGroup');
        res.data.client_group.clients = res.data.client_group.companies;
        $scope.ngDialogData.groupData = res.data.client_group;
        $scope.ngDialogData.groupData.hideSharingContainer = false;
      }).catch(function(err) {
        $scope.requestStatus = 'Error...';
      });
    }
  };

  $scope.editSkipToSharing = function() {
    $scope.ngDialogData.groupData.hideSharingContainer = false;
  };

  $scope.removeGroup = function(groupId, segmentIdx, groupIdx) {
    $scope.requestStatus = 'Please wait...';

    ClientGroupsFactory.removeClientGroup(groupId).then(function() {
      $rootScope.$emit('removeClientGroup', { segmentId: segmentIdx, groupId: groupIdx });
      ngDialog.closeAll();
    }).catch(function(e) {
      $scope.requestStatus = 'Couldn\'t delete group...';
    });
  };

  $scope.sendSuggestion = function(groupId) {
    if (isEmpty($scope.suggestion.message)) {
      $scope.suggestion.error = 'Fields cannot be blank.';
    } else {
      $scope.suggestion.error = null;
      $scope.suggestion.group_id = groupId;
      $scope.suggestion.status = 'Sending...';
      ClientGroupsFactory.sendSuggestion($scope.suggestion).then(function() {
        $scope.suggestion.status = 'Suggestion sent!';
      }).catch(function(err) {
        $scope.suggestion.status = 'Error...';
      });
    }
  };

  $scope.getAnalysts = function() {
    ClientGroupsFactory.getAnalysts().then(function(data) {
        $scope.share.analystsAll = [];
        each(data, function(anal, idx) {
          $scope.share.analystsAll.push({"id": anal.id, "name": (anal.first_name && anal.last_name) ? anal.first_name + " " + anal.last_name : anal.email});
        });
      });
  };

  $scope.getAnalystsWithQuery = function(query) {
    if (!$scope.share.analystsAll) {
      $scope.getAnalysts();
    }
    $scope.share.analystsFiltered = [];
    each($scope.share.analystsAll, function(anal, idx) {
      if (anal.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        $scope.share.analystsFiltered.push({"id": anal.id, "name": anal.name});
      }
    });
    return $scope.share.analystsFiltered;
  };

  $scope.shareWithAnalysts = function(groupId) {
    $scope.share.analyst_ids = map($scope.share.analysts, 'id');
    if (isEmpty($scope.share.message) || isEmpty($scope.share.analyst_ids)) {
      $scope.share.error = 'Fields cannot be blank.';
    } else {
      $scope.share.error = null;
      $scope.share.group_id = groupId;
      $scope.share.status = 'Sending...';
      ClientGroupsFactory.shareWithAnalysts($scope.share).then(function() {
        $scope.share.status = 'Shared!';
      }).catch(function(err) {
        $scope.share.status = 'Error...';
      });
    }
  };

  $scope.$watch('currentTab', function() {
    $scope.suggestion.status = null;
    $scope.share.status = null;
  });

  $scope.setCurrentTab = function(val) {
    $scope.currentTab = val;
  }
});
