import each from 'lodash/each';
import some from 'lodash/some';
import pick from 'lodash/pick';
import map from 'lodash/map';
import filter from 'lodash/filter';

angular.module("app").directive('companyNotesPopup', ['$compile', 'ngToast', 'ContentContainersFactory', function($compile, ngToast, ContentContainersFactory) {
  return {
    restrict: 'AE', //attribute or element
    template: require("./company_notes_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.companyNotesPopupOpened = false; // popup closed on init
      $scope.errors = {};
      $scope.company_notes = '';
      $scope.company_id = null;

      $scope.closeCompanyNotes = () => {
        $scope.companyNotesPopupOpened = false;
        $scope.errors = {};
        $scope.company_notes = '';
        $scope.company_id = null;

        angular.element('body').removeClass('popup-opened');
      };

      $scope.openCompanyNotes = async (company) => {
        const { name, id } = company;

        $scope.companyName = name;
        $scope.company_id = id;
        $scope.loading = true;
        angular.element('body').addClass('popup-opened');

        const { data: { company_notes } } = await ContentContainersFactory.getCompanyContainerMetaField(id, 'company_notes');

        $scope.company_notes = company_notes;
        $scope.loading = false;
        $scope.companyNotesPopupOpened = true;
      };

      $scope.saveCompanyNote = async () => {
        await ContentContainersFactory.updateCompanyContainerMetaField($scope.company_id, 'company_notes', $scope.company_notes);
        $scope.closeCompanyNotes();
      };

      $scope.$on('monitor.editCompanyNoteType', (_, company) => $scope.openCompanyNotes(company));
    }
  };
}]);
