import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image } from 'rebass';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

const NavLink = styled(Link)`
  position: relative;
  width: 100%;
  &.active:after {
    position: absolute;
    left: 7px;
    bottom: 5px;
    width: calc(100% - 15px);
    height: 3px;
    content: '';
    background-color: #4a90e2;
  }
`;

const NavItem = ({ src, width, ...props }) => (
  <NavLink exact {...props}>
    <Flex my={20} justifyContent="center" alignItems="center">
      <Image src={src} width={width} />
    </Flex>
  </NavLink>
);

NavItem.defaultProps = {
  width: 30,
};

NavItem.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default NavItem;
