import * as React from "react";

function SvgIconPreviewHover(props) {
  return (
    <svg viewBox="4471.964 1737 23.072 17" width="1em" height="1em" {...props}>
      <path
        d="M4483.5 1753.5a11.152 11.152 0 01-6.676-2.237 12.9 12.9 0 01-4.324-5.763 12.894 12.894 0 014.323-5.763 11.082 11.082 0 0113.354 0 12.894 12.894 0 014.323 5.763 12.894 12.894 0 01-4.323 5.763 11.154 11.154 0 01-6.677 2.237zm0-12.5a4.5 4.5 0 104.5 4.5 4.505 4.505 0 00-4.5-4.5z"
        fill="#1e88e5"
        stroke="#1e88e5"
      />
    </svg>
  );
}

export default SvgIconPreviewHover;

