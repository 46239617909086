import { action, observable } from "mobx";
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';

class ArticlesStore {
  @observable article = undefined;

  @action
  getArticle = async id => {
    const { data: { article } } = await api(API_ROUTES.getArticle(id));
    this.article = article;
  };
}

export default new ArticlesStore();
