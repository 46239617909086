import React, { useState } from "react";
import {
  FormControl,
  Container,
  Button,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

const AddTodo = ({ addTodo }) => {
  const [text, setText] = useState("");
  const handleChange = (e) => setText(e.target.value);
  const createTodo = (e) => {
    e.preventDefault();
    addTodo(text);
    setText("");
  };

  return (
    <div>
      <Container maxWidth="sm">
        <form onSubmit={createTodo} className="add-todo">
          <FormControl fullWidth={true}>
            <TextField
              label="New item"
              variant="standard"
              onChange={handleChange}
              required={true}
              value={text}
              inputProps={{ style: {border: "none"} }}
            />
            <Button
              variant="contained"
              type="submit"
              style={{ background: "#74009D", marginTop: 5, color: "white"}}
            >
              <Add/>
              Add
            </Button>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default AddTodo;
