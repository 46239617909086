import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    width: "360px",
    paddingBottom: "8px",
    marginTop: "16px",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
});

@inject("searchContainerStore")
@observer
class DropdownSelector extends Component {
  render() {
    const { searchContainerStore, classes } = this.props;

    const {
      article_sources,
      setArticleSources,
      selectedArticleSources,
      setSelectedArticleSources,
      fetchedArticleSources,
      fetchArticleSources,
    } = searchContainerStore;

    const handleOpen = async () => {
      if (fetchedArticleSources.length === 0) {
        await fetchArticleSources();
      }
    };

    if (article_sources.length > 0) {
      handleOpen();
    }

    const handleChange = (value) => {
      setSelectedArticleSources(value);
      setArticleSources(value.map((e) => e.id));
    };

    return (
      <div className={classes.root}>
        <Autocomplete
          multiple
          limitTags={1}
          id="tags-standard-article-sources"
          options={fetchedArticleSources}
          getOptionLabel={(option) => option.title}
          onChange={(event, value) => handleChange(value)}
          onOpen={handleOpen}
          value={selectedArticleSources || []}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Article Sources" />
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DropdownSelector);
