import { action, observable, computed } from 'mobx';
import { publicationAlertsSocket } from '@inbox/_app/socket';
import { API_ROUTES } from '@inbox/_app/routes';
import api from '@inbox/_app/api';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import find from 'lodash/find';

class PublicationAlertsStore {
  constructor(publicationAlertsSocket) {
    this.socket = publicationAlertsSocket;
    this.socket.on('newPublication', this.unshiftAlerts);
  }

  @observable isLoading = false;
  @observable publicationAlerts = [];
  @observable popupAlerts = [];
  @observable error = '';
  @observable lastPublicationAlertId = null;
  @observable isTrash = false;

  @computed get unreadNum() {
    return this.publicationAlerts.filter(e => e.unread).length;
  }

  @action
  unshiftAlerts = (alert) => {
    this.publicationAlerts.unshift(alert);
    this.popupAlerts.unshift(alert);
  }

  @action
  markAsRead = ({ _id }) => {
    this.socket.toggleUnread(_id, false);
    const alert = find(this.publicationAlerts, { _id });
    alert.unread = false;
    this.closePopup({ _id });
  }

  @action
  closePopup = ({ _id }) => {
    this.popupAlerts = this.popupAlerts.filter(e => e._id !== _id);
  }

  @action
  deleteAllPublicationAlerts = async () => {
    if (this.isLoading) return;

    this.isLoading = true;

    try {
      await api.delete(API_ROUTES.getPublicationAlerts);

      this.publicationAlerts = [];
    } catch (e) {
      this.error = "Couldn't load data";
    } finally {
      this.isLoading = false;
    }
  }

  @action
  fetchPublicationAlerts = async (page = 1) => {
    if (this.isLoading) return;

    this.isLoading = true;
    if (page === 1) {
      this.publicationAlerts = [];
      this.error = '';
      this.lastPublicationAlertId = null;
    }

    try {
      const { data } = await api(API_ROUTES.getPublicationAlerts, {
        params: {
          lastId: this.lastPublicationAlertId,
          deleted: this.isTrash,
        }
      });
      if (!isEmpty(data)) {
        this.lastPublicationAlertId = last(data)._id;
        this.publicationAlerts = this.publicationAlerts.concat(data);
      }
    } catch (e) {
      this.error = "Couldn't load data";
    } finally {
      this.isLoading = false;
    }
  }
}

export default new PublicationAlertsStore(publicationAlertsSocket);
