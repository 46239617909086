angular.module('app').factory("PackageFactory", function ($http, $q, Config) {

  var getAllPackages = function() {
    return $http.get(Config.shibiUrl + '/packages.json').
      then(function (response) {
        return response.data.packages;
      });
  };

  return {
    getAllPackages: getAllPackages,
  };
});
