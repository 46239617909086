import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import orderBy from 'lodash/orderBy';
import { Box, Flex, Text } from 'rebass';
import { Form, Field } from 'react-final-form';
import Button from '@inbox/_common/components/Button';
import CardContainer from '../../_common/components/CardContainer';
import AsyncMultiSelect from '../../_common/components/forms/AsyncMultiSelect';

@inject('alertsStore', 'commonStore')
@observer
class ForwardMessageForm extends Component {
  onSubmit = async (values) => {
    const {
      toggleMessageSentDialog,
    } = this.props.commonStore;
    const { errors } = await this.props.alertsStore.forward(values);
    if (!errors) {
      toggleMessageSentDialog();
      setTimeout(toggleMessageSentDialog, 2000);
    }
    return errors;
  };

  loadAnalysts = async (inputValue) => {
    const analysts = await this.props.commonStore.fetchAnalysts();
    return orderBy(
      analysts.map(analyst => ({
        label: analyst.email,
        value: analyst.id,
        name: `${analyst.first_name || ''} ${analyst.last_name || ''}`,
      })),
      [user => user.label.toLowerCase()],
      ['asc'],
    ).filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  render() {
    const { toggleForwardForm, defaultInputProps } = this.props;
    return (
      <CardContainer p={40}>
        <Text mb={40} fontSize={35}>
          Forward message to
        </Text>
        <Form
          onSubmit={this.onSubmit}
          validate={(values) => {
            const errors = {};
            if (!values.analysts) {
              errors.analysts = 'Required';
            }
            if (!values.message) {
              errors.message = 'Required';
            }
            return errors;
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field name="analysts">
                {({ input, meta }) => (
                  <Box mb={30}>
                    <AsyncMultiSelect
                      autoFocus
                      {...input}
                      meta={meta}
                      loadOptions={this.loadAnalysts}
                      placeholder="To"
                      {...defaultInputProps}
                    />
                  </Box>
                )}
              </Field>
              <Field name="message">
                {({ input, meta }) => (
                  <div>
                    <textarea
                      {...input}
                      type="text"
                      placeholder="Message"
                      className="primary-textarea"
                      rows="10"
                      {...defaultInputProps}
                    />

                    {(meta.error || meta.submitError)
                      && meta.touched && (
                      <span className="error-message">
                        {meta.error || meta.submitError}
                      </span>
                    )}
                  </div>
                )}
              </Field>
              <Flex justifyContent="flex-end" width={1} mt={40} mb={-30}>
                <Text onClick={() => toggleForwardForm(false)}>
                  close window
                </Text>
              </Flex>
              <Flex justifyContent="center" width={1}>
                <Button
                  bg="#4A90E2"
                  color="#fff"
                  px={70}
                  py={10}
                  borderRadius={4}
                  fontSize={18}
                  type="submit"
                  disabled={submitting}
                >
                  Send
                </Button>
              </Flex>
            </form>
          )}
        />
      </CardContainer>
    );
  }
}

export default ForwardMessageForm;
