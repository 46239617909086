import styled from 'styled-components';

const TextField = styled.input`
  border-radius: 4px;
  padding: 5px 15px;
  width: 100%;
  height: 32px;
  border: 1px solid ${props => props.error ? '#ad0a0a' : '#E3E3E3'};
  :focus {
    outline: none !important;
    border:1px solid ${props => props.error ? '#ad0a0a' : '#6B9FFA'};
  };
  color: black;
`;

export default TextField;
