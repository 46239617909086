import createContentAndOpenPreview from "../../../inbox/helpers/createContentAndOpenPreview";

var app = angular.module('app');

app.controller("CreateClientCoverageWithArticleController", function ($scope, $controller, $location, article, $stateParams,
                                                                      ArticleSourcesFactory, PreviewFactory) {
  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.updateClientSection($stateParams.clientId, 0);

  ArticleSourcesFactory.getArticleSource(article.article_source_id).then(function (article_source) {
    $scope.article_source = article_source;
  });

  createContentAndOpenPreview(article, null, true, "coverage");

  // $scope.coverageUrl = $scope.getCoverageUrl(article);
  // $scope.summaryUrl = $scope.getSummaryUrl(article);
  // $scope.previewSummaryUrl = $scope.getPreviewSummaryUrl(article);
  // $scope.previewCoverageUrl = $scope.getPreviewCoverageUrl(article);
  //
  // $scope.disableUrl = true;
  //
  // $scope.article = article;
  // $scope.title = article.title;
  // $scope.author = article.author;
  //
  // $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing && $scope.article.full_article_content_permitted;
  // $scope.coverageUrlPublished = !!$scope.coverageUrl && !$scope.disablePublishing && $scope.article.full_article_content_permitted;
  // $scope.briefingContentPermitted = $scope.article.full_article_content_permitted;
  // $scope.coverageContentPermitted = $scope.article.full_article_content_permitted;
  //
  // $scope.create_summary = false;
  // $scope.create_client_coverage = true;
  // if($location.search().showPreview) {
  //   PreviewFactory.preview($scope.previewCoverageUrl);
  // }
  //
  // $scope.restoreForm($stateParams.token);
});
