import React, { Component } from 'react';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Search from "./Search";
import CompaniesTable from "./CompaniesTable";
import { GreenCheckbox } from "./GreenCheckbox";
import { observer, inject } from "mobx-react";
import GroupsTable from "./GroupsTable";
import styled from "styled-components";
import GroupModal from "./GroupModal";
import SectionsSelectButton from "./SectionsSelectButton";


const styles = {
  label: {
    width: "100%",
    textAlign: "left",
    color: "#530C74",
    fontSize: "12px"
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px",
    fontSize: "11px"
  },
  tableCell: {
    fontSize: "11px",
    padding: "3px 0 0 10px"
  }
};

const NewGroupButton = styled.button`
  color: #1e88e5;
  background-color: transparent;
  border: none;
  outline:none;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  `;

@inject("contentStore")
@observer
class Row extends Component {
  render() {
    const { row, isGroup } = this.props;
    const { expandSector, isLoadingSector, toggleModal, toggleGroups, toggleSector, selectSections } = this.props.contentStore;

    let expand;
    if(isGroup) {
      expand = () => {
        row.expanded = !row.expanded
      }
    } else {
      expand = expandSector
    }

    const handleCheckboxChange = () => {
      isGroup ? toggleGroups() : toggleSector(row.id);
    };

    return (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row" style={{ ...styles.tableCell, paddingLeft: "20px" }}>
            <label style={styles.label}>
              <GreenCheckbox checked={row.selected || false} key={row.label} size="small" indeterminate={row.indeterminate || false} onChange={handleCheckboxChange} style={styles.checkbox}/>
              {row.label}
            </label>
          </TableCell>
          <TableCell style={{ textAlign: "right", ...styles.tableCell }}>
            {row.label !== "My Groups" && row.label !== "Search results" && <SectionsSelectButton label={"Default section"} onClick={() => selectSections(row.id, false)}/>}
          </TableCell>
          <TableCell style={{ textAlign: "right", ...styles.tableCell }}>
            {row.label !== "My Groups" && row.label !== "Search results" && <SectionsSelectButton label={"Sector news"} onClick={() => selectSections(row.id, true)}/>}
          </TableCell>
          <TableCell style={{ textAlign: "right", ...styles.tableCell }}>
            {row.label === "My Groups" && <NewGroupButton onClick={() => toggleModal(true, null)}>Create new group</NewGroupButton>}
          </TableCell>
          <TableCell style={{ textAlign: "right", ...styles.tableCell }}>
            <IconButton aria-label="expand row" size="small" onClick={() => expand(row.id)}>
              {row.expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={row.expanded} timeout="auto" unmountOnExit>
              {(!isLoadingSector || (row.companies && row.companies.length > 0) ) && row.label !== "My Groups" && <CompaniesTable companies={row.companies} sectorId={row.id}/>}
              {(!isLoadingSector || (row.companies && row.companies.length > 0) ) && row.label === "My Groups" && <GroupsTable groups={row.groups} sectorId={row.id}/>}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

}

@inject("contentStore")
@observer
export default class SectorsTable extends Component {

  componentDidMount() {
    this.props.contentStore.fetchGroupsAndSectors()
  }

  render() {
    return (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead style={{ backgroundColor: "#F0F0F0" }}>
            <TableRow>
              <TableCell style={{ color: "#9ca3ab" }}>Select Companies</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <Search value={this.props.contentStore.searchQuery} active={this.props.contentStore.searchQuery !== ""} clearSearch={this.props.contentStore.clearSearch} setSearch={this.props.contentStore.setSearch} search={this.props.contentStore.search}/>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "#e5e6e6" }}>
            <Row key={this.props.contentStore.searchResults.name} row={this.props.contentStore.searchResults}/>
            <Row isGroup row={this.props.contentStore.groups}/>
            {!this.props.contentStore.isLoading && this.props.contentStore.sectors.map((sector) => (
              <Row key={sector.name} row={sector}/>)
            )}
          </TableBody>
        </Table>
        <GroupModal groupId={this.props.contentStore.editGroupId} open={this.props.contentStore.isModalOpen} onClose={() => this.props.contentStore.toggleModal(false, null)}/>
      </TableContainer>
    );
  }
}
