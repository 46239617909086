import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  root: {
    backgroundColor: "white",
    border: "1px solid #9CA2AB",
    width: "250px",
    height: "30px",
    borderRadius: "100px",
    textAlign: "right",
    display: "inline-block"
  }
}

const BasicInput = styled.input`
  border: 0;
  color: #9CA2AB;
  padding-left: 10px;
  height: 95%;
  :focus {
    outline: 0;
  };
`;

const SmallClearIcon = withStyles({
  root: {
    width: "18px",
    height: "18px",
  }
})(HighlightOffIcon);

const handleKeyDown = (e, search) => {
  if (e.key === 'Enter') {
    search()
  }
}
export default function Search(props) {

  return (
    <div style={styles.root}>
      <BasicInput style={{width: props.active? "72%": "81%"}} value={props.value} onChange={e => props.setSearch(e.target.value)} onKeyDown={e => {handleKeyDown(e, props.search)}} placeholder="Find it faster ..." />
      {props.active && <IconButton size="small" onClick={() => props.clearSearch()} style={{ padding: 0, marginRight: "5px", marginBottom: "1px", color: "#9CA2AB" }}>
        <SmallClearIcon/>
      </IconButton>}
      <IconButton onClick={() => props.search()} style={{ padding: 0, marginRight: "10px", color: "#9CA2AB" }}>
        <SearchIcon/>
      </IconButton>
    </div>
  );
}
