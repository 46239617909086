import config from 'config';

export const APP_ROUTES = {
  alerts: '/alerts',
  settings: '/alerts/settings',
  trash: '/alerts/trash'
};

const shibiPath = (...params) => [config.shibiUrl, ...params].join('/');
const kefearPath = (...params) => [config.kefearUrl, ...params].join('/');

export const API_ROUTES = {
  getSmartSnippet: (articleId, keywords, weight = 1.0) =>
    shibiPath(
      `smart_snippets/smart_snippet.json?article_id=${articleId}&keywords=${keywords}&weight=${weight}`
    ),
  contentPublicationsCount: shibiPath('content_publications_count.json'),
  sectors: shibiPath('sectors.json'),
  notificationStatus: shibiPath(
    'analysts/get_global_email_client_notification_status.json'
  ),
  updateNotificationStatus: shibiPath(
    'analysts/update_global_email_client_notification_status.json'
  ),
  analysts: shibiPath('analysts'),
  updateAvatar: (analystId) =>
    shibiPath(`analysts/update_avatar?analyst_id=${analystId}`),

  getMessages: kefearPath('messages'),
  messageForward: (id) => kefearPath(`messages/${id}/forward`),

  messagesById: (id) => kefearPath(`messages/${id}`),
  auth: kefearPath('auth'),

  getPublicationAlerts: kefearPath('publication-alerts'),
  publicationAlertById: (id) => kefearPath(`publication-alerts/${id}`),

  followedByAnalyst: (id) => kefearPath(`analysts/followed?followerId=${id}`),
  followAnalyst: kefearPath('analysts/follow'),
  unfollowAnalyst: kefearPath('analysts/unfollow'),
  massFollowToggle: kefearPath('analysts/mass-follow-toggle'),

  getBriefingTypes: shibiPath('content_containers/briefing_types.json'),
  getGroupsAndSectors: shibiPath('briefing_coverage_screen_merged'),
  getSector: (id) =>
    shibiPath(`briefing_coverage_screen_merged/get_sector_companies?id=${id}`),
  postContent: shibiPath('contents'),
  getContent: (id) => shibiPath(`contents/${id}`),
  getArticle: (id) => shibiPath(`articles/${id}`),
  getRelatedContentPublications: (id) =>
    shibiPath(`articles/${id}/related_content_publications`),
  searchArticles: (query) => shibiPath(`articles/search?${query}`),
  translate: shibiPath('/translations/translate_text.json'),
  getArticleSources: () => shibiPath(`article_sources.json`),
  getAuthors: shibiPath(`/authors/search`),
  search: shibiPath(`v2/briefing_coverage_screen_merged/search`),
  updateContent: (id) => shibiPath(`contents/${id}`),
  searchForGroups: shibiPath(`briefing_coverage_screen_merged/search_client`),
  getSectorsForGroups: shibiPath('merged_client_groups/get_public'),
  createGroup: shibiPath('merged_client_groups'),
  manageGroup: (id) => shibiPath(`merged_client_groups/${id}`),
  getAnalysts: shibiPath(`analysts`),
  shareWithAnalysts: shibiPath(`merged_client_groups/share`),
  favouriteCompany: shibiPath(`favourites/favourite_company`),
  unfavouriteCompany: shibiPath(`favourites/unfavourite_company`),
  markArticleAsRead: (articleID) =>
    shibiPath(`articles/${articleID}/mark_as_read.json`),
  autosave: shibiPath(`auto_save.json`),
  showByToken: (token) => shibiPath(`auto_save/${token}/show_by_token.json`),
  getAuthorRegex: (author) =>
    shibiPath(`authors/recognize_authors?author=${author}`),
  getUnpublishedCoveragesCount: shibiPath('unpublished_coverages_count')
};
