import React from 'react';

const Checkbox = ({
  className,
  kind,
  label,
  type,
  ...input
}) => (
  <label
    className={`custom-control custom-control--${kind}${className ? ` ${className}` : ''}`}
  >
    <input
      {...input}
      className={`custom-control__input custom-control__input--${kind}`}
      type={type}
      data-testid={`custom-checkbox-input__${label}`}
    />
    <span className={`custom-control__control custom-control__control--${kind}`} />
    { label && <span className="custom-control__label">{label}</span>}
  </label>
);

export default Checkbox;
