import React  from 'react';

import SearchElements from './SearchElements';
import { Provider } from 'mobx-react';
import * as stores from '../../stores';
import { useMediaQuery } from '@material-ui/core';

export const SearchContainer = () => {
  const smallScreen = useMediaQuery("(max-width: 1250px)");

  return (
    <Provider {...stores}>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: smallScreen ? '' : 'center', marginLeft: smallScreen ? 10 : 0 }}
      >
        <SearchElements />
      </div>
    </Provider>
  );
};

export default SearchContainer;
