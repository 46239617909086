angular.module("app").directive('reorderPopup', ['$http', 'Config', '$stateParams', function($http, Config, $stateParams) {
  return {
    restrict: 'AE', //attribute or element
    template: require("./reorder_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.reorderPopupOpened = false; // popup closed on init

      var closeReorder = function() {
        $scope.reorderPopupOpened = false;
        angular.element('body').removeClass('reorder-popup-opened');
      };

      $scope.openReorder = function() {
        angular.element('body').addClass('reorder-popup-opened');
        $scope.reorderPopupOpened = true;
        $scope.summariesCopy = $scope.$parent.summaries.slice();
      };

      $scope.closeReorder = function() {
        closeReorder();
      };

      $scope.saveReorderAndPublish = function (event) {
        var json = {};
        for(i = 0; i < $scope.summariesCopy.length; i += 1) {
          json[$scope.summariesCopy[i].id]={'position': i};
        }
        $http.post(
          Config.shibiUrl + '/briefings/' + $stateParams.briefingId + '/reorder_summaries.json',
          {
            summaries: json
          }
        );
        $scope.$parent.submit(event);
      };

      $scope.closePopupWithoutSave = function() {
        $scope.closeReorder();
        $scope.closeConfirmationPopup();
      };
    }
  };
}]);
