import each from 'lodash/each';
import includes from 'lodash/includes';
import values from 'lodash/values';

angular.module("app").
  directive('searchForm', function($state, $stateParams, ArticlesFactory) {
    return {
      restrict: 'E',
      template: require("./search_form.html.haml"),
      link: function (scope, element, attrs, state) {
        scope.inputFocused = false;
        scope.searchPhrase = '';
        scope.categories = [];
        scope.selectCategories= {};
        scope.selectAll = true;

        scope.$watch(function () {
          return $stateParams.searchPhrase;
        }, function (newParams, oldParams) {
          scope.searchPhrase = newParams;
        });

        scope.load = function() {
          ArticlesFactory.getSourceCategories().then(function(response){
            scope.categories = response.data;
          });
        };

        scope.searchInputFocus = function() {
          scope.inputFocused = true;
        };

        scope.searchInputBlur = function() {
          if (scope.searchPhrase === '' || scope.searchPhrase === undefined) {
            scope.inputFocused = false;
          }
        };

        scope.autocompleteSelect = function($item) {
          if ($item) {
            scope.searchPhrase = $item.title;
            scope.searchSubmit();
          }
        };

        scope.handleKeydown = function($item) {
          scope.searchPhrase = $item;
        };

        scope.searchSubmit = function() {
          scope.searchError = false;
          if (!scope.searchPhrase) {
            return false;
          } else {
            var category_keys = [];
            each( scope.selectCategories, function( val, key ) {
              if (val) {
                category_keys.push(key);
              }
            });

            // replacing apostrophes to quotations, but not the ones inside the word like Africa's, that's
            var phrase = scope.searchPhrase
              .replace(/ '| „| ”| “/g, ' "')
              .replace(/' |„ |” |“ /g, '" ')
              .replace(/^'|^”|^“|^„|'$|”$|“$|„$/g, '"');
            ArticlesFactory.searchArticles(phrase, 1, category_keys).then(
              function(response) {
                if (response.error) {
                  scope.searchError = response.error;
                  return false;
                } else {
                  $state.go('search', {
                    searchPhrase: scope.searchPhrase,
                    page: 1,
                    articles: response.articles,
                    totalPages: response.totalPages
                  });
                }
              }
            );
          }
        };

        scope.changeSingle = function() {
          scope.selectAll = !includes(values(scope.selectCategories), true)
        }

        scope.changeAll = function() {
          if (scope.selectAll) {
            each( scope.selectCategories, function( val, key ) {
              scope.selectCategories[key] = false;
            });
          } else {
            scope.selectAll = true;
          }
        }

        scope.load();

      }
    };
});
