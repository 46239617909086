export const CONNECTION_STATUS_MESSAGES = {
  disconnect: 'Disconnected from the server.',
  reconnecting: 'Connecting...',
  connect: '',
};

export const ALERT_ACTION_TYPES = {
  forward: {
    icon: '/images/icons/inbox/forward_blue_icon.svg',
    text: 'This article alert was forwared to',
  },
  delete: {
    icon: '/images/icons/inbox/trash_blue_icon.svg',
    text: 'Article alert was successfully deleted.',
    secondaryText: 'You can recover it from your trash at anytime.',
  },
};

export const KEYBOARD_SHORTCUTS = [
  {
    key: 'J',
    description: 'Move to next alert (higher element on list)',
  },
  {
    key: 'K',
    description: 'Move to previous alert (lower element on list)',
  },
  {
    key: 'Shift+J',
    description: 'Select current alert and move to next alert (higher element on list)',
  },
  {
    key: 'Shift+K',
    description: 'Select current alert and move to previous alert (lower element on list)',
  },
  {
    key: 'F',
    description: 'Forward alert',
  },
  {
    key: 'U',
    description: 'Mark alert as unread',
  },
  {
    key: '<Delete>',
    description: 'Delete currently visible alert',
  },
];
