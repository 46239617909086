import moment from 'moment';

angular.module('app').controller("InvitationWithClientController", function ($controller, $scope, $http, $stateParams, Config, ClientsFactory, PackageFactory) {
  $.extend(this, $controller('InvitationBaseController', {$scope: $scope}));

  ClientsFactory.getClient($stateParams.clientId).then(function(client){
    $scope.client_name = client.name;
  });

  ClientsFactory.getClientUsers($stateParams.clientId).then(function(response) {
    const { external, internal } = response;
    const emails = [...internal.map(client => client.email), ...external.map(client => client.email)];

    ClientsFactory.getLastEmailedAt({ emails })
      .then(({ data }) => {
        $scope.users = mapLastEmailedClients(response, data);
      }).catch(err => {
        $scope.users = mapLastEmailedClients(response, []);
      });
  });

  $scope.invite = function() {
    var params = {
      email: $scope.email || "",
      nla_password: $scope.nlaPassword || "",
      nla_email: $scope.nlaEmail || "",
      client_id: $stateParams.clientId || "",
    };

    $http.post(Config.shibiUrl + '/invitations.json', params).
      then(function () {
        $scope.success = true;
        $scope.lastEmail = $scope.email;
        $scope.email = "";
        $scope.nlaPassword = "";
        $scope.nlaEmail = "";
        ClientsFactory.getClientUsers($stateParams.clientId).then(function(response) {
          $scope.users = response;
        });
      }).
      catch(function (response) {
        $scope.errorMessage = response.data.errors;
      });
  };

  function mapLastEmailedClients({ external, internal }, statrafData) {
    return {
      external: mapUserEmails(external, statrafData),
      internal: mapUserEmails(internal, statrafData),
    }
  }
  function mapUserEmails(users, statrafData) {
    return users.map(user => ({
      ...user,
      last_email_opened_at: (statrafData.find(({ email }) => user.email === email) || {}).last_email_opened_at || '-'
    }));
  }

});
