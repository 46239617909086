import { observable, action } from "mobx";
import { unpublishedCoveragesCountSocket } from '@inbox/_app/socket';
import { API_ROUTES } from '@inbox/_app/routes';
import api from '@inbox/_app/api';

class UnpublishedCoveragesCountStore {
  @observable unpublishedCoveragesCount = 0;

  constructor(unpublishedCoveragesCountSocket) {
    this.fetchUnpublishedCoveragesCount();
    this.socket = unpublishedCoveragesCountSocket;
    this.socket.on('unpublishedCoveragesCount', this.updateUnpublishedCoveragesCount);
  }

  @action updateUnpublishedCoveragesCount = ({ unpublished_coverages_count: unpublishedCoveragesCount }) => {
    this.unpublishedCoveragesCount = unpublishedCoveragesCount;
  }

  @action fetchUnpublishedCoveragesCount = async () => {
    const {
      data:
        { unpublished_coverages_count: unpublishedCoveragesCount }
    } = await api.get(API_ROUTES.getUnpublishedCoveragesCount);
    this.unpublishedCoveragesCount = unpublishedCoveragesCount;
  }

}

export default new UnpublishedCoveragesCountStore(unpublishedCoveragesCountSocket);