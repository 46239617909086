import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { GreenCheckbox } from "./GreenCheckbox";
import { observer, inject } from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import CompaniesTable from "./CompaniesTable";
import EditIcon from '@material-ui/icons/Edit';
import withStyles from "@material-ui/core/styles/withStyles";
import SectionsSelectButton from "./SectionsSelectButton";


const styles = {
  label: {
    width: "100%",
    textAlign: "left",
    color: "#9ca2ab",
    fontSize: "12px"
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
    marginRight: "5px"
  },
  lock: {
    width: "11px",
    height: "11px",
    marginRight: "5px"
  },
  tableCell: {
    fontSize: "11px",
    padding: "3px 0 0 10px"
  }
};

const EditIconStyled = withStyles({
  root: {
    color: "#1e87e5",
    width: "12px",
    height: "12px",
  }
})(EditIcon);

@inject("contentStore")
@observer
class Row extends Component {
  render() {
    const { row, contentStore, isLoadingSector } = this.props;

    const handleCheckboxChange = () => {
      contentStore.toggleSector(row.id);
    };

    const expand = () => {
      row.expanded = !row.expanded
    };

    return (
      <React.Fragment>
        <TableRow>
          <TableCell component="th" scope="row" style={{ ...styles.tableCell, paddingLeft: "20px", width: "280px" }}>
            <label style={styles.label}>
              <GreenCheckbox checked={row.selected || false} key={row.label} size="small" indeterminate={row.indeterminate || false} onChange={() => handleCheckboxChange()} style={styles.checkbox}/>
              {row.name}
            </label>
          </TableCell>
          <TableCell style={{ textAlign: "right", ...styles.tableCell, width: "120px", padding: "4px 0px 0px 4px" }}>
            <SectionsSelectButton label={"Default section"} onClick={() => this.props.contentStore.selectSections(row.id, false)}/>
          </TableCell>
          <TableCell style={{ textAlign: "right", fontSize: "11px", width: "120px", padding: "4px 3px 0px 4px" }}>
            <SectionsSelectButton label={"Sector news"} onClick={() => this.props.contentStore.selectSections(row.id, true)}/>
          </TableCell>
          <TableCell component="th" scope="row" style={{ ...styles.tableCell, paddingLeft: "50px" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => contentStore.toggleModal(true, row.id)}>
              <EditIconStyled/>
            </IconButton>
          </TableCell>
          <TableCell style={{ textAlign: "right", ...styles.tableCell }}>
            <IconButton aria-label="expand row" size="small" onClick={() => expand()}>
              {row.expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={row.expanded} timeout="auto" unmountOnExit>
              {(!isLoadingSector || (row.companies && row.companies.length > 0) ) && <CompaniesTable test companies={row.companies} sectorId={row.id}/>}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

}

const TableContainerStyled = withStyles({
  root: {
    overflowX: "visible",
  }
})(TableContainer);

@inject("contentStore")
@observer
export default class GroupsTable extends Component {
  render() {
    return (
      <TableContainerStyled>
        <Table>
          <TableBody style={{ backgroundColor: "#F0F0F0"}}>
            {this.props.groups.map((group) => (
              <Row key={group.id} row={group}/>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    );
  }
}
