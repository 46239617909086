import each from 'lodash/each';

var app = angular.module('app');
app.controller("SummaryController", function ($scope, $controller, $stateParams, $state, BriefingTypesFactory,
                                              $http, CommonFactory, Config, SummariesFactory, ngToast, briefing,
                                              ToolFactory, PreviewFactory) {

  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  BriefingTypesFactory.getBriefingTypes()
    .then(function (briefingTypes) {
      $scope.briefingTypes = briefingTypes;
    });

  if ($stateParams.summaryId) {
    $scope.isEditForm = true;
    $http.get(`${Config.shibiUrl}/content_publications/${$stateParams.summaryId}.json`)
      .then(function (response) {
        $scope.contentPublication = response.data.content_publication;

        if (response.data.content_publication.article && response.data.content_publication.article.url) {
          $scope.contentPublication.url = response.data.content_publication.article.url;
        }
      });
    $scope.briefing = briefing.data.briefing;
    // $scope.restoreForm($stateParams.token);
  }

  $scope.update = function(event) {
    if ($scope.contentPublication.status === 'published') {
      CommonFactory.confirm({
        event: event,
        title: "Editing published summary",
        message: "<p>You are about to edit published summary. Saving this version will update the content in the app.</p><p>Are you sure?</p>",
        confirmation: doUpdate
      });
    } else {
      doUpdate();
    }
  };

  $scope.check = function (strUrl, domains) {
    if (!strUrl) return false;
    var behindBool = false;

    each(domains, function (domain) {
      if (strUrl.indexOf(domain) !== -1) {
        behindBool = true;
      }
    });

    return behindBool;
  };

  $scope.privateDomains = function (strUrl) {
    var privateDomains = [
      'www.nytsyn.com',
    ];
    return $scope.check(strUrl, privateDomains);
  }

  $scope.behindPaywall = function (article) {
    var paywallDomains = [
      'www.nytimes.com',
      'www.inframationnews.com'
    ];
    var paywallASIDs = [390];
    return $scope.check(article.url, paywallDomains) || paywallASIDs.indexOf(article.article_source_id) > -1;
  };

  $scope.isInternalUrl = function(strUrl) {
    // TODO: Make this smarter
    return strUrl && (strUrl.indexOf('http') == -1);
  };

  $scope.countOf = function(text) {
    const s = text ? text
      .replace(/<(?:.|\n)*?>/gm, '')  // strip html tags
      .replace(/(^\s*)|(\s*$)/gi,"")  // exclude start and end white-space
      .replace(/[ ]{2,}/gi," ")       // multiple space to one
      .replace(/\n /,"\n")            // exclude newline with a start spacing
      .split(/\s+/) : 0;              // splits at whitespace
    return s ? s.length : 0;
  };

  $scope.charCountOf = function(text) {
    const s = text
      ? text
          .replace(/(<([^>]+)>)/ig, '') // strip html tags
          .replace(/\s/g, '') // remove whitespace
      : ""; // splits at whitespace

    return s.length;
  };

  $scope.cancelEdit = function() {
    // $scope.deleteForm($stateParams.token);
    $state.go('briefing_publisher.summaries', { briefingId: $stateParams.briefingId })
  };

  var submitFailed = function (response) {
    $scope.errorMessage = response.data.errors;
  };

  var doUpdate = function () {
    if($scope.summaryUrl) var url = ToolFactory.addHttpToUrl($scope.summaryUrl);
    $http.patch(`${Config.shibiUrl}/content_publications/${$stateParams.summaryId}.json`,
      {
        model: {
          id: $scope.contentPublication.id,
          url: $scope.contentPublication.url,
          url_published: $scope.contentPublication.url_published,
          content: {
            id: $scope.contentPublication.content.id,
            title: $scope.contentPublication.content.title,
            body: $scope.contentPublication.content.body,
            author: $scope.contentPublication.content.author,
            rich_text_in_body: $scope.contentPublication.content.rich_text_in_body
          },
          briefing_type_id: $scope.contentPublication.briefing_type_id,
          nla_link: $scope.contentPublication.nla_link
        }
      }).then(function (response) {
        $scope.deleteForm($stateParams.token);
        ngToast.create('Summary updated');
        if(response.data.reload) {
          $state.go('briefing_publisher.summaries', { briefingId: response.data.briefing_id }, {reload: true})
        } else {
          $state.go('briefing_publisher.summaries', { briefingId: $stateParams.briefingId })
        }
      }, submitFailed);
  };

  $scope.preview = function(strUrl) {
    PreviewFactory.preview(strUrl);
  };

  $scope.cleanUpTextAreaContent = function() {
      const wordSubstitute = [
        ['million pounds', '£m'],
        ['percent', '%'],
        ['per cent', '%'],
        ['chief executive officer', 'CEO'],
        ['Chief Executive Officer', 'CEO'],
        ['PLC', ''],
      ];
      let text = $scope.contentPublication.content.body;
      if (text) {
        for (let i = 0; i < wordSubstitute.length; i++) {
          $scope.contentPublication.content.body = text.replace(wordSubstitute[i][0], wordSubstitute[i][1])
          text = $scope.contentPublication.content.body;
        }
      }
    }
});
