var app = angular.module('app');

app.controller("CreateClientCoverageController", function ($scope, $controller, $stateParams) {
  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.updateClientSection($stateParams.clientId, 0);

  $scope.create_summary = false;
  $scope.create_client_coverage = true;

  $scope.restoreForm($stateParams.token);
  $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;

  $scope.updatePublishCheckbox = function (coverage = false) {
    if (coverage) {
      $scope.coverageUrlPublished = !!$scope.coverageUrl && !$scope.disablePublishing;
    } else {
      $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;
    }
  };

  $scope.summaryUrlPublished = false;
});
