import * as React from "react";

function SvgIconNlaBlue(props) {
  return (
    <svg viewBox="4435.556 1865 9 15" width="1em" height="1em" {...props}>
      <defs>
        <clipPath id="icon-nla-blue_svg__a">
          <path data-name="Path 1729" d="M0 15l9-1.474-.089-11.594L0 0z" />
        </clipPath>
      </defs>
      <g data-name="Group 12">
        <g
          data-name="Group 11"
          clipPath="url(#icon-nla-blue_svg__a)"
          transform="translate(4435.556 1865)"
        >
          <path
            data-name="Path 1728"
            d="M6.185 4.562l.554.06-.288-.976zm1.507 6.873l-6.406.578v-.857l6.406-.438zm0-1.873l-6.406.2v-.858l6.406-.06zM7.67 7.709L1.286 7.53v-.858l6.384.32zm-.111-1.952l-.51-.04-.177-.618-.8-.08-.177.6-.488-.04.842-2.59.443.08zm-2.328-.7l-.044.478-1.507-.137V2.55l.466.08v2.31zm-2.017.319l-.421-.04-.953-2.029v1.952l-.466-.04V2.172l.421.08.931 2.072V2.41l.466.08zm5.764 7.47L8.911 2.629a.845.845 0 00-.643-.817L.643.139S0 0 0 .872v13.231s0 .876.643.777l7.714-1.255a.744.744 0 00.621-.777"
            fill="#1e88e5"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconNlaBlue;

