import { action, computed, observable } from 'mobx';
import { API_ROUTES } from '@inbox/_app/routes';
import api from '@inbox/_app/api';
import { groupBy, isEqual, uniq } from 'lodash';
import { getPreviewUrl } from '../helpers/createContentAndOpenPreview';

const INITIAL_STATE = {
  isLoading: false,
  isLoadingBriefingTypes: false,
  isLoadingSector: false,
  briefingTypes: [],
  segments: [],
  groups: [],
  sectors: [],
  content: '',
  richTextFlag: false,
  title: '',
  author: '',
  briefingTypeIds: [],
  articleUrl: '',
  articleId: undefined,
  createBriefing: false,
  createClientCoverage: false,
  isNLA: false,
  shouldPublishLinkInApp: false,
  sentimentedSections: [],
  contentType: '',
  fileInput: undefined,
  searchQuery: '',
  searchResults: {
    label: 'Search results',
    name: 'search_results',
    expanded: false,
    companies: []
  },
  selectedSectionIds: new Set(),
  selectedCompanyIds: new Set(),
  isModalOpen: false,
  validationErrors: {},
  fileName: undefined,
  briefingSection: {},
  allCompanies: {},
  allSections: {},
  relatedContentPublications: [],
  articleSources: [],
  articleSourceId: null,
  shouldDisableSourceDropdown: false,
  acastUrl: '',
  spotifyUrl: '',
  appleMusicUrl: '',
  snippetUsed: false,
  smartSnippetUsed: false
};

class ContentStore {
  @observable isLoading = false;
  @observable isLoadingBriefingTypes = false;
  @observable isLoadingSector = false;
  @observable briefingTypes = [];
  @observable segments = [];
  @observable groups = [];
  @observable sectors = [];
  @observable content = '';
  @observable richTextFlag = false;
  @observable title = '';
  @observable author = '';
  @observable briefingTypeIds = [];
  @observable articleUrl = '';
  @observable articleId = undefined;
  @observable createBriefing = false;
  @observable createClientCoverage = false;
  @observable isNLA = false;
  @observable isModalOpen = false;
  @observable shouldPublishLinkInApp = false;
  @observable briefingSummaryError = false;
  @observable sentimentedSections = [];
  @observable contentType = '';
  @observable fileInput = undefined;
  @observable editGroupId = null;
  @observable searchQuery = '';
  @observable searchResults = {
    label: 'Search results',
    name: 'search_results',
    expanded: false,
    companies: []
  };
  @observable validationErrors = {};
  @observable selectedSectionIds = new Set();
  @observable selectedCompanyIds = new Set();
  @observable fileName = undefined;
  @observable briefingSection = {};
  @observable relatedContentPublications = [];
  @observable articleSources = [];
  @observable articleSourceId;
  @observable shouldDisableSourceDropdown;
  @observable acastUrl = '';
  @observable spotifyUrl = '';
  @observable appleMusicUrl = '';
  @observable keywords = '';
  @observable snippetUsed = false;
  @observable smartSnippetUsed = false;
  @observable keywordsWeight = { value: 1.5, label: 'Default' };
  contentId = undefined;
  contentPublications = undefined;
  allCompanies = {};
  allSections = {};
  currentContentObj = null;
  token = undefined;

  constructor() {
    for (const key of Object.keys(this)) {
      if (typeof this[key] === 'function') {
        this[key] = this[key].bind(this);
      }
    }
  }

  @action fillFromAutosave = async (token) => {
    try {
      const { data } = await api.get(API_ROUTES.showByToken(token));

      const {
        url,
        body,
        title,
        author,
        article_id,
        content_type,
        rich_text_in_body,
        content_publications,
        createBriefing,
        createClientCoverage,
        articleSourceId,
        acastUrl,
        spotifyUrl,
        appleMusicUrl,
        snippet_used,
        smart_snippet_used
      } = data;

      this.contentPublications = content_publications;
      this.title = title;
      this.contentType = content_type;
      this.content = body;
      this.author = author;
      this.articleUrl = url;
      this.createClientCoverage = createClientCoverage;
      this.createBriefing = createBriefing;
      this.articleSourceId = articleSourceId;
      this.acastUrl = acastUrl;
      this.spotifyUrl = spotifyUrl;
      this.appleMusicUrl = appleMusicUrl;
      this.richTextFlag = rich_text_in_body || this.hasMediaUrls();
      this.snippetUsed = snippet_used;
      this.smartSnippetUsed = smart_snippet_used;

      if (article_id) {
        this.articleId = article_id;

        if (articleSourceId) {
          this.shouldDisableSourceDropdown = true;
        }
      }
    } catch (e) {
      console.error(`${e.message}\n${e.stak}`);
    }
  };

  autosave = async () => {
    const contentObj = this.getContentObj();

    if (!isEqual(contentObj, this.currentContentObj)) {
      this.currentContentObj = contentObj;
      const { token, currentContentObj } = this;

      currentContentObj.createBriefing = this.createBriefing;
      currentContentObj.createClientCoverage = this.createClientCoverage;
      currentContentObj.articleSourceId = this.articleSourceId;

      const { data } = await api.post(API_ROUTES.autosave, {
        token,
        form_data: currentContentObj,
        url: location.href.split('?')[0]
      });
      this.token = data.token;
    }
  };

  @action setFileInput = (fileInput) => {
    this.fileName = (fileInput && fileInput.name) || undefined;
    this.fileInput = fileInput;
  };

  @action setBriefingSection = (briefingId, section) => {
    if (this.briefingSection === section) {
      this.briefingSection[briefingId] = '';
    } else {
      this.briefingSection[briefingId] = section;
    }
  };

  @action
  fillWithDataFromArticle = async () => {
    this.title = this.article.title || '';
    this.author = (await this.useAuthorRegex(this.article.author)) || '';
  };

  @action async useAuthorRegex(author) {
    const {
      data: { author: newAuthor }
    } = await api.get(API_ROUTES.getAuthorRegex(encodeURIComponent(author)));
    return newAuthor;
  }

  @action
  fetchArticle = async (articleId) => {
    const {
      data: { article }
    } = await api.get(API_ROUTES.getArticle(articleId));

    if (this.articleUrl === '') {
      this.articleUrl = article && (article.nla_link || article.url);
    }

    if (!this.articleUrl) {
      this.articleUrl = [
        document.location.origin,
        getPreviewUrl(article.id, null, null, null, null, 'plain_text')
      ].join('');
    }

    this.articleId = article && article.id;
    this.article = article;

    if (this.article.article_source_id) {
      this.articleSourceId = this.article.article_source_id;
      this.shouldDisableSourceDropdown = true;
    }

    if (
      this.article.article_source_automatically_add_snippet &&
      !this.content
    ) {
      this.insertStandfirst();
    }
    const {
      data: { content_publications }
    } = await api.get(API_ROUTES.getRelatedContentPublications(articleId));

    this.relatedContentPublications = content_publications;
  };

  @action
  toggleModal = (isOpen, groupId = null) => {
    this.isModalOpen = isOpen;
    this.editGroupId = groupId;
  };

  @action
  fetchContent = async (contentId) => {
    if (this.isLoading && this.briefingTypes.length > 0) {
      return;
    }
    this.isLoading = true;
    try {
      this.contentId = contentId;
      const { data } = await api.get(API_ROUTES.getContent(contentId));
      console.warn(data.content);
      const {
        title,
        content_type,
        body,
        author,
        content_publications,
        url,
        article_id,
        image_name,
        attachment_name,
        rich_text_in_body,
        acast_url,
        spotify_url,
        apple_music_url
      } = data.content;
      this.contentPublications = content_publications;
      this.title = title;
      this.contentType = content_type;
      this.content = body;
      this.author = author;
      this.articleUrl = url;
      this.fileName = image_name || attachment_name;
      this.articleSourceId = content_publications[0].article_source_id;
      this.shouldDisableSourceDropdown = true;
      this.acastUrl = acast_url;
      this.spotifyUrl = spotify_url;
      this.appleMusicUrl = apple_music_url;
      this.richTextFlag = rich_text_in_body || this.hasMediaUrls();

      if (article_id) {
        this.articleId = article_id;
        await this.fetchArticle(article_id);
      }

      const publicationsByType = groupBy(
        content_publications,
        (e) => e.publication_type
      );

      if (publicationsByType['Summary']) {
        await this.fetchBriefingTypes();

        publicationsByType['Summary'].forEach((cp) => {
          this.briefingTypeIds.push(cp.briefing_type_id);
          if (cp.briefing_section) {
            this.briefingSection[cp.briefing_type_id] = cp.briefing_section;
          }
        });
        this.createBriefing = true;
      } else {
        this.createBriefing = false;
      }

      if (publicationsByType['ClientCoverage']) {
        this.createClientCoverage = true;
        const clientCoverages = publicationsByType['ClientCoverage'];
        const sectors = uniq(clientCoverages.map((e) => e.sector_id));

        await Promise.all(sectors.map((sector) => this.expandSector(sector)));

        for (const clientCoverage of clientCoverages) {
          this.toggleSection(
            clientCoverage.content_container_id,
            clientCoverage.company_id,
            clientCoverage.sector_id,
            true,
            clientCoverage.sentiment,
            clientCoverage.spokesperson_mention,
            clientCoverage.headline_mention
          );
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  setCreateBriefing = (createBriefing) => {
    this.createBriefing = createBriefing;
  };

  @action
  setIsNLA = (isNLA) => {
    this.isNLA = isNLA;
  };

  @action
  setSearch = (query) => {
    this.searchQuery = query;
  };

  @action
  setArticleSource = (source) => {
    this.articleSourceId = source.id;
  };

  @action
  setCreateClientCoverage = (createClientCoverage) => {
    this.createClientCoverage = createClientCoverage;
  };

  @action
  setShouldPublishLinkInApp = (shouldPublishLinkInApp) => {
    this.shouldPublishLinkInApp = shouldPublishLinkInApp;
  };

  @action
  setArticleUrl = (articleUrl) => {
    this.articleUrl = articleUrl;
  };

  @action
  setTitle = (title) => {
    this.title = title;
  };

  @action
  setContent = (content) => {
    this.content = content;
  };

  @action
  setContentType = (contentType) => {
    this.contentType = contentType;
  };

  hasMediaUrls = () => {
    if (
      this.acastUrl !== undefined &&
      this.acastUrl !== null &&
      this.acastUrl.length > 0
    )
      return true;
    if (
      this.spotifyUrl !== undefined &&
      this.spotifyUrl !== null &&
      this.spotifyUrl.length > 0
    )
      return true;
    if (
      this.appleMusicUrl !== undefined &&
      this.appleMusicUrl !== null &&
      this.appleMusicUrl.length > 0
    )
      return true;
    return false;
  };

  @action
  setRichTextFlag = (richTextFlag) => {
    this.richTextFlag = richTextFlag || this.hasMediaUrls();
  };

  @action
  setAuthor = (authors) => {
    this.author = authors.map((author) => author.value).join(', ');
  };

  @action
  checkBriefingSummaryError = () => {
    this.briefingSummaryError = !(
      this.createBriefing || this.createClientCoverage
    );
  };

  @action
  setBriefingType = (briefingType) => {
    if (this.briefingTypeIds.indexOf(briefingType.id) >= 0) {
      this.briefingTypeIds = this.briefingTypeIds.filter(
        (id) => id !== briefingType.id
      );
    } else {
      this.briefingTypeIds.push(briefingType.id);
    }
    this.briefingSection[briefingType.id] = '';
  };

  @action
  fetchBriefingTypes = async () => {
    if (this.isLoadingBriefingTypes || this.briefingTypes.length > 0) {
      return;
    }
    this.isLoadingBriefingTypes = true;
    try {
      const { data } = await api.get(API_ROUTES.getBriefingTypes, {});
      this.briefingTypes = data.briefing_types;
    } catch (e) {
      this.error = 'Cannot fetch briefing types';
      console.error(e);
    } finally {
      this.isLoadingBriefingTypes = false;
    }
  };

  @action
  clearContent = () => {
    for (const key of Object.keys(INITIAL_STATE)) {
      this[key] = INITIAL_STATE[key];
    }
  };

  findContentPublicationId = (containerId, publicationType) => {
    if (this.contentPublications) {
      const contentPublication = this.contentPublications.find(
        (cp) =>
          (publicationType === 'Summary'
            ? cp.briefing_type_id
            : cp.content_container_id) == containerId
      );
      return (contentPublication && contentPublication.id) || undefined;
    } else {
      return undefined;
    }
  };

  getContentPublications = () => {
    let contentPublications = [];
    if (this.createBriefing) {
      this.briefingTypeIds.forEach((briefingTypeId) => {
        const newBriefing = {
          id: this.findContentPublicationId(briefingTypeId, 'Summary'),
          briefing_type_id: briefingTypeId,
          publication_type: 'Summary',
          briefing_section: this.briefingSection[briefingTypeId] || ''
        };
        contentPublications.push(newBriefing);
      });
    }
    if (this.createClientCoverage) {
      this.selectedSectionIds.forEach((sectionId) => {
        const section = this.sentimentedSections.find(
          (e) => e.id === sectionId
        );
        contentPublications.push({
          id: this.findContentPublicationId(sectionId, 'ClientCoverage'),
          content_container_id: sectionId,
          sentiment: section && section.sentiment,
          publication_type: 'ClientCoverage',
          spokesperson_mention: section && section.spokesperson_mention,
          headline_mention: section && section.headline_mention
        });
      });
    }
    return contentPublications;
  };

  @action insertStandfirst = () => {
    if (this.article) {
      this.content = this.getSnippet(this.article.summary);
      this.snippetUsed = true;
    }
  };

  @action insertSmartSnippet = async () => {
    const { data } = await api.get(
      API_ROUTES.getSmartSnippet(
        this.articleId,
        this.keywords,
        this.keywordsWeight.value
      )
    );
    if (data && data.snippet && this.article) {
      this.content = this.getSnippet(data.snippet);
      this.smartSnippetUsed = true;
    }
  };

  getSnippet = (snippet) => {
    const isLicensorPubliclyAvailable =
      this.article.article_source_licensor === 'Publicly Available';
    const shouldShorten =
      isLicensorPubliclyAvailable && snippet && snippet.length > 200;
    return shouldShorten ? this.shortenSnippet(snippet) : snippet;
  };

  shortenSnippet = (snippet) => {
    const lastSpaceBeforeCutoff = snippet.lastIndexOf(' ', 200);
    return snippet.substring(0, lastSpaceBeforeCutoff);
  };

  setKeywords = (keywords) => {
    this.keywords = keywords.replace(' ', ',').replace(/[,]+/g, ',');
  };

  setKeywordsWeight = (weight) => {
    this.keywordsWeight = weight;
  };

  getContentObj = () => {
    const contentPublications = this.getContentPublications();
    const content = {
      title: this.title,
      body: this.content,
      author: this.author,
      rich_text_in_body: this.richTextFlag,
      content_type: this.contentType,
      article_id: this.articleId,
      url: this.articleUrl,
      content_publications: contentPublications,
      acast_url: this.acastUrl,
      spotify_url: this.spotifyUrl,
      apple_music_url: this.appleMusicUrl,
      snippet_used: this.snippetUsed,
      smart_snippet_used: this.smartSnippetUsed
    };

    return content;
  };

  @action
  createContent = async () => {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      const content = this.getContentObj();
      this.validateForm();

      if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
        console.log(Object.values(this.validationErrors));
        return;
      }

      const { data } = await api({
        method: 'POST',
        url: API_ROUTES.postContent,
        //IMPORTANT!!! You might think this should be set to 'multipart/form-data'
        // but this is not true because when we are sending up files the request
        // needs to include a 'boundary' parameter which identifies the boundary
        // name between parts in this multi-part request and setting the Content-type
        // manually will not set this boundary parameter. For whatever reason,
        // setting the Content-type to 'false' will force the request to automatically
        // populate the headers properly including the boundary parameter.
        headers: { 'Content-Type': undefined },
        //This method will allow us to change how the data is sent up to the server
        // for which we'll need to encapsulate the model data in 'FormData'
        transformRequest: function (data) {
          let formData = new FormData();
          //need to convert our json object to a string version of json otherwise
          // the browser will do a 'toString()' on the object which will result
          // in the value '[Object object]' on the server.
          formData.append('model', JSON.stringify(data.model));
          if (data.file) {
            formData.append('file', data.file);
          }
          return formData;
        },
        data: {
          file: this.fileInput,
          model: {
            content,
            common_params: {
              coverage_url_published: true,
              summary_url_published: true,
              article_source_id: this.articleSourceId
            }
          }
        }
      });

      const contentPublications = data.content.content_publications;
      const briefing = contentPublications.find(
        (cp) => cp.publication_type === 'Summary'
      );

      this.redirectToProperPage(briefing, contentPublications);
    } catch (e) {
      console.error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  redirectToProperPage(briefing, contentPublications) {
    if (briefing) {
      window.location.href = `/briefing_publisher/${briefing.content_container_id}/summaries?summaryId=${briefing.id}`;
    } else {
      window.location.href = `/clients/${contentPublications[0].company_id}/unpublished/${contentPublications[0].content_container_id}/page/1`;
    }
  }

  @action
  updateContent = async () => {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      const content = this.getContentObj();
      this.validateForm();

      if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
        console.log(Object.values(this.validationErrors));
        return;
      }

      const { data } = await api({
        method: 'PATCH',
        url: API_ROUTES.updateContent(this.contentId),
        //IMPORTANT!!! You might think this should be set to 'multipart/form-data'
        // but this is not true because when we are sending up files the request
        // needs to include a 'boundary' parameter which identifies the boundary
        // name between parts in this multi-part request and setting the Content-type
        // manually will not set this boundary parameter. For whatever reason,
        // setting the Content-type to 'false' will force the request to automatically
        // populate the headers properly including the boundary parameter.
        headers: { 'Content-Type': undefined },
        //This method will allow us to change how the data is sent up to the server
        // for which we'll need to encapsulate the model data in 'FormData'
        transformRequest: function (data) {
          let formData = new FormData();
          //need to convert our json object to a string version of json otherwise
          // the browser will do a 'toString()' on the object which will result
          // in the value '[Object object]' on the server.
          formData.append('model', JSON.stringify(data.model));
          if (data.file) {
            formData.append('file', data.file);
          }
          return formData;
        },
        data: {
          file: this.fileInput,
          model: {
            content,
            common_params: {
              coverage_url_published: true,
              summary_url_published: true,
              article_source_id: this.articleSourceId,
              remove_file: this.fileName === undefined
            }
          }
        }
      });

      const contentPublications = data.content.content_publications;
      const briefing = contentPublications.find(
        (cp) => cp.publication_type === 'Summary'
      );

      this.redirectToProperPage(briefing, contentPublications);
    } catch (e) {
      console.error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  fetchGroupsAndSectors = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { data } = await api.get(API_ROUTES.getGroupsAndSectors, {});
      this.groups = data.segments[0];
      this.sectors = data.segments[1].sectors;
      const companies = this.groups.groups.flatMap((group) => group.companies);
      this._addAllCompanies(companies);
    } catch (e) {
      this.error = 'Cannot fetch segments';
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  clearSearch = () => {
    this.setSearch('');
    this.searchResults = {
      label: 'Search results',
      name: 'search_results',
      expanded: false,
      companies: []
    };
    this.sectors.forEach((sector) => {
      if (sector.companies) {
        if (
          sector.companies.filter((company) =>
            this.selectedCompanyIds.has(company.id)
          ).length === 0
        ) {
          sector.companies = [];
        }
      }
    });
  };

  _addAllCompanies = (companies) => {
    for (const company of companies) {
      this.allCompanies[company.id] = company;

      for (const section of company.sections) {
        this.allSections[section.id] = section;
      }
    }
  };

  @action
  expandSector = async (id, shouldExpand = true) => {
    const segmentId = this.sectors.findIndex((sector) => sector.id === id);
    this.isLoadingSector = true;
    if (this.sectors[segmentId].expanded === true) {
      this.sectors[segmentId].expanded = false;
      this.isLoadingSector = false;
      return;
    }

    if (
      this.sectors[segmentId]['companies'] &&
      this.sectors[segmentId]['companies'].length > 0 &&
      this.sectors[segmentId].expanded === false
    ) {
      this.sectors[segmentId].expanded = true;
      this.isLoadingSector = false;
      return;
    }

    this.isLoadingSector = true;
    try {
      const { data } = await api.get(API_ROUTES.getSector(id), {});
      this.sectors[segmentId]['companies'] = data.sector.companies;
      this._addAllCompanies(this.sectors[segmentId]['companies']);
      this.sectors[segmentId]['expanded'] =
        data.sector.expanded && shouldExpand;
    } catch (e) {
      this.error = 'Cannot fetch segments';
      console.error(e);
    } finally {
      this.isLoadingSector = false;
    }
    this.sectorCheckboxState(this.sectors[segmentId]);
  };

  @action
  toggleFavouriteCompany = async (sectorId, companyId) => {
    try {
      const sector = this._findSectorOrGroup(sectorId);
      const company = sector.companies.find(
        (client) => client.id === companyId
      );
      if (company.is_favourite) {
        await api.post(API_ROUTES.unfavouriteCompany, {
          company_container_id: companyId
        });
        company.is_favourite = false;
      } else {
        await api.post(API_ROUTES.favouriteCompany, {
          company_container_id: companyId
        });
        company.is_favourite = true;
      }
    } catch (e) {
      console.error(e);
    }
  };

  @action
  search = async () => {
    try {
      const { data } = await api.get(API_ROUTES.search, {
        params: { query: this.searchQuery }
      });
      const { companies } = data;

      this.searchResults.companies = companies.map((c) => {
        c.sectorId = c.sector_id;
        return c;
      });

      this.sectors.forEach((sector) => {
        if (this.checkIndeterminate(sector).indeterminate) {
          sector.companies = sector.companies.filter((c) =>
            this.selectedCompanyIds.has(c.id)
          );

          sector.companies = sector.companies.concat(
            companies.filter(
              (c) =>
                !this.selectedCompanyIds.has(c.id) && c.sector_id === sector.id
            )
          );
        } else {
          sector.companies = companies.filter(
            (company) => company.sector_id === sector.id
          );
        }

        sector.expanded = sector.companies && sector.companies.length > 0;

        this._addAllCompanies(sector.companies);
      });

      this.searchResults.expanded = this.searchResults.companies.length > 0;
    } catch (e) {
      this.error = 'Cannot search';
      console.error(e);
    }
  };

  handleSentiment(
    section,
    shouldAdd,
    value = '',
    isSectionNameEqualCompanyName = false,
    spokesperson_mention,
    headline_mention
  ) {
    if (shouldAdd) {
      if (isSectionNameEqualCompanyName) {
        // NOTE: *_mention flags use 3-state boolean logic in the tool
        // as shibi accepts only true/false values, form is not valid until all nulls are resolved
        this.sentimentedSections.push({
          id: section.id,
          name: section.name,
          sentiment: value,
          spokesperson_mention:
            spokesperson_mention !== null ? spokesperson_mention : null,
          headline_mention: headline_mention !== null ? headline_mention : null
        });
      }
    } else {
      this.sentimentedSections = this.sentimentedSections.filter(
        (x) => x.id !== section.id
      );
      this.selectedSectionIds.delete(section.id);
    }
  }

  @action
  setSentiment = (section, sentiment) => {
    const sentimentedSection = this.sentimentedSections.find(
      (x) => x.id === section.id
    );
    sentimentedSection.sentiment = sentiment;
  };

  _findSectorOrGroup(sectorId) {
    let sector = this.sectors.find((sector) => sector.id === sectorId);

    if (!sector) {
      sector = this.groups.groups.find((group) => group.id === sectorId);
    }

    return sector;
  }

  @action.bound
  selectSections(sectorId, isSectorNews) {
    const sector = this._findSectorOrGroup(sectorId);
    sector.companies.forEach((company) => {
      const sectionId = this.chooseAppropriateSection(company, isSectorNews);
      if (sectionId) {
        this.toggleSection(sectionId, company.id, sectorId, true);
      } else {
        if (this.isCompanySelected(company.id)) {
          this.toggleCompany(company.id, sectorId);
        }
      }
    });
  }

  @action.bound
  toggleSection(
    sectionId,
    companyId,
    sectorId,
    toggleCompany = true,
    sentimentValue = '',
    spokesperson_mention = null,
    headline_mention = null
  ) {
    // const sector = this._findSectorOrGroup(sectorId);
    const company = this.allCompanies[companyId];
    const section = this.allSections[sectionId];

    if (!section) {
      console.warn(`Section ${sectionId} in undefined`);
      return;
    }
    const selectedSectionsIncludeSectionId = this.isSectionSelected(sectionId);

    if (selectedSectionsIncludeSectionId) {
      this.selectedSectionIds.delete(sectionId);
    } else {
      company.sections.forEach((section) =>
        this.handleSentiment(section, false, sentimentValue)
      );
      this.selectedSectionIds.add(sectionId);
    }

    if (toggleCompany) {
      if (
        selectedSectionsIncludeSectionId === this.isCompanySelected(companyId)
      ) {
        this.toggleCompany(companyId, sectorId, false);
      }
    }

    this.handleSentiment(
      section,
      !selectedSectionsIncludeSectionId,
      sentimentValue,
      section.name === company.name || section.allow_sentiment,
      spokesperson_mention,
      headline_mention
    );
    this.groupsCheckboxState();
  }

  @action.bound
  toggleCompany(companyId, sectorId, toggleSection = true) {
    const sector = this._findSectorOrGroup(sectorId);
    const company = this.allCompanies[companyId];

    if (this.selectedCompanyIds.has(companyId)) {
      this.selectedCompanyIds.delete(companyId);
      if (toggleSection) {
        company.sections.forEach((section) => {
          if (this.isSectionSelected(section.id)) {
            this.toggleSection(section.id, companyId, sectorId, false);
          }
        });
      }
    } else {
      this.selectedCompanyIds.add(companyId);
      if (toggleSection) {
        this.toggleSection(
          this.chooseAppropriateSection(company),
          companyId,
          sectorId,
          false
        );
      }
    }
    this.sectorCheckboxState(sector);
  }

  chooseAppropriateSection = (company, isSectorNews = false) => {
    let section;
    if (isSectorNews) {
      section = company.sections.find(
        (section) => section.name === 'Sector news'
      );
      return section ? section.id : false;
    } else {
      section = company.sections.find(
        (section) =>
          section.name === company.name && section.section_type === 'standard'
      );
      if (!section) {
        return company.default_section_id;
      } else {
        return section.id;
      }
    }
  };

  isCompanySelected = (companyId) => this.selectedCompanyIds.has(companyId);
  isSectionSelected = (sectionId) => this.selectedSectionIds.has(sectionId);

  @action.bound
  async toggleSector(sectorId) {
    const sector = this._findSectorOrGroup(sectorId);

    if (!sector.companies) {
      await this.expandSector(sectorId, false);
    }

    if (this.checkIndeterminate(sector).indeterminate) {
      sector.companies.forEach((company) => {
        if (this.isCompanySelected(company.id)) {
          this.toggleCompany(company.id, sectorId);
        }
      });
    } else {
      sector.companies.forEach((company) => {
        this.toggleCompany(company.id, sectorId);
      });
    }
  }

  @action
  toggleGroups = () => {
    const { groups } = this.groups;

    if (this.groups.indeterminate) {
      groups.forEach((group) => {
        if (this.checkIndeterminate(group).indeterminate) {
          this.toggleSector(group.id);
        }
      });
    } else {
      groups.forEach((group) => {
        this.toggleSector(group.id);
      });
    }
  };

  groupsCheckboxState = () => {
    const { groups } = this.groups;
    const indeterminateGroups = groups.filter((group) => group.indeterminate);
    const selectedGroups = groups.filter((group) => group.selected);
    this.groups.indeterminate =
      (selectedGroups.length > 0 && selectedGroups.length !== groups.length) ||
      indeterminateGroups.length > 0;
    this.groups.selected =
      indeterminateGroups.length + selectedGroups.length > 0;
  };

  sectorCheckboxState = (sector) => {
    const { indeterminate, allSelected } = this.checkIndeterminate(sector);
    sector.indeterminate = indeterminate && !allSelected;
    sector.selected = indeterminate || allSelected;
    this.groupsCheckboxState();
  };

  checkIndeterminate = (sector) => {
    if (sector.companies && sector.companies.length > 0) {
      const selectedSectorCompanies = sector.companies.filter((company) =>
        this.selectedCompanyIds.has(company.id)
      );
      return {
        indeterminate: selectedSectorCompanies.length > 0,
        allSelected: selectedSectorCompanies.length === sector.companies.length
      };
    } else {
      return { indeterminate: false, allSelected: false };
    }
  };

  @action
  validateForm = () => {
    this.validationErrors.title =
      this.title === null || this.title.length === 0
        ? 'Title should not be empty'
        : undefined;
    this.validationErrors.content =
      this.content === null || this.content.length === 0
        ? 'Content should not be empty'
        : undefined;
    this.validationErrors.publication = !(
      this.createBriefing || this.createClientCoverage
    )
      ? 'You have to select at least one option'
      : undefined;
    this.validationErrors.briefingType =
      this.createBriefing && !this.briefingTypeIds.length > 0
        ? 'Please, select briefing type'
        : undefined;
    this.validationErrors.section =
      this.createClientCoverage && this.selectedSectionIds.size === 0
        ? 'Please, select at least one section'
        : undefined;
    this.validationErrors.articleSourceId =
      this.articleSourceId === null
        ? 'Please, select article source'
        : undefined;
    this.validationErrors.sentiments = undefined;

    for (const sentimentedSection of this.sentimentedSections) {
      if (sentimentedSection['sentiment'] === '') {
        this.validationErrors.sentiments = 'Please, fill in sentiment values';
      }
    }

    for (const sentimentedSection of this.sentimentedSections) {
      if (sentimentedSection.spokesperson_mention === null) {
        this.validationErrors.sentiments =
          'Please, mark whether the spokesperson was mentioned';
      }
    }
    for (const sentimentedSection of this.sentimentedSections) {
      if (sentimentedSection.headline_mention === null) {
        this.validationErrors.sentiments =
          'Please, mark whether the headline was mentioned';
      }
    }
  };

  @computed
  get charCount() {
    const text =
      this.content.replace(/(<([^>]+)>)/gi, '').replace(/\s/g, '') || '';
    return text.length;
  }

  @computed
  get wordCount() {
    const words = this.content
      ? this.content
          .replace(/<(?:.|\n)*?>/gm, '')
          .replace(/(^\s*)|(\s*$)/gi, '')
          .replace(/[ ]{2,}/gi, ' ')
          .replace(/\n /, '\n')
          .split(/\s+/)
      : '';
    return words.length;
  }

  @action
  titleToLowerCase = () => {
    this.title =
      this.title.charAt(0).toUpperCase() + this.title.toLowerCase().slice(1);
  };

  @action
  cleanUpText = () => {
    const wordSubstitute = [
      ['million pounds', '£m'],
      ['percent', '%'],
      ['per cent', '%'],
      ['chief executive officer', 'CEO'],
      ['Chief Executive Officer', 'CEO'],
      ['PLC', ''],
      [' Euro ', ' € '],
      [' Euros ', ' € '],
      [' euro ', ' € '],
      [' euros ', ' € '],
      [' Euro.', ' €.'],
      [' Euros.', ' €.'],
      [' euro.', ' €.'],
      [' euros.', ' €.'],
      [' Euro,', ' €,'],
      [' Euros,', ' €,'],
      [' euro,', ' €,'],
      [' euros,', ' €,']
    ];
    let text = this.content;
    if (text) {
      for (let i = 0; i < wordSubstitute.length; i++) {
        this.content = text.replace(wordSubstitute[i][0], wordSubstitute[i][1]);
        text = this.content;
      }
    }
  };

  async fetchArticleSources() {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      const {
        data: { article_sources }
      } = await api.get(API_ROUTES.getArticleSources());
      this.articleSources = article_sources.map((e) => {
        return {
          id: e.id,
          title: e.title
        };
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  getArticleSource = () => {
    return this.articleSources.find((as) => as.id === this.articleSourceId);
  };

  async fetchAuthors(query) {
    try {
      const { data } = await api.get(API_ROUTES.getAuthors, {
        params: { part: query }
      });
      return data.map((option) => ({
        value: option,
        label: option
      }));
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setAcastUrl = (url) => {
    this.richTextFlag = true;
    this.acastUrl = url;
  };

  @action
  setSpotifyUrl = (url) => {
    this.richTextFlag = true;
    this.spotifyUrl = url;
  };

  @action
  setAppleMusicUrl = (url) => {
    this.richTextFlag = true;
    this.appleMusicUrl = url;
  };

  @action
  setSpokespersonMention = (section) => {
    const sentimentedSection = this.sentimentedSections.find(
      (x) => x.id === section.id
    );
    sentimentedSection.spokesperson_mention =
      sentimentedSection.spokesperson_mention !== true;
  };

  @action
  setHeadlineMention = (section) => {
    const sentimentedSection = this.sentimentedSections.find(
      (x) => x.id === section.id
    );
    sentimentedSection.headline_mention =
      sentimentedSection.headline_mention !== true;
  };
}

export default new ContentStore();
