import map from 'lodash/map';
import find from 'lodash/find';
import each from 'lodash/each';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import createContentAndOpenPreview from "../../../inbox/helpers/createContentAndOpenPreview";

angular
  .module('app')
  .controller('CoverageListController', function(
    $scope,
    $stateParams,
    $state,
    $controller,
    CommonFactory,
    ClientCoveragesFactory,
    coveragesResult,
    goPublished,
    ClientSectionsFactory,
    ClientsFactory,
    ngDialog,
    ClientGroupsFactory
  ) {
    if (coveragesResult.coverage_groups.length === 0) {
      if($state.current.name === 'client.published' || $state.current.name === 'client.published_without_section') {
        $state.go('client.no_results', {}, { location: 'replace', reload: true });
      }
      if($state.current.name === 'client.unpublished') {
        $state.go('client.published', $stateParams, { location: 'replace', reload: true });
      }
      if($state.current.name === 'client.unpublished_without_section') {
        $state.go('client.published_without_section', $stateParams, { location: 'replace', reload: true });
      }
    }

    $scope.$parent.$watch('clientName', function(value) {
      $scope.clientName = value;
    });

    $scope.$parent.$watch('publishedSections', function(value) {
      if($state.current.name === 'client.published') {
        if($stateParams.sectionId == 'all') {
          $scope.sectionName = 'All';
        } else {
          if($scope.$parent.publishedSections) {
            $scope.sectionName = find($scope.$parent.publishedSections, { id: parseInt($stateParams.sectionId) }).name;
          }
        }
      }
    });

    $scope.$parent.$watch('unpublishedSections', function(value) {
      if($state.current.name === 'client.unpublished') {
        if($stateParams.sectionId == 'all') {
          $scope.sectionName = 'All';
        } else {
          if($scope.$parent.unpublishedSections) {
            $scope.sectionName = find($scope.$parent.unpublishedSections, { id: parseInt($stateParams.sectionId) }).name;
          }
        }
      }
    });

    $scope.page = $stateParams.page;
    $scope.$parent.sectionId = $stateParams.sectionId;
    $scope.$parent.clientId = $stateParams.clientId;
    $scope.coverageGroups = coveragesResult.coverage_groups;
    $scope.totalPages = coveragesResult.totalPages;

    $scope.publishCoverage = function (coverage, multiple) {
      coverage.published_at = moment(new Date()).format("DD.MM.YYYY HH:mm");
      if (multiple) {
        ClientCoveragesFactory.getRelatedCoveragesData(coverage.id).
          then( function(data) {
            var message = `<div class="list-like-table"><div class="list-like-table__header">You are about to publish a coverage for:</div>`
                        + `<div class="list-like-table__row"><div class="list-like-table__row--left ng-binding">${coverage.client_name}</div>`
                        + `<div class="list-like-table__row--right ng-binding">${coverage.section_name}</div></div></div>`
                        + `<div class="list-like-table"><div class="list-like-table__header">This coverage has also been written for:</div>`;
            each(data.content_publications.filter(e => e.id !== coverage.id), function(c) {
              message += `<div class="list-like-table__row"><div class="list-like-table__row--left ng-binding">${c.client_name}</div>`
                       + `<div class="list-like-table__row--right ng-binding">${c.section_name}</div></div>`;
            });
            message += `</div>`
                     + `<p class="separated">Do you want to publish this coverage for all these clients?</p>`;
            $scope.$emit('showPublicationConfirm', {
              confirm: $scope.confirmPublish,
              message: message,
              coverage: coverage,
              multiple: multiple,
              relatedCoverages: data,
              testMode: coverage.test_mode,
            });
          });
      } else {
        var message = "<p>You are about to publish this coverage in the "
                    + "<b>" + coverage.client_name + "</b> app in the "
                    + "<b>" + coverage.section_name + "</b> section.</p>"
                    + "<p>It will become visible online.</p><p>Are you sure?</p>";

        $scope.$emit('showPublicationConfirm', {
          confirm: $scope.confirmPublish,
          message: message,
          confirmButtonText: `Yes, publish in ${coverage.client_name} app`,
          coverage: coverage,
          multiple: multiple,
          relatedCoverages: null,
          testMode: coverage.test_mode,
        });
      }
    };

    $scope.$on('showPublicationConfirm', function(event, dialogData) {
      ngDialog.open({
        plain: true,
        template: require('../modals/publish_confirmation.html.haml'),
        className: 'ng-confirmation-box',
        data: dialogData
      });
    });


    // CONFIRMING PUBLISH
    $scope.confirmPublish = function(opt) {
      if (!opt.relatedCoverages) {
        ClientCoveragesFactory.publishCoverage(opt.coverage, opt.multiple || opt.publishRelatedCoverages).then(function() {
          $state.go($state.current, {}, { reload: true });
          ngDialog.close(opt.ngDialogId);
        });
      }
      else {
        ngDialog.close(opt.ngDialogId);
        var rc = opt.relatedCoverages;
        rc.all_coverages_count = rc.content_publications.length;
        rc.uniq_client_count = uniq(rc.content_publications.map(e => e.client_id)).length;

        var message = '<p>You are about to publish <b>' + rc.all_coverages_count + '</b> coverages in <b>' + rc.uniq_client_count + '</b></p>'
                    + ' different client apps. Are you sure?';

        if (opt.multiple) {
          $scope.$emit('showPublicationConfirm', {
            confirm: $scope.confirmPublish,
            message: message,
            confirmButtonText: 'YES, PUBLISH IN ' + rc.uniq_client_count,
            coverage: opt.coverage,
            multiple: false,
            publishRelatedCoverages: true
          });
        }
        else {
          $scope.publishCoverage(opt.coverage, false);
        }
      }
    };

    $scope.deleteCoverage = function (event, coverage) {
      CommonFactory.confirm({
        event: event,
        title: "Deleting coverage",
        message: "<p>You are going to delete this coverage.</p><p>Are you sure?</p>",
        confirmation: function() {
          ClientCoveragesFactory.deleteCoverage(coverage).then(function() {
            $state.go($state.current, {}, { reload: true });
          });
        }
      });
    };

    $scope.showRelatedCoverages = function (event, coverageId) {
      ClientCoveragesFactory.getRelatedCoverages(coverageId).then(function (resp) {
        var message = `<div class="list-like-table"><div class="list-like-table__header">This coverage has been written for:</div>`
        each(resp.content_publications, function(c) {
          message += `<div class="list-like-table__row">${c.client_name} - ${c.section_name}${(c.status === 'published' ? '<span class="list-elem__coverage-published">(PUBLISHED)</span>' : '')}</div>`;
        });
        message += `</div>`;
        CommonFactory.alert({
          event: event,
          title: "Grouped coverages",
          message: message
        });
      });
    };

    $scope.isUnpublishedGroup = function (group) {
      return (group.coverages[0].status === 'unpublished');
    };

    $scope.publishSelectedCoverages = function (event, coveragesSortArray) {
      CommonFactory.confirm({
        event: event,
        title: "Publishing Selected Coverages",
        message: "<p>You are about to publish selected coverages. They will become visible online.</p>" +
          "<p>NOTE: If these coverages are related to coverages in other client apps (grouped coverages), coverages for other client apps will not be published.</p>" +
          "<p>Are you sure?</p>",
        confirmation: function(coveragesSortArray) {
          var coverageIds = map($scope.selectedListItems, "id");
          ClientCoveragesFactory.publishCoverages(coverageIds).then(function() {
            $state.go($state.current, {}, { reload: true });
          });
        }
      });
    };

    var currentPage = $state.params.page,
      busy = false;

    $scope.fireEndlessScroll = function() {
      if (busy) { return; }
      busy = true;

      if (currentPage < $scope.totalPages) {
        currentPage++;

        var nextPageResults = [];

        if ($state.current.name.indexOf('unpublished') != -1) {
          ClientCoveragesFactory.getPublications($state.params.clientIdWithoutSection || $state.params.clientId, $state.params.sectionId, 'published', currentPage).then(function(resp) {
            nextPageResults = $scope.coverageGroups.concat(resp.coverage_groups);
            $scope.coverageGroups = nextPageResults;
            busy = false;
          });
        }
        else {
          ClientCoveragesFactory.getPublications($state.params.clientIdWithoutSection || $state.params.clientId, $state.params.sectionId, 'published', currentPage).then(function(resp) {
            nextPageResults = $scope.coverageGroups.concat(resp.coverage_groups);
            $scope.coverageGroups = nextPageResults;
            busy = false;
          });
        }
      }
    };

    $scope.preview = function(article) {
      createContentAndOpenPreview(article)
    };

    $scope.isListingUnpublished = () => $state.current.name.includes('unpublished');

    // filtering coverages by analyst id
    $scope.analysts = [];
    $scope.sortByAllAnalysts = true;
    ClientGroupsFactory.getAnalysts().then(function(data) {
      $scope.analysts = sortBy(data.map(analyst => ({
        id: analyst.id,
        name: analyst.first_name ? `${analyst.first_name} ${analyst.last_name}` : analyst.email,
      })), a => a.name.toLowerCase());
    });
    $scope.inputChanged = value => {
      if (!value) {
        $scope.sortByAllAnalysts = true;
      }
    };
    $scope.clearAutocomplete = clear => {
      if (clear) {
        $scope.$broadcast('angucomplete-alt:clearInput');
        $scope.totalPages = coveragesResult.totalPages;
        $scope.coverageGroups = coveragesResult.coverage_groups;
        busy = false;
      }
    };

    $scope.$watch('selectedAnalyst', val => {
      if ($scope.sortByAllAnalysts && val) {
        currentPage = 1;
        $scope.sortByAllAnalysts = false;

        if ($state.current.name.indexOf('unpublished') != -1) {
          ClientCoveragesFactory.getPublications($state.params.clientIdWithoutSection || $state.params.clientId, $state.params.sectionId, 'unpublished', currentPage, val.originalObject.id).then(function(resp) {
            $scope.coverageGroups = resp.coverage_groups;
            $scope.totalPages = resp.totalPages;
            busy = false;
          });
        } else {
          ClientCoveragesFactory.getPublications($state.params.clientIdWithoutSection || $state.params.clientId, $state.params.sectionId, 'published', currentPage, val.originalObject.id).then(function(resp) {
            $scope.coverageGroups = resp.coverage_groups;
            $scope.totalPages = resp.totalPages;
            busy = false;
          });
        }
      }
    });
  });
