import each from 'lodash/each';
import get from 'lodash/get';

angular.module("app").
  directive('articleList', function(ArticlesFactory, ClientCoveragesFactory, PreviewFactory, SearchPhrasesFactory, $state, $timeout, $location, Config, $http) {
    return {
      scope: {
        articles: '=articles',
        page: '=page',
        totalPages: '=totalPages',
        hasBriefingTypeId: '=hasBriefingTypeId',
        articleSourcesScope: '=articleSourcesScope'
      },
      template: require("./article_list.html.haml"),
      link: function (scope, element, attrs) {
        var firstPage, lastPage = null;

        scope.check = function (strUrl, domains) {
          if (!strUrl) return false;
          var behindBool = false;

          each(domains, function (domain) {
            console.log(`checking ${strUrl}`);
            if (strUrl.indexOf(domain) !== -1) {
              behindBool = true;
            }
          });

          return behindBool;
        };

        scope.privateDomains = function (strUrl) {
          var privateDomains = [
            'www.nytsyn.com',
          ];
          return scope.check(strUrl, privateDomains);
        }

        scope.behindPaywall = function (article = {}) {
          var paywallDomains = [
            'www.nytimes.com',
            'www.inframationnews.com'
          ];
          var paywallASIDs = [390];
          return scope.check(article.url, paywallDomains) || paywallASIDs.indexOf(article.article_source_id) > -1 || !article.url;
        };

        scope.isInternalUrl = function(strUrl) {
          // TODO: Make this smarter
          return strUrl && strUrl.indexOf && (strUrl.indexOf('http') == -1);
        };

        scope.preview = (articleSourceDefaultPreviewMode, strUrl, articleId, nlaLink, articleSourceDefaultPreview) => {
          if(scope.isInternalUrl(strUrl) || scope.behindPaywall({ url: strUrl }) || scope.privateDomains(strUrl) || !strUrl || (articleSourceDefaultPreview === 'tool')) {
            if(articleSourceDefaultPreviewMode === "raw") {
              scope.rawText(articleId);
            } else if (articleSourceDefaultPreviewMode === "plain_text"){
              scope.plainText(articleId);
            }
          } else {
            if (nlaLink !== undefined && nlaLink != null) {
              PreviewFactory.preview(nlaLink);
            } else {
              PreviewFactory.preview(strUrl);
            }
            ArticlesFactory.markAsRead(articleId);
          }
        };

        scope.plainText = function(articleId) {
          var strUrl = '/preview/' + articleId;
          if($state.params.searchPhraseId && $state.params.searchPhraseId != 'all') {
            strUrl += '?search_phrase_id=' + $state.params.searchPhraseId;
          } else if($state.params.clientId) {
            strUrl += '?client_id=' + $state.params.clientId;
          }
          PreviewFactory.preview(strUrl);
          ArticlesFactory.markAsRead(articleId);
        };

        scope.rawText = function(articleId) {
          var strUrl = '/raw-preview/' + articleId;
          if($state.params.searchPhraseId && $state.params.searchPhraseId != 'all') {
            strUrl += '?search_phrase_id=' + $state.params.searchPhraseId;
          } else if($state.params.clientId) {
            strUrl += '?client_id=' + $state.params.clientId;
          }
          PreviewFactory.preview(strUrl);
          ArticlesFactory.markAsRead(articleId);
        };

        scope.translate = async function(articleId) {
          const article = scope.articles.find((article) => article.id === articleId)
          if(article) {
            if(article.titleOrginal && article.titleTranslated) {
              if(article.title === article.titleOrginal) {
                article.title = article.titleTranslated;
                article.summary = article.summaryTranslated;
              } else {
                article.title = article.titleOrginal;
                article.summary = article.summaryOrginal;
              }
            } else {
              const { data: { translated_text: titleTranslation } } = await ArticlesFactory.translate(article.title, article.article_source_id);
              const { data: { translated_text: summaryTranslation } } = await ArticlesFactory.translate(article.summary, article.article_source_id);
              article.titleOrginal = article.title;
              article.summaryOrginal = article.summary;
              article.titleTranslated = titleTranslation;
              article.summaryTranslated = summaryTranslation;
              article.title = titleTranslation;
              article.summary = summaryTranslation;
            }
          }
        };

        var getPage = function(page) {
          var articleList = null;
          if ($state.params.articleSourceId) {
            articleList = ArticlesFactory.getArticles($state.params.articleSourceId, page);
          } else {
            if ($state.params.searchPhraseId) {
              articleList = SearchPhrasesFactory.getArticlesForSearchPhrase($state.params.clientId, $state.params.searchPhraseId, page);
            } else {
              articleList = ArticlesFactory.searchArticles($state.params.searchPhrase, page);
            }
          }
          return articleList;
        };

        var afterResults = function(page) {
          $location.search({ page: page });
          $location.replace();
          $timeout(function() {
            Waypoint.enableAll();
            Waypoint.refreshAll();
          });
        };

        var insertPageBottomWaypoint = function(page) {
          var elems = document.querySelectorAll(".list-elem__content");
          if (elems.length > 0) {
            var elem = elems[elems.length - 1];
            if (elem !== undefined) {
              new Waypoint({
                element: elem,
                handler: function(direction) {
                  $timeout(function() {
                    if (direction == 'down') {
                      $location.search({ page: page + 1});
                      $location.replace();
                    } else {
                      $location.search({ page: page });
                      $location.replace();
                    }
                  });
                }
              });
            }
          }
        };

        var insertPageTopWaypoint = function(page) {
          var elems = document.querySelectorAll(".list-elem__content");
          if (elems.length > 0) {
            var elem = elems[0];
            if (elem !== undefined) {
              new Waypoint({
                element: elem,
                handler: function(direction) {
                  $timeout(function() {
                    if (direction == 'down') {
                      $location.search({ page: page});
                      $location.replace();
                    } else if (page > 1) {
                      $location.search({ page: page - 1 });
                      $location.replace();
                    }
                  });
                }
              });
            }
          }
        };

        scope.minDiff = function(dateA, dateB) {
          return ((moment(dateA) - moment(dateB)) / 60000);
        };

        scope.sheetOptions = function() {
          const options = document.getElementById('sheet-options');
          if (options.style.display === 'none') {
            options.style.display = 'inline';
            const pickerFrom = document.getElementById('sheet-from');
            const pickerTo = document.getElementById('sheet-to');
            if (!pickerFrom.value) {
              pickerFrom.value = moment().subtract(1, 'months').format('YYYY-MM-DD');
            }
            if (!pickerTo.value) {
              pickerTo.value = moment().format('YYYY-MM-DD');
            }
          } else {
            options.style.display = 'none';
          }
        }

        scope.downloadSheet = function() {
          const dateFrom = document.getElementById('sheet-from').value;
          const dateTo = document.getElementById('sheet-to').value;
          $http.get(
              Config.shibiUrl + '/search_phrases/' + $state.params.searchPhraseId + '/articles/xlsx',
              {
                responseType: 'blob',
                params: {
                  content_container_id: $state.params.clientId,
                  date_from: moment(dateFrom).format('YYYY-MM-DD'),
                  date_to: moment(dateTo).format('YYYY-MM-DD'),
                }
              }
            ).then(function (response) {
              const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const elem = document.createElement('a');
              elem.setAttribute('href', url);
              elem.setAttribute('download', `search_${$state.params.clientId}_${$state.params.searchPhraseId}.xlsx`);
              elem.click();
              elem.remove();
            });
        }

        $timeout(function() {
          insertPageTopWaypoint(scope.page);
          insertPageBottomWaypoint(scope.page);
          if (document.getElementById('top-waypoint') !== null) {
            $state.params.topWaypoint = new Waypoint({
              element: document.getElementById('top-waypoint'),
              handler: function(direction) {
                if (firstPage == null || lastPage == null) {
                  firstPage = scope.page;
                  lastPage = scope.page;
                }
                if(direction == 'up' && firstPage > 1) {
                  Waypoint.disableAll();
                  var page = firstPage - 1;
                  scope.$parent.$parent.currentPage = page;
                  getPage(page).then(function(result) {
                    if (page < firstPage) {
                      firstPage = page;
                    }
                    scope.articles = result.articles.concat(scope.articles);
                    scope.totalPages = page + 1;
                    $timeout(function() {
                      insertPageTopWaypoint(page);
                      // $location.search({ page: page });
                      // $location.replace();
                      Waypoint.enableAll();
                      Waypoint.refreshAll();
                    });
                  });
                }
              }
            });
          }
          if (document.getElementById('bottom-waypoint') !== null) {
            $state.params.bottomWaypoint = new Waypoint({
              element: document.getElementById('bottom-waypoint'),
              offset: 'bottom-in-view',
              handler: function(direction) {
                if (firstPage == null || lastPage == null) {
                  firstPage = scope.page;
                  lastPage = scope.page;
                }
                if (direction == 'down' && (scope.totalPages > lastPage) || scope.totalPages == 1) { // scope.totalPages = 1 for initial request (comes as fixed from Shibi)
                  Waypoint.disableAll();
                  var page = lastPage + 1;
                  getPage(page)
                    .then(function(result) {
                      if (page > lastPage) {
                        lastPage = page;
                      }
                      scope.articles = scope.articles.concat(result.articles);

                      const articlesAmount = get(result, 'articles', []).length;
                      scope.totalPages = articlesAmount < 12 ? page : page + 1;
                      $timeout(function() {
                        insertPageBottomWaypoint(page);
                        // $location.search({ page: page });
                        // $location.replace();
                        Waypoint.enableAll();
                        Waypoint.refreshAll();
                      });
                  });
                }
              }
            });
          }
        });

        scope.searchPhraseId = $state.params.searchPhraseId;
        scope.articleSourceId = $state.params.articleSourceId;
        scope.briefingTypeId = $state.params.briefingTypeId;
        scope.sortAvailable = $state.params.hasOwnProperty('sort');
        scope.sortString = $state.params.sort;
        scope.moment = moment;
      }
    };
});
