import { Box } from 'rebass';
import styled from 'styled-components';

const CardContainer = styled(Box)`
  border-radius: 10px;
`;
CardContainer.defaultProps = {
  bg: '#fff',
};

export default CardContainer;
