import filter from 'lodash/filter';
import find from 'lodash/find';
import result from 'lodash/result';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import mapValues from 'lodash/mapValues';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

angular
  .module('app')
  .controller('ClientCoveragesController', function(
    $scope,
    $rootScope,
    $stateParams,
    $state,
    $http,
    CommonFactory,
    Config,
    ClientCoveragesFactory,
    ClientSectionsFactory,
    ngToast,
    ClientsFactory,
    PreviewFactory,
    ngDialog,
    $controller
  ) {
    $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));
    var client_id;
    var client_section_id;
    var popupData = {};
    var unpublishedCoveragesIds, allCoveragesIds;

    $scope.clientSections = [];
    $scope.sentimentIsValid = true;
    $scope.sentimentData = {};

    if ($stateParams.clientCoverageId) {
      $scope.isEditForm = true;

      var getCoveragePromise = new Promise((resolve, reject) => {
        ClientCoveragesFactory.getCoverage($stateParams.clientCoverageId)
          .then(clientCoverage => {
            const {
              id,
              client_name,
              client_section_id: data_client_section_id,
              client_id: data_client_id,
              status,
              published_at,
              sentiments,
              section_ids,
            } = clientCoverage;

            const date = moment(published_at).format('DD.MM.YYYY HH:mm');
            const sentiment = {};

            if (sentiments.length) {
              for (const sentimentItem of sentiments) {
                sentiment[sentimentItem.client_id] = sentimentItem;
              }
            }

            $scope.sentimentData = sentiment;
            $scope.sectionIds = section_ids;
            clientCoverage.published_at = date;
            $scope.clientCoverage = clientCoverage;

            $scope.content = clientCoverage.content;
            if($scope.content.attachment.url){
              $scope.file = { name: $scope.content.attachment.url.split('/').pop() };
            }

            if ($stateParams.clientId === 'all' && $stateParams.clientIdWithoutSection != undefined) {
              client_id = $stateParams.clientIdWithoutSection;
              client_section_id = data_client_section_id;
            } else if ($stateParams.clientId === 'all') {
              client_id = data_client_id;
              client_section_id = data_client_section_id;
            } else {
              client_id = $stateParams.clientId;
              client_section_id = $stateParams.sectionId;
            }

            if (status === 'published' && $state.current.name.includes('unpublished')) {
              ngToast.create({
                className: 'warning',
                content: 'This coverage has been already published!',
                timeout: 3000
              });
              $state.go(
                'client.published',
                { sectionId: client_section_id, page: 1 },
                { reload: true }
              );
            }

            if (client_section_id === 'all') {
              $scope.sectionName = 'All';
            } else {
              ClientSectionsFactory.getClientSection(client_section_id).then(
                function(clientSection) {
                  $scope.sectionName = clientSection.name;
                  $scope.sectionBespoke = (clientSection.type == "bespoke");
                }
              );
            }

            ClientSectionsFactory.getClientSections(
              $scope.clientCoverage.client_id
            ).then(function(clientSections) {
              $scope.currentClientSections = clientSections;
              resolve('getCoverage success');
            });
          });
      });
    }

    $scope.showRelatedCoverages = (event, coverageId) => {
      ClientCoveragesFactory.getRelatedCoverages(coverageId)
        .then(({ content_publications }) => {
          var clientList = content_publications;
          var formattedClientList = "";
          var publishedText = "";

          if (clientList) {
            for (const client of clientList) {
              publishedText = client.status === 'published' ? " <span class='list-elem__coverage-published'>(PUBLISHED)</span>" : "";
              formattedClientList += "<p class=left_align>" + client.client_name + publishedText + "</p>";
            }
          }

          CommonFactory.alert({
            event,
            title: "Grouped coverages",
            message: "<p>This coverage has been written for:</p>" + formattedClientList
          })
        });
    };

    $scope.updateClientSection = function(selectedClient, index) {
      ClientSectionsFactory.getClientSections(selectedClient).then(function(
        clientSections
      ) {
        if ($scope.clientList != undefined && index != undefined) {
          $scope.clientSections[index] = clientSections;
          $scope.clientList.items[index].selected_client_section = null;
        } else {
          $scope.clientCoverage.client_section_id = null;
          $scope.currentClientSections = clientSections;
        }
      });
    };

    $scope.$on('fileSelected', (event, args) => {
      $scope.$apply(() => {
        $scope.file = args.file;
      });
    });

    $scope.updateRelated = related_content_publication_ids => doUpdate;

    $scope.update = event => {
      ClientCoveragesFactory.getCoverage($scope.clientCoverage.id)
        .then(({ status }) => {
          if (status === 'published' && $state.current.name.includes('unpublished')) {
            $scope.dialog = ngDialog.open({
              template: 'overwriteConfirmation',
              controller: 'EditClientCoveragePopupController',
              controllerAs: 'file',
              className: 'ng-confirmation-box',
              data: {
                cancel: function() {
                  $state.go(
                    'client.published',
                    { sectionId: client_section_id, page: 1 },
                    { reload: true }
                  );
                },
                save: updateCoverage
              }
            });
          } else {
            updateCoverage(event);
          }
        });
    };

    $scope.clearInputFile = () => {
      $scope.file = undefined;
      $scope.delete_attachment = true;
    };

    const updateCoverage = event => {
      if ($scope.clientCoverage.related_coverages_count == 1) {
        if ($scope.clientCoverage.status === 'published') {
          CommonFactory.confirm({
            event: event,
            title: 'Editing published coverage',
            message:
              '<p>You are about to edit published coverage. Saving this version will update the content in the app.</p><p>Are you sure?</p>',
            confirmation: doUpdate
          });
        } else {
          doUpdate();
        }
      } else {
        if (
          $scope.content_changed ||
          $scope.delete_attachment === true ||
          $scope.delete_image === true ||
          $scope.file != undefined
        ) {
          popupData.all_coverages = popupData.data.content_publications;
          popupData.published_coverages = filter(popupData.data.content_publications, { status: 'published' });
          popupData.unpublished_coverages = filter(popupData.data.content_publications, { status: 'unpublished' });


          $scope.dialog = ngDialog.open({
            template: 'firstPopup',
            controller: 'EditClientCoveragePopupController',
            controllerAs: 'file',
            className: 'ng-confirmation-box',
            scope: $scope.$parent,
            data: popupData
          });
        } else {
          if ($scope.clientCoverage.status === 'published') {
            CommonFactory.confirm({
              event,
              title: 'Editing published coverage',
              message: '<p>You are about to edit published coverage. Saving this version will update the content in the app.</p><p>Are you sure?</p>',
              confirmation: () => {
                doUpdate(
                  $scope.clientCoverage.related_content_publication_ids,
                  'all'
                );
              }
            });
          } else {
            doUpdate(
              $scope.clientCoverage.related_content_publication_ids,
              'all'
            );
          }
        }
      }
      $scope.deleteForm($stateParams.token);
    }

    $scope.cancelEdit = () => {
      $scope.deleteForm($stateParams.token);
      goBackToList();
    };

    const iterateClientsCheckNew = clients => {
      let newClients = [];

      each(clients, client => {
        if (client.selected && !client.readOnly) {
          each(client.client_sections, section => {
            if (section.id == client.default_section_id) {
              newClients.push({
                client_id: client.id,
                client_name: client.name,
                client_section_id: client.default_section_id,
                client_section_name: section.name,
                client_section_bespoke: (section.section_type == "bespoke")
              });

              $scope.clientList.items.push({
                selected_client: client.id,
                selected_client_section: client.default_section_id
              });
            }
          });
        }
      });

      return newClients;
    };

    const markSections = (clientCoverage) => {
      each($scope.allSegments.segments, segment => {
        if (!!segment.clients) {
          iterateSegmentClients(segment, clientCoverage.section_ids);
        }

        if (!!segment.groups) {
          each(segment.groups, group => {
            iterateSegmentClients(group, clientCoverage.section_ids);

            if (group.selected === null || group.selected) {
              segment.selected = true;
            }
          });
        }
      });
    };

    const getSectorsCompanies = (ids) => {
      const promises = [];

      each(ids, id => {
        promises.push(ClientsFactory.getSector(id).then(function(res) {
          var segmentLocalId = $scope.checkSegmentIdByName($scope.allSegments.segments, res.name);
          $scope.allSegments.segments[segmentLocalId]['clients'] = res.clients;
        }));
      });

      return Promise.all(promises);
    };

    const iterateSegmentClients = (segment, sectionIds) => {
      let clientsSelected = 0;

      each(segment.clients, client => {
        each(client.client_sections, section => {
          if (sectionIds.indexOf(section.id) !== -1) {
            client.selected = true;
            client.default_section_id = section.id;
            clientsSelected++;
          }
        });
      });

      if (segment && clientsSelected > 0) {
        segment.selected = segment.clients.length === clientsSelected ? true : null;
      }
    };

    $scope.$watch('segmentsFetched', fetched => {
      if (!!fetched) {
        ClientCoveragesFactory.getRelatedCoveragesData($stateParams.clientCoverageId)
          .then((data) => {
            $scope.relatedCoveragesData = data.content_publications;

            var allSectorIds = [];
            each($scope.relatedCoveragesData, coverage => {
              allSectorIds = allSectorIds.concat(coverage.sector_ids);
            });
            allSectorIds.concat($scope.clientCoverage.sector_ids);
            allSectorIds = uniq(allSectorIds);

            Promise.all([getSectorsCompanies(allSectorIds), getCoveragePromise])
              .then(_ => {
                markSections($scope.clientCoverage);
              });

            popupData.data = data;
          });
      }
    });

    const doUpdate = (related_content_publication_ids, edit_action_type) => {
      if ($scope.file && $scope.file.size > 30 * 1024 * 1024) {
        $scope.errorMessage = {
          file: ['File size is too large']
        };

        return;
      }

      // if (isEmpty($scope.sentimentData)) {
      //   $scope.analystSentimentFormData = [];
      // }
      //
      // $scope.clientList.items = [];
      //
      // mapValues($scope.clientsSelected, client => {
      //   if (!client.readOnly) {
      //     $scope.clientList.items.push({
      //       selected_client: +client.client_for_section_ids[client.default_section_id],
      //       selected_client_section: +client.default_section_id,
      //       name: client.name
      //     });
      //   }
      //
      //   if (client.id === $scope.clientCoverage.client_id) {
      //     $scope.clientCoverage.client_section_id = +client.default_section_id;
      //   }
      // });

      $http({
        method: 'PATCH',
        url: `${Config.shibiUrl}/content_publications/${$stateParams.clientCoverageId}.json`,
        // IMPORTANT!!! You might think this should be set to 'multipart/form-data'
        // but this is not true because when we are sending up files the request
        // needs to include a 'boundary' parameter which identifies the boundary
        // name between parts in this multi-part request and setting the Content-type
        // manually will not set this boundary parameter. For whatever reason,
        // setting the Content-type to 'false' will force the request to automatically
        // populate the headers properly including the boundary parameter.
        headers: { 'Content-Type': undefined },
        // This method will allow us to change how the data is sent up to the server
        // for which we'll need to encapsulate the model data in 'FormData'
        transformRequest: data => {
          var formData = new FormData();
          // need to convert our json object to a string version of json otherwise
          // the browser will do a 'toString()' on the object which will result
          // in the value '[Object object]' on the server.
          formData.append('model', angular.toJson(data.model));
          if (data.file) formData.append('file', data.file);
          return formData;
        },
        // Create an object that contains the model and files which will be transformed
        // in the above transformRequest method
        data: {
          model: {
            id: $scope.clientCoverage.id,
            url: $scope.clientCoverage.url,
            url_published: $scope.clientCoverage.url_published,
            content: {
              id: $scope.content.id,
              title: $scope.content.title,
              body: $scope.content.body,
              author: $scope.content.author,
              rich_text_in_body: $scope.content.rich_text_in_body,
              remove_image: $scope.delete_image === true && !$scope.file,
              remove_attachment: $scope.delete_attachment === true && !$scope.file,
              content_type: $scope.content.content_type
            },
            sentiments: Object.values($scope.sentimentData),
            related_content_publication_ids: $scope.clientCoverage.related_content_publication_ids,
            edit_action_type,
            section_ids: $scope.clientCoverage.section_ids
          },
          file: $scope.file,
        }
      })
      .success((data, status, headers, config) => {
        var currentUrl = $rootScope.previousState.url;
        if ($rootScope.previousState.name.indexOf('briefing_types_article_sources') != -1) {
          currentUrl = currentUrl.replace(
            '{briefingTypeId:int}',
            $rootScope.previousStateParams.briefingTypeId
          );
          currentUrl = currentUrl.replace(
            ':briefingTypeId',
            $rootScope.previousStateParams.briefingTypeId
          );
          currentUrl = currentUrl.replace(
            ':articleSourceId',
            $rootScope.previousStateParams.articleSourceId
          );
          currentUrl = currentUrl.replace(
            '{articleSourceId:int}',
            $rootScope.previousStateParams.articleSourceId
          );
          currentUrl = currentUrl.replace('{page:int}', 1);
        } else if ($rootScope.previousState.name.indexOf('clients_article_sources') != -1) {
          currentUrl = currentUrl.replace(
            ':searchPhraseId',
            $rootScope.previousStateParams.searchPhraseId
          );
          currentUrl = currentUrl.replace('{page:int}', 1);
        }
        if (data.redirect_to_new_client) {
          goBackToList(data.client_id, data.client_section_id);
        } else {
          goBackToList(
            $scope.clientCoverage.client_id,
            $scope.clientCoverage.client_section_id
          );
        }
      })
      .error((data, status, headers, config) => {
        $scope.errorMessage = data.errors;
        // var ind = result($scope.errorMessage, 'client_index', false);
        // if (ind) {
        //   $scope.clientList.items[ind].has_error = true;
        // }
        if (data.errors.locking_fail) {
          ngToast.create({
            className: 'danger',
            content: 'Error - please refresh this site'
          });
        }
      });
    };


    popupData['doUpdate'] = doUpdate;

    const goBackToList = (client_id, client_section_id) => {
      if (
        $stateParams.clientId === 'all' &&
        $stateParams.clientIdWithoutSection != undefined
      ) {
        $state.go(
          'client.' + $scope.clientCoverage.status + '_without_section',
          { page: 1 },
          { reload: true }
        );
      } else if (client_id != undefined && client_section_id != undefined) {
        $state.go(
          'client.' + $scope.clientCoverage.status,
          { clientId: client_id, sectionId: client_section_id, page: 1 },
          { reload: true }
        );
      } else {
        $state.go(
          'client.' + $scope.clientCoverage.status,
          { page: 1 },
          { reload: true }
        );
      }
    };

    $scope.deleteImage = function() {
      $scope.content.image_url = null;
      $scope.content.image_name = null;
      $scope.delete_image = true;
    };

    $scope.deleteAttachment = function() {
      $scope.content.attachment_url = null;
      $scope.content.attachment_name = null;
      $scope.delete_attachment = true;
    };

    $scope.preview = function(strUrl) {
      PreviewFactory.preview(strUrl);
    };

    $scope.getError = function(what) {
      if ($scope.errorMessage) {
        return result($scope.errorMessage, what, false);
      } else {
        return false;
      }
    };

    $scope.charCountOf = function(text) {
      const s = text
        ? text
            .replace(/(<([^>]+)>)/ig, '') // strip html tags
            .replace(/\s/g, '') // remove whitespace
        : ""; // splits at whitespace

      return s.length;
    };

    $scope.countOf = function(text) {
      const s = text
        ? text
            .replace(/<(?:.|\n)*?>/gm, '') // strip html tags
            .replace(/(^\s*)|(\s*$)/gi, '') // exclude start and end white-space
            .replace(/[ ]{2,}/gi, ' ') // multiple space to one
            .replace(/\n /, '\n') // exclude newline with a start spacing
            .split(/\s+/)
        : 0; // splits at whitespace
      return s ? s.length : 0;
    };

    $scope.cleanUpTextAreaContent = function() {
      const wordSubstitute = [
        ['million pounds', '£m'],
        ['percent', '%'],
        ['per cent', '%'],
        ['chief executive officer', 'CEO'],
        ['PLC', ''],
      ];
      let text = $scope.content.body;
      if (text) {
        for (let i = 0; i < wordSubstitute.length; i++) {
          $scope.content.body = text.replace(wordSubstitute[i][0], wordSubstitute[i][1])
          text = $scope.content.body;
        }
      }
    }
});
