import find  from 'lodash/find';

angular.module("app").
  directive('toggle', ['ClientNotificationFactory', '$rootScope', function(ClientNotificationFactory, $rootScope) {
    return {
      restrict: 'AE',
      template: require("./toggle.html.haml"),
      link: function($scope, elem, attr, ctrl) {
        $scope.$parent.loading = true;
        $scope.analyst_notification_toggle_id = attr.toggleId;

        $scope.load = function() {
          if (attr.global == 'true') {
            ClientNotificationFactory.getGlobalClientNotificationStatus().then(function(client_notification_status) {
              $scope.disabled = !client_notification_status;
              $rootScope.globalToggleStatus = !client_notification_status;
              $scope.$parent.loading = false;
            });
          } else {
            if (attr.type != undefined) {
              $scope.disabled = attr.groupDisabled == 'true';
            } else {
              $scope.disabled = !$scope.$parent.item.notification_status;
            }
          }
        };
        $scope.disableCheckbox = false;
        $scope.change = function($event) {
          $event.stopPropagation();
          if (attr.global == 'true') {
            $rootScope.globalToggleStatus = !$scope.disabled;
            if (!$scope.disableCheckbox) {
              $scope.disableCheckbox = true;
              ClientNotificationFactory.updateGlobalClientNotificationStatus($scope.disabled)
              .then(function() {
                $scope.disableCheckbox = false;
              })
              .catch(function() {
                $scope.disabled = !$scope.disabled;
                $scope.disableCheckbox = false;
              });
            } else {
              $event.preventDefault();
              return;
            }
          } else {
            if (attr.type != undefined) {
              if (attr.type == 'sector') {
                ClientNotificationFactory.updateSector($scope.analyst_notification_toggle_id, $scope.disabled).then(function(){
                  $scope.$emit('monitor.toggleGroup', {name: $scope.analyst_notification_toggle_id, status: !$scope.disabled, sector: true});
                });
              }
              if (attr.type == 'alert') ClientNotificationFactory.updateAlertStatusGroup($scope.analyst_notification_toggle_id, $scope.disabled).then(function(){
                $scope.$emit('monitor.toggleGroup', {name: $scope.analyst_notification_toggle_id, status: !$scope.disabled, alert: true});
                $scope.$emit('monitor.reload');
              });
              if (attr.type == 'type') ClientNotificationFactory.updateAlertTypeGroup($scope.analyst_notification_toggle_id, $scope.disabled).then(function(){
                $scope.$emit('monitor.toggleGroup', {name: $scope.analyst_notification_toggle_id, status: $scope.disabled});
              });
            } else {
              if(!$scope.disabled) {
                $scope.$parent.item.live_alerts_status = false;
              }
              ClientNotificationFactory.updateClientNotificationStatus($scope.$parent.item.id, $scope.disabled, $scope.$parent.item.live_alerts_status).then(function(){
                $scope.$parent.item.notification_status = !$scope.disabled;
                $scope.$emit('monitor.toggleSingleElement', {disabled: !$scope.disabled, group_id: attr.groupId, client_id: attr.clientId, group_type: attr.groupType});
              });
            }
          }
        };

        $scope.$on('monitor.reloadToggle', function (ev, data) {
          // render everything again i.e. reload the directive
          $scope.render();
        });

        $scope.$on('monitor.changeStatus', function (event, params) {
          if($scope.analyst_notification_toggle_id == params.group_id) {
            $scope.disabled = params.status;
          }
        });

        if(attr.global == 'true') {
          $rootScope.$on('globalAlertsToggle.reload', function(event, params) {
            $scope.load();
          });
        }

        $scope.load();

      }
    };
}]);
