import config from 'config';
import io from 'socket.io-client';

class Websocket {

  socket = {};
  socketInitialized = false;
  interval = {};

  constructor(io, url) {
    if (config.kefearEnabled) {
      this.initSocket(io, url);
      this.interval = setInterval(() => {
        const token = localStorage.getItem('kefear-jwt');
        if (token) {
          this.initSocket(io, url);
          clearInterval(this.interval);
        }
      }, 1000);
    } else {
      this.socket = {
        on: () => console.log('Socket not initialized (kefearEnabled = false)'),
        disconnect: () => console.log('Socket disconnect (kefearEnabled = false)')
      }
    }
  }


  initSocket(io, url) {
    const token = localStorage.getItem('kefear-jwt');
    if (token) {
      this.socketInitialized = true;
    }
    this.io = io;
    this.socket = this.io(url, {
      autoConnect: true,
      reconnectionDelay: 20000,
      reconnectionDelayMax: 60000,
      randomizationFactor: 0.1,
      timeout: 10000,
      transports: ['websocket'],
      upgrade: false,
      query: `token=${localStorage.getItem('kefear-jwt')}`
    });
  }

  setQueryToken(token) {
    this.socket.io.opts.query = `token=${token}`;
    this.socket.io.readyState = 'closed';
    this.socket.io.reconnect();
  }
  connect() {
    this.socket.open();
  }
  disconnect() {
    this.socket.disconnect();
  }
  toggleUnread(id, unread) {
    this.socket.emit('unread', { id, unread });
  }

  flag(id, flagged) {
    this.socket.emit('flag', { id, flagged });
  }
  delete(id, deleted) {
    this.socket.emit('delete', { id, deleted });
  }
  deleteSelected(id, deleted) {
    this.socket.emit('delete', { id, deleted });
  }
  markAllAsRead() {
    this.socket.emit('markAllAsRead', {});
  }

  addTodo(todo) {
    this.socket.emit('add', todo)
  }

  updateTodo(todo) {
    this.socket.emit('update', todo)
  }

  moveTodo(positions) {
    this.socket.emit('move', positions)
  }

  deleteTodo(todo) {
    this.socket.emit('delete', todo)
  }

  deleteAllTodos() {
    this.socket.emit('deleteAll')
  }

  clearCompleted() {
    this.socket.emit('clearCompleted')
  }

  on(eventName, cb) {
    this.socket.on(eventName, cb);
  }
}
const socket = new Websocket(io, `${config.kefearUrl}/alerts`);
export const publicationAlertsSocket = new Websocket(io, `${config.kefearUrl}/publication-alerts`);
export const todosSocket = new Websocket(io, `${config.kefearUrl}/todos`);
export const unpublishedCoveragesCountSocket = new Websocket(io, `${config.kefearUrl}/unpublished-coverages-count`);

export default socket;
