import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import isArray from 'lodash/isArray';
import {
  Box, Text, Flex, Link,
} from 'rebass';
import moment from 'moment';
import styled from 'styled-components';
import KeywordHighlight from '@inbox/_common/components/KeywordHighlight';
import ButtonLink from '@inbox/_common/components/ButtonLink';
import isEmpty from 'lodash/isEmpty';
import ClickableImg from '@inbox/_common/components/ClickableImg';
import ForwardMessageBox from './ForwardMessageBox';
import Embargoed from './Embargoed';

const ContainerHeading = styled(Box)`
  background-color: #fff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  width: 45%;
  text-align: center;
  font-size: 18px;
  margin-left: 35px;
`;
const Container = styled(Box)`
  background-color: #fff;
  border-radius: 9px;
  width: 100%;
  padding: 25px;
  font-size: 12px;
`;
const ScrollWrapper = styled(Box)`
  overflow-y: auto;
  max-height: calc(100vh - 220px);
`;

@observer
class AlertItem extends Component {
  render() {
    const {
      currentAlert: {
        phrases,
        createdAt,
        _id,
        forwardMessage,
        flagged,
        forwardedBy,
        article,
        clients,
        deleted,
        createLink,
      },
      toggleForwardForm,
      showForwardForm,
      deleteAlert,
      flagAlert,
    } = this.props;

    const { embargoed, embargoedUntil } = article || {};

    if (isEmpty(this.props.currentAlert) || showForwardForm) {
      return null;
    }

    return (
      <Box>
        <ContainerHeading py={12}>Article</ContainerHeading>
        <Container>
          <ScrollWrapper>
            <Flex flexDirection="column" width={1} p={12}>
              { embargoed && (
                <Embargoed
                  embargoedUntil={embargoedUntil}
                />
              )}
              {forwardMessage && (
                <ForwardMessageBox
                  mb={30}
                  bg="#E3EEFB"
                  p={20}
                  forwardedBy={forwardedBy}
                  message={forwardMessage}
                />
              )}
              <Flex
                justifyContent="center"
                alignItems="flex-end"
                flexDirection="column"
                mb={20}
              >
                <Text fontWeight="bold">
                  Processed at:
                  {' '}
                  {moment(article.processedAt).format('D/M/YY h:mm A Z')}
                </Text>
                <Text fontWeight="bold">
                  Publication date:
                  {' '}
                  {moment(article.pubDate).format('D/M/YY h:mm A Z')}
                </Text>
                <Text color="#D62828" fontWeight="bold">
                  Received at:
                  {' '}
                  {moment(createdAt).format('D/M/YY h:mm A Z')}
                </Text>
              </Flex>
              <Text
                fontSize={18}
                fontWeight="bold"
                mb={10}
                dangerouslySetInnerHTML={{ __html: article.title }}
              />
              <Text fontWeight="bold" mb={10}>
                {article.articleSource}
              </Text>
              <Text fontWeight="bold" mb={10}>
                {article.author}
              </Text>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                mt={10}
              >
                <Flex flexWrap="wrap" justifyContent="center">
                  {clients.map((client, i) => (
                    <KeywordHighlight
                      mx="5px"
                      mb="5px"
                      key={`clientKeyword${i}`}
                    >
                      {client}
                    </KeywordHighlight>
                  ))}
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                mt={10}
              >
                <Text fontWeight="bold" mb={15}>
                  Keywords
                </Text>
                <Flex flexWrap="wrap" justifyContent="center">
                  {/* TODO: delete isArray when proper data comes */}
                  {((isArray(phrases) && phrases) || []).map((phrase, i) => (
                    <KeywordHighlight
                      mx="5px"
                      mb="5px"
                      key={`keywordMention${i}`}
                      secondary
                    >
                      {phrase}
                    </KeywordHighlight>
                  ))}
                </Flex>
              </Flex>
              <Text
                mt={40}
                mb={70}
                dangerouslySetInnerHTML={{ __html: article.content }}
              />
              { embargoed && (
                <Embargoed
                  embargoedUntil={embargoedUntil}
                />
              )}
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mt={20}
              mb={-35}
            >
              <Link
                color="#000"
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                OPEN IN NEW WINDOW
              </Link>
              <Box>
                <ClickableImg
                  mr={12}
                  src="/images/icons/inbox/forward_icon.svg"
                  alt="Forward"
                  width={20}
                  onClick={() => toggleForwardForm()}
                />
                <ClickableImg
                  mr={12}
                  src={
                    flagged
                      ? '/images/icons/inbox/flag_red_icon.svg'
                      : '/images/icons/inbox/flag_icon.svg'
                  }
                  alt="Flag"
                  width={10}
                  onClick={() => flagAlert(_id, !flagged)}
                />
                {deleteAlert && (
                  <ClickableImg
                    mr={10}
                    src="/images/icons/inbox/alert_trash_icon.svg"
                    alt="Delete"
                    onClick={() => deleteAlert(_id, !deleted)}
                    width={10}
                  />
                )}
              </Box>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <ButtonLink
                href={createLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Create coverage
              </ButtonLink>
            </Flex>
          </ScrollWrapper>
        </Container>
      </Box>
    );
  }
}

AlertItem.propTypes = {
  toggleForwardForm: PropTypes.func.isRequired,
  currentAlert: PropTypes.object.isRequired,
  showForwardForm: PropTypes.bool.isRequired,
  deleteAlert: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  flagAlert: PropTypes.func.isRequired,
};
AlertItem.defaultProps = {
  deleteAlert: null,
};

export default AlertItem;
