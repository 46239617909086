import config from 'config';
import SocketFactory from '../../inbox/_app/socket';

angular
  .module('app')
  .controller('NavController', function (
    NavAlertFactory,
    ClientGroupsFactory,
    ipCookie,
    $timeout,
    NavAlertService,
    Config,
    $http,
    $scope,
    $rootScope,
    $state,
    AuthenticationFactory,
    CommonFactory,
    $location,
    $interval
  ) {
    const fetchInterval = 60000 * 5;
    $timeout(() => {
      initializeFetching();
    });
    $interval(() => {
      initializeFetching();
    }, fetchInterval);

    $scope.showInbox = true;
    $scope.showAnalystAlerts = true;
    if (!config.kefearEnabled) {
      $scope.showInbox = false;
    }

    if (!config.analystAlertsEnabled) {
      $scope.showAnalystAlerts = false;
    }

    $scope.avatarUrl = '';
    $scope.loginService = AuthenticationFactory;
    $scope.$watch('loginService.isLoggedIn()', function (isLogged) {
      if (isLogged && config.kefearEnabled) {
        SocketFactory.connect();
      } else {
        SocketFactory.disconnect();
      }
      if (isLogged) setUserAvatar();
      $scope.loggedIn = isLogged;
    });

    $scope.$watch('loginService.loggedInAs()', function (newVal) {
      $scope.loggedInAs = newVal;
    });

    $scope.getClass = function (path) {
      if ($location.path().indexOf(path) != -1) {
        return 'active';
      } else {
        return '';
      }
    };
    $scope.goToAlerts = function () {
      if ($location.path().includes('alerts')) {
        return;
      }
      $state.go('alerts', {});
    };

    $scope.logout = function (e) {
      CommonFactory.confirm({
        event: e,
        title: 'Logout',
        message: '<p>Are you sure you want to log out?</p>',
        confirmation: function () {
          $state.go('logout', {});
        }
      });
    };

    $scope.showAlert = false;
    $rootScope.alertBarHeight = 0;
    $scope.msg = [];

    $scope.$watch('msg', function (val) {
      $timeout(function () {
        setHeights();
      });
    });

    $scope.hideAlert = function () {
      if (localStorage.getItem('hiddenAlerts') === null) {
        var hiddenAlerts = [$scope.msg[0].id];
        localStorage.setItem('hiddenAlerts', JSON.stringify(hiddenAlerts));
      } else {
        var items = JSON.parse(localStorage.getItem('hiddenAlerts'));
        items.push($scope.msg[0].id);
        localStorage.setItem('hiddenAlerts', JSON.stringify(items));
      }
      $scope.msg = $scope.msg.slice(1);
      if ($scope.msg.length === 0) {
        $scope.showAlert = false;
      }
    };

    $scope.showHeader = function () {
      return (
        $location.path().indexOf('preview') !== 1 &&
        $location.path().indexOf('raw-preview') !== 1 &&
        $location.path().indexOf('todo') !== 1
      );
    };

    function setHeights() {
      var alertBar = document.querySelector('.nav-alert__container');
      var button = document.querySelector('.new_button_box');
      var body = document.querySelector('body');
      if (alertBar) {
        $rootScope.alertBarHeight = document.querySelector(
          'header'
        ).clientHeight;
        body.style.paddingTop =
          $rootScope.alertBarHeight + ($rootScope.newApp() ? 0 : 8) + 'px';
      } else {
        $rootScope.alertBarHeight = 0;
        body.style.paddingTop = ($rootScope.newApp() ? 64 : 150) + 'px';
      }
      if (button && $rootScope.alertBarHeight > 0) {
        button.style.top = $rootScope.alertBarHeight - 54 + 'px';
      }
    }

    $scope.reloadPage = () => {
      window.location.reload(true);
    };

    function initializeFetching() {
      if ($scope.loggedIn) {
        NavAlertService.getAlerts().then(function (res) {
          $scope.msg = res.alerts;
          $scope.showAlert = res.showAlert;
          if (res.restart) {
            $scope.reloadPage();
          }
        });
      } else {
        $scope.showAlert = false;
      }
    }

    $scope.setChatPosition = () => {
      $('#drift-widget-container').first().removeClass('left-side');
    };

    function setUserAvatar() {
      ClientGroupsFactory.getAnalysts().then((analysts) => {
        const analystEmail = ipCookie('analyst_email');
        analysts.forEach((analyst) => {
          if (analyst.email === analystEmail) {
            $scope.avatarUrl = analyst.avatar_url;
          }
        });
      });
    }
  });
