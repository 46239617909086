angular.module('app').factory("ContentContainersFactory", function ($http, Config) {

  const toggleFavouriteContentContainer = (content_container_id) => {
    return $http.post(`${Config.shibiUrl}/content_containers/${content_container_id}/toggle_favourites.json`, {}).then(function (response) {
      return response.data.content_container;
    });
  };

  const silentlyCloseBriefing = (briefing_id) => {
    return $http.post(`${Config.shibiUrl}/content_containers/${briefing_id}/silent_close_briefing.json`, {});
  };

  const getCompanyContainerMetaField = (content_container_id, field_name) => {
    return $http.get(`${Config.shibiUrl}/content_containers/${content_container_id}/get_company_container_meta_field?field_name=${field_name}`);
  };

  const updateCompanyContainerMetaField = (content_container_id, field_name, field_value) => {
    return $http.post(`${Config.shibiUrl}/content_containers/${content_container_id}/update_company_container_meta_field`, { field_name, field_value });
  };

  return {
    toggleFavouriteContentContainer,
    silentlyCloseBriefing,
    getCompanyContainerMetaField,
    updateCompanyContainerMetaField
  };
});
