import countBy from 'lodash/countBy';

angular.module("app").directive('confirmationPopup', ['$http', 'Config', '$stateParams', function($http, Config, $stateParams) {
  return {
    restrict: 'AE', //attribute or element
    template: require("./confirmation_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.popupOpened = false; // popup closed on init
      $scope.reorderPopupOpened = false; // popup closed on init
      $scope.$parent.reorderBackground = false;
      var count = countBy($scope.$parent.summaries, function(s) {return s.status==='rejected' ? 'rejected' : 'other' })

      $scope.reorderEnabled = (count.other > 1) ? true : false

      var summariesBackupCopy;

      var close = function() {
        $scope.$parent.reorderBackground = false;
        $scope.popupOpened = false;
        angular.element('body').removeClass('popup-opened');
      };

      $scope.open = function() {
        angular.element('body').addClass('popup-opened');
        $scope.popupOpened = true;
      };

      $scope.close = function(event) {
        close();
        $scope.$parent.submit(event, true);
      };

      $scope.cancel = function() {
        close();
      };

      $scope.closeConfirmationPopup = function(event) {
        close();
      };

      $scope.openReorder = function() {
        $scope.$parent.reorderBackground = true;
        $scope.reorderPopupOpened = true;
        $scope.popupOpened = false;
        $scope.summariesCopy = $scope.$parent.summaries;
        summariesBackupCopy = $scope.$parent.summaries.slice();
      };

      $scope.closeReorder = function() {
        $scope.reorderPopupOpened = false;
        angular.element('body').removeClass('popup-opened');
      };

      $scope.saveReorderAndPublish = function (event) {
        $scope.$parent.reorderBackground = true;
        var json = {};
        for(let i = 0; i < $scope.summariesCopy.length; i += 1) {
          json[$scope.summariesCopy[i].id]={'position': i};
        }
        $scope.$parent.submitAfterReorder(event, true, json);
        close();
        $scope.closeReorder();
      };

      $scope.closePopupWithoutSave = function() {
        $scope.$parent.summaries = summariesBackupCopy;
        $scope.closeReorder();
        $scope.closeConfirmationPopup();
      };

    }
  };
}]);
