import React from 'react';
import PropTypes from 'prop-types';
import { Image, Flex, Text } from 'rebass';
import CardContainer from './CardContainer';

const MessageBox = ({
  imgSrc, text, children, ...props
}) => (
  <CardContainer {...props}>
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Image src={imgSrc} width={60} mb={40} />
      <Text fontWeight="bold" textAlign="center" fontSize={18} mb={15}>
        {text}
      </Text>
      <Text fontSize={18}>{children}</Text>
    </Flex>
  </CardContainer>
);

MessageBox.defaultProps = {
  py: 100,
  px: 50,
};
MessageBox.propTypes = {
  py: PropTypes.number,
  px: PropTypes.number,
  imgSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default MessageBox;
