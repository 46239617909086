export const cookieAsObject = () => {
  const currentCookie = document.cookie;
  const result = {};

  currentCookie.split(';').forEach(e => {
    const [key, value] = e.split('=').map(e => e.trim());
    result[key] = value;
  });

  return result;
};
