angular.module('app').factory("ClientSectionsFactory", function ($http, Config) {

  var getClientSections = function (client_id) {
    return $http.get(Config.shibiUrl + '/content_containers/sections_for_company.json?id=' + client_id).
        then(function (response) {
          return response.data.sections;
        });
  };

  var getPublishedAndUnpublishedSections = function(client_id){
    return $http.get(Config.shibiUrl + '/content_containers/published_and_unpublished_sections.json?id=' + client_id).
      then(function (response) {
        return response.data;
      });
  };

  var countUnpublishedSections = function(client_id){
    return $http.get(Config.shibiUrl + '/content_containers/count_published_unpublished_sections.json?id=' + client_id).then(function (response) {
      return response.data;
    });
  };

  var getClientSection = function(client_section_id) {
    return $http.get(Config.shibiUrl + '/content_containers/client_section.json?id=' + client_section_id).then(function (response) {
      return response.data;
    });
  };

  return {
    getClientSection: getClientSection,
    getClientSections: getClientSections,
    countUnpublishedSections: countUnpublishedSections,
    getPublishedAndUnpublishedSections: getPublishedAndUnpublishedSections,
  };
});
