import { observable, action } from 'mobx';
import socket from '@inbox/_app/socket';
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import { CONNECTION_STATUS_MESSAGES } from '../_app/constants';
import alertsStore from './alertsStore';

const NAVIGATION_DOT_CLASS = '.inbox-navigation__dot';

class CommonStore {
  constructor(socket) {
    this.socket = socket;
    this.socket.on('hasUnreadMsg', this.setUnread);

    this.setConnectionStatuses();
  }

  @observable contentPublicationsCount = '';
  @observable analysts = [];
  @observable unreadCount = 0;
  @observable connectionStatus = '';
  @observable shortcutDialogOpened = false;
  @observable sendSuccessDialogOpened = false;

  @action
  toggleShortcutDialog = () => {
    this.shortcutDialogOpened = !this.shortcutDialogOpened;
  };

  @action
  toggleMessageSentDialog = () => {
    this.sendSuccessDialogOpened = !this.sendSuccessDialogOpened;
  };

  @action
  setUnread = (num) => {
    if (num > 0) {
      document.querySelector(NAVIGATION_DOT_CLASS).style.display = 'block';
    }
    this.unreadCount = num;
  };

  @action
  fetchSummaries = async () => {
    const {
      data: { unpublished_content_publications }
    } = await api(API_ROUTES.contentPublicationsCount);
    this.contentPublicationsCount = unpublished_content_publications || '';
  };

  @action
  fetchAnalysts = async () => {
    try {
      const {
        data: { data }
      } = await api(API_ROUTES.analysts);
      return data;
    } catch (e) {
      return [];
    }
  };

  setConnectionStatuses() {
    Object.keys(CONNECTION_STATUS_MESSAGES).forEach((status) => {
      this.socket.on(status, () => {
        this.connectionStatus = CONNECTION_STATUS_MESSAGES[status];
      });
    });
    this.socket.on('reconnect', () => {
      alertsStore.clear();
      alertsStore.isTrash = null;
    });
    this.socket.on('connect_error', () => {
      if(socket.socketInitialized) {
        this.connectionStatus = "Connection error...";
      }
    });
  }
}

export default new CommonStore(socket);
