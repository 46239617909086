import React, { Component } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import ShortcutDialog from '@inbox/_common/components/ShortcutDialog';
import MessageSentDialog from '@inbox/_common/components/MessageSentDialog';
import Routes from './Routes';
import * as stores from './stores';
import './App.scss';

const Wrapper = styled.div`
  background-color: #dedede;
  padding-left: 66px;
  padding-top: 20px;
  min-height: calc(100vh - 64px);
  padding-bottom: 10px;
  position: relative;
`;

export default class App extends Component {
  render() {
    return (
      <Provider {...stores}>
        <Wrapper>
          <ShortcutDialog />
          <MessageSentDialog />
          <Router>
            <Routes />
          </Router>
        </Wrapper>
      </Provider>
    );
  }
}
