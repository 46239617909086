var app = angular.module('app');

app.controller("CreateSummaryController", function ($scope, $controller, $stateParams) {
  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.selectedBriefingType = $stateParams.briefingTypeId;
  $scope.create_summary = true;
  $scope.create_client_coverage = false;

  $scope.restoreForm($stateParams.token);
});
