import * as React from "react";

function SvgIconReceivedDate(props) {
  return (
    <svg
      viewBox="4805.944 1852.476 17.036 17.049"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".icon-recieveddate_svg__cls-1,.icon-recieveddate_svg__cls-2{fill:none;stroke-linecap:round}.icon-recieveddate_svg__cls-1{stroke:#3c475a}.icon-recieveddate_svg__cls-2{stroke:#505a6b}"
          }
        </style>
      </defs>
      <g
        id="icon-recieveddate_svg__icon-recieveddate"
        transform="translate(2527 662)"
      >
        <path
          id="icon-recieveddate_svg__Path_1716"
          data-name="Path 1716"
          className="icon-recieveddate_svg__cls-1"
          d="M0 0v5.645"
          transform="translate(2286.778 1193.786)"
        />
        <path
          id="icon-recieveddate_svg__Path_1717"
          data-name="Path 1717"
          className="icon-recieveddate_svg__cls-1"
          d="M-.235 0H-4"
          transform="translate(2290.777 1199.667)"
        />
        <path
          id="icon-recieveddate_svg__Path_1718"
          data-name="Path 1718"
          className="icon-recieveddate_svg__cls-2"
          d="M0 0v6.586"
          transform="translate(2281.088 1191.002)"
        />
        <path
          id="icon-recieveddate_svg__Path_1719"
          data-name="Path 1719"
          className="icon-recieveddate_svg__cls-2"
          d="M0 0l1.643 1.643"
          transform="translate(2279.444 1195.945)"
        />
        <path
          id="icon-recieveddate_svg__Path_1721"
          data-name="Path 1721"
          className="icon-recieveddate_svg__cls-2"
          d="M0-.1l1.643-1.646"
          transform="translate(2281.088 1197.691)"
        />
        <path
          id="icon-recieveddate_svg__Path_1722"
          data-name="Path 1722"
          className="icon-recieveddate_svg__cls-1"
          d="M0-.544a7.97 7.97 0 00.5 2.163 8 8 0 0010.332 4.6A7.967 7.967 0 0014.981 2.6a7.979 7.979 0 00.455-6.714A8 8 0 005.1-8.713"
          transform="translate(2279.475 1200.247)"
        />
      </g>
    </svg>
  );
}

export default SvgIconReceivedDate;

