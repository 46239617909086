angular.module('app').factory("FavouritesFactory", function ($http, $q, Config) {

  var getFavourites = function(type){
    switch (type) {
      case 'clients':
        return $http.get(Config.shibiUrl + '/favourite_and_others/clients_for_left_panel.json').
          then(function (response) {
            return response.data;
          });
      case 'articles':
        return $http.get(Config.shibiUrl + '/favourite_and_others/clients_and_briefing_types_for_left_panel.json').
          then(function (response) {
            return response.data;
          });
    }
  };

  var getMonitorItems = function() {
    return $http.get(Config.shibiUrl + '/favourite_and_others/monitor_sections.json').
      then(function (response) {
        return response.data;
      });
  }

  var getMonitorSectorsItems = function() {
    return $http.get(Config.shibiUrl + '/monitor_sectors.json').
      then(function (response) {
        return response.data.data;
      });
  }

  const getAllClientGroups = () => {
    return $http.get(Config.shibiUrl + '/merged_client_groups/get_all')
      .then(res => res.data.data);
  };

  return {
    getFavourites,
    getMonitorItems,
    getAllClientGroups,
    getMonitorSectorsItems
  };
});
