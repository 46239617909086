import find from 'lodash/find';

var app = angular.module('app');

app.config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false,
    rewriteLinks: false
  });

  // for invalid routes
  $urlRouterProvider.otherwise('/monitor');
  $stateProvider
    .state('preview', {
      url: '/preview/{articleId:int}',
      template: require('./shared/preview/preview.html.haml'),
      controller: 'ArticlePreviewController'
    })
    .state('raw_preview', {
      url: '/raw-preview/{articleId:int}',
      template: require('./shared/preview/raw_preview.html.haml'),
      controller: 'ArticlePreviewController'
    })
    //DASHBOARD
    .state('monitor', {
      url: '/monitor',
      template: require('./components/v2/monitor/monitor.html.haml'),
      controller: 'MonitorController'
    })
    // SEARCH
    .state('search', {
      url: '/search',
      template: require('./components/v2/search/results.html.haml'),
      controller: 'SearchController'
    })
    .state('content', {
      url: '/content/new/{token:string}?',
      template: require('./components/v2/contents/standalone-form.html.haml'),
      controller: 'ContentController'
    })
    .state('briefing_types_article_sources', {
      url: '/briefing_types_article_sources',
      template: require('./components/v2/article_sources/view.html.haml'),
      controller: 'ArticleSourcesController',
      abstract: true
    })
    .state('briefing_types_article_sources.first_source', {
      url: '/first_source',
      template: require('./components/v2/article_sources/no_sources.html.haml'),
      controller: 'OpenFirstSourceController'
    })
    .state('briefing_types_article_sources.article_source_details', {
      url: '/:articleSourceId/details',
      template: require('./components/v2/article_sources/view_detail.html.haml'),
      controller: 'ArticleSourcesDetailsController'
    })
    .state('briefing_types_article_sources.first_source_articles', {
      url: '/:articleSourceId/articles/page/{page:int}/{sort:string}?',
      template: require('./components/v2/articles/list_view.html.haml'),
      controller: 'ArticleListController'
    })
    .state('briefing_types_article_sources.create_from_first_other_source', {
      url: '/:otherSourceId/create_from_first_other_source/{token:string}?',
      params: { token: { value: null, squash: true } },
      controller: 'CreateFromFirstOtherSourceController',
      template: require('./components/v2/summaries_combined/form.html.haml'),
      resolve: {
        article: function () {
          return '';
        }
      }
    })
    .state('briefing_types_article_sources.create_from_first_article', {
      url:
        '/:articleSourceId/create_from_first_article/:articleId/{token:string}?',
      params: { token: { value: null, squash: true } },
      controller: 'CreateFromFirstArticleController',
      template: require('./components/v2/summaries_combined/form.html.haml'),
      resolve: {
        article: function ($stateParams, $http, Config, ArticlesFactory) {
          return ArticlesFactory.getArticle($stateParams.articleId);
        }
      }
    })
    .state('briefing_types_article_sources.first_briefing_type_articles', {
      url: '/{briefingTypeId:int}/first_briefing_type_articles',
      template: require('./components/v2/articles/list_view.html.haml'),
      controller: 'FindFirstBriefingTypeArticles'
    })
    .state('briefing_types_article_sources.articles', {
      url:
        '/{briefingTypeId:int}/article_source/{articleSourceId:int}/articles/page/{page:int}',
      template: require('./components/v2/articles/list_view.html.haml'),
      controller: 'ArticleListController'
    })
    .state('briefing_types_article_sources.create_from_other_source', {
      url:
        '/{briefingTypeId:int}/article_source/{otherSourceId:int}/create_from_other_source/{token:string}?',
      params: { token: { value: null, squash: true } },
      controller: 'CreateFromOtherSourceController',
      template: require('./components/v2/summaries_combined/form.html.haml')
    })
    .state('briefing_types_article_sources.create_from_article', {
      url:
        '/{briefingTypeId:int}/article_source/{articleSourceId:int}/create_from_article/:articleId/{token:string}?',
      params: { token: { value: null, squash: true } },
      controller: 'CreateFromArticleController',
      template: require('./components/v2/summaries_combined/form.html.haml'),
      resolve: {
        article: function ($stateParams, $http, Config, ArticlesFactory) {
          return ArticlesFactory.getArticle($stateParams.articleId);
        }
      }
    })
    .state('briefing_types_article_sources.create_summary', {
      url:
        '/{briefingTypeId:int}/article_source/{articleSourceId:int}/create_summary/{token:string}?',
      params: { token: { value: null, squash: true } },
      template: require('./components/v2/summaries_combined/form.html.haml'),
      controller: 'CreateSummaryController'
    })
    .state(
      'briefing_types_article_sources.create_summary_without_article_source',
      {
        url: '/{briefingTypeId:int}/create_summary/{token:string}?',
        params: { token: { value: null, squash: true } },
        template: require('./components/v2/summaries_combined/form.html.haml'),
        controller: 'CreateSummaryWithoutArticleSourceController'
      }
    )
    .state(
      'briefing_types_article_sources.briefing_type_without_article_sources',
      {
        url: '/{briefingTypeId:int}/article_source/{articleSourceId}',
        template: require('./components/v2/article_sources/no_sources.html.haml'),
        controller: 'BriefingTypeWithoutArticleSourcesController'
      }
    )
    .state('clients_article_sources', {
      url: '/client_article_sources/{clientId:int}',
      template: require('./components/v2/article_sources/view.html.haml'),
      controller: 'ClientSearchPhrasesController'
    })
    .state('clients_article_sources.articles', {
      url: '/search_phrase/:searchPhraseId/page/{page:int}/{sort:string}?',
      template: require('./components/v2/articles/list_view.html.haml'),
      controller: 'ClientSearchPhrasesArticlesController'
    })
    .state('clients_article_sources.create_client_coverage_with_article', {
      url:
        '/search_phrase/:searchPhraseId/article/{articleId:int}/create_client_coverage/{token:string}?',
      params: { token: { value: null, squash: true } },
      resolve: {
        article: function ($stateParams, $http, Config, ArticlesFactory) {
          return ArticlesFactory.getArticle($stateParams.articleId);
        }
      },
      template: require('./components/v2/summaries_combined/form.html.haml'),
      controller: 'CreateClientCoverageWithArticleController'
    })
    .state('clients_article_sources.create_covereage_with_client', {
      url:
        '/search_phrase/:searchPhraseId/create_client_coverage/{token:string}?',
      params: { token: { value: null, squash: true } },
      template: require('./components/v2/summaries_combined/form.html.haml'),
      controller: 'CreateClientCoverageController'
    })
    .state('new_content', {
      url: '/content/from-article/{articleId:int}?',
      template: require('./components/v2/contents/standalone-form.html.haml'),
      controller: 'ContentController'
    })
    .state('client.create_content', {
      url: '/content/new/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        '@client': {
          template: require('./components/v2/contents/form.html.haml'),
          controller: 'ContentController'
        }
      }
    })
    .state('briefing_publisher.summaries.edit_content', {
      url: '/edit/:contentId',
      views: {
        'rightPanel@briefing_publisher': {
          template: require('./components/v2/contents/edit.html.haml'),
          controller: 'ContentController'
        }
      }
    })
    .state('briefing_types_article_sources.edit_content', {
      url: '/content/edit/:contentId',
      template: require('./components/v2/contents/edit.html.haml'),
      controller: 'ContentController'
    })
    .state('briefing_types_article_sources.new_content', {
      url: '/content/new/{articleId:int}/{token:string}?',
      template: require('./components/v2/contents/form.html.haml'),
      controller: 'ContentController'
    })
    //BRIEFING TYPES
    .state('briefing_publisher', {
      url: '/briefing_publisher',
      resolve: {
        openedBriefings: function ($http, Config) {
          return $http.get(Config.shibiUrl + '/content_containers/opened.json');
        },
        closedBriefings: function ($http, Config) {
          return $http.get(Config.shibiUrl + '/content_containers/closed.json');
        }
      },
      views: {
        '': {
          template: require('./components/v2/briefing_publisher/briefing_list_view.html.haml'),
          controller: 'BriefingListController'
        }
      }
    })
    .state('briefing_publisher.first_briefing', {
      url: '/first_briefing',
      views: {
        rightPanel: {
          template: require('./components/v2/briefing_publisher/no_briefings.html.haml'),
          controller: 'OpenFirstBriefingController'
        }
      }
    })
    .state('briefing_publisher.summaries', {
      url: '/{briefingId:int}/summaries',
      resolve: {
        briefing: function ($http, $stateParams, Config) {
          return $http.get(
            Config.shibiUrl +
              '/content_containers/' +
              $stateParams.briefingId +
              '.json'
          );
        }
      },
      views: {
        rightPanel: {
          template: require('./components/v2/briefing_publisher/summary_list_view.html.haml'),
          controller: 'SummaryListController'
        }
      }
    })
    .state('briefing_publisher.summaries.summary_edit', {
      url: '/summary_edit/{summaryId:int}/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        'rightPanel@briefing_publisher': {
          template: require('./components/v2/summaries/edit.html.haml'),
          controller: 'SummaryController'
        }
      }
    })
    // Keyword
    .state('search_phrases', {
      url: '/search_phrases',
      resolve: {
        clients: function (ClientsFactory) {
          return ClientsFactory.getClients();
        }
      },
      views: {
        '': {
          template: require('./components/v2/search_phrases/clients.html.haml'),
          controller: function ($state, $scope, clients) {
            $scope.state = $state;
            $scope.clients = clients;
          }
        },
        'main@search_phrases': {
          template: require('./components/v2/search_phrases/no_clients.html.haml'),
          controller: 'OpenFirstClientController'
        }
      }
    })
    .state('search_phrases.client', {
      abstract: true,
      url: '/client_keywords/{clientId:int}',
      resolve: {
        searchPhrases: function ($stateParams, SearchPhrasesFactory) {
          return SearchPhrasesFactory.getSearchPhrasesForClient(
            $stateParams.clientId
          );
        },
        client: function ($stateParams, clients) {
          return find(clients, function (c) {
            return c.id == $stateParams.clientId;
          });
        }
      },
      views: {
        'sidebar@search_phrases': {
          template: require('./components/v2/search_phrases/phrases_for_client.html.haml'),
          controller: 'SearchPhraseListController'
        }
      }
    })
    .state('search_phrases.client.all_articles', {
      url: '/articles/page/{page:int}',
      views: {
        'main@search_phrases': {
          template: require('./components/v2/articles/list_view.html.haml'),
          controller: 'ArticlesByClientController'
        }
      }
    })
    .state('search_phrases.client.phrase_articles', {
      url: '/phrase_articles/{searchPhraseId:int}/page/{page:int}',
      views: {
        'main@search_phrases': {
          template: require('./components/v2/articles/list_view.html.haml'),
          controller: 'ArticlesByPhraseController'
        }
      }
    })
    .state('search_phrases.client.all_articles.article', {
      url: '/article/{articleId:int}',
      resolve: {
        default_summary_create: function () {
          return false;
        },
        default_coverage_create: function () {
          return true;
        }
      },
      views: {
        'main@search_phrases': {
          template: require('./components/v2/articles/view.html.haml'),
          controller: 'ArticleController'
        },
        'form@search_phrases.client.all_articles.article': {
          template: require('./components/v2/summaries_combined/form.html.haml'),
          controller: 'SummaryCombinedController'
        }
      }
    })
    .state('search_phrases.client.phrase_articles.article', {
      url: '/article/{articleId:int}',
      resolve: {
        default_summary_create: function () {
          return false;
        },
        default_coverage_create: function () {
          return true;
        }
      },
      views: {
        'form@search_phrases.client.phrase_articles.article': {
          template: require('./components/v2/summaries_combined/form.html.haml'),
          controller: 'SummaryCombinedController'
        },
        'main@search_phrases': {
          template: require('./components/v2/articles/view.html.haml'),
          controller: 'ArticleController'
        }
      }
    })
    //CLIENTS
    .state('clients', {
      url: '/clients',
      controller: 'ClientsController'
    })
    .state('client', {
      url: '/clients/:clientId',
      abstract: true,
      template: require('./components/v2/clients/clients.html.haml'),
      controller: 'FirstClientController'
    })
    .state('client.no_results', {
      url: '/clients/no_results',
      template: require('./components/v2/clients/no_coverages.html.haml')
    })
    .state('client.unpublished', {
      url: '/unpublished/:sectionId/page/{page:int}',
      template: require('./components/v2/clients/list_view.html.haml'),
      controller: 'CoverageListController',
      resolve: {
        coveragesResult: function ($stateParams, ClientCoveragesFactory) {
          return ClientCoveragesFactory.getPublications(
            $stateParams.clientId,
            $stateParams.sectionId,
            'unpublished',
            $stateParams.page
          );
        },
        goPublished: function () {
          return false;
        }
      }
    })
    .state('client.without_section', {
      url: '/without_section/{clientIdWithoutSection}}',
      controller: 'ClientWithoutSectionController'
    })
    .state('client.published_without_section', {
      url:
        '/published_without_section/{clientIdWithoutSection}/page/{page:int}',
      template: require('./components/v2/clients/list_view.html.haml'),
      controller: 'CoverageListController',
      resolve: {
        coveragesResult: function ($stateParams, ClientCoveragesFactory) {
          return ClientCoveragesFactory.getPublications(
            $stateParams.clientIdWithoutSection,
            'all',
            'published',
            $stateParams.page
          );
        },
        goPublished: function () {
          return true;
        }
      }
    })
    .state('client.unpublished_without_section', {
      url:
        '/unpublished_without_section/{clientIdWithoutSection}/page/{page:int}',
      template: require('./components/v2/clients/list_view.html.haml'),
      controller: 'CoverageListController',
      resolve: {
        coveragesResult: function (
          $stateParams,
          $state,
          ClientCoveragesFactory
        ) {
          return ClientCoveragesFactory.getPublications(
            $stateParams.clientIdWithoutSection,
            'all',
            'unpublished',
            $stateParams.page
          );
        },
        goPublished: function () {
          return false;
        }
      }
    })
    .state('client.published', {
      url: '/published/:sectionId/page/{page:int}',
      template: require('./components/v2/clients/list_view.html.haml'),
      controller: 'CoverageListController',
      resolve: {
        coveragesResult: function ($stateParams, ClientCoveragesFactory) {
          return ClientCoveragesFactory.getPublications(
            $stateParams.clientId,
            $stateParams.sectionId,
            'published',
            $stateParams.page
          );
        },
        goPublished: function () {
          return true;
        }
      }
    })
    .state('client.unpublished.content_edit', {
      url: '/content_edit/{contentId:int}/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        '@client': {
          template: require('./components/v2/contents/edit.html.haml'),
          controller: 'ContentController'
        }
      }
    })
    .state('client.published.content_edit', {
      url: '/content_edit/{contentId:int}/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        '@client': {
          template: require('./components/v2/contents/edit.html.haml'),
          controller: 'ContentController'
        }
      }
    })
    .state('client.unpublished_without_section.content_edit', {
      url: '/content_edit/{contentId:int}/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        '@client': {
          template: require('./components/v2/contents/edit.html.haml'),
          controller: 'ContentController'
        }
      }
    })
    .state('client.published_without_section.content_edit', {
      url: '/content_edit/{contentId:int}/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        '@client': {
          template: require('./components/v2/contents/edit.html.haml'),
          controller: 'ContentController'
        }
      }
    })
    .state('client.create_covereage', {
      url: '/create_client_coverage/{token:string}?',
      params: { token: { value: null, squash: true } },
      views: {
        '@client': {
          template: require('./components/v2/summaries_combined/form.html.haml'),
          controller: 'CreateClientCoverageController'
        }
      }
    })
    //LOGIN
    .state('login', {
      url: '/login',
      template: require('./shared/authentication/login.html.haml'),
      controller: 'LoginController'
    })
    //REGISTER
    .state('register', {
      url: '/register',
      template: require('./shared/authentication/register.html.haml'),
      controller: 'RegisterController'
    })
    .state('request_password_reset', {
      url: '/request_password_reset',
      template: require('./shared/authentication/request_password_reset.html.haml'),
      controller: 'RequestPasswordResetController'
    })
    .state('change_password', {
      url: '/change_password/:token',
      template: require('./shared/authentication/change_password.html.haml'),
      controller: 'ChangePasswordController'
    })
    .state('confirm_email', {
      url: '/confirm_email/:token',
      template: require('./shared/authentication/confirm_email.html.haml'),
      controller: 'ConfirmEmailController'
    })
    //LOGOUT
    .state('logout', {
      url: '/logout',
      template: ' ',
      controller: 'LogoutController'
    })
    .state('join_group', {
      url: '/join_group/{groupId:int}',
      template: require('./shared/notification/message.html.haml'),
      controller: 'ClientGroupsJoinController'
    })
    .state('todo', {
      url: '/todo',
      template: require('./components/v2/todo/todo.html.haml'),
      controller: 'TodoController'
    })
    .state('alerts', {
      url: '/alerts',
      template: require('./components/v2/alerts/alerts.html.haml'),
      controller: 'AlertsController'
    })
    .state('alerts_trash', {
      url: '/alerts/trash',
      template: require('./components/v2/alerts/alerts.html.haml'),
      controller: 'AlertsController'
    })
    .state('alerts_settings', {
      url: '/alerts/settings',
      template: require('./components/v2/alerts/alerts.html.haml'),
      controller: 'AlertsController'
    })
    .state('invitations', {
      url: '/invitations/{clientId:int}',
      template: require('./components/v2/users/invitation.html.haml'),
      controller: 'InvitationWithClientController'
    })
    .state('invitations_without_client', {
      url: '/invitations_without_client',
      template: require('./components/v2/users/invitation_with_package.html.haml'),
      controller: 'InvitationWithPackageController'
    });
});
