import * as React from "react";

function SvgIconPublicationdate(props) {
  return (
    <svg
      viewBox="4685.944 1852.5 16.111 16.111"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".icon-publicationdate_svg__cls-2{fill:none;stroke:#3c475a;stroke-linecap:round}"
          }
        </style>
      </defs>
      <g
        id="icon-publicationdate_svg__icon-publicationdate"
        transform="translate(2559 661.556)"
      >
        <path
          id="icon-publicationdate_svg__Path_1724"
          data-name="Path 1724"
          d="M-.944-1.889a7.556 7.556 0 007.555-7.555A7.556 7.556 0 00-.944-17 7.556 7.556 0 00-8.5-9.444 7.556 7.556 0 00-.944-1.889z"
          transform="translate(2135.944 1208.444)"
          fill="none"
          stroke="#3c475a"
        />
        <path
          id="icon-publicationdate_svg__Path_1726"
          data-name="Path 1726"
          className="icon-publicationdate_svg__cls-2"
          d="M0 0v5.778"
          transform="translate(2134.37 1193.852)"
        />
        <path
          id="icon-publicationdate_svg__Path_1727"
          data-name="Path 1727"
          className="icon-publicationdate_svg__cls-2"
          d="M-.5 0h-4"
          transform="translate(2138.87 1199.63)"
        />
      </g>
    </svg>
  );
}

export default SvgIconPublicationdate;

