import find from 'lodash/find';

var app = angular.module('app');

app.controller("SidebarController", function ($scope, $element, $state, PreviewFactory, SearchPhrasesFactory) {
  $scope.state = $state;
  var setSidebarHeight = function() {
    var window_height = $(window).height(),
        sidebar_pos = $element.offset().top,
        sidebar_width = $element.width(),
        sidebar_height = $element.outerHeight();

    $element.css({
      width: sidebar_width,
      position: 'fixed',
      height: window_height - 110,
      top: 110
    });

    // angular.element('.content-column').height(window_height - 110);

  };

  $scope.preview = function(article_source) {
    PreviewFactory.preview(article_source.url_for_analysts);
  };

  $scope.previewForOtherSource = function(other_source) {
    PreviewFactory.preview(other_source.url);
  };

  // setSidebarHeight();

  // $scope.editSearchPhrase = function (search_phrase_id, search_phrase_text, e) {
  //   e.stopPropagation();
  //   $scope.$broadcast('monitor.editPhrase', {"id" : search_phrase_id, "phrase" : search_phrase_text });
  // };

  $scope.editSearchPhrase = function (searchPhrase, e) {
    e.stopPropagation();
    $scope.$broadcast('monitor.editPhrase', searchPhrase);
  };

  $scope.$on('search_phrases.reload', function (event, data) {
    if ((data !== undefined) && (data.id != null)) {
      var phrase = find($scope.$parent.article_sources, function (source) {
        return source.id === data.id;
      });
      if (phrase !== undefined) {
        phrase.phrase = data.phrase;
        $state.go("clients_article_sources.articles", {clientId: $scope.clientId, searchPhraseId: data.id, page: 1}, {reload: true});
      }
    } else {
      if (data === undefined) {
        $state.go("clients_article_sources.articles", {clientId: $scope.clientId, searchPhraseId: 'all', page: 1}, {reload: true});
      } else {
        SearchPhrasesFactory.getSearchPhrasesForClientWithAllSection($scope.clientId).then( function (search_phrases) {
          $scope.$parent.article_sources = search_phrases;
          $scope.$parent.$emit('dataLoaded', 'client_' + $scope.clientId);
          var phrase = find($scope.$parent.article_sources, function (source) {
            return source.phrase === data.phrase;
          });
          if (phrase !== undefined) {
            $state.go("clients_article_sources.articles", {clientId: $scope.clientId, searchPhraseId: phrase.id, page: 1});
          }
        });
      }
    }
  });

});
