import React from "react";
import * as Icons from "./Icons";
import moment from "moment";
import {
  withStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    padding: 0,
    maxWidth: 140,
    fontSize: theme.typography.pxToRem(10),
    border: "1px solid #CFCFCF",
    background: "#fff",
    color: "#000",
    borderRadius: 10,
    textAlign: "center",
    fontFamily: "Lato,sans-serif",
    fontWeight: "700",
    boxShadow: "0px 0px 16px -2px rgba(140,140,140,1)",
  },
  arrow: {
    fontSize: 16,
    width: 19,
    "&::before": {
      border: "1px solid #CFCFCF",
      backgroundColor: "#fff",
      boxSizing: "border-box",
    },
  },
}))(Tooltip);

export default function DatesSection(props) {
  const { createdAt, pubdate, pubDateTimeDiffStatus } = props;

  const styles = {
    root: {
      display: "flex",
      justifyContent: "space-between",
      width: 306,
    },
    icon: {
      position: "relative",
      backgroundRepeat: "no-repeat",
    },
  };

  const translateColorToHex = (pubDateTimeDiffStatus) => {
    switch(pubDateTimeDiffStatus) {
    case 'blue':
      return "#136FE2";
    case 'green':
      return "#0DB90C";
    case 'orange':
      return "#FA7900";
    case 'red':
      return "#E0360A";
    };
  };
  return (
    <div style={styles.root}>
      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
        <Icons.SvgIconPublicationDate style={styles.icon} fontSize={"small"} />
        <span style={{ marginLeft: 5 }}>
          {moment(pubdate).format("D/M/YY h:mm A Z")}
        </span>
      </div>
      <HtmlTooltip
        arrow
        title={
          <React.Fragment>
            <div
              style={{
                height: 30,
                backgroundColor: "#3F4759",
                color: "white",
                padding: "10px 0px 40px 0px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                fontSize: 10,
                width: 140,
              }}
            >
              <span>Time difference between publication and received date</span>
            </div>
            <div
              style={{
                height: 60,
                backgroundColor: "#FFFFFF",
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "40px 0px",
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                fontFamily: "Lato,sans-serif",
                width: 140,
                fontSize: 10,
                fontWeight: "700",
              }}
            >
              <span style={{ color: "#136FE2" }}>
                Received before publication
              </span>
              <span style={{ color: "#0DB90C" }}>0-15m</span>
              <span style={{ color: "#FA7900" }}>16-60m</span>
              <span style={{ color: "#E0360A" }}>+60m</span>
            </div>
          </React.Fragment>
        }
      >
        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
          <Icons.SvgIconReceivedDate style={styles.icon} fontSize={"small"} />
          <span
            style={{ color: translateColorToHex(pubDateTimeDiffStatus), marginLeft: 5 }}
          >
            {moment(createdAt).format("D/M/YY h:mm A Z")}
          </span>
        </div>
      </HtmlTooltip>
    </div>
  );
}
