angular.module('app').factory("ClientGroupsFactory", function ($timeout, $http, $q, $window, Config) {

  var getPublicGroups = function() {
    return $http.get(Config.shibiUrl + '/merged_client_groups/get_public')
      .then(function (response) {
        return response.data.data;
      });
  };

  var getAnalysts = function() {
    return $http.get(Config.shibiUrl + '/analysts')
      .then(function (response) {
        return response.data.data;
      });
  };

  var sendSuggestion = function(data) {
    return $http.post(Config.shibiUrl + '/merged_client_groups/make_suggestion', {
      group_id: data.group_id,
      message: data.message
    });
  }

  var shareWithAnalysts = function(data) {
    return $http.post(Config.shibiUrl + '/merged_client_groups/share', {
      group_id: data.group_id,
      analyst_ids: data.analyst_ids,
      message: data.message
    });
  }

  var createClientGroup = function(data) {
    return $http.post(Config.shibiUrl + '/merged_client_groups', {
      client_group: {
        name: data.name,
        description: data.description,
        content_container_ids: data.client_ids,
        is_public: data.is_public
      }
    });
  }

  var editClientGroup = function(data) {
    return $http.patch(Config.shibiUrl + '/merged_client_groups/' + data.id, {
      client_group: {
        name: data.name,
        description: data.description,
        content_container_ids: data.client_id_array,
        is_public: data.is_public
      }
    })
    .then(function (response) {
      return response;
    });
  }

  var removeClientGroup = function(groupId) {
    return $http.delete(Config.shibiUrl + '/merged_client_groups/' + groupId);
  }

  var getClientGroup = function(groupId) {
    return $http.get(Config.shibiUrl + '/merged_client_groups/' + groupId);
  };

  var getCompanyTagsByQuery = function(query) {
    return $http.get(Config.shibiUrl + '/briefing_coverage_screen_merged/search_client?query=' + query).
      then(function (response) {
        return response.data.companies;
      });
  }

  var addClientToFav = function(company_container_id) {
    return $http.post(Config.shibiUrl + '/favourites/favourite_company', {
      company_container_id: company_container_id
    })
  }

  var removeClientFromFav = function(company_container_id) {
    return $http.post(Config.shibiUrl + '/favourites/unfavourite_company', {
      company_container_id: company_container_id
    });
  }

  return {
    getPublicGroups: getPublicGroups,
    getAnalysts: getAnalysts,
    shareWithAnalysts: shareWithAnalysts,
    sendSuggestion: sendSuggestion,
    createClientGroup: createClientGroup,
    editClientGroup: editClientGroup,
    removeClientGroup: removeClientGroup,
    getClientGroup: getClientGroup,
    getCompanyTagsByQuery: getCompanyTagsByQuery,
    addClientToFav: addClientToFav,
    removeClientFromFav: removeClientFromFav
  };
});
