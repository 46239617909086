angular.module('app').controller("ArticleListController", function ($scope, $state, $stateParams, ArticleSourcesFactory, ArticlesFactory, $window, $location) {
    $scope.queryHash = $.extend({}, { page: $stateParams.page }, $location.search());
    $scope.articleSourceId = $stateParams.articleSourceId;
    $scope.articleId = $stateParams.articleId;
    $scope.page = parseInt($scope.queryHash.page);
    $scope.$parent.hasBriefingTypeId = false;
    $scope.$parent.articleSourcesScope = true;

    if($state.params.briefingTypeId) {
      $scope.$parent.briefingTypeId = $state.params.briefingTypeId;
      $scope.$parent.hasBriefingTypeId = true;
      ArticleSourcesFactory.getBriefingTypeArticleSources($state.params.briefingTypeId).then(response => {
        $scope.$parent.article_sources = response.article_sources;
        $scope.$parent.other_sources = response.other_sources;
        $scope.$parent.sourceName = response.name;
      });
    }

    $scope.$on("$destroy", Waypoint.destroyAll);

    ArticleSourcesFactory.getArticleSource($scope.articleSourceId)
      .then(article_source => {
        if ($scope.$parent) {
          $scope.$parent.article_source = article_source;
        }
      });


    ArticlesFactory.getArticles($scope.articleSourceId, $scope.page)
      .then(result => {
        $scope.articles = result.articles;
        $scope.totalPages = result.totalPages;
        $scope.currentPage = result.currentPage;
        $scope.index = result.index;
      });
});
