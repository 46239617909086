import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import RelatedCoverage from './components/RelatedCoverage';

const styles = {
  root: {
    backgroundColor: '#ffffff',
    textAlign: 'center',
    padding: '19px'
  },
  label: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '10px'
  }
};

@inject('contentStore')
@observer
export default class RelatedCoverages extends Component {
  render() {
    const { contentStore } = this.props;

    return (
      (contentStore.relatedContentPublications &&
        contentStore.relatedContentPublications.length && (
        <div style={{ marginTop: '20px' }}>
          <div style={styles.root}>
            <label style={styles.label}>Related content publications</label>
            {contentStore.relatedContentPublications.map(
              (contentPublication) => (
                <RelatedCoverage
                  contentPublication={contentPublication}
                  key={contentPublication.id}
                />
              )
            )}
          </div>
        </div>
      )) ||
      null
    );
  }
}
