import without from 'lodash/without';
import reject from 'lodash/reject';

var app = angular.module('app');
app.controller("BaseListController", function ($scope) {

  $scope.selectedListItems = [];
  $scope.selectedForExport = [];

  $scope.selectListItem = function(event, sid) {
    if ($(event.currentTarget).is(":checked")) {
      $scope.selectedListItems.push(sid);
    }
    else {
      $scope.selectedListItems = without($scope.selectedListItems, sid);
    }

    if (!$scope.selectedListItems.length) {
      $scope.ItemsCheckList = false;
    }
  };

  $scope.checkAllListItems = function(all_items) {
    if ($scope.ItemsCheckList) {
      $scope.selectedListItems = [];
      reject(all_items, function(item) {
        if (item.status == 'unpublished') {
          $scope.selectedListItems.push(item);
        }
      });
    }
    else {
      $scope.selectedListItems = [];
    }
  };

  $scope.move = (array, moveIndex, toIndex) => {
    /* #move - Moves an array item from one position in an array to another.
      Arguments:
      1. array     (String) : Array in which to move an item.         (required)
      2. moveIndex (Number) : The index of the item to move.          (required)
      3. toIndex   (Number) : The index to move item at moveIndex to. (required)
    */
    const item = array[moveIndex];
    const length = array.length;
    const diff = moveIndex - toIndex;

    if (diff > 0) { // move left
      return [
        ...array.slice(0, toIndex),
        item,
        ...array.slice(toIndex, moveIndex),
        ...array.slice(moveIndex + 1, length)
      ];
    } else if (diff < 0) { // move right
      const targetIndex = toIndex + 1;
      return [
        ...array.slice(0, moveIndex),
        ...array.slice(moveIndex + 1, targetIndex),
        item,
        ...array.slice(targetIndex, length)
      ];
    }
    return array;
  }

});
