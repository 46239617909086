import countBy from 'lodash/countBy';

angular.module("app").directive('confirmationDiscardBriefingPopup', ['$http', 'Config', '$stateParams', function() {
  return {
    restrict: 'AE', //attribute or element
    template: require("./confirmation_discard_briefing_popup.html.haml"),
    replace: true,
    link: function($scope) {
      $scope.popupOpened = false; // popup closed on init
      $scope.reorderPopupOpened = false; // popup closed on init
      $scope.$parent.reorderBackground = false;

      var close = function() {
        $scope.$parent.reorderBackground = false;
        $scope.popupOpened = false;
        angular.element('body').removeClass('popup-opened');
      };

      $scope.open = function() {
        angular.element('body').addClass('popup-opened');
        $scope.popupOpened = true;
      };

      $scope.close = function(event) {
        close();
        $scope.$parent.submitWithoutPublishing(event, true);
      };

      $scope.cancel = function() {
        close();
      };

      $scope.closeConfirmationPopup = function(event) {
        close();
      };
    }
  };
}]);
