angular.module('app').controller("CreateFromOtherSourceController", function( $scope, $http, $controller, $state, $stateParams, Config,
                                                                              BriefingTypesFactory, ArticleSourcesFactory) {

  $.extend(this, $controller('BaseFormSummaryController', { $scope: $scope }));

  $scope.$parent.articleSourcesScope = true;
  $scope.$parent.hasBriefingTypeId = true;
  $scope.$parent.briefingTypeId = $state.params.briefingTypeId;

  $scope.create_summary = true;
  $scope.create_client_coverage = false;

  $scope.disableUrl = false;
  $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;

  $scope.updatePublishCheckbox = function (coverage = false) {
    if (coverage) {
      $scope.coverageUrlPublished = !!$scope.coverageUrl && !$scope.disablePublishing;
    } else {
      $scope.summaryUrlPublished = !!$scope.summaryUrl && !$scope.disablePublishing;
    }
  };

  ArticleSourcesFactory.getBriefingTypeArticleSources($state.params.briefingTypeId).then(function (response) {
    $scope.$parent.article_sources = response.article_sources;
    $scope.$parent.other_sources = response.other_sources;
    $scope.$parent.sourceName = response.name;
  });

  BriefingTypesFactory.getBriefingType($state.params.briefingTypeId).then(function (response) {
    $scope.selectedBriefingType = response.id;
  });

  $scope.restoreForm($stateParams.token);

  $scope.summaryUrlPublished = false;
});
