import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Image, Flex, Box } from 'rebass';
import Button from '@inbox/_common/components/Button';
import { observer, inject } from 'mobx-react';
import Tag from '@inbox/_common/components/Tag';
import FilterContainer from './FilterContainer';
import SectorFilters from './SectorFilters';
import SearchFilters from './SearchFilters';

@inject('filtersStore', 'alertsStore')
@observer
export default class AlertFilters extends Component {
  render() {
    const {
      filtersStore: {
        showFilters,
        toggleSearch,
        search,
        unreadEnabled,
        flaggedEnabled,
        toggleUnread,
        toggleFlagged,
        checkedSectors,
        onFilterChange,
        onPhraseChange,
        onPhraseDelete,
      },
      isTrash,
    } = this.props;
    const { markAllAsRead, deleteSelected } = this.props.alertsStore;
    return (
      <Fragment>
        {!showFilters
          && !search.show && (
          <FilterContainer heading="Filter by">
            <Flex justifyContent="space-between">
              <Box>
                <Button
                  mr={9}
                  onClick={this.props.filtersStore.toggleFilters}
                  active={checkedSectors.length > 0}
                >
                    Sector
                </Button>
                <Button
                  mr={9}
                  onClick={toggleUnread}
                  active={unreadEnabled}
                >
                    Unread
                </Button>
                <Button
                  mr={9}
                  onClick={toggleFlagged}
                  active={flaggedEnabled}
                >
                    Flagged
                </Button>
                <Button mr={9} onClick={markAllAsRead}>
                    Mark all as read
                </Button>
                {!isTrash && (
                  <Button mr={9} onClick={deleteSelected}>
                      Delete selected
                  </Button>
                )}
              </Box>
              <Button onClick={toggleSearch}>
                <Image
                  src="/images/icons/inbox/search_icon.svg"
                  alt="Search..."
                  width={18}
                />
              </Button>
            </Flex>
            {checkedSectors.map(({ name, id }, i) => (
              <Tag
                key={`checkedSector${i}`}
                mt={10}
                mr={10}
                onClick={() => onFilterChange(id)}
              >
                {name}
              </Tag>
            ))}
            {search.phrase && (
              <Tag mt={10} mr={10} onClick={() => onPhraseDelete()}>
                {search.phrase}
              </Tag>
            )}
          </FilterContainer>
        )}
        {showFilters && (
          <SectorFilters filtersStore={this.props.filtersStore} />
        )}
        <SearchFilters
          onChange={onPhraseChange}
          toggleSearch={toggleSearch}
          searchFilters={search}
        />
      </Fragment>
    );
  }
}

AlertFilters.wrappedComponent.propTypes = {
  filtersStore: PropTypes.shape({
    showFilters: PropTypes.bool.isRequired,
    toggleSearch: PropTypes.func.isRequired,
    search: PropTypes.shape({
      show: PropTypes.bool.isRequired,
      phrase: PropTypes.string.isRequired,
    }).isRequired,
    unreadEnabled: PropTypes.bool.isRequired,
    flaggedEnabled: PropTypes.bool.isRequired,
    toggleUnread: PropTypes.func.isRequired,
    toggleFlagged: PropTypes.func.isRequired,
    checkedSectors: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onPhraseChange: PropTypes.func.isRequired,
    onPhraseDelete: PropTypes.func.isRequired,
  }).isRequired,
};
