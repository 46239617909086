import React from 'react';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 60px;
  vertical-align: middle;
  background-color: #212840;
`;

const Image = styled.img`
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Avatar = ({ src, ...props }) => (
  <AvatarContainer {...props}>
    <Image src={src} />
  </AvatarContainer>
);

export default Avatar;
