import each from 'lodash/each';
import some from 'lodash/some';
import pick from 'lodash/pick';
import map from 'lodash/map';
import filter from 'lodash/filter';

angular.module("app").directive('mediaSourcePopup', ['$compile', 'ngToast', 'ArticleSourcesFactory', 'BriefingTypesFactory', 'ToolFactory', function($compile, ngToast, ArticleSourcesFactory, BriefingTypesFactory, ToolFactory) {
  return {
    restrict: 'AE', //attribute or element
    // scope: {
    //   briefingType: '='
    // },
    template: require("./media_source_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.popupOpened = false; // popup closed on init
      $scope.newSources = [];
      $scope.deleted_sources_ids = [];
      $scope.website = {};
      $scope.errors = {};

      var close = function() {
        $scope.buttonIsDisabled = false;
        $scope.popupOpened = false;
        $scope.loading = false;
        $scope.newSources = [];
        $scope.website.url = "";
        $scope.errors = {};

        $scope.deleted_sources_ids = [];

        $scope.mediaSourceForm.$setPristine();
        angular.element('body').removeClass('popup-opened');
      };

      var checkUrlUniqness = function(website) {
        var check = some($scope.newSources, function(s) {
          return s.url == website;
        });

        if (!check) {
          check = some($scope.otherSources, function(s) {
            return s.url == website;
          });
        }

        return check;
      };

      var checkUrl = function(url) {
        var regexp = /^((?:http|https)(?::\/{2}[\w]+)(?:[\/|\.]?)(?:[^\s"]*)(?:\.).+)$/;

        // blank
        if (url == "" || url == undefined) {
          $scope.errors.website = "CAN'T BE BLANK";
          return false;
        }
        // valid url
        else if (!regexp.test(url)) {
          $scope.errors.website = "PLEASE ENTER VALID SITE URL (WITH PROTOCOL), e.g. http://twitter.com";
          return false;
        }
        else if (checkUrlUniqness(url)) {
          $scope.errors.website = "THIS SOURCE IS ALREADY ON THE LIST";
          return false;
        }
        else {
          return true;
        }
      };

      // open popup on edit
      $scope.$on('monitor.editBriefingType', function(event, briefingType) {
        $scope.briefingType = briefingType;
        $scope.open();
      });

      $scope.numGroups = function(input){
        if (input) {
          return Object.keys(input).length;
        }
      };

      $scope.open = function() {
        $scope.loading = true;
        $scope.allSourcesVisible = false;
        angular.element('body').addClass('popup-opened');
        $scope.articleSources = {};
        $scope.otherSources = {};

        if ($scope.briefingType.id) {
          ArticleSourcesFactory.getArticleSourcesForBriefingType($scope.briefingType.id).then(function(response) {
            $scope.articleSources = response.article_sources;
            $scope.otherSources = response.other_sources;
            $scope.checkAllOpenWebsites = undefined === find($scope.otherSources, function(source) { return source.is_checked == false; });
            $scope.checkAllVisibleSources = true;
            $scope.articleSources_map = {};
            each($scope.articleSources, function (ps) {
              $scope.articleSources_map[ps.id] = ps.is_checked;
              if(ps.is_checked == false) $scope.checkAllVisibleSources = false;
            });

            $scope.loading = false;
          });
        }
        else {
          close();
          return;
        }
        $scope.popupOpened = true;
      };

      $scope.close = function() {
        close();
      };

      $scope.removeWebsite = function(website) {
        var index = $scope.newSources.indexOf(website);
        $scope.newSources.splice(index, 1);
      };

      $scope.showAllSources = function() {
        $scope.allSourcesVisible = true;
      };

      $scope.submit = function () {
        $scope.loading = true;

        var aa = [];
        each($scope.articleSources_map, function (k,v) {
          aa.push([v,k]);
        });
        var article_source_types = filter(aa, function (k) {
          return k[1];
        });

        article_source_types = map(article_source_types, function (k) {
          return k[0];
        });

        var other_source_ids = [];
        each($scope.otherSources, function(source) {
          if (source.is_checked) {
            other_source_ids.push(source.id);
          }
        });

        var request_data = {
          article_source_ids: article_source_types,
          other_source_ids: other_source_ids,
          new_other_sources: $scope.newSources,
          deleted_source_ids: $scope.deleted_sources_ids
        };

        BriefingTypesFactory.updateBriefingTypeSources($scope.briefingType.id, request_data).then(function() {
            $scope.errorMessage = null;
            close();
            ngToast.create('Briefing Type has been updated');
          }).catch(function (errors) {
            $scope.errorMessage = errors;
            $scope.loading = false;
          });

      };

      $scope.selectAllOpenWebsites = function() {
        each($scope.otherSources, function(source) {
          source.is_checked = $scope.checkAllOpenWebsites;
        });
      };

      $scope.selectAllVisibleSources = function() {
        map($scope.articleSources_map, function(value, key) {
          $scope.articleSources_map[key] = $scope.checkAllVisibleSources;
        });
        $scope.showAllSources();
      };
    }
  };
}]);
