import api from '@inbox/_app/api';
import { API_ROUTES } from '@inbox/_app/routes';

export function getPreviewUrl(
  articleId,
  strUrl,
  nlaLink,
  defaultPreviewMode,
  defaultPreview,
  mode = null
) {
  api.post(API_ROUTES.markArticleAsRead(articleId));

  let preferredMode = 'url';
  let result = '';

  if (defaultPreview === 'tool') {
    preferredMode = defaultPreviewMode;
  } else if (strUrl === null || strUrl.indexOf('http') !== 0) {
    preferredMode = defaultPreviewMode;
  }

  if (nlaLink) {
    preferredMode = 'nla';
  }

  if (mode) {
    preferredMode = mode;
  }

  if (preferredMode === 'url' && (strUrl === null || strUrl.indexOf('http') !== 0)) {
    preferredMode = defaultPreviewMode || 'plain_text';
  }

  switch (preferredMode) {
  case 'url':
    result = strUrl;
    break;
  case 'plain_text':
    result = `/preview/${articleId}`;
    break;
  case 'raw':
    result = `/raw-preview/${articleId}`;
    break;
  case 'nla':
    result = nlaLink;
    break;
  default:
    break;
  }

  return result;
}

function createContentAndOpenPreview(article, mode, shouldOpenEditor = false, type = "briefing") {
  if (article === null) {
    window.location.href = "/monitor";
    return;
  }

  const previewUrl = getPreviewUrl(
    article.id,
    article.url,
    article.nla_link,
    article.article_source_default_preview_mode,
    article.article_source_default_preview,
    mode
  );

  window.open(previewUrl, 'TulchanPreview', 'left=10,top=10');

  if (shouldOpenEditor) {
    let link = `/briefing_types_article_sources/content/new/${article.id}/`;

    if(type !== 'briefing' ) {
      link += `?type=${type}`;
    }

    window.location.href = link;
  }
}

export default createContentAndOpenPreview;
