var app = angular.module('app');

app.controller("OpenFirstBriefingController", function ($scope, $state) {

  (function redirectToFirstBriefing() {
    var briefingId;

    if ($scope.opened_briefings.length > 0) {
      briefingId = $scope.opened_briefings[0].id;
    }
    else if ($scope.closed_briefings.length > 0) {
      briefingId = $scope.closed_briefings[0].id;
    }

    if (briefingId) {
      $state.go("briefing_publisher.summaries", { briefingId: briefingId }, {location: 'replace'});
    }
  })();
});
