import { action, observable, computed } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';
import alertsStore from './alertsStore';

let timeout = 0;

class FiltersStore {
  @observable unreadEnabled = false;
  @observable flaggedEnabled = false;
  @observable
  search = {
    phrase: '',
    show: false,
  };

  @observable sectors = [];
  @observable isLoading = false;
  @observable error = null;
  @observable showFilters = false;

  @action
  toggleUnread = () => {
    this.unreadEnabled = !this.unreadEnabled;
    if (this.unreadEnabled) {
      alertsStore.currentAlert = {};
    }
    alertsStore.fetchAlerts();
  };

  @action
  toggleFlagged = () => {
    this.flaggedEnabled = !this.flaggedEnabled;
    if (this.flaggedEnabled) {
      alertsStore.currentAlert = {};
    }
    alertsStore.fetchAlerts();
  };

  @action
  toggleSearch = () => {
    this.search.show = !this.search.show;
  };

  @action
  fetchSectors = async () => {
    if (!isEmpty(this.sectors)) {
      return;
    }
    this.isLoading = true;
    try {
      const {
        data: { sectors },
      } = await api(API_ROUTES.sectors);
      this.sectors = sectors;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  toggleFilters = () => {
    this.showFilters = !this.showFilters;
  };

  @action
  onFilterChange = (id) => {
    const sector = find(this.sectors, { id: +id });
    sector.checked = !sector.checked;
    alertsStore.fetchAlerts();
  };

  @action
  onPhraseChange = (phrase) => {
    this.search.phrase = phrase;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      alertsStore.fetchAlerts();
    }, 500);
  };

  @action
  onPhraseDelete = () => {
    this.search.phrase = '';
    alertsStore.fetchAlerts();
  };

  @computed
  get checkedSectors() {
    return this.sectors.filter(sector => sector.checked);
  }
  @computed
  get checkedSectorsIds() {
    return this.checkedSectors.map(({ id }) => id);
  }

  @computed
  get filters() {
    const filters = {
      deleted: alertsStore.isTrash,
      unread: this.unreadEnabled,
      flagged: this.flaggedEnabled,
      phrase: this.search.phrase,
      sectors: this.checkedSectorsIds,
    };
    return pickBy(filters, val => val);
  }
}

export default new FiltersStore();
