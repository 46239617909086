import React, { useState } from "react";
import EditConfirm from "./EditConfirm";
import {
  Card,
  CardContent,
  Typography,
  Container,
  useMediaQuery,
  Checkbox,
  Grid,
} from "@material-ui/core";
import ActionsMenu from "./ActionsMenu";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { Draggable } from "react-beautiful-dnd";

const VioletCheckbox = withStyles({
  root: {
    color: '#74009D',
    '&$checked': {
      color: '#74009D',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DraggableTodo = (
  {
    todo,
    markComplete,
    delTodo,
    editTodo,
    markStar,
    index
  }
) => {
  const matches = useMediaQuery("(max-width: 768px)");
  const [editOpen, setEditOpen] = useState(false);
  const styles = {
    card: {
      marginTop: matches ? 20 : 35,
      background: "lightgray",
    },
    icon: {
      float: "right",
      paddingTop: "10px",
    },
    text: {
      textDecoration: todo.completed ? "line-through" : "none",
      wordBreak: "break-word",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      fontWeight: todo.starred ? "600" : "normal",
    },
  };
  return (
    <Container>
      <Draggable draggableId={todo._id} index={index}>
        {(p) => (
          <Card
            className="todo-card"
            variant="outlined"
            ref={p.innerRef}
            {...p.draggableProps}
            {...p.dragHandleProps}
            style={{
              ...styles.card,
              userSelect: "none",
              ...p.draggableProps.style,
            }}
          >
            <CardContent className="card-content" style={{ padding: "16px" }}>
              <Typography
                variant="h5"
                component="h2"
                className="todo-text"
              >
                <Grid container alignItems="center" justify="flex-start">
                  <Grid item>
                    <VioletCheckbox
                      checked={todo.completed}
                      color="primary"
                      style={{ marginRight: 5 }}
                      onClick={() => markComplete(todo)}
                      centerRipple={false}
                    />
                  </Grid>
                  <Grid item style={{ flex: 2 }}>
                    <div style={styles.text}>{todo.title}</div>
                  </Grid>
                  <Grid item>
                    <ActionsMenu
                      deleteTodo={() => delTodo(todo)}
                      setEditOpen={setEditOpen}
                      todo={todo}
                      markStar={markStar}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container style={{ marginTop: 10 }}>
                      <Grid item xs={12} style={{ fontSize: 14, textAlign: "left", marginLeft: 10, color: "#565656" }}>
                        Created by: {todo.createdBy},
                        at {moment(todo.createdAt).format("MMMM Do YYYY, h:mm a").toString()}
                      </Grid>
                      {todo.completed && todo.completedBy &&
                      <Grid item xs={12}
                        style={{ fontSize: 14, textAlign: "left", marginLeft: 10, color: "#565656", marginTop: 7 }}>
                        Completed by {todo.completedBy},
                        at {moment(todo.completedAt).format("MMMM Do YYYY, h:mm a").toString()}
                      </Grid>}
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        )}
      </Draggable>
      <EditConfirm
        yes={(val) => {
          setEditOpen(false);
          setTimeout(() => {
            editTodo(todo, val);
          }, 200);
        }}
        open={editOpen}
        close={() => setEditOpen(false)}
        value={todo.title}
      />
    </Container>
  );
};

export default DraggableTodo;
