import React, { Component, Fragment } from 'react';
import AsyncSelect from 'react-select/async';
import isEmpty from 'lodash/isEmpty';

const styles = {
  control: styles => ({
    ...styles,
    width: '100%',
    borderRadius: 10,
  }),
};

const components = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

export default class AsyncMultiSelect extends Component {
  onChange = (values) => {
    this.props.onChange(isEmpty(values) ? '' : values);
  };
  render() {
    const { loadOptions, meta, autoFocus } = this.props;

    return (
      <Fragment>
        <AsyncSelect
          autoFocus={autoFocus}
          styles={styles}
          components={components}
          isMulti
          cacheOptions
          defaultOptions
          {...this.props}
          loadOptions={loadOptions}
          onChange={this.onChange}
        />
        {(meta.error || meta.submitError)
          && meta.touched && (
          <span className="error-message">
            {meta.error || meta.submitError}
          </span>
        )}
      </Fragment>
    );
  }
}
