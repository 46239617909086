import React from "react";

const styles = {
  root: {
    minWidth: "100%",
    padding: "0px 11px 11px 24px",
    fontStyle: "italic",
    fontSize: ".75rem",
    lineHeight: "1.25rem",
    cursor: "pointer",
  },
};

export default function ArticleHighlights(props) {
  return (
    <div
      onClick={props.preview}
      dangerouslySetInnerHTML={{ __html: props.highlights[0] || "" }}
      style={styles.root}
    ></div>
  );
}
