import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Flex, Box } from 'rebass';
import Button from '@inbox/_common/components/Button';
import CardContainer from '@inbox/_common/components/CardContainer';
import Loader from '@inbox/_common/components/Loader';
import FilterContainer from './FilterContainer';

@observer
class SectorFilters extends Component {
  componentDidMount() {
    this.props.filtersStore.fetchSectors();
  }

  render() {
    const {
      isLoading,
      sectors,
      onFilterChange,
      toggleFilters,
    } = this.props.filtersStore;
    return (
      <FilterContainer heading="Sort by">
        <Flex justifyContent="space-between">
          <Button width={1} mr={9} disabled>
            Sector
          </Button>
          <Button width={55} onClick={toggleFilters}>
            x
          </Button>
        </Flex>
        <CardContainer width={1} px={15} py={30} mt={10}>
          {isLoading && <Loader />}
          {sectors.map(({ name, id, checked }, i) => (
            <Box pb={10} key={`sectorItemFilter${i}`}>
              <label
                className="checkbox-container list-table__label"
                htmlFor={id}
              >
                <input
                  type="checkbox"
                  defaultChecked={checked}
                  value={id}
                  id={id}
                  onChange={e => onFilterChange(e.target.value)}
                />
                <span className="checkbox-container__checkmark" />
                {name}
              </label>
            </Box>
          ))}
        </CardContainer>
      </FilterContainer>
    );
  }
}

SectorFilters.protoTypes = {
  fetchSectors: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sectors: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
};

export default SectorFilters;
