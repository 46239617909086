import API from "../../../inbox/_app/api";

angular
  .module("app")
  .factory("ClientsFactory", function ($http, $q, $window, Config) {
    const getSectors = function () {
      return $http
        .get(Config.shibiUrl + "/sectors.json")
        .then(function (response) {
          return response.data.sectors;
        });
    };

    const getClientSections = function (client_id) {
      const start = performance.now();
      return $http
        .get(
          Config.shibiUrl +
            "/content_containers/" +
            client_id +
            "/separated_client_section.json"
        )
        .then(function (response) {
          $window._StatHat.push([
            "_trackValue",
            Config.stathat.timing.get_client_sections,
            performance.now() - start,
          ]);
          return response.data.clients;
        });
    };

    const getClientSectionsList = function (client_id) {
      const start = performance.now();
      return $http
        .get(Config.shibiUrl + "/client_sections.json?client_id=" + client_id)
        .then(function (response) {
          $window._StatHat.push([
            "_trackValue",
            Config.stathat.timing.get_client_sections_list,
            performance.now() - start,
          ]);
          return response.data.client_sections;
        });
    };

    const getClientUsers = function (client_id) {
      const start = performance.now();
      return $http
        .get(Config.shibiUrl + "/content_containers/" + client_id + "/users")
        .then(function (response) {
          $window._StatHat.push([
            "_trackValue",
            Config.stathat.timing.get_client_users,
            performance.now() - start,
          ]);
          return response.data;
        });
    };

    const updateClientSection = function (data, action, position) {
      if (action === "edit" && data.client_id && data.id) {
        return $http
          .patch(
            Config.shibiUrl +
              "/content_containers/" +
              data.client_id +
              "/client_sections/" +
              data.id,
            { client_section: data }
          )
          .then(function (response) {
            return response;
          });
      }
      if (action === "create" && data.client_id) {
        // add Client section on last position
        data.position = position || 0;
        return $http
          .post(
            Config.shibiUrl +
              "/content_containers/" +
              data.client_id +
              "/client_sections",
            { client_section: data }
          )
          .then(function (response) {
            return response;
          });
      }
    };

    const getClients = function () {
      const start = performance.now();
      return $http
        .get(Config.shibiUrl + "/content_containers.json")
        .then(function (response) {
          $window._StatHat.push([
            "_trackValue",
            Config.stathat.timing.get_clients,
            performance.now() - start,
          ]);
          return response.data.clients;
        });
    };

    const getClient = function (client_id) {
      const start = performance.now();
      return $http
        .get(Config.shibiUrl + "/content_containers/" + client_id + ".json")
        .then(function (response) {
          $window._StatHat.push([
            "_trackValue",
            Config.stathat.timing.get_client,
            performance.now() - start,
          ]);
          return response.data;
        })
        .catch(() => {
          console.error(`Content container ID ${client_id} is undefined!`);
          window.location.href = "/monitor";
        });
    };

    const getClientsWithAndWithoutApp = function () {
      return $http
        .get(Config.shibiUrl + "/content_containers/with_and_without_app.json")
        .then(function (response) {
          return response.data.clients;
        });
    };

    const getSector = function (id) {
      return $http
        .get(
          Config.shibiUrl +
            "/briefing_coverage_screen_merged/get_sector_companies?id=" +
            id
        )
        .then(function (res) {
          return res.data.sector;
        });
    };

    const getAllSegments = function () {
      return $http
        .get(Config.shibiUrl + "/briefing_coverage_screen_merged")
        .then(function (res) {
          const allSegments = res.data;
          const sectors = allSegments.segments.pop();
          allSegments.segments = allSegments.segments.concat(sectors.sectors);
          return allSegments;
        });
    };

    const getClientsWithPublishedAndUnpublishedCoverages = function () {
      return $http
        .get(
          Config.shibiUrl +
            "/content_containers/published_and_unpublished_clients.json"
        )
        .then(function (res) {
          return res.data;
        });
    };

    const getFirstClientForCoverages = function () {
      return $http
        .get(
          Config.shibiUrl +
            "/content_containers/first_client_for_coverages.json"
        )
        .then(function (response) {
          return response.data;
        });
    };

    const createClient = function (client_name, sector_name) {
      let new_sector_name = undefined;
      if (sector_name.originalObject) {
        new_sector_name = { name: sector_name.originalObject.name };
      } else {
        new_sector_name = sector_name;
      }
      return $http
        .post(Config.shibiUrl + "/content_containers.json", {
          client: { name: client_name, sector: new_sector_name },
        })
        .catch(function (response) {
          return $q.reject(response.data.errors);
        });
    };

    const destroyClient = function (client_id) {
      return $http.delete(
        Config.shibiUrl + "/content_containers/" + client_id + ".json"
      );
    };

    const updateClient = function (client_id, client_name, sector_name) {
      let new_sector_name = undefined;
      if (sector_name.originalObject) {
        new_sector_name = { name: sector_name.originalObject.name };
      } else {
        new_sector_name = sector_name;
      }
      return $http
        .patch(Config.shibiUrl + "/content_containers/" + client_id + ".json", {
          client: { name: client_name, sector: new_sector_name },
        })
        .catch(function (response) {
          return $q.reject(response.data.errors);
        });
    };

    const searchClient = function (query) {
      return $http
        .get(
          Config.shibiUrl +
            "/briefing_coverage_screen_merged/search?query=" +
            query
        )
        .then(function (res) {
          const allSegments = res.data;
          const sectors = allSegments.segments.pop();
          allSegments.segments = allSegments.segments.concat(sectors.sectors);
          return allSegments;
        });
    };

    const getLastEmailedAt = ({ emails }) => {
      return API.post(`${Config.statrafUrl}/api/v1/last_emailed_at`, {
        emails,
      });
    };

    return {
      getSectors,
      getClientSections,
      getClientSectionsList,
      getClients,
      getClient,
      getClientUsers,
      createClient,
      destroyClient,
      updateClient,
      getFirstClientForCoverages,
      getClientsWithAndWithoutApp,
      getAllSegments,
      getClientsWithPublishedAndUnpublishedCoverages,
      updateClientSection,
      searchClient,
      getLastEmailedAt,
      getSector,
    };
  });
