angular.module("app").
  controller("RequestPasswordResetController", function ($scope, $http, Config) {

    $scope.sendResetEmail = function () {
      $http.post(Config.shibiUrl + '/analysts/password.json', { analyst: { email: $scope.email || "" } }).
        then(function () {
          $scope.emailSent = $scope.email;
          $scope.email = "";
        });
    };
  });
