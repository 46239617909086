import each from 'lodash/each';
import chain from 'lodash/chain';
import find from 'lodash/find';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';

angular.module("app").directive('keywordPopup', ['$compile', '$state', 'ArticleSourcesFactory', 'SearchPhrasesFactory', 'ngToast', function($compile, $state, ArticleSourcesFactory, SearchPhrasesFactory, ngToast) {
  return {
    restrict: 'AE', //attribute or element
    template: require("./keyword_popup.html.haml"),
    replace: true,
    link: function($scope, elem, attr, ctrl) {
      $scope.popupOpened = false; // popup closed on init
      $scope.search_phrase = {};
      $scope.showCreateButton = ("true" == attr.showCreateButton);
      $scope.availableSources = [];
      $scope.for_public_sections = false;
      $scope.for_private_sections = false;
      $scope.showCodes = false;


      var close = function() {
        $scope.buttonIsDisabled = false;
        $scope.popupOpened = false;
        $scope.loading = false;
        $scope.search_phrase = {};
        $scope.clientKeywordForm.$setPristine();
        angular.element('body').removeClass('popup-opened');
        $scope.for_public_sections = false;
        $scope.for_private_sections = false;
      };

      var getPhraseSources = function(phrase_sources) {
        $scope.article_source_types = phrase_sources;
        if ($scope.new) {
          each($scope.article_source_types, function (ps) {
            ps.is_checked = true;
          });
        }
        $scope.loading = false;
      };

      // open popup on edit
      $scope.$on('monitor.editPhrase', function(event, searchPhrase) {
        $scope.new = false;

        $scope.search_phrase = { ...searchPhrase };
        $scope.showCodes =
          searchPhrase.ric_codes ||
          searchPhrase.ric_keywords ||
          searchPhrase.factiva_codes ||
          searchPhrase.factiva_keywords;
        $scope.for_public_sections = $scope.search_phrase.for_public_sections;
        $scope.for_private_sections = $scope.search_phrase.for_private_sections;
        $scope.open(false);
      });

      $scope.open = function(isNew) {
        $scope.client = {id: attr.clientId || $scope.$parent.clientId, name: attr.clientName || $scope.$parent.sourceName};
        $scope.loading = true;
        $scope.new = isNew;
        angular.element('body').addClass('popup-opened');

        var clientName = $scope.client.name || '';
        if ($scope.new) {
          $scope.popupTitle = "NEW KEYWORD" + (clientName != "" ? " FOR " + clientName : "");
          ArticleSourcesFactory.getArticleSourceTypes().then( function (phrase_sources) {
            getPhraseSources(phrase_sources);
            updateCheckboxes();
          });
        } else if ($scope.search_phrase.id) {
          $scope.popupTitle = "EDIT " + $scope.search_phrase.phrase + " KEYWORD" + (clientName != "" ? " FOR " + clientName : "");
          SearchPhrasesFactory.getArticleSourceTypesForSearchPhrase($scope.search_phrase.id).then( function(phrase_sources) {
            getPhraseSources(phrase_sources);
            updateCheckboxes();
          });
        } else {
          close();
          return;
        }

        $scope.popupOpened = true;
      };

      $scope.close = function() {
        close();
      };

      $scope.submit = function() {
        $scope.loading = true;

        var article_source_type_ids = map(filter($scope.article_source_types, function(item) {
          return item.is_checked === true;
        }), "id");

        if ($scope.new) {
          var client_id = $scope.client.id
          SearchPhrasesFactory.createSearchPhraseForClient(client_id, $scope.search_phrase, article_source_type_ids, $scope.for_private_sections, $scope.for_public_sections).then(function () {
            $scope.$emit('search_phrases.reload', {id: null, phrase: $scope.search_phrase.phrase});
            $scope.errorMessage = null;
            close();
            ngToast.create('Keyword added');
          }).catch(function (errors) {
            $scope.errorMessage = errors;
            $scope.loading = false;
          });
        } else {
          SearchPhrasesFactory.updateSearchPhrase($scope.search_phrase, article_source_type_ids, $scope.for_private_sections, $scope.for_public_sections).then(function() {
            $scope.$emit('search_phrases.reload', $scope.search_phrase);
            $scope.errorMessage = null;
            close();
            ngToast.create('Keyword updated');
          }).catch(function (errors) {
            $scope.errorMessage = errors;
            $scope.loading = false;
          });
        }
      };

      var updateCheckboxes = function() {
        $scope.checkAllSources = $scope.checkAllSources || [];
        var none_checked = (undefined === find($scope.article_source_types, {is_checked: true}));
        $scope.checkAllSources['None'] = none_checked;
        var all_checked = none_checked != true && (undefined === find($scope.article_source_types, {is_checked: false}));
        $scope.checkAllSources['All'] = all_checked;

        let categories = map($scope.article_source_types, (sourceType) => sourceType.category);
        categories = uniq(categories);
        if (!$scope.availableSources.length) {
          each(categories, cat => {
            let label = cat;
            if (label) {
              label = cat.toLowerCase();
              label = label.charAt(0).toUpperCase() + label.slice(1);
            }
            $scope.availableSources.push({ name: cat, label });
          });
        }
        each(categories, (category) => {
          let types = filter($scope.article_source_types, (type) => type.category === category);
          types = find(types, (type) => type.is_checked === false);
          $scope.checkAllSources[category] = none_checked != true && (all_checked || undefined === types);
        });
        $scope.buttonIsDisabled = none_checked;
      };

      $scope.selectAllVisibleSources = function(category) {
        var checked = $scope.checkAllSources[category];

        each($scope.article_source_types, function (item) {
          if (category == 'All' || category == 'None' || item.category == category) {
            item.is_checked = checked;
          }
        });
        updateCheckboxes();
      };

      $scope.stateChanged = function () {
        updateCheckboxes();
      };

      $scope.SectionsCheckboxes = function (event) {
        if (event.target.id === 'for_public_sections') {
          $scope.for_public_sections = true;
          $scope.for_private_sections = false;
        }
        if (event.target.id === 'for_private_sections') {
          $scope.for_public_sections = false;
          $scope.for_private_sections = true;
        }
      };
    },
  };
}]);
