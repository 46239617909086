import React from "react";
import CountSection from "./CountSection";
import DatesSection from "./DatesSection";

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 14px 14px 24px",
    fontSize: ".625rem",
    lineHeight: "1rem",
    color: "#505a6b",
  },
};

export default function ArticleFooter(props) {
  const {
    article_source_title,
    author,
    pubdate,
    created_at,
    pubdate_time_diff_status,
    summaries_count,
    coverage_count,
  } = props.article;
  return (
    <div style={styles.root}>
      <DatesSection createdAt={created_at} pubdate={pubdate} pubDateTimeDiffStatus={pubdate_time_diff_status} />
      <div
        style={{
          width: "calc(100% - 606px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{flex: "50%"}}>{article_source_title}</span>
        <span style={{flex: "50%"}}>{author}</span>
      </div>
      <CountSection
        summariesCount={summaries_count}
        coverageCount={coverage_count}
      />
    </div>
  );
}
